import { CommonService } from '../../common/common.service';
import { TokenStorageService } from './../token/token-storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  param: any;
  fromCart = false
  currentStatus = false
  constructor(private router: Router, private tokenStorageService: TokenStorageService,
    private commonService: CommonService) { }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const isLoggedIn = true; // ... your login logic here
    const token = this.tokenStorageService.getToken();
    localStorage.setItem('fromCart', "false")
    debugger
    if (token == null) {
      if(state.url == '/cookie-cart'){
        console.log(state.url, 'ghhhh')
        this.currentStatus = true
        localStorage.setItem('fromCart', "true")
      }else if(state.url.startsWith('order/details')){
        console.log(state.url, 'ghhhh')
        this.currentStatus = true
        localStorage.setItem('fromCart', "true")
      }
      else if(this.currentStatus == true && state.url == '/login'){
        localStorage.setItem('fromCart', "true")
      }
      else{
        this.currentStatus = false
      }
      this.commonService.loggedIn.next(false);
      return true;
    }
    // else if (token == null && state.url == '/products') {
    //   this.commonService.loggedIn.next(false);
    //   return true;
    // }
    // else if (token == null && state.url == '/cookie-cart') {
    //   this.commonService.loggedIn.next(false);
    //   console.log(state.url)
    //   return true;
    // }
    // else if (token != null && state.url == '/home') {
    //   this.commonService.loggedIn.next(true);
    //   this.router.navigate(["/edit-profile"])
    //   return false
    // }
    else {
      this.commonService.loggedIn.next(true);
      return true
    }
  }


}
