import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import * as _ from 'underscore';
import * as uuid from 'uuid';

const myId = uuid.v4();
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss', './../breadcrumb/breadcrumb.component.css']
})
export class ProductsComponent implements OnInit, AfterViewInit {
  cartForm: FormGroup;
  productArray: any = [];
  counter
  showItem = false;
  items
  disable_qty = false
  min_order_qty;
  isChecked = false;
  boxId
  colors
  value
  products = []
  cartProductList = [];
  cartItems;
  cartLength = 0
  selectedColorImg
  colorSelected = false;
  bgColor: string = 'grey';
  showMore = false
  @ViewChild('target') targetEl: ElementRef;
  index: string;
  baseUrl: string;
  myId: any;
  is_gift = false;
  loader = false
  selectBox = false;
  id: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.baseUrl = location.origin
    this.id = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.getProducts();
    console.log(this.productArray)
    console.log(this.id)

    this.getCartForm();
    if (JSON.parse(localStorage.getItem('cart'))) {
      this.cartLength = JSON.parse(localStorage.getItem('cart')).length
      this.getCartItems();
    }
  }
  ngAfterViewInit(): void {

  }
  getCartForm() {
    this.cartForm = this.fb.group({
      color: ['']
    })
  }
  getProducts() {
    this.api.getProduct().subscribe(re => {
      this.productArray = re['data']['Items']
      this.loader = false
      this.productArray = _.sortBy(this.productArray, (o) => {
        return o.cookie_number
      })
      console.log(this.productArray)
      if (this.id) {
        console.log(this.productArray)
        let product = _.find(this.productArray, (o) => {
          return o.id == this.id
        })
        this.selectedProduct(product)
        console.log(product, 'product')
      }
    })
  }
  getCartItems() {
    debugger
    this.cartProductList = JSON.parse(localStorage.getItem('cart'));
    this.api.getCart().subscribe((resp) => {
      const online_cart_items = resp['data'];
      if (this.cartProductList?.length == 0 || this.cartProductList?.length == undefined || this.cartProductList?.length == null) {
        this.cartProductList = online_cart_items;
      } else if (this.cartProductList != null && online_cart_items?.length != 0) {
        const newdata = online_cart_items;
        const filteredItems = this.cartProductList.filter((x) => !newdata.find((y) => y.id === x.id));
        console.log(filteredItems, 'filtereditems');
        const mergedData = filteredItems.concat(newdata);
        this.cartProductList = mergedData
    // this.cartProductList = JSON.parse(localStorage.getItem('cart'));
    // this.api.getCart().subscribe((resp) => {
    //   let online_cart_items = resp['data']
    //   if (this.cartProductList?.length == 0 || this.cartProductList?.length == undefined || this.cartProductList?.length == null) {
    //     this.cartProductList = online_cart_items
    //   } else if (this.cartProductList != null && online_cart_items?.length != 0) {
    //     let newdata: any = []
    //     let localdata: any = []

    //     newdata = online_cart_items
    //     const filteredItems = this.cartProductList.filter(x => newdata.some(y => y.id !== (x.id)));

    //     console.log(filteredItems,'filterItems');
    //     let test = filteredItems.concat(newdata)
    //     this.cartProductList = test
        // let test = this.cartProductList.concat(newdata)
        // this.cartProductList = test
        

      }
      
    })
  }
  selectedProduct(product: any) {
    this.showItem = true;
    this.items = product
    this.counter = parseInt(this.items.min_qty)
    this.selectedColorImg = product.image
    console.log(product, 'product')
    this.cd.detectChanges();
    document.querySelector('#cartTarget').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }
  increment(count) {
    debugger
    this.counter = count
    this.counter += 1;
  }
  decrement(count) {
    console.log(count)
    if (this.counter > this.items.min_qty) {
      this.counter = count
      this.counter -= 1;
    }
  }
  changeSelection(colors: any, value) {
    console.log(colors, 'colors')
    this.colorSelected = true;
    this.selectBox = false;
    this.boxId = colors.name
    this.selectedColorImg = colors.image_url
    this.bgColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }
  alert() {
    this.selectBox = true;
    this.toastr.error('Please choose a color')
  }
  continue() {
    this.selectBox = false;
    this.colorSelected = false;
    this.boxId = ''
  }
  addToCart(product) {
    this.myId = uuid.v4();
    console.log(this.myId, 'myId')
    console.log('prod', product)
    let total = product.price * this.counter
    let local_storage;
    let itemsInCart = []
    debugger
    let cart_items = {
      "PK": product.PK,
      "SK": product.SK,
      "customize": [],
      "quantity": this.counter,
      "box_color": this.boxId,
      "id": this.myId,
      "min_qty": product.min_qty,
      "is_gift": this.is_gift,
      "box_img_url": this.selectedColorImg,
      "flavours": product.flavours,
      "product": {
        "id": product.id,
        "name": product.name,
        "price": product.price,
        "item_total": total.toFixed(2),
        "image": this.selectedColorImg,
        "cookie_number": product.cookie_number
      }
    }
    if (localStorage.getItem('cart') == null) {
      local_storage = [];
      console.log("LOCALSTORAGE NULL", JSON.parse(localStorage.getItem('cart')));
      itemsInCart.push(cart_items);
      localStorage.setItem('cart', JSON.stringify(itemsInCart));
    }
    else {
      local_storage = JSON.parse(localStorage.getItem('cart'));
      console.log("LOCAL STORAGE HAS ITEMS", JSON.parse(localStorage.getItem('cart')));
      // for (var i in local_storage) {
      //   console.log(local_storage[i].product.id );
      //   if (product.id === local_storage[i].product.id ) {
      //     if(this.boxId === local_storage[i].box_color){
      //       local_storage[i].quantity = this.counter
      //       local_storage[i].product.item_total = this.counter * product.price
      //       local_storage[i].product.box_color = this.boxId
      //       console.log('same product! index is ', i);
      //       cart_items = null;
      //       break;
      //     }

      //   }
      // }
      if (cart_items) {
        itemsInCart.push(cart_items);
      }
      local_storage.forEach(function (item) {
        itemsInCart.push(item);
      })
      this.cartProductList = itemsInCart
      console.log(this.cartProductList, 'list')
      localStorage.setItem('cart', JSON.stringify(itemsInCart));
    }
    this.colorSelected = false;
    this.boxId = '';
    this.toastr.success('Product added successfully')
    this.showMore = true
    this.ngOnInit();

  }

  remove(item) {
    debugger
    item = item;
    console.log("Deleting : ", item);
    let shopping_cart;
    shopping_cart = JSON.parse(localStorage.getItem('cart'));
    for (let i in shopping_cart) {
      if (item.id == shopping_cart[i].id) {
        this.index = i;
        console.log(this.index);
      }
    }
  }
  //deleting cart item
  deleteItem(index) {
    let shopping_cart;
    shopping_cart = JSON.parse(localStorage.getItem('cart'));
    shopping_cart.splice(index, 1);
    console.log("shopping_cart ", shopping_cart);
    localStorage.setItem('cart', JSON.stringify(shopping_cart));
    if (shopping_cart.length == 0) {
      this.cartLength = 0
    }
    this.getCartItems();
    this.toastr.success('Deleted product successfully')
  }
  cartItem() {
    debugger
    let user = localStorage.getItem('loginEmail')
    if(user){
      this.router.navigate(['cookie-cart'])
    }else{
      this.router.navigate(['login'])
    }
   
  }
}


