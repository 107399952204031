<div class="container">
  <div class="row mt-3">
    <div class="col-md-12">
      <div>
        <button type="button" class="btn m-3 back-btn-icon" routerLink="/login" routerLinkActive="router-link-active">
          <span class="material-icons-outlined btn-snape-white back-icon">
            chevron_left
          </span>
          <span class="back-btn-span">Back</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div class="login-image">
        <img src="../../../assets/loginimg.png">
      </div>
    </div>
    <div class="col-md-6 logintxt">
      <!-- Forget password -->
      <div class="auth-ui">
        <div class="ml-3">
          <h5 class="snape-red">Forgot Password?</h5>
          <p>Enter the email address associated with your account</p>
        </div>

        <form class="pt-2" [formGroup]="forgotPasswordForm">
          <div class="position-relative form-group">
            <div class="col-md-12">
              <input formControlName="email" id="inputEmail" placeholder="Enter email address" required="" type="email"
                class="form-control">
              <span class="text-danger"
                *ngIf="(forgotPasswordForm.controls.email.touched || submitted) && forgotPasswordForm.controls.email.errors?.required">
                Email is required
              </span>
            </div>
          </div>
          <div class="position-relative form-group">
            <div class="d-flex justify-content-center col-md-12 mt-4">
              <button type="submit" (click)="send()" class="btn btn-snape-bak btn-block"
                [disabled]="forgotPasswordForm.invalid">Send</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>