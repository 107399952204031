import { AfterViewInit, Component, OnInit, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TokenStorageService } from '../../common/token/token-storage.service';
import 'fabric';
import * as _ from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
declare const fabric: any;
import * as uuid from 'uuid';
import { ViewportScroller } from '@angular/common';
import { CompressImgageService } from 'src/app/compress-imgage.service';
import { take } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr';
//import { Zlib } from '@angular/common'


@Component({
  selector: 'app-add-cookie',
  templateUrl: './add-cookie.component.html',
  styleUrls: ['./add-cookie.component.scss']
})
export class AddCookieComponent implements OnInit, AfterViewInit {

  @ViewChild('chooseFlav') chooseFlav;
  @ViewChild('selectnew') selectnew;
  @ViewChild('modifyimage') modifyimage;

  requestForCostShipment = false;
  num = 0
  hideCustomizeACookieText = false;
  hideFullCookiePic = true;
  hideEditableCookiePic = true;
  loader = false
  activateButton = false
  arrayCheck = false
  showFlavour: boolean = false;
  FlavourArray
  cookieBoxForm: FormGroup;
  selectedFlavourArray
  SelectedProductId
  flavourImg: any;
  showImage = false
  localStorage: any;
  selectedProduct
  cookie_box_no
  totalCookieBoxNo: any;
  boxNo: any;
  canvas: any;
  isLoggedIn: boolean
  onlineCartItem: any;
  cookie_quantity: any;
  dataUrl
  customized: any;
  sub_arr
  arr
  boxArray
  flavourImages = "../../../assets/adDesign.PNG"
  boxIndex: any;
  cookie_per_box: any;
  mirror: any[];
  cookieBoxArray = []
  copyCookieArray = []
  firstCookie = false
  firstBox = false
  textAdded = false
  canvasText: string = 'Please add an image'
  i: any;
  j: any;
  selectedImage: any;
  showSave = false
  count: number = 0;
  files
  cookieImg
  customImg: any;
  boxImage: any;
  boxImageUrl: any;
  cookieCount = { count: 0 };
  title = 'angular-editor-fabric-js';
  cookieForm: FormGroup;
  public textString: string;
  imageSelected: boolean = false;
  changeImages = false
  showText = false;
  selected: any;
  fontSize;
  opacity;
  brightness;
  contrast;
  saturation;
  fontWeight;
  fill;
  textEditor = false;
  showCanvas = false;
  isCheckedfirst = false;
  isCheckedbox = false
  props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    brightness: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };
  images: Object;
  index: any;
  editImage = false;
  flavorName: any;
  flavorImage: any;
  deleteIcon: string;
  cornerimg: HTMLImageElement;
  cornerSize: any;
  no_of_cookies: any;
  cookieBox: any[];
  cookieBoxQty: any;
  boxNumber: any[];
  enableCartBtn = false;
  showRemoveBtn = false
  favBtn = false;
  enableApply = false
  myImages = []
  objectKeys = Object.keys;
  items = { keyOne: 'value 1', keyTwo: 'value 2', keyThree: 'value 3' };
  temp_images: any;
  smileys;
  quotes: any[];
  categories
  selectedKey = 'myImages'
  convertedImages = []
  redesignCanvasImage = false
  selectedIndex = false;
  base64Image: string;
  localCart: any
  sum: number;
  constructor(
    private api: CommonService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private token: TokenStorageService,
    private modalService: NgbModal,
    private viewportScroller: ViewportScroller,
    private cd: ChangeDetectorRef,
    private compressImage: CompressImgageService,
    private toastr: ToastrService
  ) { }
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    window.opener.location.reload();
  }
  ngOnInit(): void {
    debugger
    // this.localStorage = JSON.parse(localStorage.getItem('cart'))
    // this.api.getCart().subscribe((resp) => {
    //   let online_cart_items = resp['data']
    //   if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
    //     this.localStorage = online_cart_items
    //   } else if (this.localStorage != null && online_cart_items?.length != 0) {
    //     let newdata: any = []
    //     newdata = online_cart_items
    //     const filteredItems = this.localStorage.filter(x => newdata.some(y => y.id !== (x.id)));
    //     console.log(filteredItems, 'filterItems');
    //     let test = filteredItems.concat(newdata)
        //let test = this.localStorage.concat(newdata)
        // this.localStorage = test
        this.localStorage = JSON.parse(localStorage.getItem('cart'));
        this.api.getCart().subscribe((resp) => {
          const online_cart_items = resp['data'];
          if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
            this.localStorage = online_cart_items;
          } else if (this.localStorage != null && online_cart_items?.length != 0) {
            const newdata = online_cart_items;
            const filteredItems = this.localStorage.filter((x) => !newdata.find((y) => y.id === x.id));
            console.log(filteredItems, 'filteredItems');
            const mergedData = filteredItems.concat(newdata);
            this.localStorage = mergedData
      }
      this.startingFunction()
    })
    console.log(this.isCheckedfirst, 'checked')
    this.canvas = new fabric.Canvas('canvas', { selection: false, targetFindTolerance: 0 });
    console.log(this.boxIndex, 'dfs')
    this.deleteIcon = "https://cdn1.iconfinder.com/data/icons/ui-color/512/Untitled-12-128.png";
    this.cornerimg = new Image();
    this.cornerimg.src = this.deleteIcon;
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.6,
      offsetY: 16,
      cursorStyle: 'pointer',
      mouseUpHandler: this.deleteObject,
      // render: this.renderIcon,
      cornerSize: 24,
      hasControls: true,
      customiseCornerIcons: this.deleteIcon,
      renderOnAddRemove: true,
    });
  }
  startingFunction() {

    this.getFlavours();
    this.getLocalCartItems();
    this.loggedUser();
    this.getcartItem();
    this.counter(this.i)
    this.createCookieBoxArray();
    this.showBoxImage();
    this.checkImages();
    this.cookieBoxForm = this.fb.group({
      flavour: ['']
    })
    this.getTemplateImages();
    this.getFavImages();
    this.getMyImages();
    this.getTempImg();
    this.showImageOrText();
    this.SelectedProductId = this.activatedRoute.snapshot.paramMap.get('id')
    this.cookieForm = this.fb.group({
      textString: [''],
      filesource: [''],
      image: '',
      brightness: [''],
      contrast: [''],
      fontFamily: [''],
      fontSize: [''],
      opacity: [''],
      fill: [''],
      BgFill: ['#FFFFFF'],
      saturation: ['']
    })
    console.log(this.hideCustomizeACookieText, this.hideEditableCookiePic, this.hideFullCookiePic)
  }
  ngAfterViewInit() {

  }


  counter(i: number) {
    return new Array(i);
  }
  deleteObject(eventData, transform) {
    var target = transform.target;
    var canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  }
  renderIcon(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(this.cornerimg, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  getTempImg() {
    this.api.getTemplateImage().subscribe(img => {
      this.temp_images = img['data']
      this.categories = this.groupBy(this.temp_images, temp => temp.category);
    })
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  key(category_name) {
    this.selectedKey = category_name
    this.cd.detectChanges();
    document.querySelector('#' + this.selectedKey).scrollIntoView({ behavior: 'smooth' })
  }
  getFavImages() {
    if (this.isLoggedIn) {
      this.api.getFavouriteImage().subscribe(img => {
        this.favBtn = true;
        this.images = img['data']['Items']
        console.log(this.images, 'fav fav')
      })
    }
  }
  getMyImages() {
    this.myImages = JSON.parse(localStorage.getItem('image'))
  }
  showImageOrText() {
    this.activatedRoute.params.subscribe(item => {
      console.log(item.queryParams, item, 'gg')
      if (item.queryParams == "true") {
        this.showText = true;
        this.imageSelected = true
      }
      else {
        this.index = item.queryParams
      }
    })
  }
  getTemplateImages() {
    this.api.getTemplateImages().subscribe(item => {
      console.log(item, 'item')
    })
  }
  handleDrop(e: any) {

    this.editImage = true;
    this.showText = false;
    this.imageSelected = true;
    this.showImage = false
    this.showRemoveBtn = true
    console.log(e, 'e')
    const file = e.dataTransfer.files[0];
    console.log(file, 'file')
    const reader = new FileReader();

    reader.onload = (imgFile: any) => {
      console.log(imgFile, 'imgFile');
      const data = imgFile.target["result"];
      console.log(data, 'data')
      fabric.Image.fromURL(data,
        (img: {
          set: (arg0: { left: number; top: number; angle: number; }) =>
            { (): any; new(): any; scale: { (arg0: number): any; new(): any; }; };

        }) => {
          console.log(img, 'img')
          let oImg = img.set({
            left: 15,
            top: 12,
            angle: 0
          }).scale(1);
          oImg.scaleToHeight(275);
          oImg.scaleToWidth(275);
          this.canvas.add(oImg).renderAll();
          this.selectItemAfterAdded(oImg);
          var dataURL = this.canvas.toDataURL({ format: 'png', quality: 0.8 });
        });
    };
    reader.readAsDataURL(file);
    return false;
  }

  uploadImage(event: any) {

    console.log(this.showCanvas, 'canv')
    this.editImage = true;
    this.showText = false;
    this.imageSelected = true;
    this.showImage = false
    this.showRemoveBtn = true
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.compressImage.compress(file)
        .pipe(take(1))
        .subscribe(compressedImage => {
          console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
          // now you can do upload the compressed image 
          const reader = new FileReader();

          reader.onload = (e: any) => {
            const data = e.target.result;
            fabric.Image.fromURL(data, img => {
              img.set({
                left: 100,
                top: 350,
                angle: 0,
                backgroundColor: "white"
              }).scale(1);
              img.scaleToHeight(270);
              img.scaleToWidth(270);
              console.log(data, 'da')
              this.canvas.add(img);
              this.canvas.sendToBack(img);
              this.selectItemAfterAdded(img);
              this.canvas.centerObject(img);
            });
          };

          reader.readAsDataURL(compressedImage);
        })

    }
  }
  //remove object
  remove() {
    console.log(this.canvas.getActiveObject, 'y')
    this.canvas.remove(this.canvas.getActiveObject());
    if (this.canvas.toJSON().objects.length != 0) {
      console.log('canvas is empty');
      this.editImage = true
      this.imageSelected = true;
    }
    else {
      this.editImage = false;
      this.imageSelected = false;
      this.showImage = true;
      this.showText = false;
      this.textAdded = false;
      this.hideCustomizeACookieText = true
      this.hideEditableCookiePic = true
      this.hideFullCookiePic = false
      console.log(this.hideCustomizeACookieText, this.hideEditableCookiePic, this.hideFullCookiePic)
    }

  }
  //set brightness
  setBrightness() {
    console.log(this.cookieForm.controls.brightness.value, 'brightness');
    this.applyFilter(5, new fabric.Image.filters.Brightness({
      brightness: parseInt(this.cookieForm.controls.brightness.value, 10)
    }));
    this.applyFilterValue(5, 'brightness', parseFloat(this.cookieForm.controls.brightness.value));
  }
  //set contrast
  setContrast() {
    this.applyFilter(6, new fabric.Image.filters.Contrast({
      contrast: parseInt(this.cookieForm.controls.contrast.value, 10)
    }));
    this.applyFilterValue(6, 'contrast', parseFloat(this.cookieForm.controls.contrast.value));
  }
  setSaturation() {
    this.applyFilter(7, new fabric.Image.filters.Saturation({
      saturation: parseInt(this.cookieForm.controls.saturation.value, 10)
    }));
    this.applyFilterValue(7, 'saturation', parseFloat(this.cookieForm.controls.saturation.value));
  }
  applyFilter(index, filter) {
    var obj = this.canvas.getActiveObject();
    console.log(obj, 'obj')
    console.log(obj.filters, 'obj')
    obj.filters[index] = filter;
    console.log(obj, 'obj')
    obj.applyFilters();
    this.canvas.renderAll();
  }
  applyFilterValue(index, prop, value) {

    var obj = this.canvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      var timeStart = +new Date();
      obj.applyFilters();
      this.canvas.renderAll();
    }
  }

  //set font family
  setFontFamily() {
    console.log(this.cookieForm.controls.fontFamily.value, 'font')
    this.setActiveProp('fontFamily', this.cookieForm.controls.fontFamily.value);
  }
  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }
  //set font size
  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.cookieForm.controls.fontSize.value, 10), null);
  }
  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({ underline: true });
          object.dirty = true;
        } else {
          object.setSelectionStyles({ underline: false });
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
        }
        object.dirty = true;
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.set('underline', true);
          object.dirty = true;
        } else {
          object.set('underline', false);
          object.dirty = true;
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }
  //set bold
  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    console.log(this.props.fontWeight, 'weight')
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
  }
  //set Italics
  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null);
    }
  }
  //set text decoration
  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration ? 'underline' : '', null);
  }
  //set bg opacity
  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.cookieForm.controls.opacity.value, 10) / 100, null);
  }
  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }
  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  //set text color
  setFill() {
    console.log(this.cookieForm.controls.fill.value, 'text color')
    this.setActiveStyle('fill', this.cookieForm.controls.fill.value, null);
  }
  //set bg color
  setBgFill() {
    console.log(this.cookieForm.controls.BgFill.value, 'imah')
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.cookieForm.controls.BgFill.value;
      this.canvas.renderAll();
    }
  }
  // // choose template image
  getTemplate(fav: any) {
    console.log(fav, 'fav')
    this.editImage = true;
    this.imageSelected = true;
    this.showText = false;
    this.showImage = false
    this.showRemoveBtn = true
    let image = fav.image
    let img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = image;
    this.getBase64Image(img)
    // 
    fabric.Image.fromURL(image + '?not-from-cache-please',
      (img) => {
        let oImg = img.set({
          left: 16,
          top: 14,
          angle: 0,
          padding: 0
        })
        this.canvas.clear();
        oImg.scaleToHeight(200);
        oImg.scaleToWidth(270);
        this.selectItemAfterAdded(oImg);
        this.canvas.add(oImg).renderAll();
      }, { crossOrigin: 'anonymous' });
    // 
    // img.onload = () => {
    //   var imgInstance = new fabric.Image(img);
    //   imgInstance.set({ left: 50, top: 45, height: 300, width: 300, });
    //   this.selectItemAfterAdded(imgInstance)
    //   this.canvas.add(imgInstance);
    // }


  }
  getBase64Image(img) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  getMyImage(fav: any) {
    console.log(fav, 'fav')
    this.editImage = true;
    this.imageSelected = true;
    this.showText = false;
    this.showImage = false
    let image = fav.image
    this.showRemoveBtn = true
    this.redesignCanvasImage = true
    fabric.Image.fromURL(image + '?To fix the cors',
      (img) => {
        let oImg = img.set({
          left: 16,
          top: 14,
          angle: 0,
          padding: 0
        })
        this.canvas.clear();
        oImg.scaleToHeight(200);
        oImg.scaleToWidth(270);
        this.selectItemAfterAdded(oImg);
        this.canvas.add(oImg).renderAll();
      }, { crossOrigin: 'anonymous' });
    console.log(this.canvas, 'sds')
  }
  getcartItem() {
    this.SelectedProductId = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.isLoggedIn) {
      console.log(this.isLoggedIn)
      // this.api.getCart().subscribe(item => {
      //   this.onlineCartItem = item['data']
      // })
      console.log(this.onlineCartItem, 'on item')
      if (this.onlineCartItem) {
        // this.localStorage = this.onlineCartItem.concat(this.localStorage)
        // this.getLocalProduct();
      }
      else {
        this.getLocalProduct();
      }

    }
    else {
      this.getLocalProduct();
    }

  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
    })
  }
  getLocalCartItems() {
    // this.localStorage = JSON.parse(localStorage.getItem('cart'))
    for (var i in this.localStorage) {
      this.sum = 0
      let customized = this.localStorage[i].customize
      for (var j in customized) {
        if (customized[j]?.image_url) {
          this.sum = this.sum + 1
        }
        this.localStorage[i].count = this.sum
      }
    }
  }
  getFlavours() {
    this.api.getFlavour().subscribe(item => {
      this.FlavourArray = item['data']['Items']
    })
    console.log(this.FlavourArray, 'flvr array')
  }
  getLocalProduct() {
    debugger
    for (var i in this.localStorage) {
      if (this.SelectedProductId == this.localStorage[i].id) {
        this.selectedProduct = this.localStorage[i]
        this.FlavourArray = this.localStorage[i].flavours
        this.totalCookieBoxNo = this.localStorage[i].product.cookie_number * this.localStorage[i].quantity
        this.cookie_quantity = this.localStorage[i].quantity
        this.cookie_per_box = this.localStorage[i].product.cookie_number
        this.cookieBoxArray = this.localStorage[i].customize
        break;
      }
    }
  }
  checkImages() {

    let sum = 0
    if (this.isCheckedfirst) {
      console.log("same")
    }
    this.selectedProduct.customize.map((item) => {
      if (item?.image_url) {
        sum = sum + 1
      }
    }
    );
    this.count = sum
    console.log(this.count, 'count')
    if (this.count == this.totalCookieBoxNo) {
      this.showSave = true
      this.showImage = false
      this.enableCartBtn = true
    }
    else {
      this.enableCartBtn = false
    }
  }
  /**
   * on click event for storing index value
    // design(index, sub_index) {
    //   this.i = index
    //   this.j = sub_index
    //   this.boxIndex = index + ',' + sub_index
    //   this.showFlavour = true;
    // }
  */

  clickToScroll(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  design(index, array, id) {
    debugger
    this.canvas.clear();
    this.flavorName = ''
    console.log(this.hideCustomizeACookieText, this.hideEditableCookiePic, this.hideFullCookiePic)
    this.boxIndex = index.toString()
    console.log(array, 'array')
    console.log(index, 'index')
    for (var i in this.cookieBoxArray) {
      if (this.cookieBoxArray[i]?.flavor && this.cookieBoxArray[i].image_url == null || this.cookieBoxArray[i].image_url == '') {
        this.selectedIndex = this.cookieBoxArray[i].tempId
        console.log(this.cookieBoxArray[i].tempId, 'tempId')
        this.modalService.open(this.selectnew, { centered: true }).result.then(res => {
          if (res === true) {
            //this.ngOnInit();
            this.startingFunction()
            this.flavorName = ''
          }
          else {
            this.boxIndex = this.selectedIndex
            this.showImage = true;
          }
        })
      }
      else if (this.cookieBoxArray[i].flavor == null && this.cookieBoxArray[i].image_url == null) {
        this.showText = false;
        this.imageSelected = false
        this.showImage = false;
        this.editImage = false;
        this.showFlavour = true;
        this.textAdded = false;
        this.hideCustomizeACookieText = false
        this.hideEditableCookiePic = true
        this.hideFullCookiePic = true
        this.boxImageUrl = ''
        this.boxImage = ''
        this.canvas.clear();
      }
      if (this.cookieBoxArray[i] == this.cookieBoxArray[index]) {
        if (this.cookieBoxArray[i]?.flavor && this.cookieBoxArray[i].image_url) {
          this.modalService.open(this.modifyimage, { centered: true }).result.then(res => {
            if (res === false) {
              this.showText = false;
              this.imageSelected = false
              this.showImage = false;
              this.editImage = false;
              this.showFlavour = false;
              this.textAdded = false;
              this.hideCustomizeACookieText = false
              this.hideEditableCookiePic = true
              this.hideFullCookiePic = true
            }
            else {
              this.showFlavourArray(index, array)
              this.redesignCanvasImage = true
              this.enableCartBtn = false
            }
          }).catch((res) => {
            this.showText = false;
            this.imageSelected = false
            this.showImage = false;
            this.editImage = false;
            this.showFlavour = false;
            this.textAdded = false;
            this.hideCustomizeACookieText = false
            this.hideEditableCookiePic = true
            this.hideFullCookiePic = true
          });
        }
      }
      else {
        this.showFlavour = true;
        this.redesignCanvasImage = false
        console.log(this.cookieBoxArray[i], 'g')
        this.hideCustomizeACookieText = false
        this.hideEditableCookiePic = true
      }

    }
    this.cd.detectChanges();
    document.querySelector('#choosedFlavour').scrollIntoView({ behavior: 'smooth' })
  }
  showFlavourArray(index, array) {
    this.boxIndex = index.toString()
    if (array.flavor) {
      this.flavorImage = array.flavor.image
      console.log(array.flavor.image)
    }
    if (array.image && array.image_url) {
      for (var i in this.FlavourArray) {
        if (this.FlavourArray[i].SK == array.flavor.id) {
          this.flavorName = this.FlavourArray[i].name
        }
      }
      console.log(this.flavorName, 'flvr name')
      this.boxImage = array.image
      this.boxImageUrl = array.image_url
      this.boxIndex = array.tempId
      this.hideCustomizeACookieText = true;
      this.hideEditableCookiePic = false;
      this.hideFullCookiePic = true;
      console.log(this.hideCustomizeACookieText, this.hideEditableCookiePic, this.hideFullCookiePic)
      this.imageOnCanvas(array.image_url)
      this.showImage = false;
      this.showText = false
      this.imageSelected = true
      this.editImage = true;
      this.showFlavour = true;

    }
    else {
      this.boxImage = null
      this.boxImageUrl = null
      this.imageOnCanvas(array.image_url)
      this.showFlavour = true;
      this.imageSelected = false;
      this.editImage = false;
      this.showImage = false;
      this.showText = false
    }
  }
  /** 
     * show box image of 2d array  */
  // showBoxImage() {
  //   if (localStorage.getItem('file')) {
  //     let files = JSON.parse(localStorage.getItem('file'))
  //     console.log(typeof (files), 'files')
  //   }
  //   for (var i in this.localStorage) {
  //     if (this.SelectedProductId == this.localStorage[i].id) {
  //       this.customized = this.localStorage[i].customize
  //       this.boxArray = []
  //       for (var j in this.customized) {
  //         if (this.customized[j]) {
  //           this.sub_arr = this.customized[j]
  //           this.arr= []
  //           for (var k in this.sub_arr) {  
  //             this.arr.push(this.sub_arr[k])
  //             this.cookieBoxArray[j]=this.arr
  //           }
  //         }
  //       }
  //     }
  //   }
  // }


  showBoxImage() {
    this.files = JSON.parse(localStorage.getItem('file'))
    let mirrorArray = []
    console.log(this.files, 'files')
    for (var i in this.localStorage) {
      if (this.SelectedProductId == this.localStorage[i].id) {
        console.log(this.localStorage[i], 'current product')
        this.no_of_cookies = this.localStorage[i].product['cookie_number']
        this.customized = this.localStorage[i].customize
        if (this.customized[0]?.image_url) {
          this.isCheckedfirst = this.customized.every(v =>
            v.image_url == this.customized[0].image_url);
          console.log(this.isCheckedfirst, 's')
        }
        for (let k = 0; k < this.cookie_per_box; k++) {
          if (this.customized[k]?.image_url) {
            mirrorArray.push(this.customized[k])
          }
        }
        let c = 0
        _.each(this.customized, el => {
          _.each(mirrorArray, (item) => {
            if (item?.image_url == el?.image_url) {
              console.log("same")
              c = c + 1
            }
          })
        })
        if (c == this.cookie_per_box * this.cookie_quantity) {
          this.isCheckedbox = true
        }
        console.log(c, 'cccc')
        this.boxArray = []
        let count = 0
        if (this.no_of_cookies > 1) {
          if (this.no_of_cookies == 6 && this.localStorage[i].quantity == 1) {
            this.firstBox = false
          }
          else {
            for (let k = 0; k < this.cookie_per_box; k++) {
              if (this.customized[k]?.image_url) {
                count = count + 1
              }
            }
            if (count == this.no_of_cookies) {
              this.firstBox = true
            }
          }

        }
        else {
          this.firstBox = false;
        }
        let n = 0
        for (var j in this.customized) {
          if (this.customized[j]) {
            this.cookieBoxArray[j] = this.customized[j]
          }
          if (this.customized[j]?.image_url) {
            n = n + 1
            this.customImg = this.customized[j].image_url
            if (this.customized[0]?.image_url) { this.firstCookie = true }
            else {
              this.firstCookie = false
            }
          }
          if (n > 0) {
            this.showRemoveBtn = true;
          }
        }
      }
    }
    this.boxNumber = new Array(this.totalCookieBoxNo)
    this.cookieBox = new Array(this.cookie_quantity)

    if (this.files) {
      Object.values(this.files).map(key => {
        _.each(key, function (item, key, value) {
        })
      })
    }
  }


  /** create total 2d cookie box array
   *  */
  // createCookieBoxArray() {
  //   let obj = {}
  //   this.cookieBoxArray = []
  //   for (let i = 0; i < this.cookie_quantity; ++i) {
  //     this.cookieBoxArray[i] = []
  //     for (let j = 0; j < this.cookie_per_box; ++j) {
  //       this.cookieBoxArray[i][j] = j
  //     }
  //   }
  //   console.log(this.cookieBoxArray,'cooke box array')
  // }


  createCookieBoxArray() {
    this.cookieBoxArray = []
    for (let i = 0; i < this.totalCookieBoxNo; ++i) {
      this.cookieBoxArray[i] = []
    }
    console.log(this.cookieBoxArray, 'cookie box array')
  }

  //mirror cookie
  mirrorCookie(event) {

    const copy = this.cookieBoxArray
    this.copyCookieArray = this.cookieBoxArray
    console.log(copy, 'copy array')
    console.log(event.checked, 'event')
    let customized = this.selectedProduct.customize
    let mirrorFirst
    let tempId: string
    let flavor, image, image_url
    if (customized[0]) {
      tempId = customized[0]['tempId']
      flavor = customized[0]['flavor']
      if (customized[0].image_url) {
        image = customized[0]['image']
      }
      else {
        image = null
      }
      if (customized[0]['image_url']) {
        image_url = customized[0]['image_url']
      }
      else {
        image_url = null
      }
      mirrorFirst = customized[0]
    }
    let new_array = []
    if (event.checked == true) {
      this.isCheckedfirst = true
      _.each(this.cookieBoxArray, function (item, index, theArray) {

        theArray[index] = { 'flavor': flavor, 'image': image, 'tempId': index.toString(), 'image_url': image_url }
        // theArray[index] = mirrorFirst
        console.log(theArray[index], 'theee')
        new_array.push(theArray[index])
      })
      console.log(new_array, 'newwwww')
      this.cookieBoxArray.length = 0;
      this.cookieBoxArray = new_array
      console.log(this.cookieBoxArray, 'cookie')
      this.enableCartBtn = true
      this.showFlavour = false;
      this.showSave = true
      this.imageSelected = false;
      this.showImage = false
    }
    else {
      let count = this.selectedProduct.count

      //this.ngOnInit();
      this.startingFunction()
      if (count == this.totalCookieBoxNo) {
        this.enableCartBtn = true
      }
      else {
        this.enableCartBtn = false
      }
      this.showFlavour = false;
      this.showSave = false
    }
  }

  //mirror first box
  mirrorFirstBox(event) {
    const copy = this.cookieBoxArray
    this.copyCookieArray = this.cookieBoxArray
    console.log(copy, 'copy array')
    console.log(event.checked, 'event')
    let customized = this.selectedProduct.customize
    let mirrorArray = []
    let new_array = []
    let cookieTotal = new Array(this.cookie_quantity)
    if (event.checked == true) {
      this.isCheckedbox = true
      for (let k = 0; k < this.cookie_per_box; k++) {
        if (this.customized[k]?.image_url) {
          mirrorArray.push(this.customized[k])
        }
      }
      console.log(mirrorArray, 'copy array')
      let count = 0
      _.each(cookieTotal, function (el) {
        _.each(mirrorArray, function (item, index, theArray) {
          console.log(count, 'count')
          theArray[index] = { 'flavor': item['flavor'], 'image': item['image'], 'tempId': count.toString(), 'image_url': item['image_url'] }
          console.log(theArray[index], 'theee')
          new_array.push(theArray[index])
          count = count + 1
          console.log(new_array, 'theee')
        })
      })
      this.cookieBoxArray = new_array
      console.log(this.cookieBoxArray, 'cookie')
      this.enableCartBtn = true
      this.showFlavour = false;
      this.showSave = true
      this.imageSelected = false;
      this.showImage = false
    }
    else {
      // this.ngOnInit();
      this.startingFunction()
      //if all cookies are customized, check count
      // then enable all btn true
      if (this.selectedProduct.count == this.totalCookieBoxNo) {
        this.enableCartBtn = true
      }
      else {
        this.enableCartBtn = false
      }
      this.showFlavour = false;
      this.showSave = false
    }
  }
  //show image on canvas
  imageOnCanvas(image) {
    this.showRemoveBtn = true
    // fabric.Image.fromURL(image,
    //   (img) => {
    //     img.set({
    //       left: 30,
    //       top: 20,
    //       angle: 0,
    //       padding: 0
    //     })
    //     this.canvas.clear();
    //     img.scaleToHeight(250);
    //     img.scaleToWidth(250);
    //     this.selectItemAfterAdded(img);
    //     this.canvas.add(img).renderAll();
    //   });
    let img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = image;
    this.getBase64Image(img)
    img.onload = () => {
      console.log(img, 'img')
      var imgInstance = new fabric.Image(img);
      imgInstance.set({ left: 50, top: 45, height: 300, width: 300, });
      this.selectItemAfterAdded(imgInstance)
      this.canvas.add(imgInstance);
    }
    this.hideCustomizeACookieText = true;
    this.hideEditableCookiePic = false;
    this.hideFullCookiePic = true;
  }

  chooseFlavor(img) {
    console.log(img, 'flvr img')
    let image = img.image
    this.showImage = true;
    this.flavourImg = image
    this.flavorImage = img.image
    this.flavorName = img.name
    console.log(typeof (this.boxIndex, 'boxIndex'))
    this.selectedFlavourArray = {
      "flavor":
      {
        "id": img.SK,
        "price": img.extra_price,
        "image": image,
        "name": img.name

      },
      "tempId": this.boxIndex,
      "image": this.boxImage,
      "image_url": this.customized[this.boxIndex].image_url
    }

    let customizedImage = this.selectedProduct.customize
    customizedImage[this.boxIndex] = this.selectedFlavourArray
    console.log(customizedImage, 'customized')
    // for (var j in customizedImage) {
    //   if (customizedImage[j]?.flavor && !customizedImage[j].image_url) {
    //     customizedImage[j] = []
    //     console.log(customizedImage[j], 'image has')
    //   }
    //   localStorage.setItem('cart', JSON.stringify(this.localStorage));
    //   console.log("LOCAL STORAGE HAS ITEMS", JSON.parse(localStorage.getItem('cart')));
    // }
    this.showBoxImage();
    this.showImage = false;
    this.showText = false
    this.imageSelected = true
    this.editImage = true;
    this.showFlavour = true;
  }
  // choose flavour image
  getFlavorImg(event: any, img) {

    this.hideFullCookiePic = false;
    this.hideCustomizeACookieText = true;
    this.hideEditableCookiePic = true;
    this.isCheckedfirst = false
    this.isCheckedbox = false
    this.customized = this.selectedProduct.customize
    console.log(this.customized[this.boxIndex], 'gigttt')
    this.flavorImage = img.image
    let image
    let currentBox = this.customized[this.boxIndex]
    if (this.customized[this.boxIndex]?.image_url) {
      this.modalService.open(this.chooseFlav, { centered: true }).result.then(res => {
        if (res === true) {
          this.chooseFlavor(img)
          this.checkImages();
        }
        else {
          console.log(' else')
          image = currentBox?.flavor?.image
          this.showImage = true;
          this.flavourImg = image
          this.flavorImage = currentBox?.flavor?.image
          console.log(this.flavorImage)
          this.flavorName = img.name
          console.log(typeof (this.boxIndex, 'boxIndex'))
          this.selectedFlavourArray = {
            "flavor":
            {
              "id": currentBox?.flavor?.id,
              "price": currentBox?.flavor?.price,
              "image": currentBox?.flavor?.image,
              "name": currentBox?.flavour?.name

            },
            "tempId": this.boxIndex,
            "image": currentBox.image,
            "image_url": currentBox.image_url
          }
        }
        this.checkImages();
        this.hideCustomizeACookieText = true;
        this.hideEditableCookiePic = false;
        this.hideFullCookiePic = true;
        this.showImage = false;
        this.enableCartBtn = false;
      }).catch((err) => {
        console.log(' else')
        image = currentBox?.flavor?.image
        this.showImage = true;
        this.flavourImg = image
        this.flavorImage = currentBox?.flavor?.image
        console.log(this.flavorImage)
        this.flavorName = img.name
        console.log(typeof (this.boxIndex, 'boxIndex'))
        this.selectedFlavourArray = {
          "flavor":
          {
            "id": currentBox?.flavor?.id,
            "price": currentBox?.flavor?.price,
            "image": currentBox?.flavor?.image,
            "name": currentBox?.flavour?.name
          },
          "tempId": this.boxIndex,
          "image": currentBox.image,
          "image_url": currentBox.image_url
        }
        this.checkImages();
        this.hideCustomizeACookieText = true;
        this.hideEditableCookiePic = false;
        this.hideFullCookiePic = true;
        this.showImage = false;
        this.enableCartBtn = false;
      });
    }
    else {
      console.log('sdsfdsf')
      this.showImage = true;
      this.flavourImg = img.image
      this.flavorImage = img.image
      console.log(this.flavorImage)
      this.flavorName = img.name
      console.log(typeof (this.boxIndex, 'boxIndex'))
      this.selectedFlavourArray = {
        "flavor":
        {
          "id": img.SK,
          "price": img.extra_price,
          "image": img.image,
          "name": img.name
        },
        "tempId": this.boxIndex,
        "image": this.boxImage,
        "image_url": this.boxImageUrl
      }
      this.checkImages();
      for (var i in this.localStorage) {
        if (this.SelectedProductId == this.localStorage[i].id) {
          this.selectedProduct = this.localStorage[i]
          this.customized = this.selectedProduct.customize
          this.customized[this.boxIndex] = this.selectedFlavourArray
          console.log(this.customized, 'customized')
        }
      }

    }
    this.showBoxImage();
    console.log(this.showImage, 'show image')
    this.showImage = true
    this.cd.detectChanges();
    document.querySelector('#addImageSection').scrollIntoView({ behavior: 'smooth' })
  }

  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }
  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }
  selectItemAfterAdded(obj) {
    this.canvas.setActiveObject(obj);
    this.canvas.getActiveObject(obj).borderScaleFactor = 2
  }
  // choose template image
  getImgPolaroid(event: any) {
    // this.ngOnInit();
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 30,
        top: 40,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      image.scaleToHeight(200);
      image.scaleToWidth(200);
      this.extend(image, this.randomId());
      // this.canvas.add(image);
      this.selectItemAfterAdded(image);
      this.canvas.add(image).renderAll();
    });
    this.editImage = true;
    this.showText = false;
    this.imageSelected = true;
    this.showRemoveBtn = true
    this.showImage = false
  }
  addImage() {
    console.log(this.cookieBoxArray, 'array')
    this.showText = false;
    this.imageSelected = true;
    this.showRemoveBtn = true;
    this.hideCustomizeACookieText = true;
    this.hideFullCookiePic = true;
    this.hideEditableCookiePic = false;

    this.cd.detectChanges();
    document.querySelector('#newtarget').scrollIntoView({ behavior: 'smooth' })
  }
  editAddImage() {
    this.showText = false;
    this.imageSelected = true
    this.editImage = true;
  }
  addText() {
    this.hideCustomizeACookieText = true;
    this.hideFullCookiePic = true;
    this.hideEditableCookiePic = false;
    this.showRemoveBtn = true;
    this.showText = true;
    this.imageSelected = false;
    this.cd.detectChanges();
    document.querySelector('#addText').scrollIntoView({ behavior: 'smooth' })

  }
  enableApplyBtn() {
    if (this.cookieForm.controls.textString.value) { this.enableApply = true }
  }
  applyText() {
    if (this.cookieForm.controls.textString.value) {
      const text = new fabric.IText(this.cookieForm.controls.textString.value, {
        left: 75,
        top: 175,
        fontFamily: 'helvetica',
        angle: 0,
        fill: 'black',
        scaleX: 0.8,
        scaleY: 0.8,
        fontWeight: '',
        hasRotatingPoint: true
      });
      this.extend(text, this.randomId());
      this.canvas.add(text).setActiveObject(text);
      // text.on('mousedown', () => {
      //   this.textString = text.text
      //   this.cookieForm.patchValue({
      //     textString: this.textString
      //   })
      //   console.log(this.textString, 'sdsaf')
      // });

    }
    this.cookieForm.get('textString').reset();
    this.enableApply = false;
    this.textAdded = true
    this.showRemoveBtn = true
  }


  // //save the  cookie design
  // saveDesign() {
  //   this.selectedProduct.customize = this.cookieBoxArray
  //   console.log(this.selectedProduct, 'cookie')
  //   let local_storage
  //   local_storage = JSON.parse(localStorage.getItem('cart'));
  //   for (var i in local_storage) {
  //     if (this.SelectedProductId == local_storage[i].id) {
  //       local_storage[i].customize = this.cookieBoxArray
  //       break;
  //     }
  //   }
  //   localStorage.setItem('cart', JSON.stringify(local_storage));
  //   console.log("LOCAL STORAGE HAS ITEMS", JSON.parse(localStorage.getItem('cart')));
  //   this.router.navigate(['cookie-cart'])
  // }

  //  //save the  cookie design
  saveDesign() {
    const myId = uuid.v4();
    console.log(myId, 'id')
    console.log(this.redesignCanvasImage, 'fsd')
    this.customized = this.selectedProduct.customize
    this.customized[this.boxIndex] = this.selectedFlavourArray

    console.log(this.cookieBoxArray, 'cookie Box')
    this.selectedProduct.customize = this.cookieBoxArray
    console.log(this.selectedProduct, 'cookie')
    let local_storage
    // local_storage = JSON.parse(localStorage.getItem('cart'));
    local_storage = this.localStorage
    for (var i in local_storage) {
      if (this.SelectedProductId == local_storage[i].id) {
        local_storage[i].customize = this.cookieBoxArray
        break;
      }
    }

    //localStorage.setItem('cart', JSON.stringify(local_storage));
    let localCart = local_storage
    console.log(this.canvas, 'cabdsd')
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: 'png' });
    console.log(image.src, 'sdfddsf')
    let filename = myId
    this.selectedImage = {
    }
    this.selectedImage['image'] = image.src
    var key = filename
    var obj = {};
    var data = {}
    let imageName = filename + '.png'
    let c_Img = this.DataURIToBlob(this.canvas.toDataURL({ format: 'png' }))
    let c_imageFile: File = new File([c_Img], imageName, {
      type: "image/png"
    });
    console.log(c_Img, 'converted blob')
    obj[imageName] = image.src;
    data[imageName] = c_imageFile
    console.log(typeof (obj), 'src')
    local_storage = localCart
    for (var i in local_storage) {
      if (this.SelectedProductId == local_storage[i].id) {
        let customize = local_storage[i].customize
        console.log(customize, 'customize')
        for (var j in customize) {
          if (customize[j]?.tempId) {
            console.log(customize[j].tempId, 'temp')
            if (customize[j].tempId == this.boxIndex) {
              customize[j].image = imageName
              customize[j].image_url = image.src
            }
          }
          else {
            console.log(customize[j], 'null')
          }
        }
        let filesInCart = []
        let files
        let images = []
        let img = []
        let convInCart = []
        let c_files = []
        if (localStorage.getItem('file') == null) {
          files = [];
          console.log("LOCALSTORAGE NULL", JSON.parse(localStorage.getItem('file')));
          filesInCart.push(obj);
          if (!this.redesignCanvasImage) {
            localStorage.removeItem('file')
            localStorage.setItem('file', JSON.stringify(filesInCart));
          }
          images.push(this.selectedImage);
          if (!this.redesignCanvasImage) {
            localStorage.removeItem('file')
            localStorage.setItem('image', JSON.stringify(images));
          }
          convInCart.push(data)
          // localStorage.setItem('converted', JSON.stringify(convInCart));
        }
        else {
          files = JSON.parse(localStorage.getItem('file'))
          if (obj) {
            filesInCart.push(obj);
          }
          files.forEach(function (item) {
            filesInCart.push(item);
          })
          if (!this.redesignCanvasImage) {
            localStorage.removeItem('file')
            localStorage.setItem('file', JSON.stringify(filesInCart));
          }

          img = JSON.parse(localStorage.getItem('image'))
          if (this.selectedImage) {
            images.push(this.selectedImage);
          }
          img.forEach(item => {
            images.push(item);
          });

          // c_files = JSON.parse(localStorage.getItem('converted'))
          // if (data) {
          //   convInCart.push(data);
          // }
          // c_files.forEach(item => {
          //   convInCart.push(item);
          // });
          if (!this.redesignCanvasImage) {
            localStorage.removeItem('file')
            localStorage.setItem('file', JSON.stringify(filesInCart));
            localStorage.removeItem('image')
            localStorage.setItem('image', JSON.stringify(images));
          }
          // localStorage.setItem('converted', JSON.stringify(convInCart));
        }
      }
    }
    // localStorage.removeItem('cart')
    // localStorage.setItem('cart', JSON.stringify(local_storage));
    this.localStorage = local_storage
    console.log("LOCAL STORAGE HAS ITEMS", JSON.parse(localStorage.getItem('cart')));
    this.checkImages();
    this.boxIndex = 'undefined'
    // this.ngOnInit();
    this.startingFunction()
    this.showText = false;
    this.imageSelected = false
    this.showImage = false;
    this.editImage = false;
    this.showFlavour = false;
    this.textAdded = false;
    this.hideCustomizeACookieText = false;
    this.hideFullCookiePic = true;
    this.hideEditableCookiePic = true;
    this.flavorName = ''
  }
  // exampleFunction(){

  //   if(this.counter < 10){
  //    this.service.getData().subscribe(data=>{
  //         if(data){
  //          console('data received for: counter)
  //          this.counter++
  //          this.exampleFunction()
  //          }  
  //        });
  //   }
  //   }
  revisedArray() {
    //console.log(this.selectedProduct)
    debugger
    this.loader = true
    this.saveDesign();
    let newProduct = []
    if (this.num < this.selectedProduct.customize.length) {
      if (this.selectedProduct.customize[this.num].image_url.startsWith('https')) {
        this.selectedProduct.customize[this.num].image_url = this.selectedProduct.customize[this.num].image_url
        newProduct.push(this.selectedProduct.customize[this.num])
        //console.log('color',res)
        this.num++
        this.revisedArray()
        return
      } else {
        let data: any = { "imagedata": this.selectedProduct.customize[this.num].image_url, "filename": this.selectedProduct.customize[this.num].image }
        this.api.uploadBoxcolor(data).subscribe((res) => {
          this.selectedProduct.customize[this.num].image_url = res['body']
          newProduct.push(this.selectedProduct.customize[this.num])
          //console.log('color',res)
          this.num++
          this.revisedArray()

        })
        return
      }

    } else {
      this.loader = false
      this.activateButton = true
    }
    //  _.each(this.selectedProduct.customize,(item)=>{
    //     let data:any={"imagedata":item.image_url, "filename":item.image}
    //     this.api.uploadBoxcolor(data).subscribe((res)=>{
    //       item.image_url = res['body']
    //       newProduct.push(item)
    //       console.log('color',res)

    //     })

    //     return item
    //   })
    //console.log(newProduct,'newProduct')
    // if(this.selectedProduct.customize.length == newProduct.length){
    //   this.selectedProduct.customize = newProduct
    //   this.arrayCheck = true
    // }
    // for(var i in this.localStorage){
    //   if(this.selectedProduct.id == this.localStorage[i].id){
    //     let selectedProduct :any
    //     // let customizeArray=[]
    //     // customizeArray= this.localStorage[i].customize
    //     // let newArray:any = customizeArray.slice( 1, 7)
    //     // this.localStorage[i]['customize'] = newArray
    //    // console.log(local_storage)
    //    selectedProduct = this.localStorage[i]
    //    let newProduct=[]
    // newProduct =   _.each(selectedProduct.customize,(item)=>{
    //     let data:any={"imagedata":item.image_url, "filename":item.image}
    //     this.api.uploadBoxcolor(data).subscribe((res)=>{
    //       item.image_url = res['body']
    //      return item
    //     })


    //    })
    //    this.selectedProduct.customize = newProduct
    //   //  if(newProduct.length == selectedProduct.customize.length){

    //   //  }

    //   // this.localStorage[i] = selectedProduct
    //   }
    // }
    // return this.selectedProduct
  }
  gotoCart() {
    //this.revisedArray()
    debugger
    this.loader = true
    // this.saveDesign();
    let keyFile = {}
    // this.revisedArray()
    // if(this.arrayCheck){
    let products = {
      "products": this.selectedProduct
    }
    console.log('reqdata', products)
    this.api.addCart(products, keyFile).subscribe((result) => {
      console.log(result, 'result')
      if (result['status'] == true) {
        this.loader = false
        let product = JSON.parse(localStorage.getItem('cart'))
        let index = _.findIndex(product, { id: this.selectedProduct.id })
        //index >= 0
        if (index >= 0 || index <= 0) {
          product.splice(index, 1)
        }

        localStorage.removeItem('cart')
        localStorage.setItem('cart', JSON.stringify(product));
        this.toastr.success(result['message'])
        this.router.navigate(['/cookie-cart'])
      } else {
        this.loader = false
        this.toastr.error(result['message'])
      }
    })

    // }else{
    //  this.loader = false
    // }


    // console.log()


    //localStorage.setItem('cart', JSON.stringify(this.localStorage));

  }
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  redirectToCookieCartPage() {
    this.router.navigate(['/cookie-cart'])
  }
}
