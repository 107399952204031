import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { CompressImgageService } from 'src/app/compress-imgage.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-favourite-images',
  templateUrl: './favourite-images.component.html',
  styleUrls: ['./favourite-images.component.scss', '../../components/breadcrumb/breadcrumb.component.css']
})
export class FavouriteImagesComponent implements OnInit {

  imageArray
  favouriteForm: FormGroup;
  imageURL: string;
  showDelete: boolean = false;
  selectedImages = []
  clicked = false
  loader = false
  id: any;
  highlightedBatch = [];
  imageUploaded = false
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService,
    private compressImage: CompressImgageService
  ) { }
  ngOnInit(): void {
    this.loader = true
    this.getFavImages();
    this.favouriteForm = this.fb.group({
      filesource: [''],
      image: ''
    })
  }
  getFavImages() {
    this.api.getFavouriteImage().subscribe(data => {
      this.imageArray = data['data']['Items']
      this.loader = false
      this.imageArray = _.sortBy(this.imageArray, function (o) {
        return o.created_at
      })
      this.imageArray = this.imageArray.reverse()
      console.log(data['data']['Items'])
    })
  }
  addImage() {
    this.loader = true
    let formData = {
      "filename": this.favouriteForm.controls.image.value
    }
    let newformData = new FormData();
    _.each(formData, function (value, key) {
      newformData.append(key, value)
    })
    this.api.addFavouriteImage(newformData).subscribe(data => {
      this.loader = false
      let message = data['message']
      this.toastr.success('Image added successfully')
      this.getFavImages();
      this.favouriteForm.reset();
      this.imageURL = '';
      this.imageUploaded = false;
      console.log(data)
    })
  }
  showPreview(event) {

    const file = (event.target as HTMLInputElement).files[0];
    this.compressImage.compress(file).pipe(take(1))
      .subscribe(compressedImage => {
        console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
        // upload the compressed image 
        this.favouriteForm.patchValue({
          image: compressedImage
        });
        if (file == null) {
          this.imageUploaded = false
          this.imageURL = ''
        }
        this.favouriteForm.get('image').updateValueAndValidity()

        // File Preview
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imageURL = e.target.result;

        };

        reader.readAsDataURL(compressedImage)
        this.imageUploaded = true
      })



  }

  deleteFavImage() {
    let data = []
    _.each(this.highlightedBatch, function (value) {
      data.push({ "id": value.id, "filename": value.filename })
    })
    console.log(data, 'array')
    this.api.deleteFavouriteImage(data).subscribe(data => {
      console.log(data, 'data')
      let message = data['message']
      this.toastr.success('Image deleted successfully')
      this.getFavImages();
    })
  }
  changeSelection(selectedImages, event) {
    console.log(selectedImages, event)
    this.id = selectedImages.id
    this.clicked = true
    this.showDelete = true
    this.selectedImages.push(selectedImages)
    if (this.highlightedBatch.indexOf(selectedImages) === -1) {
      this.highlightedBatch.push(selectedImages);
      console.log('add');
    } else {
      const _index = this.highlightedBatch.indexOf(selectedImages);
      console.log(_index, 'imdex')
      this.highlightedBatch.splice(_index, 1);
      console.log('remove');
    }
    console.log(this.highlightedBatch, 'highli')
    console.log(this.selectedImages, 'selected')
  }
  clear() {
    this.favouriteForm.reset();
    this.imageURL = '';
  }
}


