import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.css', '../../components/breadcrumb/breadcrumb.component.css']
})
export class ManageAddressComponent implements OnInit {

  addressForm: FormGroup;
  showAddressForm: boolean = false;
  addressArray
  deletedId
  addressValue
  addressId;
  submitted = false
  loader = false

  numberPattern = ''
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService,
    private viewportScroller: ViewportScroller,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getAddress();
    this.addAddressForm();
  }
  addAddressForm() {
    this.addressForm = this.fb.group({
      fullName: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      flatVilla: ['', Validators.required],
      streetName: ['', Validators.required],
      city: ['', Validators.required],
      zone: ['', Validators.required],
      areaNumber: [''],
      contactNumber: ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      // map:['']
    })
  }
  getAddress() {
    this.loader = true
    this.api.getAddress().subscribe(data => {
      this.loader = false
      this.addressValue = data
      this.addressArray = data['data']
      this.addressArray = _.sortBy(this.addressArray, function (o) {
        return o.created_at
      })
      this.addressArray = this.addressArray.reverse();
      console.log(data)
    })
  }

  showAddress() {
    debugger
    this.showAddressForm = true;
    console.log('clicked');
    this.cd.detectChanges();
    document.querySelector('#addAddressSection').scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }
  addAddress() {
    debugger
    console.log(this.addressId)
    debugger
    this.submitted = true;
    this.showAddressForm = true;
    if (this.addressId) {
      let data = {
        "id": this.addressId,
        "name": this.addressForm.controls.fullName.value,
        "building": this.addressForm.controls.buildingNumber.value,
        "flat_villa": this.addressForm.controls.flatVilla.value,
        "street": this.addressForm.controls.streetName.value,
        "city": this.addressForm.controls.city.value,
        "zone": this.addressForm.controls.zone.value,
        "area": this.addressForm.controls.areaNumber.value,
        "contact_number": this.addressForm.controls.contactNumber.value,
      }
      this.api.updateAddress(data).subscribe(res => {
        debugger
        if (res) {
          this.addressId = ''
          console.log(res);
          let message = res['message']
          this.toastr.success('Address updated successfully')
          this.ngOnInit();
          this.showAddressForm = false;
          this.submitted = false;
        }
      })
    }
    else {
      this.showAddressForm = false;
      let data = {
        "name": this.addressForm.controls.fullName.value,
        "building": this.addressForm.controls.buildingNumber.value,
        "flat_villa": this.addressForm.controls.flatVilla.value,
        "street": this.addressForm.controls.streetName.value,
        "city": this.addressForm.controls.city.value,
        "zone": this.addressForm.controls.zone.value,
        "area": this.addressForm.controls.areaNumber.value,
        "contact_number": this.addressForm.controls.contactNumber.value,
        // "map": this.addressForm.controls.map.value
      }
      this.api.addAddress(data).subscribe(res => {
        this.showAddressForm = true;
        let message = res['message']
        this.toastr.success('Address added successfully')
        console.log(res)
        this.ngOnInit();
        this.showAddressForm = false
        this.submitted = false;
        this.cd.detectChanges();
        document.querySelector('#addressTopSection').scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      })
    }
  }
  updateaddress(address: any) {
    console.log(address.id, 'address')

    this.showAddressForm = true;
    this.addressForm = this.fb.group({
      id: address.id,
      fullName: [address.name ? address.name : '', Validators.required],
      buildingNumber: [address.building ? address.building : '', Validators.required],
      flatVilla: [address.flat_villa ? address.flat_villa : '', Validators.required],
      streetName: [address.street ? address.street : '', Validators.required],
      city: [address.city ? address.city : '', Validators.required],
      zone: [address.zone ? address.zone : '', Validators.required],
      areaNumber: [address.area ? address.area : ''],
      contactNumber: [address.contact_number ? address.contact_number : '', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      // map:['']
    })
    this.addressId = address.id
    this.cd.detectChanges();
    document.querySelector('#addAddressSection').scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }
  saveUpdateAddress(id: any) {
    let data = {
      "id": id,
      "name": this.addressForm.controls.fullName.value,
      "building": this.addressForm.controls.buildingNumber.value,
      "flat_villa": this.addressForm.controls.flatVilla.value,
      "street": this.addressForm.controls.streetName.value,
      "city": this.addressForm.controls.city.value,
      "zone": this.addressForm.controls.zone.value,
      "area": this.addressForm.controls.areaNumber.value,
      "contact_number": this.addressForm.controls.contactNumber.value,
    }
    this.api.updateAddress(data).subscribe(res => {
      console.log(res);
      this.addressId = ''
      this.showAddressForm = false;
      let message = res['message']
      this.toastr.success(message)
      this.ngOnInit();
      this.submitted = false;
      this.addressForm.reset();
    })
  }
  setDeletedId(id) {
    this.deletedId = id
  }
  deleteaddress(id) {
    console.log(id)
    this.api.deleteAddress({ "id": id }).subscribe(res => {
      console.log(res)
      let message = res['message']
      this.toastr.success(message)
      this.ngOnInit();
    })
  }
}
