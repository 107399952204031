import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import 'fabric';
declare const fabric: any;
@Component({
  selector: 'app-image-customize',
  templateUrl: './image-customize.component.html',
  styleUrls: ['./image-customize.component.css']
})
export class ImageCustomizeComponent implements OnInit, AfterViewInit {

  title = 'angular-editor-fabric-js';
  cookieForm: FormGroup;
  canvas: any;
  public textString: string;
  selected: any;
  fontSize;
  opacity;
  brightness;
  contrast;
  saturation;
  fontWeight;
  fill;
  textEditor = false;
  props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    brightness:null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };
  constructor(
    private fb: FormBuilder
  ) { }


  ngOnInit(): void {

    this.canvas = new fabric.Canvas('canvas', { selection: false });
    console.log(this.canvas, 'canvas')
    this.cookieForm = this.fb.group({
      textString: [''],
      image: [''],
      brightness:[''],
      contrast:[''],
      fontFamily:[''],
      fontSize:[''],
      opacity:[''],
      fill:[''],
      BgFill:[''],
      saturation:['']
    })
    // this.fontSize=this.cookieForm.controls.fontSize.value
  }
ngAfterViewInit(){
  
}

  handleDrop(e: any) {
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onload = (imgFile: any) => {
      console.log(imgFile);
      const data = imgFile.target["result"];
      fabric.Image.fromURL(data,
         (img: { set: (arg0: { left: number; top: number; angle: number; }) => 
         { (): any; new(): any; scale: { (arg0: number): any; new(): any; }; }; }) => {
        let oImg = img.set({
          left: 0,
          top: 0,
          angle: 0
        }).scale(1);
        oImg.scaleToHeight(200);
        oImg.scaleToWidth(200);
        this.canvas.add(oImg).renderAll();
        var a = this.canvas.setActiveObject(oImg);
        var dataURL = this.canvas.toDataURL({ format: 'png', quality: 0.8 });
      });
    };
    reader.readAsDataURL(file);
    return false;
  }
  uploadImage(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = e.target.result;
        fabric.Image.fromURL(data, img => {
          img.set({
            left: 100,
            top: 350
          });
          img.scaleToHeight(200);
          img.scaleToWidth(200);
          console.log(data, 'da')
          this.canvas.add(img);
          this.canvas.centerObject(img);
        });
      };

      reader.readAsDataURL(file);
    }
  }
 //set brightness
 setBrightness() {
  console.log(this.cookieForm.controls.brightness.value, 'brightness');
  this.applyFilter(5, new fabric.Image.filters.Brightness({
    brightness: parseInt(this.cookieForm.controls.brightness.value, 10)
  }));
  this.applyFilterValue(5, 'brightness', parseFloat(this.cookieForm.controls.brightness.value));
}
//set contrast
setContrast() {
  this.applyFilter(6, new fabric.Image.filters.Contrast({
    contrast: parseInt(this.cookieForm.controls.contrast.value, 10)
  }));
  this.applyFilterValue(6, 'contrast', parseFloat(this.cookieForm.controls.contrast.value));
}
setSaturation() {
  this.applyFilter(7, new fabric.Image.filters.Saturation({
    saturation: parseInt(this.cookieForm.controls.saturation.value, 10)
  }));
  this.applyFilterValue(7, 'saturation', parseFloat(this.cookieForm.controls.saturation.value));
}
applyFilter(index, filter) {
  var obj = this.canvas.getActiveObject();
  obj.filters[index] = filter;
  obj.applyFilters();
  this.canvas.renderAll();
}
applyFilterValue(index, prop, value) {
  var obj = this.canvas.getActiveObject();
  if (obj.filters[index]) {
    obj.filters[index][prop] = value;
    var timeStart = +new Date();
    obj.applyFilters();
    this.canvas.renderAll();
  }
}
  addText() {
    console.log(this.cookieForm.controls.textString.value,'string')
    if (this.cookieForm.controls.textString.value) {
      const text = new fabric.IText(this.cookieForm.controls.textString.value, {
        left: 75,
        top: 175,
        fontFamily: 'helvetica',
        angle: 0,
        fill: 'red',
        scaleX: 0.5,
        scaleY: 0.5,
        fontWeight: '',
        hasRotatingPoint: true
      });

      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.selectItemAfterAdded(text);
      this.textString = '';
    }
  }
  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function() {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }
  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }
  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }
  
  //set font family
  setFontFamily() {
   console.log(this.cookieForm.controls.fontFamily.value,'font')
    this.setActiveProp('fontFamily', this.cookieForm.controls.fontFamily.value);
  }
  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }
//set font size
  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.cookieForm.controls.fontSize.value, 10), null);
  }
  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    debugger
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({underline: true});
        } else {
          object.setSelectionStyles({underline: false});
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({overline: true});
        } else {
          object.setSelectionStyles({overline: false});
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({linethrough: true});
        } else {
          object.setSelectionStyles({linethrough: false});
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
        object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }
//set bold
setBold() {
  this.props.fontWeight = !this.props.fontWeight;
  console.log(this.props.fontWeight,'weight')
  this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
}
//set Italics
setFontStyle() {
  this.props.fontStyle = !this.props.fontStyle;
  if (this.props.fontStyle) {
    this.setActiveStyle('fontStyle', 'italic', null);
  } else {
    this.setActiveStyle('fontStyle', 'normal', null);
  }
}
//set text decoration
setTextDecoration(value) {
  let iclass = this.props.TextDecoration;
  if (iclass.includes(value)) {
    iclass = iclass.replace(RegExp(value, 'g'), '');
  } else {
    iclass += ` ${value}`;
  }
  this.props.TextDecoration = iclass;
  this.setActiveStyle('textDecoration', this.props.TextDecoration, null);
}
//set bg opacity
setOpacity() {
  this.setActiveStyle('opacity', parseInt(this.cookieForm.controls.opacity.value, 10) / 100, null);
}
getFill() {
  this.props.fill = this.getActiveStyle('fill', null);
}
getActiveStyle(styleName, object) {
  object = object || this.canvas.getActiveObject();
  if (!object) { return ''; }

  if (object.getSelectionStyles && object.isEditing) {
    return (object.getSelectionStyles()[styleName] || '');
  } else {
    return (object[styleName] || '');
  }
}

//set text color
setFill() {
  console.log(this.cookieForm.controls.fill.value,'text color')
  this.setActiveStyle('fill', this.cookieForm.controls.fill.value, null);
}
//set bg color
setBgFill()
{
  console.log(this.cookieForm.controls.BgFill.value,'imah')
  if (!this.props.canvasImage) {
    this.canvas.backgroundColor = this.cookieForm.controls.BgFill.value;
    this.canvas.renderAll();
  }
}
 // choose template image
 getImgPolaroid(event: any) {
   debugger
  const el = event.target;
  fabric.loadSVGFromURL(el.src, (objects, options) => {
    const image = fabric.util.groupSVGElements(objects, options);
    image.set({
      left: 30,
      top: 40,
      angle: 0,
      padding: 10,
      cornerSize: 10,
      hasRotatingPoint: true,
    });
    image.scaleToHeight(200);
    image.scaleToWidth(200);
    this.extend(image, this.randomId());
    this.canvas.add(image);
    this.selectItemAfterAdded(image);
  });
}
  //save the  cookie design
  saveDesign() {
    console.log(this.cookieForm.controls.image.value, 'value')
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: 'png' });
    console.log(image.src, 'src')
    const w = window.open('');
    w.document.write(image.outerHTML);
  }
  
}
