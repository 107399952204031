import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { data } from 'jquery';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  loader: boolean = false

  userStatus
  userImg: any;
  isLoggedIn: boolean
  productArray: any;
  scrHeight: number;
  scrWidth: number;
  favCount
  userFavourite
  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private api: CommonService,
    private token: TokenStorageService,
    private angularFireMessaging:AngularFireMessaging
  ) { }

  ngOnInit(): void {
    debugger
    this.loader = true;

    this.activatedRouter.fragment
      .pipe(
        map(fragment => new URLSearchParams(fragment)),
        map(params => ({
          access_token: params.get('access_token'),
          id_token: params.get('id_token')
        }))
      )
      .subscribe(params => {
        debugger
        if (params.access_token && params.id_token) {
          this.token.saveToken(params['access_token']);
          // this.token.saveRefreshToken(data['refresh_token']);
          this.token.loggedStatus(true)
          this.token.saveIdToken(params['id_token']);
          this.api.getSocialLogin(params?.access_token).then(data => {
            let socialLogin = data
            console.log(data, 'data')
            this.angularFireMessaging.requestToken.subscribe((token)=>{
              let fcm_token = token
              let reqdata:any={
                "username": socialLogin['username'],
                "email":socialLogin['email'],
                "fcm_token": { "web":fcm_token },
                
              }
              this.api.tokenUpdate(reqdata).subscribe((resp)=>{
                console.log('tokenresp',resp)
                localStorage.setItem('loginEmail',socialLogin['email'])
                //window.location.reload()
                
              })
              
            })
            debugger
            this.token.saveUser(data)
            this.loggedUser();
            this.getProducts();
          })
          this.loader = false
        }
        else {
          this.loggedUser();
          this.getProducts();
          this.loader = false
        }
      });
  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      this.token.loggedStatus(res)
      console.log(this.isLoggedIn, 'user')
    })
  }
  getProducts() {
    this.api.getProduct().subscribe(re => {
      this.productArray = re['data']['Items']
      this.productArray = _.sortBy(this.productArray, function (o) {
        return o.cookie_number
      })
      //   console.log(this.productArray)


      //     if( this.scrWidth>1300){
      //       this.favCount=4;
      //     } else if(this.scrWidth<1300 && this.scrWidth>1025){
      // this.favCount='3';
      //     } else if(this.scrWidth<1025  ){
      //       this.favCount='2';
      //           }
      //           else if(this.scrWidth<600 ){
      //             this.favCount='1';
      //                 }
      //     this.userFavourite = this.chunks(this.productArray,this.favCount);


    })
  }
  order() {
    this.router.navigate(['products'])
  }
  selectedProduct(id) {
    this.router.navigate(['products'], { queryParams: { id: id } })
  }
  /**************************************************************** */
  //gets the size of window screen to adjust number of images in an array to fit carousel
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
  }
  /******************************************************************** */

  //adds images from server to array
  chunks(array, size) {
    let results = [];
    results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }
}
