<div class="col-md-12">
    <div class="row bg-about">
        <p class="a-title1">How we work</p>
        <p class="a-title2">Baking with passion</p>
    </div>
</div>
<div class="container p-50">
    <section class="large-view">
        <div class="row">
            <div class="col-md-2">
                <div class="">
                    <div class="text-align-end">
                        <img src="../../../assets/how1.png">
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="first-arrow text-align-end">
                    <img src="../../../assets/Group19596.svg">
                </div>
            </div>
            <div class="col-md-2">
                <div class="image-down-position">
                    <div class="text-align-end">
                        <img src="../../../assets/how2.png">
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="second-arrow text-align-end">
                    <img src="../../../assets/Group19597.svg">
                </div>
            </div>
            <div class="col-md-2">
                <div class="">
                    <div class="text-align-end">
                        <img src="../../../assets/how3.png">
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="third-arrow text-align-end">
                    <img src="../../../assets/Group19599.svg">
                </div>
            </div>
            <div class="col-md-2">
                <div class="image-down-position">
                    <div class="text-align-end">
                        <img src="../../../assets/how4.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="text-align">
                    <p class="how-we-work-caption">Select your Cookie Flavour</p>
                    <p class="fontSize12px secondary-font-color text-center">Login to your personal profile, select
                        cookie falvour you wish to place in our
                        different
                        pack sizes.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="m-t-145 text-align">
                    <p class="how-we-work-caption">Upload your image</p>
                    <p class="fontSize12px secondary-font-color text-center">Choose your photo or text you wish to match
                        on the cookies, customize it and view it
                        in your pack.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="text-align">
                    <p class="how-we-work-caption">Check out and track your order</p>
                    <p class="fontSize12px secondary-font-color text-center">Once cookie design and customization
                        completed checkout, select method of delivery and track your delicious cookies being prepared
                        .</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="m-t-145 text-align">
                    <p class="how-we-work-caption">Happy Customer</p>
                    <p class="fontSize12px secondary-font-color text-center">Our delicious cookies make your event more
                        memorable. Enjoy the taste of our multi flavoured cookies with your loved ones.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="mobile-view">
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <img src="../../../assets/how1.png">
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-12">
                <div class="text-align">
                    <p class="how-we-work-caption">Happy Customer</p>
                    <p class="fontSize12px secondary-font-color text-center">Select your Cookie Flavour
                        Login to your personal profile, select cookie falvour you wish to place in our
                        different
                        box sizes.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <!-- <img src="../../../assets/Group19596.svg"> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <img src="../../../assets/how2.png">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <p class="how-we-work-caption">Delivered to your place</p>
                    <p class="fontSize12px secondary-font-color text-center">Uploade your image
                        Chose your photo or text you wish to match on the cookies, customize it and view it
                        in your box.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <!-- <img src="../../../assets/Group19597.svg"> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <img src="../../../assets/how3.png">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <p class="how-we-work-caption">Delivered to your place</p>
                    <p class="fontSize12px secondary-font-color text-center">Check out and track your order
                        Once cookie design and customization completed checkout, select method of delivery and track
                        your delicious cookies being prepared
                        .</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <!-- <img src="../../../assets/Group19599.svg"> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <img src="../../../assets/how4.png">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-align">
                    <p class="how-we-work-caption">Happy Customer</p>
                    <p class="fontSize12px secondary-font-color text-center">Select your Cookie Flavour
                        Login to your personal profile, select cookie falvour you wish to place in our
                        different
                        box sizes.</p>
                </div>
            </div>
        </div>
    </section>
    <div class="row m-100">
        <div class="col-md-12">
            <p class="snape-red fw600">FAQ</p>
            <h3 class="fsize40 fw600">Frequently Asked Questions</h3>
        </div>

        <div class="col-md-6">
            <div class="row faqlist" *ngFor="let faq of faqArray">
                <div class="col-md-2 p-3 text-center">
                    <!-- <img src="../../../assets/img/faq1.svg" /> -->
                    <div class="faq-qus-count-image">
                        <!-- <p class="faq-qus-counts">1</p> -->
                    </div>
                </div>
                <div class="col-md-9 pt-2 my-auto">
                    <div class="my-auto">
                        <h3 class="fsize17px">{{faq.question}}</h3>
                        <p class="fontSize12px ColorBOp" [innerHTML]="faq.answer"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4">
            <div class="bgdot">
                <img src="../../../assets/img/bgdots.png">
            </div>
        </div>
    </div>
</div>