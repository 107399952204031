import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { HeaderwhiteComponent } from './headerwhite/headerwhite.component';
import { HeaderblackComponent } from './headerblack/headerblack.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProductHeaderComponent } from './product-header/product-header.component';
@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    NavbarComponent,
    HeaderwhiteComponent,
    HeaderblackComponent,
    ProductHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [HeaderComponent,
    FooterComponent,
    NavbarComponent,
    HeaderwhiteComponent,
    HeaderblackComponent,
    ProductHeaderComponent]
})
export class LayoutModule { }
