import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import * as _ from 'underscore';
import { filter } from 'underscore';
@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css', '../../components/breadcrumb/breadcrumb.component.css']
})
export class MyOrdersComponent implements OnInit {

  orderDetails = []
  orderedItem;
  
  requestData:any=[]
  loader = false
  email
  constructor(
    private api: CommonService,
    private router: Router,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.loader = true
    this.api.getOrder().subscribe(res => {
      this.orderDetails = res['order_data']['Items']
      this.loader = false
      this.orderDetails = _.sortBy(this.orderDetails, function (o) {
        return o.created_at
      })
      this.orderDetails = filter(this.orderDetails,function(item){
        return item.orderstatus != 'Order Pending'
      })
      this.orderDetails.reverse();
      console.log(res['order_data']['Items'], 'res')
      
    })
    //let userDetails =  JSON.parse(window.sessionStorage.getItem('auth-user'))
    let userDetails =  localStorage.getItem('loginEmail')
    this.email = userDetails
    console.log(userDetails, 'email')
    console.log(this.email, 'email')
  }
  message(id) {
    let orderId = id
    this.router.navigate(['message-center', { orderId: orderId }])
  }
  getData(item:any){
    this.requestData = item

  }
  orderDetail(id) {
    let orderId = id
    let extra = {
      "order_id": orderId,
      "user_email": this.email
    }
    this.router.navigate(['order/details'], {
      queryParams: extra
    });
  }
  trackOrder(id) {
    let orderId = id
    let extra = {
      "order_id": orderId
    }
    this.router.navigate(['track-order'], { queryParams: extra })
  }
  cancelOrder(){
    debugger
    this.loader = true
    let data:any={"id":this.requestData.id,"PK":this.requestData.PK}
    this.api.orderCancel(data).subscribe((resp)=>{
      console.log(resp)
      if(resp['status'] == true){
        this.toastr.success(resp['message'])
        this.loader = false
        this.ngOnInit()
      }else{
        this.toastr.error('Something went wrong')
        this.loader = false
      }
      
    })
  }
  buyAgain(id) {
    debugger
    let local_storage
    this.loader = true
    this.api.buyAgain(id, this.email).subscribe(item => {
      console.log(item['item_data']['Items'], 'item')
      let itemsInCart = []
      let cart_item = {}
      this.orderedItem = item['item_data']['Items']
      this.loader = false
      for (var i in this.orderedItem) {
        this.orderedItem[i].PK = this.orderedItem[i].product['PK']
        this.orderedItem[i].SK = this.orderedItem[i].product['SK']
        this.orderedItem[i].min_qty = parseInt(this.orderedItem[i].product['min_qty'])
        this.orderedItem[i].product['cookie_number'] = parseInt(this.orderedItem[i].product['cookie_number'])
        this.orderedItem[i].product.item_total = this.orderedItem[i].quantity * this.orderedItem[i].product['price']
        delete this.orderedItem[i]['company_id']
        delete this.orderedItem[i]['created_at']
        delete this.orderedItem[i]['delivery_status']
        delete this.orderedItem[i].product['PK']
        delete this.orderedItem[i].product['SK']
        delete this.orderedItem[i].product['color']
        delete this.orderedItem[i].product['flavours']
        delete this.orderedItem[i].product['created_at']
        delete this.orderedItem[i].product['box_id']
        delete this.orderedItem[i].product['product_image']
        delete this.orderedItem[i].product['min_qty']
        itemsInCart.push(this.orderedItem[i]);
      }
      local_storage = JSON.parse(localStorage.getItem('cart'));
      if (localStorage.getItem('cart') == null) {
        local_storage = [];
        console.log("LOCALSTORAGE NULL", JSON.parse(localStorage.getItem('cart')));
        localStorage.setItem('cart', JSON.stringify(itemsInCart));
        this.ngOnInit();
        this.router.navigate(['/cookie-cart'])
      }
      else {
        debugger
        local_storage.forEach(function (item) {
          itemsInCart.push(item);
        })
        localStorage.setItem('cart', JSON.stringify(itemsInCart));
        this.ngOnInit();
        this.router.navigate(['/cookie-cart'])
        console.log(local_storage, 'local')
      }
    })
  }
}
