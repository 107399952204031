<div class="container" *ngIf="showSuccess==true">
    <section class="order-success">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="success-image">
                    <img src="../../../assets/tik.png">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="success-msg">
                    <h6>Order Successful</h6>
                    <p>Thank you so much for your order</p>
                </div>
            </div>
        </div>
        <div class="row m-t-20">
            <div class="col-md-4"> </div>
            <div class="col-md-4">
                <div class="row" *ngIf="pickupStatus == 'self_pickup'">
                    <div class="col-md-6 col-lg-4">
                        <div class="address-map mob-text-align">
                            <img src="../../../assets/mapCopy.png">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-8">
                        <div class="address-area mob-text-align mob-m-t-20">
                            <h6>PICKUP LOCATION</h6>
                            <p>{{orderAddress.order.order_address?.building}}</p>
                            <p>{{orderAddress.order.order_address?.city}}, {{orderAddress.order.order_address?.street}}
                            </p>
                            <p>{{orderAddress.order.order_address?.contact_number}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4"> </div>
        </div>
        <div class="row m-t-20">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12" *ngFor="let cartItem of localStorage">
                        <div class="ordered-cookie-border">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td style="width: 18%;">
                                            <img src="{{cartItem.box_img_url}}" class="ordered-img">
                                        </td>
                                        <td class="line-height-39">
                                            <span class="ordered-cookie">
                                                {{cartItem.product.name}}
                                            </span>
                                            <br>
                                            <span class="font-size-16 gray-color">
                                                QAR {{cartItem.product.price}} x {{cartItem.quantity}}
                                            </span>
                                            <br>
                                            <span class="font-size-16 gray-color">
                                                Box color
                                            </span>
                                            <br>
                                            <!-- <span class="font-size-16 pink-color">
                                                This cookie box contains gift
                                            </span> -->
                                        </td>
                                        <td class="float-right m-t-50">
                                            <span class="font-size-16 pink-color">
                                                QAR {{cartItem.product.price*cartItem.quantity}}
                                            </span>
                                            <br>
                                            <span class="font-size-16 gray-color">
                                                {{cartItem. box_color}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-1"></div> -->

            <div class="col-md-6 ordered-summary-border mob-m-15">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-borderless table-responsive">
                            <tbody>
                                <tr>
                                    <td class="order-summary">Order Summary</td>
                                </tr>
                                <tr class="summary-column-line-height">
                                    <td class="dark-gray-color">Order date</td>
                                    <td class="float-right">{{orderData.placed_date | date:'dd-MM-YYYY'}}</td>
                                </tr>
                                <tr class="summary-column-line-height">
                                    <td class="dark-gray-color">Order reference</td>
                                    <td class="float-right">{{orderData.reference_id}} </td>
                                </tr>
                                <tr class="summary-column-line-height">
                                    <td class="dark-gray-color">Delivery date</td>
                                    <td class="float-right">{{orderData.delivery_date}} </td>
                                </tr>
                                <tr class="summary-column-line-height">
                                    <td class="dark-gray-color">Delivery time</td>
                                    <td class="float-right">{{orderData.delivery_time}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td class="gray-color">Order Amount</td>
                                    <td class="float-right">QAR {{orderData?.order_amount}}</td>
                                </tr>
                                <tr>
                                    <td class="gray-color">Delivery Fee</td>
                                    <td class="float-right">QAR {{deliveryCharge}}.00</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td class="float-right pink-color">QAR {{orderData?.order_total}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-12 mt-4 mb-4">
                        <button class="btn btn-snape-redOut w100 text-uppercase mb-3" (click)="trackOrder()">Track
                            order</button>
                        <button class="btn btn-snape-red w100 text-uppercase" (click)="continueShopping()">Continue
                            Shopping</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- order completed ends  -->
<div *ngIf="showFailed==true">
    <br>
    <h5 class="text-center"> Order failed</h5>
</div>