<!-- https://bbbootstrap.com/snippets/meet-our-team-section-hover-social-icons-16498436 -->
<!-- https://stackblitz.com/edit/angular-multi-file-upload-preview?file=app%2Fhello.component.ts -->

<section class="bg-color">

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-2 col-md-4 m-r-minus20">
                <app-profile-sidebar></app-profile-sidebar>
            </div>
            <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
                <div class="card messageCenter m-r-minus30">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a routerLink="/home"
                                                routerLinkActive="router-link-active"><i class="fas fa-home"></i>
                                                Home</a>
                                        </li>
                                        <li class="breadcrumb-item"><a routerLink="/my-orders"
                                                routerLinkActive="router-link-active">My orders</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Message center</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <section class="msg-sec">
                                    <div class="row" *ngFor="let message of allMessages">
                                        <div class="col-md-12">
                                            <div class="row" *ngIf="message.from != loginEmail">
                                                <div class="col-md-1">
                                                    <div>
                                                        <img width="100%" class="profile-img"
                                                            src="../../../assets/avatar.png" />
                                                    </div>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="sysChat pt-0 mt-0">
                                                        <p class="sysChat-msg">{{message.message}}</p>
                                                    </div>
                                                    <div *ngFor="let image of message.image" class="m-b-10 mr-2">
                                                        <img class="uploaded-img" [src]="image" />
                                                    </div>
                                                    <p class="msgtime">{{message.datetime | date:'medium'}}</p>
                                                </div>
                                            </div>
                                            <div class="row reverseRow" *ngIf="message.to != loginEmail">
                                                <div class="col-md-1" *ngIf="profileData?.image">
                                                    <img width="100%" class="profile-img"
                                                        src="{{profileData?.image}}" />
                                                </div>
                                                <div class="col-md-1" *ngIf="!profileData?.image">
                                                    <img width="100%" class="profile-img"
                                                        src="../../../assets/avatar.png" />
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="sysChat pt-0 mt-0 out-going-msg" *ngIf="message.image">
                                                        <p class="sysChat-msg">{{message.message}}</p>
                                                    </div>
                                                    <div *ngFor="let item of message.image" class="m-b-10 mr-2">
                                                        <img class="uploaded-img" src="{{item}}" />
                                                    </div>
                                                    <p class="msgtime">{{message.datetime | date:'MMM dd YYYY, h:mm:a'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div class="row mx-auto">
                                    <div class="col-md-12 col-xs-12 col-lg-12">
                                        <div>
                                            <form [formGroup]="messageForm" style="padding-bottom: 20px;">
                                                <textarea rows="2" placeholder="Type your message" class="form-control"
                                                    formControlName="message" (keyup.enter)="send()"
                                                    (keydown.shift.enter)="newLine($event)"
                                                    id="scrollToChatBox"></textarea>
                                                <button class="btn mCenterAttach" (click)="openFile()">
                                                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                    <input type="file" formControlName="fileSource" id="files"
                                                        name="files[]" multiple style="display:none"
                                                        (change)="handle($event)">
                                                </button>
                                                <button class="btn btn-snape-red mCenterSend" (click)="send()"
                                                    [disabled]="sendBtn">
                                                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                </button>
                                                <div class="d-flex">
                                                    <div *ngFor='let url of urls; let i=index'>
                                                        <div class="box" *ngIf="urls">
                                                            <img [src]="url" class="uploading-img" height="75">
                                                            <br />
                                                            <div class="cover">
                                                                <div class="social" (click)=getIndex(i)><a><i
                                                                            class="far fa-trash-alt"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>