<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>

<section class="bg-color p-50">
  <div class="container">
    <div class="card">
      <div class="card-body">
        <div class="row mt-3 p-b-30">
          <div class="col-md-5 col-lg-5 col-xs-12 my-auto">
            <div class="notification-heading mob-text-center">
              <h4 class="fsize24">Notifications</h4>
            </div>
          </div>
          <div class="col-md-5">
            <div>
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                    aria-controls="pills-home" aria-selected="true" (click)="allNotification()">All</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                    aria-controls="pills-profile" aria-selected="false" (click)="unreadNotification()">Unread</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
                    aria-controls="pills-contact" aria-selected="false" (click)="readNotification()">Read</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-2">
            <div class="clear-btn mob-text-center">
              <button class="btn btn-link snape-red fSize14px" type="button" data-target="#clearAllModal"
                data-toggle="modal">Clear All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="tab-content" id="pills-tabContent">
        <div class="col-md-12 tab-pane fade show active" id="pills-home" role="tabpanel"
          aria-labelledby="pills-home-tab">
          <div class="card" *ngIf="notificationArray?.length != 0">
            <div class="card-body">
              <div class="row p-t-20">
                <div class="col-md-12 col-xs-12 col-lg-12" *ngFor="let notifications of notificationArray">
                  <div class="row card-border" *ngIf="notifications.status == 'read'">
                    <div class="col-md-11 col-xs-12 col-lg-11 cursor-pointer" (click)="redirectPage(notifications)">
                      <div class="row">
                        <div class="col-md-1 col-lg-1 col-xs-12">
                          <div class="msg-icon">
                            <img src="../../../assets/img/notifyGrey.svg" />
                          </div>
                        </div>
                        <div class="col-md-11 col-lg-11 col-xs-12 my-auto">
                          <div class="sub-heading">
                            <p class="my-auto">{{notifications.content}}</p>
                          </div>
                          <div class="notify-date">
                            <p class="fSize14px">
                              {{notifications.created_at | date:'fullDate'}} - {{notifications.created_at |
                              date:'shortTime'}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-lg-1 col-xs-12 my-auto">
                      <div class="delete-icon mt-3">
                        <i class="far fa-trash-alt my-auto" data-target="#delModal" data-toggle="modal"
                          (click)="remove(notifications.id)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="row card-border" *ngIf="notifications.status == 'unread'">
                    <div (click)="updateStatus(notifications)" class="col-md-11 col-xs-12 col-lg-11 cursor-pointer">
                      <div class="row">
                        <div class="col-md-1 col-lg-1 col-xs-12 my-auto">
                          <div class="msg-icon">
                            <img src="../../../assets/unread-msg.png" />
                          </div>
                        </div>
                        <div class="col-md-11 col-lg-11 col-xs-12 my-auto">
                          <div class="sub-heading">
                            <p class="my-auto">{{notifications.content}}</p>
                          </div>
                          <div class="notify-date mt-2">
                            <p class="fSize14px">
                              {{notifications.created_at | date:'fullDate'}} - {{notifications.created_at |
                              date:'shortTime'}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-lg-1 col-xs-12 my-auto">
                      <div class="delete-icon mt-3">
                        <i class="far fa-trash-alt my-auto" data-target="#delModal" data-toggle="modal"
                          (click)="remove(notifications.id)"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- no notification start -->
          <div class="card" *ngIf="notificationArray?.length == 0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xs-12">
                  <div class="notify-date">
                    <p class="fSize14px">
                    <div class="alert alert-warning alert-align" role="alert">
                      <i class="fas fa-exclamation-triangle"> No notification found</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- no notification end -->
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Delete Modal -->
<div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-labelledby="delModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pink-color" id="delModalLabel">Confirm delete?</h6>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row d-flex justify-content-center mb-4">
            Are you sure you want to delete this notification?
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="d-flex justify-content-center align-items-center">
                  <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                  <button data-dismiss="modal" class="btn btn-snape-red" (click)="deleteNotification()"
                    type="button">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- clear all Modal -->
<div class="modal fade" id="clearAllModal" tabindex="-1" role="dialog" aria-labelledby="clearAllModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pink-color" id="clearAllModalLabel">Confirm clear?</h6>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row d-flex justify-content-center mb-4">
            Are you sure you want to clear all notifications?
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                <button data-dismiss="modal" class="btn btn-snape-red" type="button"
                  (click)="clearAll()">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>