import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css', '../../components/breadcrumb/breadcrumb.component.css', './trackTimeLine.scss']
})
export class TrackOrderComponent implements OnInit {
  orderStatus: string;
  active = false;
  classStyle = 'deactive'
  orderDetails
  orderData = []
  placed_date = ''
  processed_date = ''
  accepted_date = ''
  ready_for_delivery_date = ''
  out_for_delivery_date = ''
  delivered_date = '';
  loader = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private api: CommonService
  ) { }

  ngOnInit(): void {
    debugger
    this.activatedRoute.queryParams.subscribe(item => {
      this.orderDetails = item
      console.log(item, this.orderStatus, 'gg')
      this.api.trackOrder(this.orderDetails?.order_id).subscribe(item => {
        console.log('test', item)
        this.orderData = item['order_data']
        this.orderStatus = this.orderData['status']
        this.placed_date = this.orderData['placed_date']
        this.processed_date = this.orderData['processed_date']
        this.accepted_date = this.orderData['accepted_date']
        this.ready_for_delivery_date = this.orderData['ready_for_delivery_date']
        this.out_for_delivery_date = this.orderData['out_for_delivery_date']
        this.delivered_date = this.orderData['delivered_date']

      })
    })
  }
}
