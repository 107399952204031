<section class="bg-color">
    <div class="container p-50">
        <div class="row">
            <div class="col-md-12 card">
                <div class="row">
                    <div class="col-md-5">
                        <div>
                            <button type="button" class="btn m-3 back-btn-icon" [routerLink]="['/home']">
                                <span class="material-icons-outlined btn-snape-white back-icon">
                                    chevron_left
                                </span>
                                <span class="back-btn-span ml-2">Back</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="p-t-25">
                            <h3 class="heading">Terms & Conditions</h3>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <div class="row p-30">
                    <div class="col-md-12">
                        <div>
                            <h5 class="font-size-15">Acceptance of Terms of Use
                            </h5>
                            <p class="secondary-font-color">&emsp;Please read these Terms of Use carefully. These Terms
                                of Use govern your access and use of this Site. By accessing or using this Site you
                                agree to be bound by these Terms of Use and to any additional guidelines, restrictions,
                                or rules that may be posted in connection with specific sections or services of this
                                Site. All such additional posted guidelines, restrictions, or rules are hereby
                                incorporated by reference into these Terms of Use.</p>
                            <p class="secondary-font-color">SnapE Cookies Trading reserves the right to make changes to
                                this Site and to these Terms of Use at any time without prior notice. You should review
                                these Terms of Use each time you access this Site.
                            </p>
                            <p class="secondary-font-color">You also agree that we may provide all legal communications
                                and notices to you electronically by posting them on our website or, at our election, by
                                sending an e-mail to the e-mail address you provided at purchase. You may withdraw your
                                consent to receive communications from us electronically by contacting customer care <a
                                    href="customerservice@snapecookies.com"
                                    target="_blank">customerservice@snapecookies.com.</a>
                            </p>
                            <h5 class="font-size-15"> Copyrighted Materials for Limited Use
                            </h5>
                            <p class="secondary-font-color">&emsp;This Site contains graphics, photographs, images,
                                document layouts, artwork, text, fonts, software tools, and other information (referred
                                to herein as “Content”). This Site and all Content are the copyrighted property of SnapE
                                Cookies Trading orthe copyrighted property of parties from whom SnapE Cookies Trading
                                has licensed such property. All rights in this Site and its Content are reserved
                                worldwide. It is strictly prohibited to retain, copy, distribute, publish, or use any
                                portion of the Content except as expressly allowed in these Terms of Use. SnapE Cookies
                                Trading reserves the right to add to, delete from, or modify any part of Content at any
                                time without prior notice. Any modifications to Content, whether by You or SnapE Cookies
                                Trading remain the property of SnapE Cookies Trading and its licensors.
                            </p>
                            <p class="secondary-font-color">The SnapE Cookies Trading design tools provided on this Site
                                employ a limited number of elements, including icons, fonts, colour schemes, and design
                                effects. SnapE Cookies Trading reserves the right to use all such elements and to make
                                all such elements available for use by other parties in the future. You do not obtain
                                any right or claim to any of the individual SnapE Cookies Trading design elements
                                through your creation of a SnapE Cookies Trading design and/or your incorporation of a
                                SnapE Cookies Trading design into one or more products. Other SnapE Cookies Trading
                                customers may use the design tools to create SnapE Cookies Trading that have similar or
                                identical combinations of these elements and SnapE Cookies Trading does not guarantee
                                that your SnapE Cookies Trading will not have similarities to SnapE Cookies Trading
                                designed and used by other parties. SnapE Cookies Trading provides no warranty of any
                                kind that logo designs created using the design tools will not infringe, or be subject
                                to a claim of infringing, the trademark or other rights of another party. It is solely
                                your responsibility to obtain the advice of an attorney regarding whether the SnapE
                                Cookies Trading design is legally available for your use and does not infringe the
                                rights of another party.
                            </p>
                            <h5 class="font-size-15"> Use of Site
                            </h5>
                            <p class="secondary-font-color">&emsp;You are granted permission to access and use this Site
                                and its Content for the sole purpose of preparing, evaluating, and ordering products or
                                services solely through SnapE Cookies Trading (referred to herein as “Products”). No
                                other download, retention, use, publication, or distribution of any portion of the
                                Content is authorized or permitted. Obtaining Products from SnapE Cookies Trading does
                                not entitle you to use any portion of Content apart from the finished Products as they
                                are supplied by SnapE Cookies Trading.
                            </p>
                            <p class="secondary-font-color">You agree to use this Site in a responsible manner that is
                                in full compliance with these Terms of Use and with your local laws and regulations,
                                including export and import regulations. Without limitation, no portion of Content may
                                be utilized as a trademark or service mark, for any pornographic use, for any unlawful
                                purpose or use, to defame any person, to violate any person’s right of privacy or
                                publicity, to infringe upon any copyright, trade name, trademark, service mark or other
                                intellectual property right of any person or entity. You agree that you will not use the
                                Site to produce Products that are offensive, unlawful, harassing, libelous, threatening,
                                harmful, obscene, malicious or otherwise objectionable. SnapE Cookies Trading may
                                terminate its service to customers found to be using SnapE Cookies Trading to engage in
                                undesirable activities.</p>
                            <p class="secondary-font-color">You are solely responsible for your use of Content in
                                combination with any other images, graphics, text or other materials you incorporate
                                into your Products. You agree that you will not include any text, image, design,
                                trademark, service mark, or any copyrighted work of any third party in your Products
                                unless you have obtained the appropriate authorizations from the owners. You warrant
                                that your Products do not infringe upon any rights of any third party, including
                                copyright, trademark, right of publicity or privacy, and will not libel or defame any
                                third party, and that you have all required rights or permissions necessary to
                                incorporate third party material into your Products. By placing an order on this Site,
                                you warrant that you have all necessary permission, right and authority to place the
                                order and you authorize SnapE Cookies Trading to produce the Products on your behalf.
                                You grant SnapE Cookies Trading the right to copy, modify, create derivative works and
                                vectorize any uploaded or emailed content (i.e., content you have uploaded or emailed)
                                for the purpose of fulfilling your order. Moreover, you warrant that you have sufficient
                                rights to permit SnapE Cookies Trading to copy, modify, create derivative works and
                                vectorize any uploaded or emailed content for the purpose of fulfilling your order.
                            </p>
                            <p class="secondary-font-color">You may not use this site to send SnapE Cookies Trading 's
                                in any manner that would constitute the transmission of Spam or unsolicited e-mails or
                                regular mail.</p>
                            <h5 class="font-size-15">Transfer of Title
                            </h5>
                            <p class="secondary-font-color">&emsp;The customer agrees that ownership transfers to the
                                customer upon shipment. This means that the risk of loss and title for such items pass
                                to you upon our delivery to the carrier.
                            </p>




                            <h5 class="font-size-15"> Indemnification

                            </h5>
                            <p class="secondary-font-color">&emsp;You agree that you shall indemnify and defend SnapE
                                Cookies Trading and all parties from whom SnapE Cookies Trading has licensed portions of
                                Content, and their directors, officers, and employees, against all claims, liability,
                                damages, costs and expenses, including reasonable legal fees and expenses arising out of
                                or related to (i) your breach of these Terms of Use or (ii) any suit, claim, or demand
                                arising from or relating to any text, photograph, image, graphic or other material you
                                incorporated into Products that was not part of the standard Site Content.
                            </p>
                            <h5 class="font-size-15"> Disclaimer of Warranty
                            </h5>
                            <p class="secondary-font-color">&emsp;THE SITE AND ITS CONTENT ARE PROVIDED "AS IS" WITHOUT
                                WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
                                YOU ACKNOWLEDGE THAT OPERATION OF THE SITE MAY NOT BE UNINTERRUPTED OR ERROR FREE.
                                REFERENCES AND LINKS TO PRODUCTS OR SERVICES OF INDEPENDENT COMPANIES MAY APPEAR ON THE
                                SITE. THESE REFERENCES AND LINKS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND,
                                EITHER EXPRESSED OR IMPLIED.
                            </p>
                            <p class="secondary-font-color">Certain circumstances are beyond SnapE Cookies Trading’s
                                control and may not be covered by the Satisfaction Guarantee. Please note that we cannot
                                be responsible for: Spelling, punctuation or grammatical errors made by the customer,
                                inferior quality or low-resolution of uploaded or emailed images, design errors
                                introduced by the customer in the SnapE Cookies Trading creation process, errors in
                                user-selected options such as choice of cookie, quantity or product type, and damage to
                                the products arising after delivery to the customer.

                            </p>
                            <p class="secondary-font-color">Please preview your designs and photos carefully and correct
                                any mistakes prior to placing your order. SnapE Cookies Trading does not proof photos
                                created by its customers prior to processing.
                            </p>
                            <h5 class="font-size-15">Limitation of Liability

                            </h5>
                            <p class="secondary-font-color">&emsp;IN NO EVENT SHALL SnapE Cookies Trading OR ITS
                                LICENSORS, SUPPLIERS, OR VENDORS, THEIR OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE
                                LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR
                                FOR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA, OR PROFITS, WHETHER OR NOT
                                SnapE Cookies Trading HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGE, ARISING OUT OF OR
                                IN CONNECTION WITH THE USE OR PERFORMANCE OF THE SITE OR OF FAILURE TO PROVIDE PRODUCTS
                                OR SERVICES THAT YOU ORDER FROM SnapE Cookies Trading OR ITS AFFILIATES, INCLUDING
                                WITHOUT LIMITATION, DAMAGES ARISING FROM MISTAKE, OMISSION, VIRUS, DELAY, OR
                                INTERRUPTION OF SERVICE. IN NO EVENT SHALL SnapE Cookies Trading BE LIABLE OR
                                RESPONSIBLE FOR ANY DAMAGES OR CONSEQUENCES ARISING FROM OR RELATED TO YOUR
                                INAPPROPRIATE OR UNAUTHORIZED USE OF THIS SITE OR ITS CONTENT.
                            </p>
                            <h5 class="font-size-15">Site Feedback

                            </h5>
                            <p class="secondary-font-color">&emsp;Any comments, suggestions, proposals or other feedback
                                (collectively “Feedback Information”) provided to SnapE Cookies Trading in connection
                                with the operation or content of this Site shall be provided by the submitter and
                                received by SnapE Cookies Trading on a non-confidential basis. All such comments,
                                suggestions, and other information shall become the exclusive property of SnapE Cookies
                                Trading. By submitting any such information to SnapE Cookies Trading, you agree that you
                                are transferring and assigning, at no charge, all of your right, title and interest in
                                the information, including all copyrights and other intellectual property rights. You
                                agree that SnapE Cookies Trading shall be free to use such information on an
                                unrestricted basis.</p>
                            <h5 class="font-size-15">Governing Law
                            </h5>
                            <p class="secondary-font-color">&emsp;The party you are contracting with and the seller of
                                the products and services offered and sold on this Site is SnapE Cookies Trading, the
                                last is a limited company organized under the laws of Qatar. All issues related to the
                                protection, infringement, or misuse of copyrighted materials shall be governed by the
                                copyright laws of the Qatar. All other matters relating to your access to or use of this
                                Site shall be governed by the laws of Qatar. Any legal action or proceeding relating to
                                or arising from your access to or use of this Site shall be instituted in Qatar. You
                                agree to submit to the jurisdiction of courts sitting in Qatar and agree that venue in
                                these courts is proper in any such legal action or proceeding. You also agree that you
                                not to assert in any legal action or proceeding involving SnapE Cookies Trading that a
                                court sitting in Qatar is an inconvenient forum for such legal action or proceeding.
                            </p>
                            <h5 class="font-size-15">Ratification of the Agreement

                            </h5>
                            <p class="secondary-font-color">&emsp;To be a member of the website & associated application
                                of SnapE Cookies Trading , first of all it is required that you fill in the registration
                                form and then agree and undertake to comply with the provisions of the user agreement
                                for SnapE Cookies Trading. By filling in and ratifying the registration form or getting
                                any services or ordering a using this system, the user agrees to be bound by the user
                                agreement of SnapE Cookies Trading.</p>
                            <p class="secondary-font-color">You are responsible for making all arrangements necessary
                                for you to have access to the website & associated application. You are also responsible
                                for ensuring that all persons who access the website & associated application through
                                your Internet connection are aware of these terms and that they comply with them. By
                                viewing, using, accessing, browsing, or submitting any content or material on the site,
                                you agree to these Terms of Use as a binding legal agreement between you and SnapE
                                Cookies Trading, without limitation or qualification. The term "you" or "You" shall
                                refer to any person or entity, who views, uses, accesses, browses or submits any content
                                or material to the site or application. If you do not agree to these Terms of Use, then
                                you may not use the site & application. SnapE Cookies Trading reserves the right to
                                modify these Terms of Use at any time without prior notice. You agree that each visit
                                you make to the sites & application shall be subject to the then-current Terms of Use,
                                and continued use of the site now or following modifications in these Terms of Use
                                confirms that you have read, accepted, and agreed to be bound by such modifications.
                                The terms and conditions apply to SnapE Cookies Trading website and its associated
                                applications on iPhone, iPad, Android, and mobile site platforms
                                .</p>
                            <h5 class="font-size-15">Description of the Services

                            </h5>
                            <p class="secondary-font-color">&emsp;SnapE Cookies Trading allows its members who ratify
                                the agreement to order from its online shop.
                                SnapE Cookies Trading provides a way for you to communicate your orders with
                                customization to delivery shops displayed on SnapE Cookies Trading website and its
                                associated applications.

                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading does not sell or interfere in any way
                                in the production of any food produced at the shops. But what this online market offers
                                to the users is only providing the ability to customize cookies through local shops that
                                deliver or make cookie available to their addresses and enables ordering this cookie
                                through this SnapE Cookies Trading website and its associated applications. The shops
                                are obligated to comply with country local laws, rules, regulations, and standards
                                pertaining to food preparation, sale, marketing and safety.
                            </p>
                            <p class="secondary-font-color">However, it is important for users to understand that SnapE
                                Cookies Trading does not in any way independently verify the credentials,
                                representations or products of shops, the ingredients or the quality of any products, or
                                that a shop is in compliance with applicable laws. Buyers must make themselves
                                comfortable through information provided by shops on the website/application or as
                                requested by buyers directly from the shops as to the quality and reliability of the
                                shops, as well as to their compliance with applicable laws.
                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading does not in any way guaranty the
                                quality of any food or that any food complies with applicable laws and does not
                                guarantee matching the menu displayed on the site of what is actually provided to the
                                users.
                            </p>
                            <p class="secondary-font-color">In addition, a shop may represent that food and cookies
                                preparation is in accordance with special standards such as "organic,""macrobiotic" or
                                allergen-specific standards such as "nut-free", "gluten-free", or "lactose-free".
                                However, SnapE Cookies Trading does not independently investigate or verify such
                                representations.
                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading shall not be liable or responsible for
                                any food and/or cookie or services offered by shops that is unhealthy, is the cause of
                                injury, that is otherwise unacceptable to buyers or that does not meet the expectation
                                of buyers in any manner.</p>
                            <p class="secondary-font-color">Buyers are solely responsible for verifying the accuracy of
                                delivery addresses, and SnapE Cookies Trading shall have no liability or responsibility
                                for any such erroneous addresses. All food preparation and deliveries are the sole
                                responsibility of the shop accepting the order. Please be aware that the shop will do
                                everything they can to meet the delivery time specified, however delivery times may be
                                affected by factors beyond their control and therefore cannot be guaranteed. We will
                                inform you if we become aware of an unexpected delay.
                            </p>
                            <h5 class="font-size-15">Order Cancelation
                            </h5>
                            <p class="secondary-font-color">&emsp;You have the right to cancel an order up to 5 minutes
                                from placing the order on our website or associate application.
                            </p>
                            <p class="secondary-font-color">While every effort is made to ensure that accurate pricing
                                and descriptions are maintained, we reserve the right to cancel any order that is based
                                on inaccurate information.
                            </p>
                            <p class="secondary-font-color">An order may be subsequently cancelled by a restaurant or
                                shop after you have received a confirmation that it has been sent to the restaurant or
                                shop. SnapE Cookies Trading and our partner restaurants or shops reserve the right to
                                cancel any order, before or after acceptance, and will notify you immediately of any
                                such cancellation.</p>
                            <p class="secondary-font-color">“Your history will only hold your successful orders, and in
                                case of failed online payment, the refunded amount will be returned to your respectable
                                account within14 days or less, according to your bank.
                            </p>
                            <h5 class="font-size-15"> Membership system

                            </h5>
                            <p class="secondary-font-color">&emsp;Any member of SnapE Cookies Trading website and its
                                associated applications has a “user name” and “password” which are determined by the
                                member herself/himself. The "user name" is personal to the member and the same “user
                                name” is not given to two different members. The “password” is only known by the user.
                                The user can change the password at any time. It is solely under the responsibility of
                                the user to select and protect the password. SnapE Cookies Trading is not liable for any
                                problems, which may arise from the use of password.. SnapE Cookies Trading does not
                                share the user information with the third party companies – the phone number may be
                                shared with the restaurant or shop which deals with take-out foods, in order to provide
                                a faster and correct delivery. For the user to connect to the services, which require a
                                membership of SnapE Cookies Trading website and its associated applications, the user
                                must enter the user name and password.
                                By using the website and its associated applications, you warrant that any personal
                                information provided by you is true, accurate, current and complete in all respects and
                                that you will notify us immediately of any changes to your personal information by
                                updating your member profile on the site or by contacting us, you agree not to
                                impersonate any other person or entity or to use a false name or a name that you are not
                                authorized to use.
                            </p>
                            <p class="secondary-font-color">You are not allowed to register multiple memberships for use
                                by the same person. Violation of this clause may result in termination of your
                                membership.
                            </p>
                            <p class="secondary-font-color">Members' passwords should be kept confidential at all times,
                                and must not be shared or disclosed to anyone. You are responsible for all activities
                                and orders that occur or are submitted through your membership. If you know or suspect
                                that someone has knowledge of your password then you should contact us immediately.
                            </p>
                            <p class="secondary-font-color">You may not use the site for any unlawful or unauthorized
                                purpose, including without limitation to:
                            </p>
                            <p class="secondary-font-color">- Distributing any unlawful, libelous, abusive, threatening,
                                harmful, obscene or otherwise objectionable material
                            </p>
                            <p class="secondary-font-color">- Interfering with any other persons use or enjoyment of the
                                site.
                            </p>
                            <p class="secondary-font-color">- Damaging, disabling or impairing the operation of this
                                site or attempting to gain unauthorized access to this site or to networks connected to
                                it, through hacking, spoofing or other such similar means.
                            </p>
                            <p class="secondary-font-color">- We expect a device to hold one customer account only. We
                                may allow service in such cases but we will not allow the use of promotions.
                            </p>
                            <h5 class="font-size-15">Responsibilities of the Member

                            </h5>
                            <p class="secondary-font-color">&emsp;SnapE Cookies Trading website and its associated
                                applications member accepts and undertakes:
                                The information in the registration form is correct; in the conditions that this
                                information is required the member is the only responsible for any losses which may
                                arise from any misinformation or inadequate information (ex. forgetting the password);
                                and in these cases his/her membership may be terminated.
                            </p>
                            <p class="secondary-font-color">The member is the sole responsible for all personal ideas,
                                opinions, statements set forth by the member while using the services of SnapE Cookies
                                Trading, all the files uploaded to SnapE Cookies Trading website and its associated
                                applications and the personal information sent, and that in no way SnapE Cookies Trading
                                shall be held liable for these files, not to access any services provided in SnapE
                                Cookies Trading unauthorized and in any way other that the one identified by SnapE
                                Cookies Trading; not to change the software in any way; not to use the software which is
                                identified as being changed; and to indemnify SnapE Cookies Trading for all material and
                                moral losses in the event the member does not comply with the aforementioned articles,
                                SnapE Cookies Trading shall not be responsible for any damages which may arise from
                                reading of data belonging to any members by any unauthorized people, not to send any
                                messages which are threatening, immoral, racially and contrary to the Laws of Qatar and
                                any international agreements, any correspondences, titles, nicknames posted to the
                                context shall not be contrary to the rules of public morality, good manners and laws,
                                not to harass and/or threaten other users, or any of SnapE Cookies Trading live chat
                                personnel, not to act in a way affecting the use of services by other users, not to
                                post, print, distribute, circulate any amoral, inappropriate and unlawful materials and
                                information which may give harm to the names of any people or institutions, not to
                                advertise, sell or offer to sell any products or services, engage in any activities such
                                as survey, competition or chain letter, not to send any information or programs which
                                may give harm to the information or software in the computers of other users,
                                All of the records or materials obtained by using the services of SnapE Cookies Trading
                                website and its associated applications are within the consent of the user; the user
                                shall be absolutely responsible for any damages, loss of information or other losses
                                caused by them in the user’s computer; the user shall not demand any compensation for
                                any losses arising from the use of the service, not to use the services of SnapE Cookies
                                Trading for any commercial and advertising purposes without obtaining the consent of
                                SnapE Cookies Trading may monitor all system at any time or continuously.

                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading may use its system for commercial
                                purposes, not to mail any information which is forbidden legally and circulate any mails
                                which do not have authorization to be sent such as chain mail, malicious software, etc.,
                                not to record and misuse any personal information belonging to others, The member
                                himself/herself is responsible for all kinds of acts made under the “username”, The
                                clients must pay the service fee which is notified previously at the time of delivery of
                                products; otherwise the products shall not be delivered to the clients. not to access
                                the site or services using a third-party's account/registration without the express
                                consent of the account holder; not to use the site for illegal purposes; not to commit
                                any acts of infringement on the site or with respect to content on the site; not to use
                                the site to engage in commercial activities apart from sanctioned use of SnapE Cookies
                                Trading services; not to copy any content, including, but not limited to shop menu
                                content and third-party reviews, for republication in print or online; not to create
                                shop reviews or blog entries for or with any commercial or other purpose or intent that
                                does not in good faith comport with the purpose or spirit of the site; not to attempt to
                                gain unauthorized access to other computer systems from or through the site; not to
                                interfere with another person's use and enjoyment of the site or another entity's use
                                and enjoyment of the site; not to upload or transmit viruses or other harmful,
                                disruptive or destructive files; and/ornot to disrupt, interfere with, or otherwise harm
                                or violate the security of the site, or any services, system resources, accounts,
                                passwords, servers or networks connected to or accessible through the site or affiliated
                                or linked sites (including those of our shop partners).
                            </p>
                            <p class="secondary-font-color">All users who are minors in the jurisdiction in which they
                                reside (generally under the age of 18) must have the permission of, and be directly
                                supervised by, their parent or guardian to use the website. If you are a minor, you must
                                have your parent or guardian read and agree to this Agreement prior to you using the
                                website.</p>
                            <p class="secondary-font-color">Credit or Debit cards used in placing orders through the
                                online payment gateway on SnapE Cookies Trading website or applications must belong to
                                the user. Otherwise, the user must attain the legal permission from the card owner to
                                perform the transaction.</p>
                            <h5 class="font-size-15">Visitor Material and conduct

                            </h5>
                            <p class="secondary-font-color">&emsp;You are prohibited from posting, uploading or
                                transmitting to or from this website any material that:
                                Breaches any applicable local, national or international law; Is unlawful or fraudulent.
                            </p>
                            <p class="secondary-font-color">Amounts to unauthorized advertising; or Contains viruses or
                                any other harmful programs.
                                You may not misuse the website (including by hacking).Any comments or feedback that you
                                submit through the website must not:
                            </p>
                            <p class="secondary-font-color">Contain any defamatory, obscene or offensive material;
                            </p>
                            <p class="secondary-font-color">- Promote violence or discrimination;
                            </p>
                            <p class="secondary-font-color">- Infringe the intellectual property rights of another
                                person;
                            </p>
                            <p class="secondary-font-color">- Breach any legal duty owed to a third party (such as a
                                duty of confidence);</p>
                            <p class="secondary-font-color">- Promote illegal activity or invade another’s privacy;
                            </p>
                            <p class="secondary-font-color">- Give the impression that they originate from us; or
                            </p>
                            <p class="secondary-font-color">- Be used to impersonate another person or to misrepresent
                                your affiliation with another person.
                            </p>
                            <p class="secondary-font-color">-The prohibited acts listed in the above paragraphs are
                                non-exhaustive. You will pay SnapE Cookies Trading for all costs and damages which it
                                incurs as a result of you breaching any of these restrictions.
                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading will fully co-operate with any law
                                enforcement authorities or court order requesting or directing us to disclose the
                                identity or location of anyone posting any material in breach of the above paragraph.
                            </p>
                            <h5 class="font-size-15">Prohibited Activities

                            </h5>
                            <p class="secondary-font-color">&emsp;You may not access or use the website for any other
                                purpose other than that for which pay SnapE Cookies Trading makes it available. The
                                website is for the personal use of users only and may not be used in connection with any
                                commercial endeavors except those that are specifically endorsed or approved by SnapE
                                Cookies Trading. In case of any prohibited activities committed by the user, the company
                                has the right to suspend, block or terminate the user's membership and prevent his
                                access to log in the service. Prohibited activities include but not limited to the
                                following:
                            </p>
                            <p class="secondary-font-color">Criminal or tortuous activity, including child pornography,
                                fraud, trafficking in obscene material, drug dealing, gambling, harassment, stalking,
                                spamming, copyright infringement, patent infringement, or theft of trade secrets;
                            </p>
                            <p class="secondary-font-color">Advertising to, or solicitation of, any user to buy or sell
                                any products or services, unless authorized by pay SnapE Cookies Trading;
                            </p>
                            <p class="secondary-font-color">Systematic retrieval of data or other content from the
                                website to create or compile, directly or indirectly, a collection, compilation,
                                database or directory without written permission from pay SnapE Cookies Trading;
                            </p>
                            <p class="secondary-font-color">Making any unauthorized use of the pay SnapE Cookies Trading
                                services, including collecting usernames and/or email addresses of users by electronic
                                or other means for the purpose of
                                sending unsolicited email;
                            </p>
                            <p class="secondary-font-color">Engaging in unauthorized framing of or linking to the
                                website;
                            </p>
                            <p class="secondary-font-color">Transmitting chain letters or junk email to other users;</p>
                            <p class="secondary-font-color">Using any information obtained from the website in order to
                                contact, advertise to, solicit, or sell to any user without their prior explicit
                                consent;</p>
                            <p class="secondary-font-color">Engaging in any automated use of the system, such as using
                                scripts to add friends or send comments or messages, or using any data mining, robots or
                                similar data gathering and extraction tools;
                            </p>
                            <p class="secondary-font-color">Interfering with, disrupting, or creating an undue burden on
                                the website or the networks or services connected to the website; Attempting to
                                impersonate another user or person;
                                Using the username of another user; Selling or otherwise transferring your profile;
                                Using any information obtained from the website in order to harass, abuse, or harm
                                another person; Using the pay SnapE Cookies Trading service as part of any effort to
                                compete with pay SnapE
                            </p>
                            <p class="secondary-font-color">Cookies Trading or to provide services as a service office;
                            </p>
                            <p class="secondary-font-color">Deciphering, decompiling, disassembling or reverse
                                engineering any of the software comprising or in any way making up a part of the
                                website;</p>
                            <p class="secondary-font-color">Attempting to bypass any measures of the website designed to
                                prevent or restrict access to the website, or any portion of the website;</p>
                            <p class="secondary-font-color">Harassing, annoying, intimidating or threatening pay SnapE
                                Cookies Trading company through any medium of communication or to any of its employees
                                or agents engaged in providing any part of pay SnapE Cookies Trading Company services to
                                you.</p>
                            <p class="secondary-font-color">Displaying an advertisement, or accepting payment or
                                anything of value from a third person in exchange for your performing any commercial
                                activity on or through the website on behalf of that person, such as posting blogs or
                                bulletins with a commercial purpose;</p>
                            <p class="secondary-font-color">Deleting the copyright or other proprietary rights notice
                                from any Contribution or pay SnapE Cookies Trading Content; and Using the website in a
                                manner inconsistent with any and all applicable laws and regulations.</p>
                            <p class="secondary-font-color">Any other suspected malicious activities on SnapE Cookies
                                Trading website or applications.</p>
                            <h5 class="font-size-15">Authority of SnapE Cookies

                            </h5>
                            <p class="secondary-font-color">&emsp;SnapE Cookies Trading may temporary suspend or
                                completely cease the system operation at any time.</p>
                            <p class="secondary-font-color">SnapE Cookies Trading shall not have any responsibilities
                                against the members of SnapE Cookies Trading or any third parties due to the temporarily
                                suspension or completely cessation of the system operation.
                            </p>
                            <p class="secondary-font-color">The user name and password shall be displayed and approved
                                after the filling of the application parts to be submitted to the clients by SnapE
                                Cookies Trading. SnapE Cookies Trading may prevent its clients who have filled in all
                                parts or have connected although they have passwords from having a new password or from
                                using their passwords for an indefinite period of time if it is deemed necessary by
                                SnapE Cookies Trading.
                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading does not warrant that its services
                                shall be provided on time in a secure and complete way, the results obtained from the
                                services shall be correct and reliable, and that the service quality shall meet the
                                expectations.
                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading has the right to back-up and delete
                                all or some of the files, messages which are provided by the members during the use of
                                services for some periods deemed suitable by SnapE Cookies Trading. SnapE Cookies
                                Trading shall not be held responsible for back-up and delete processes.
                            </p>
                            <p class="secondary-font-color">SnapE Cookies Trading has the ownership and copyrights
                                arising from the ownership of the information, documents, software, designs, graphics
                                and etc. which are produced by itself and/or bought from outside.
                            </p>
                            <p class="secondary-font-color">The sales of SnapE Cookies Trading are limited to the
                                current availability of menus of the firms provided in SnapE Cookies Trading website or
                                associate application. SnapE Cookies Trading is not responsible for any item ordered and
                                cancelled by the shop due to unavailability.</p>
                            <p class="secondary-font-color">SnapE Cookies Trading has right to post any information,
                                documents, software, designs, graphics, etc. (such as the messages, poets, reports/news,
                                file posted to the bulletin board) which are member generated and uploaded to the system
                                by the members and/or to transmit them to another place within the site deemed as
                                appropriate by SnapE Cookies Trading. It is probable that this information is copied
                                and/or posted by other users. In such cases, the users shall not demand any royalty from
                                SnapE Cookies Trading</p>
                            <p class="secondary-font-color">The obligation to change the information in relation to the
                                price and product specifications, which are provided to sell in SnapE Cookies Trading,
                                belongs to the companies which have cyber shops in SnapE Cookies Trading website or
                                associated application. In the event of any misinformation relating to the price and
                                specifications of products, SnapE Cookies Trading may correct this mistake by cancelling
                                the order.</p>
                            <p class="secondary-font-color">SnapE Cookies Trading may provide transition for a member to
                                other websites. In this case, the member agrees that SnapE Cookies Trading shall not be
                                responsible for the context of the websites, which the member may access through its
                                site.</p>
                            <p class="secondary-font-color">SnapE Cookies Trading may make any changes in the
                                implementation of this agreement, and amend the existing articles or add new articles
                                for the purpose of complying with any technical necessities and legislation which shall
                                come into being in the future.</p>
                            <p class="secondary-font-color">SnapE Cookies Trading may change in time the services for
                                which any membership is not required to a form, which requires membership. SnapE Cookies
                                Trading may provide addition services, change some of the services partially or
                                completely, or transform into a paid service.</p>
                            <p class="secondary-font-color">This site may be unavailable at certain times to allow for
                                maintenance and upgrades. Although we will endeavor to notify customers in advance of
                                any service unavailability, this cannot be guaranteed and we reserve the right to alter
                                or withdraw the service at any time.</p>
                            <h5 class="font-size-15">Termination of agreement

                            </h5>
                            <p class="secondary-font-color">&emsp;SnapE Cookies Trading may terminate the relationship
                                arising from these Terms unilaterally at any time.
                            </p>
                            <h5 class="font-size-15">Restriction for Non-Personal and Non-Commercial Use

                            </h5>
                            <p class="secondary-font-color">&emsp;You agree that the consequences of commercial use or
                                re-publication of content or information of SnapE Cookies Trading, may lead to serious
                                and incalculable monetary compensation that may not be a sufficient or appropriate
                                remedy and that SnapE Cookies Trading will be entitled to temporary and permanent
                                injunctive relief to prohibit such use.
                            </p>
                            <h5 class="font-size-15">Product Delivery

                            </h5>
                            <p class="secondary-font-color">&emsp;During any product delivery made by shops, if the user
                                is not available in the address specified in the system, the products ordered by the
                                user shall not deliver to anywhere else. Within the framework of such a case, the member
                                must accept all legal responsibilities arising from ordering a meal to an address where
                                he/she does not exist.
                            </p>
                            <h5 class="font-size-15">Payment Methods

                            </h5>
                            <p class="secondary-font-color">&emsp;1.Customer can choose between different payment
                                methods provided on the platforms, which are currently the following: Cash on Delivery,
                                local debit cards and credit cards.
                            </p>
                            <p class="secondary-font-color">2.SnapE Cookies Tradingreserves the right to provide other
                                payment methods or to no longer offer certain payment methods.
                            </p>
                            <p class="secondary-font-color">3.Customer bindingly chooses the payment method when placing
                                the respective order, provided that the customer chooses an online payment method, the
                                payment will be processed by an external online payment provider cooperating with SnapE
                                Cookies Trading.
                            </p>
                            <p class="secondary-font-color">4.Card data will be stored for future orders by the external
                                online payment providers, on the condition that the customer has given consent to the
                                storage and future usage.
                            </p>
                            <p class="secondary-font-color">5.. Customer is obliged to ensure sufficient cover of the
                                respective account or, when using credit card, to use the credit card only within the
                                card transaction limit. Customer has to refrain from causing unauthorized debit charge
                                backs.
                            </p>
                            <h5 class="font-size-15">Product Return

                            </h5>
                            <p class="secondary-font-color">&emsp;In relation to any product returns, the terms of
                                product return belonging to the restaurants are applied. SnapE Cookies Trading does not
                                undertake any responsibilities for the disputes, which may arise from terms of product
                                return belonging to the restaurants.
                            </p>
                            <h5 class="font-size-15">Refund Policy

                            </h5>
                            <p class="secondary-font-color">&emsp;SnapE Cookies Trading takes user satisfaction very
                                seriously. In the case of problems with your food order, please contact SnapE Cookies
                                Trading through our live chat or call us on our hotline number and we will assist you.
                                In appropriate cases, if you have already been billed by SnapE Cookies Trading on behalf
                                of the shop, SnapE Cookies Trading will issue full or partial refunds. In the following
                                cases: if you did not receive your order or received an incorrect order, you may be
                                issued a full refund; if part of your order is missing, we may issue a partial refund.
                                In every event, we will do our best to ensure your satisfaction.
                            </p>
                            <h5 class="font-size-15">Price and Payment

                            </h5>
                            <p class="secondary-font-color">&emsp;Once your order has been accepted, this represents an
                                agreement between you the user and the shop. The shop has sole responsibility for this
                                order. Cash payments are made directly to the shop upon delivery to fulfill the order.
                                If you have chosen to pay with credit or debit card on the Platform then the transaction
                                is processed by SnapE Cookies Trading as agent of the shop. Delivery charges are
                                calculated per order and based on the policy of the individual shop. Any delivery
                                charges or any other fees will be displayed clearly in your Order Summary.

                            </p>
                            <h5 class="font-size-15">Debit / Credit Cards:

                            </h5>
                            <p class="secondary-font-color">&emsp;Please read the following terms of use and disclaimers
                                carefully before using Debit / Credit Cards, whenever it is available:

                            </p>
                            <p class="secondary-font-color">The user is liable to pay by cash upon delivery any extra
                                charges the shop might charge as a result of the "Special Request" or the "General
                                Request" placed by the user.
                                You may cancel the order if the delivery time exceeded the shop delivery promise time;
                                your paid amount will be refunded back to your account.
                                The user order cancellation is limited to a maximum time of 5 minutes from the time of
                                placing the order.

                            </p>
                            <p class="secondary-font-color">The customer refund procedure might 14 working days to be
                                initiated by SnapE Cookies Trading, however, the time needed to process it on the Debit
                                /Credit Cards bank payment gateway largely depends on the operations of the Central Bank
                                of Iraq. The customer has to follow on with the bank in case of any delay in crediting
                                back the customer’s account with the amount previously paid by the user. We will send an
                                email to the user that contains a printout of the refund advice printed from Debit /
                                Credit Cards bank payment gateway as reference in case the customer wants to revise the
                                bank with.
                            </p>
                            <p class="secondary-font-color">Users using the Debit / Credit Cards facility are requested
                                to be available on their respective contact numbers.
                            </p>
                            <p class="secondary-font-color">The user would be reimbursed by the shop in case the shop
                                faced the inconvenience of a missing item (Reimbursement applicable only for the missing
                                item and is subject to the shop own reimbursement policy).
                            </p>
                            <p class="secondary-font-color">Credit or Debit cards used in placing orders through the
                                online payment gateway on the Platform must belong to the user. Otherwise, the user must
                                attain the legal permission from the card owner to perform the transaction.

                            </p>
                            <p class="secondary-font-color">The user is entirely liable for placing an order using the
                                Debit / Credit Cards facility after carefully reading all the terms & conditions.
                            </p>
                            <h5 class="font-size-15">Governing Law & competent

                            </h5>
                            <p class="secondary-font-color">&emsp;These Website Terms shall be governed by and construed
                                in accordance with the law. Disputes arising in connection with these Website Terms
                                (including non-contractual disputes) shall be subject to the exclusive jurisdiction of
                                the courts.
                            </p>
                            <h5 class="font-size-15">Indemnity

                            </h5>
                            <p class="secondary-font-color">&emsp;You agree to indemnify and hold SnapE Cookies Trading
                                , its parents, subsidiaries and affiliates, agents, officers, directors, or other
                                employees harmless from any claim, demand, or damage (whether direct, indirect, or
                                consequential), including reasonable attorneys' fees, made by anyone in connection with
                                your use of the site, with your submissions, with any alleged infringement of
                                intellectual property or other right of any person or entity relating to the site, your
                                violation of these Terms of Use, and any other acts or omissions relating to the site.
                            </p>
                            <h5 class="font-size-15">Disclaimer
                            </h5>
                            <p class="secondary-font-color">&emsp;THE INFORMATION, CONTENT, PRODUCTS, SERVICES, AND
                                MATERIALS AVAILABLE THROUGH THE SITE (WHETHER PROVIDED BY SnapE Cookies Trading, YOU,
                                OTHER USERS OR OTHER AFFILIATES/THIRD PARTIES), INCLUDING WITHOUT LIMITATION,
                                FOOD/BEVERAGE ORDERS, SUBMISSIONS, TEXT, PHOTOS, GRAPHICS, AUDIO FILES, VIDEO, AND
                                LINKS, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER
                                EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, SnapE Cookies
                                TradingDISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT
                                NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                                NONINFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND IMPLIED WARRANTIES ARISING FROM COURSE
                                OF DEALING OR COURSE OF PERFORMANCE
                            </p>
                            <h5 class="font-size-15">ALLERGEN INFORMATION
                            </h5>
                            <p class="secondary-font-color">&emsp;Most of our bakeries, restaurants and shops regularly
                                use wheat, eggs, soy, milk, peanuts, and tree nut products and others. Please keep in
                                mind that since these products are used, even though non allergy cookies are prepared on
                                food safety standard sanitized equipment there is still a high risk of cross
                                contamination. Always assume an allergen could be present in the products if you have a
                                very high sensitivity. There can always be traces of allergens.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>