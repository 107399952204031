import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomvalidationService } from '../../common/customvalidation.service'
import { CommonService } from '../../common/common.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from './interfaces/image-cropped-event.interface';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss', '../../components/breadcrumb/breadcrumb.component.css']
})
export class EditProfileComponent implements OnInit {

  croppedImage: any = 'assets/avatar.png';
  imageChangedEvent: any = '';
  containWithinAspectRatio = false;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  showCropper = false;
  profileForm: FormGroup;
  resetPasswordForm: FormGroup;
  otpForm: FormGroup;
  profileData;
  imageURL: string;
  myImageUrl;
  demoImg: string = 'assets/avatar.png'
  userName: any;
  userDetails: any;
  loadedImage: HTMLImageElement;
  blob: Blob;
  submitted = false;
  numberLogin:any
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService,
    private token: TokenStorageService,
    private customValidator: CustomvalidationService,
  ) { 
    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      image: [''],
      phoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
    })
  }

  ngOnInit(): void {
    this.userDetails = this.token.getUser()
    let number = localStorage.getItem('loginNumber')
    console.log(number)
    if(number != null){
      this.numberLogin = true
    }
    this.api.getProfile().subscribe(result => {
      this.profileData = result['data']['Item']
      this.profileForm.patchValue({
        name: this.profileData?.name,
        phoneNumber: this.profileData?.phone_number
      })
      console.log(this.profileData, 'data')
      this.imageURL = this.profileData.image
    })
   
    this.getResetFormArray();
  }
  getResetFormArray() {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      cpassword: [''],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'cpassword'),
      }
    );
    this.otpForm = this.fb.group({
      otp: ['']
    })
  }
  continuePass() {
    this.submitted = true
    this.resend();
  }
  changePassword() {
    console.log('sdad', 'data')
    let data = {
      "username": this.profileData.email,
      "password": this.resetPasswordForm.controls.password.value,
      "code": this.otpForm.controls.otp.value
    }
    this.api.changePassword(data).subscribe(res => {
      console.log(res, 'res')
      let message = res['message'];
      this.toastr.success(message)
      let status = res['status']
      if (status == true) {
        this.api.loggedIn.next(false);
        this.token.signOut()
        this.router.navigate(['/login']).then(() => {
          window.location.reload();
        });
      }
    })
  }
  resend() {
    let data = {
      "username": this.profileData.email
    }
    this.api.resendPasswordOtp(data).subscribe(res => {
      let message = res['message'];
      this.toastr.success(message)
      console.log(res, 'res')
    })
  }
  saveProfile() {
    this.submitted = true
    let data = {
      "name": this.profileForm.controls.name.value,
      "phone_number": this.profileForm.controls.phoneNumber.value
    }
    let file = ''
    this.api.profileUpdate(data, file).subscribe(res => {
      
      let message = res['message'];
      this.toastr.success(message)
      this.ngOnInit();
      console.log(res, 'res')
    })
  }
  showPreview(event) {
    const file = event.target.files[0];
    this.profileForm.patchValue({
      image: file
    });
    this.profileForm.get('image');

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(file)
    console.log(file)
  }

  fileChangeEvent(event: any): void {
    console.log(event)
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.loadedImage = this.croppedImage.split(";");
    var contentType = this.loadedImage[0].split(":")[1];
    var realData = this.loadedImage[1].split(",")[1];
    this.blob = this.b64toBlob(realData, contentType, 512!);
  }
  base64ToFile(base64Image: string): Blob {
    console.log(base64Image, 'base')
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  imageLoaded() {
    // show cropper
    this.showCropper = true;
    console.log('Image loaded');
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  click() {

  }
  updateImage() {
    let data = {
      "name": this.profileData.name,
      "user_status": this.profileData.user_status
    }
    // let data = {
    //   "name": this.profileData.name,
    //   "status": this.profileData.user_status
    // }
    let formdata = new FormData();
    _.each(data, function (value, key) {
      console.log(formdata)
      formdata.append(key, value)
    })
    console.log(data, formdata)
    let file = { "filename": this.blob };
    console.log(data, file)
    this.api.profileUpdate(data, file).subscribe(res => {
      if (res) {
        this.toastr.success("Profile image updated successfully")
        window.location.reload();
      }
      console.log(res, 'res')
    })
  }
  deactivateAccount() {
    let data = {
      "name": this.profileData.name,
      "user_status": "inactive",
    }
    // let data = {
    //   "name": this.profileData.name,
    //   "status": "inactive"
    // }
    let formdata = new FormData();
    _.each(data, function (value, key) {
      console.log(formdata)
      formdata.append(key, value)
    })
    console.log(data, formdata)
    let file = ''
    console.log(data, file)
    this.api.profileUpdate(data, file).subscribe(res => {
      console.log(res, 'res')
      if (res['status'] == true) {
        this.api.loggedIn.next(false);
        window.sessionStorage.clear();
        this.router.navigate(['/login']);
      }
    })

  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}