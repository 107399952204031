<nav class="navbar container navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span>
      <i class="fas fa-bars" width="120" height="80"></i>
    </span>
  </button>
  <div class="only-mobile-view">
    <a class="navbar-brand" href="#">
      <img src="../../../assets/logo-without-sloth.png" alt="" width="120" />
    </a>
  </div>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <div class="col-md-12">
      <div class="row d-flex align-items-center">
        <div class="col-md-3 col-lg-3 col-xs-12 only-show-large-screen">
          <a class="navbar-brand" href="#">
            <img src="../../../assets/img/snapeLogo.svg" alt="" width="120" height="80" />
          </a>
        </div>
        <div class="col-md-12 col-xs-12 col-lg-9">
          <ul class="navbar-nav nav-flexend mr-auto mt-2 mt-lg-0">
            <li class="nav-item active m-r-30">
              <a class="nav-link" routerLink="/home" routerLinkActive="router-link-active">Home <span
                  class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item m-r-30">
              <a class="nav-link" routerLink="/products" routerLinkActive="router-link-active">Order now</a>
            </li>
            <li class="nav-item m-r-30">
              <a class="nav-link" routerLink="/about-us" routerLinkActive="router-link-active">About us</a>
            </li>
            <li class="nav-item m-r-30">
              <a class="nav-link" routerLink="/how-we-work" routerLinkActive="router-link-active">How we work</a>
            </li>
            <li class="nav-item m-r-30">
              <a class="nav-link" routerLink="/contact-us" routerLinkActive="router-link-active">Contact us</a>
            </li>
            <li class="nav-item mt-2 prel-l10 m-r-30" *ngIf="isLoggedIn">
              <span class="material-icons-outlined cursor-pointer" routerLink="/notifications">
                notifications
              </span>
              <span class='badge badge-warning cursor-pointer' id='notificationCount' routerLink="/notifications">
                {{notificationLength}} </span>
            </li>
            <li class="nav-item mt-2 m-r-30">
              <span class="material-icons-outlined cursor-pointer fsize23" routerLink="/cookie-cart">
                shopping_bag
              </span>
              <span class='badge badge-warning cursor-pointer' id='lblCartCount' routerLink="/cookie-cart">
                {{cartLength}} </span>
            </li>
            <li class="nav-item m-l-minus-25">
              <div *ngIf="isLoggedIn">
                <div class="dropdown show">
                  <a class="btn btn-trans dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img [src]="userImg" class="userImg-preview">
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" routerLink="/edit-profile">Account</a>
                    <a class="dropdown-item cursor-pointer" (click)="logout()">Logout</a>
                  </div>
                </div>
                <!-- <a class="nav-link" routerLink="/edit-profile">
                  <img [src]="userImg" class="userImg-preview"></a> -->
              </div>
              <span *ngIf="!isLoggedIn">
                <a class="nav-link" routerLink="/login">Log In</a></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#">Disabled</a>
        </li>
      </ul> -->

    <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form> -->
  </div>
</nav>