import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import { CustomvalidationService } from '../../common/customvalidation.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  password;
  show = false;

  public showPassword: boolean;
  public showPasswordOnPress: boolean;

  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService
  ) { }
  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      passwordConfirm: ['', [Validators.required]],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'passwordConfirm'),
      }
    );

    this.password = 'password';
  }
  send() {
    this.submitted = true
    if (this.resetPasswordForm.valid) {
      let data =
      {
        "password": this.resetPasswordForm.controls.password.value
      }
      this.router.navigate(['confirm-password'])
      this.api.resetPassword(data)
      // this.api.confirmPassword(this.resetPasswordForm.controls.password.value).subscribe(res => {
      //   let message = res['message'];
      //   this.toastr.success(message);
      //   let status = res['status']
      //   if (status == true) {
      //     this.router.navigate(['login'])
      //   }
      // })
    }
  }

  showPasswordOnClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
