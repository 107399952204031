<section class="bg-color">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 m-r-minus20">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
      <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
        <div class="card m-r-minus30">
          <div class="card-body height550">
            <div class="row">
              <div class="col-md-12 m-l-minus17">
                <app-breadcrumb></app-breadcrumb>
              </div>
              <div class="col-md-4 m-b-15">
                <div class="card borRad10px">
                  <div class="card-body text-center user-bg-color">
                    <img class="proPc4" src="{{userImg}}" />
                    <h6 class="mt-2">{{userData?.name}}</h6>
                    <p>{{userData?.email}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6" *ngIf="orders?.length == 0">
                    <!-- with out order -->
                    <!-- <div class="text-center mb-3">
                      <img src="../../../assets/Group18780.png">
                      <h5 class="no-order-heading">No Order yet !</h5>
                      <p>You haven't placed any order yet,
                        <br>
                        When you do,their status will appear here
                      </p>
                      <button class="btn btn-snape-red" [routerLink]="['/products']">Explore our cookie</button>
                    </div> -->
                  </div>
                  <!-- with order -->

                  <div class="col-md-6 m-b-15" *ngFor="let order of orders">
                    <div class="card borRad10px">
                      <div class="card-body summary-bg">
                        <div>
                          <p>
                            <span class="status-span">Status : </span>
                            <span class="process-span">{{order.orderstatus}}</span>
                          </p>
                          <p>
                            <span class="order-span">Order Ref : </span>
                            <span class="order-id-span">{{order.id}}</span>
                          </p>
                          <button class="btn btn-snape-red" (click)="trackOrder(order.id)">Track the
                            order</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- old code  start-->
<!-- <section class="bg-color">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 m-r-minus20">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
      <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
        <div class="card m-r-minus30">
          <div class="card-body height550">
            <div class="row">
              <div class="col-md-12">
                <app-breadcrumb></app-breadcrumb>
              </div>
              <div class="col-md-8">
  
                <div class="text-center mb-3" *ngIf="orders?.length == 0"> 
                  <img src="../../../assets/Group18780.png">
                  <h5 class="no-order-heading">No Order yet !</h5>
                  <p>You haven't placed any order yet,
                    <br>
                    When you do,their status will appear here
                  </p>
                  <button class="btn btn-snape-red" [routerLink]="['/products']">Explore our cookie</button>
                </div> 
  
                <div class="card borRad10px mb-3" *ngFor="let order of orders">
                  <div class="card-body summary-bg minh184">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="ml-4">
                          <div class="ml-4">
                            <p>
                              <span>Status:</span>
                              <span>{{order.orderstatus}}</span>
                            </p>
                            <p>
                              <span>Order Ref:</span>
                              <span>{{order.id}}</span>
                            </p>
                            <button class="btn btn-snape-red" (click)="trackOrder(order.orderstatus)">Track the
                              order</button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 zIndex9">
                        <img class="proPc" src="../../../assets/1.jpg" />
                      </div>
                    </div>
                  </div>
                  <img class="btmSumCirc" src="../../../assets/img/circ1.png" />
                  <img class="r8SumCirc" src="../../../assets/img/circ2.png" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="card borRad10px">
                  <div class="card-body minh184 text-center user-bg-color">
                    <img class="proPc4" src="{{userImg}}" />
                    <h6 class="mt-2">{{userData?.name}}</h6>
                    <p>{{userData?.email}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section> -->
<!-- old code end-->