<section class="bg-color">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 m-r-minus20">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
      <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
        <div class="card m-r-minus30">
          <div class="card-body height550">
            <div class="col-md-12 m-l-minus30">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active"><i
                        class="fas fa-home"></i> Home</a></li>
                  <!-- <li class="breadcrumb-item"><a routerLink="/edit-profile" routerLinkActive="router-link-active">Account</a></li> -->
                  <li class="breadcrumb-item active" aria-current="page"> My Profile</li>
                </ol>
              </nav>
            </div>
            <div class="editProfile">
              <div class="container">
                <div class="avatar-upload">
                  <div class="avatar-edit">
                    <input type="file" id="imageUpload" (change)="fileChangeEvent($event)" accept=".png, .jpg, .jpeg"
                      data-toggle="modal" [attr.data-target]="'#scan-page-modal'" />
                    <label for="imageUpload"><i class="fa fa-scam fa-camera"></i></label>
                  </div>
                  <!-- <div class="avatar-preview"> -->
                  <!-- <div id="imagePreview" style="background-image: url('../../../assets/1.jpg');"> -->
                  <img [src]="imageURL!=null ? imageURL : croppedImage " class="avatar-preview">
                  <!-- <input formControlName="image" hidden> -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>

                <div #modal class="modal fade" [attr.id]="'scan-page-modal'" tabindex="-1" role="dialog"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header modal-header-bg-color">
                        <h5 class="modal-title" id="exampleModalLabel">Adjust Image</h5>
                        <button type="button" class="close white-colr" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body model-body-height">
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                          [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3"
                          [resizeToWidth]="256" [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                          [canvasRotation]="canvasRotation" [transform]="transform"
                          [style.display]="showCropper ? null : 'none'" format="png,jfif,jpg,jpeg,*"
                          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                          (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
                      </div>
                      <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->
                      <div class="update-image-btn">
                        <i class="fas fa-check-circle" id="cropImageBtn" data-dismiss="modal"
                          (click)="updateImage()"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="epro-div mob-m-b-10">
                  <p class="title-grey">Full Name</p>
                  <p class="text-overflow">{{profileData?.name}}</p>
                  <span class="cursor-pointer edit edit-icon-position" data-toggle="modal" data-target="#editName">
                    <i class="fa fa-snape-red fa-pencil" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="epro-div mob-m-b-10">
                  <p class="title-grey">Email</p>
                  {{profileData?.email}}
                </div>
              </div>
              <div class="col-md-4" *ngIf="!numberLogin">
                <div class="epro-div">
                  <p class="title-grey">Password</p>
                  <p class="text-overflow">************</p>
                  <!-- <input class="w95" type="password" value="***************" /> -->
                  <span class="cursor-pointer edit edit-icon-position" data-toggle="modal"
                    data-target="#editPasswordCon">
                    <i class="fa fa-snape-red fa-pencil" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="epro-div mob-m-b-10">
                  <p class="title-grey">Phone number</p>
                  <p class="text-overflow">{{profileData?.phone_number}}</p>
                  <!-- <input class="w95" type="text" value="{{profileData?.name}}" /> -->
                  <!-- hide edit phone_number -->
                  <!-- <span class="cursor-pointer edit edit-icon-position" data-toggle="modal" data-target="#editPhone" *ngIf="!numberLogin">
                    <i class="fa fa-snape-red fa-pencil" aria-hidden="true"></i>
                  </span> -->
                   <!-- hide edit phone_number -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mt-3">
                  <p class="deactivate-account" data-target="#deactivateModal" data-toggle="modal">Deactivate Account
                  </p>
                </div>
              </div>
            </div>
            <!-- Change Password -->
            <!-- <div class="row">
            <div class="col-md-12 col-xs-12 col-lg-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="box">
                    <div>
                      <div class="resend-otp-btn">
                        <button type="button" class="btn btn-link">Resend OTP</button>
                      </div>
                      <h6 class="mt-2 font-weight">Change Password</h6>
                    </div>
                    <p>Please enter the OTP sent to admin@links4engg.com</p>
                    <input type="text" class="form-control" placeholder="Enter OTP" />
                    <div class="continue-btn">
                      <button type="button" class="btn btn-snape-red">CONTINUE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

            <!-- Reset Password -->
            <!-- <div class="row">
            <div class="col-md-12 col-xs-12 col-lg-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="box">
                    <h6 class="mt-2 font-weight">Reset Password</h6>
                    <p>Please enter the OTP sent to admin@links4engg.com</p>
                    <input type="text" class="form-control" placeholder="Enter new password" />
                    <input type="text" class="form-control" placeholder="Re enter new password" />
                    <div class="continue-btn">
                      <button type="button" class="btn btn-snape-red">CHANGE PASSWORD</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

            <!-- popups -->
            <div class="modal fade" id="editName" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="mt-2 pink-color">Change name</h6>
                      </div>
                    </div>
                    <form [formGroup]="profileForm">
                      <div class="form-group has-float-label mt-3">
                        <input class="form-control" type="text" placeholder="Enter name here" formControlName="name"
                          required />
                        <label>Name</label>
                      </div>

                      <span class="text-danger"
                        *ngIf="(profileForm.controls.name?.touched || submitted) && profileForm.controls.name.errors?.required">
                        Please enter a name
                      </span>
                      <div class="row">
                        <div class="col-md-12 mfooterBtn">
                          <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                          <button type="button" class="btn btn-snape-red ml-2" data-dismiss="modal"
                            [disabled]="!profileForm.controls.name.value" (click)="saveProfile()">Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- popups -->
            <div class="modal fade" id="editPhone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="mt-2 pink-color">Change phone number</h6>
                      </div>
                    </div>
                    <form [formGroup]="profileForm">
                      <div class="d-flex mt-3">
                        <div>
                          <span class="input-group-text" id="basic-addon3"> +974</span>
                        </div>
                        <div class="input-group form-group has-float-label">
                          <input class="form-control" type="text" placeholder="Phone number"
                            formControlName="phoneNumber" required />
                          <label>Phone number</label>
                        </div>
                      </div>

                      <!-- <div class="form-group has-float-label input-group mt-3">+974
                        <div class="country-code">
                          +974
                        </div>
                        <input class="form-control country-code-txt-box" formControlName="phoneNumber" type="number"
                          placeholder="Phone number" required>
                        <label class="label-margin">Phone number</label>
                      </div> -->

                      <span class="text-danger"
                        *ngIf="(profileForm.controls.phoneNumber?.touched || submitted) && profileForm.controls.phoneNumber.errors?.required">
                        Please enter a phone number
                      </span>
                      <div class="row">
                        <div class="col-md-12 mfooterBtn">
                          <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                          <button type="button" class="btn btn-snape-red ml-2" data-dismiss="modal"
                            [disabled]="!profileForm.controls.phoneNumber.value" (click)="saveProfile()">Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="editEmail" tabindex="-1" role="dialog" aria-labelledby="exampleEmailLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-11">
                        <h6 class="snape-red mt-2">Change email address</h6>
                        <p>{{profileData?.email}}</p>
                      </div>
                      <div class="col-md-1">
                        <button type="button" class="close mt-2" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                    <p class="text-grey fontSize12px">Enter the new email address you would like to associate with your
                      account below.<br>We will send a One Time Password (OTP) to that address.</p>
                    <input type="text" class="form-control mt-4" placeholder="Enter new email id" />
                    <div class="row">
                      <div class="col-md-12 mfooterContinue">
                        <button type="button" class="btn btn-snape-red text-uppercase" data-toggle="modal"
                          data-target="#editEmailCon">Continue</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="editEmailCon" tabindex="-1" role="dialog" aria-labelledby="exampleEmailLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-11">
                        <h6 class="snape-red mt-2">Change email address</h6>
                        <p>admin@links4engg.com to newadmin@links4engg.com</p>
                      </div>
                      <div class="col-md-1">
                        <button type="button" class="close mt-2" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                    <input type="text" class="form-control mt-4" placeholder="Enter OTP" />
                    <input type="text" class="form-control mt-4" placeholder="Enter your password" />
                    <div class="row">
                      <div class="col-md-12 mfooterContinue">
                        <button type="button" class="btn btn-snape-red text-uppercase" data-toggle="modal"
                          data-target="#editEmailCon">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal fade" id="editPassword" tabindex="-1" role="dialog" aria-labelledby="examplePasswordLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <form [formGroup]="otpForm">
                      <h6 class="mt-2 font-weight pink-color">Reset Password</h6>
                      <p class="font-size-13">Please enter the OTP sent to {{profileData?.email}}</p>
                      <!-- <input type="text" class="form-control mt-4" formControlName="otp" placeholder="Enter OTP" required/> -->

                      <div class="form-group has-float-label mt-3">
                        <input class="form-control" type="text" placeholder="Enter OTP" formControlName="otp"
                          required />
                        <label>Enter OTP</label>
                      </div>

                      <span class="text-danger"
                        *ngIf="(otpForm.controls.otp.touched || submitted) && otpForm.controls.otp.errors?.required">
                        Otp is required
                      </span>
                      <p class="snape-red mt-1 fontSize12px d-flex justify-content-end cursor-pointer"
                        (click)="resend()">Resend OTP</p>
                      <div class="row">
                        <div class="col-md-12 mfooterContinue1">
                          <button type="button" class="btn btn-snape-red text-uppercase" [disabled]="otpForm.invalid"
                            (click)="changePassword()">CHANGE PASSWORD</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="editPasswordCon" tabindex="-1" role="dialog"
              aria-labelledby="examplePasswordLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <form [formGroup]="resetPasswordForm">
                      <div class="row">
                        <div class="col-md-12 col-xs-12 col-lg-12">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="m-b-20">
                                <h6 class="mt-2 pink-color">Reset Password</h6>
                                <!-- <input type="password" class="form-control" formControlName="password"
                                  placeholder="Enter new password" required /> -->

                                <div class="form-group has-float-label mt-3">
                                  <input class="form-control" type="password" placeholder="Enter new password"
                                    formControlName="password" required />
                                  <label>Enter new password</label>
                                </div>

                                <span class="text-danger"
                                  *ngIf="(resetPasswordForm.controls.password.touched || submitted) && resetPasswordForm.controls.password.errors?.required">
                                  Password is required
                                </span>
                                <span class="text-danger"
                                  *ngIf="resetPasswordForm.controls.password.touched && resetPasswordForm.controls.password.errors?.invalidPassword">
                                  Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                                  letter, 1 special character and 1 number
                                </span>
                              </div>
                              <div class="m-b-20">
                                <!-- <input type="password" class="form-control" formControlName="cpassword"
                                  placeholder="Re enter new password" required /> -->

                                <div class="form-group has-float-label mt-3">
                                  <input class="form-control" type="password" placeholder="Re enter new password"
                                    formControlName="cpassword" required />
                                  <label>Re enter new password</label>
                                </div>
                                <span class="text-danger"
                                  *ngIf="(resetPasswordForm.controls.cpassword.touched || submitted) && resetPasswordForm.controls.cpassword.errors?.required">
                                  Confirm Password is required
                                </span>
                                <span class="text-danger"
                                  *ngIf="resetPasswordForm.controls.cpassword.touched && resetPasswordForm.controls.cpassword.errors?.passwordMismatch">
                                  Passwords doesnot match
                                </span>
                              </div>

                              <div class="continue-btn">
                                <button type="button" class="btn btn-snape-red" data-toggle="modal" data-dismiss="modal"
                                  data-target="#editPassword" (click)="continuePass()"
                                  [disabled]="resetPasswordForm.invalid">CONTINUE</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal -->

            <div class="modal fade" id="deactivateModal" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h6 class="modal-title" id="exampleModalLongTitle">Confirm deactivate?</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body text-center">
                    Are you sure you want to deactivate this account?
                  </div>
                  <div class="row margin">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-no-grey mr-2" data-dismiss="modal">No</button>
                        <button data-dismiss="modal" class="btn btn-snape-red" data-dismiss="modal"
                          (click)="deactivateAccount()" type="button">Yes</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<script>
  $(document).ready(function () {


    var readURL = function (input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $('.profile-pic').attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]);
      }
    }



    $(".file-upload").on('change', function () {
      readURL(this);
    });

    $(".upload-button").on('click', function () {
      $(".file-upload").click();
    });
  });

</script>