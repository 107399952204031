import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service'
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.css']
})
export class ConfirmPasswordComponent implements OnInit {

  confirmPasswordForm: FormGroup;
  otpPattern = "^[0-9]{6}$"
  submitted = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.confirmPasswordForm = this.fb.group({
      code: ['', [Validators.required, Validators.pattern(this.otpPattern)]]
    })
  }
  resendOtp() {
    this.api.resendOtp().subscribe(res => {
      console.log(res, 'res')
      let message = res['message'];

      if (res['status'] == true) {
        this.toastr.success(message);
      }
      else {
        this.toastr.error(message, null, { timeOut: 5000 })
      }

    })
  }
  continue() {
    this.submitted = true;
    this.api.confirmPassword(this.confirmPasswordForm.controls.code.value).subscribe(res => {
      let message = res['message'];
      if (res['status'] == true) {
        this.toastr.success(message);
        this.router.navigate(['login'])
      }
      else {
        this.toastr.error(message, null, { timeOut: 5000 })
      }
      // let status = res['status']
      // if (status == true) {
      //   this.router.navigate(['login'])
      // }
    })
  }
}
