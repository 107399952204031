<section class="pink-sec">
    <div class="container">
    </div>
</section>

<section class="white-sec">
    <div class="container white-sec-holder">
        <div class="row">
            <div class="col-10 mx-auto">
                <div class="card">
                    <div class="snape-logo">
                        <img src="../../../assets/img/snapeLogo.svg">
                    </div>
                    <div class="card-body p-5">
                        <div class="row text-center">
                            <div class="col-12">
                                <h2>Thank you for your order</h2>
                                <p>Order id :&nbsp;<span class="font-weight">#4123365</span></p>
                                <ul class="list">
                                    <li>
                                        <p>Order date :&nbsp;<span class="font-weight">15-May-2021</span></p>
                                    </li>
                                    <li>
                                        <p>Delivery date :&nbsp;<span class="font-weight">18-may-2021</span></p>
                                    </li>
                                    <p>Hi <span class="bink-color">Lakshmi</span> , we have received your order and
                                        getting it ready for
                                        <br>
                                        processing. You can track the status of your order by clicking
                                        <br>
                                        the button below.
                                    </p>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-2">
                                <button class="rounded">Track Order</button>
                            </div>
                        </div>
                        <div class="row m-t-20">
                            <div class="col-md-12">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr class="ordered-cookie-bg-color">
                                            <td style="width: 18%;">
                                                <img src="../../../assets/6cookie.jpg" class="ordered-img">
                                            </td>
                                            <td>
                                                <span class="ordered-cookie">
                                                    6 cookie box
                                                </span>
                                                <br>
                                                <span class="font-size-16">
                                                    QAR 84.00 x 2
                                                </span>
                                                <br>
                                                <span class="font-size-16">
                                                    Box color
                                                </span>
                                                <br>
                                            </td>
                                            <td class="float-right">
                                                <span class="font-size-16 bink-color">
                                                    QAR 198.00
                                                </span>
                                                <br>
                                                <span class="font-size-16">
                                                    Brown
                                                </span>
                                            </td>
                                        </tr>
                                        <tr class="ordered-cookie-bg-color">
                                            <td style="width: 18%;">
                                                <img src="../../../assets/6cookie.jpg" class="ordered-img">
                                            </td>
                                            <td>
                                                <span class="ordered-cookie">
                                                    6 cookie box
                                                </span>
                                                <br>
                                                <span class="font-size-16">
                                                    QAR 84.00 x 2
                                                </span>
                                                <br>
                                                <span class="font-size-16">
                                                    Box color
                                                </span>
                                            </td>
                                            <td class="float-right">
                                                <span class="font-size-16 bink-color">
                                                    QAR 198.00
                                                </span>
                                                <br>
                                                <span class="font-size-16">
                                                    Brown
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr class="hr-1">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Order Total
                                            </td>
                                            <td> </td>
                                            <td class="float-right">QAR 336.00</td>
                                        </tr>
                                        <tr>
                                            <td>Delivery Fee</td>
                                            <td> </td>
                                            <td class="float-right">QAR 20.00</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight">Total</td>
                                            <td></td>
                                            <td class="float-right font-weight bink-color">QAR 356.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-sec2">
    <div class="container">
        <div class="row">
            <div class="col-10 mx-auto">
                <div class="card">
                    <div class="card-body white-sec2-padding">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td class="font-weight">
                                        Delivery Address
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="font-weight">Albin</span>
                                        <br>
                                        <span>
                                            Matar Qadeem box no: 112233
                                        </span>
                                        <br>
                                        <span>
                                            Doha
                                        </span>
                                        <br>
                                        <span>
                                            Qatar
                                        </span>
                                        <br>
                                        <span>
                                            Phn (00974) 4433234
                                        </span>
                                        <br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="gray-sec">
    <div class="container gray-sec-padding">
        <div class="row">
            <div class="col-10 mx-auto">
                <div class="row">
                    <div class="col-md-4 col-lg-6"></div>
                    <div class="col-md-4 col-lg-3">
                        <div class="mob-text-align">
                            <a href="https://apps.apple.com/jo/app/snape-cookies/id1568674032" target="_blank">
                                <img src="../../../assets/iphonestore.jpg" class="cursor-pointer">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                        <div class="mob-text-align mob-m-t-10">
                            <a href="https://play.google.com/store/apps/details?id=com.snapecookies.snape_cookies"
                                target="_blank">
                                <img src="../../../assets/googleplay.jpg" class="cursor-pointer">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="mob-text-align mob-m-t-10">
                            <img src="../../../assets/img/facebook-logo.svg" (click)="redirectToUrl()"
                                class="m-r-10 cursor-pointer" />

                            <a href="https://instagram.com/snapecookiesqa?igshid=ZmRlMzRkMDU=" target="_blank">
                                <img src="../../../assets/img/instagram.svg" class="cursor-pointer" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row terms-area">
                    <div class="col-md-2"></div>
                    <div class="col-md-2">
                        <div class="mob-text-align mob-m-t-10">
                            <p class="cursor-pointer" routerLink="/terms" routerLinkActive="router-link-active">Terms &
                                conditions</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mob-text-align">
                            <p class="cursor-pointer" routerLink="/privacy-policy"
                                routerLinkActive="router-link-active">Privacy policy</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mob-text-align">
                            <p class="cursor-pointer" routerLink="/cookie-policy" routerLinkActive="router-link-active">
                                Cookie policy</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mob-text-align">
                            <p class="cursor-pointer">Refund policy</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <div class="copyright-area">
                            <p>Copyright © 2021 SnapEcookies. All right reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>