import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit {

  items
  showItem = false;
  disable_qty = false
  min_order_qty;
  isChecked = false;
  boxId
  colors
  value
  products = []
  cartProductList = [];
  cartItems;
  counter
  cartLength: any;
  cartForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService
  ) { }

  ngOnInit(): void {
    this.getCartForm()
    if (JSON.parse(localStorage.getItem('cart'))) {
      this.cartLength = JSON.parse(localStorage.getItem('cart')).length
      this.getCartItems();
      console.log(this.cartProductList)
    }
  }
  getCartForm() {
    this.cartForm = this.fb.group({
      color: ['']
    })
  }
  getCartItems(){
    this.cartProductList = JSON.parse(localStorage.getItem('cart'));
  }
  selectedProduct(product: any) {
    this.showItem = true;
    this.items = product
    this.counter = parseInt(this.items.min_qty)
    console.log(product, 'product')
  }
  increment(count) {
    this.counter = count
    this.counter += 1;
  }
  decrement(count) {
    console.log(count)
    if (this.counter > this.items.min_qty) {
      this.counter = count
      this.counter -= 1;
    }
  }
  changeSelection(colors: any, value) {
    console.log(colors, 'colors')
    this.boxId = colors.name
  }
  addToCart(product) {
    console.log('prod', product)
    let local_storage;
    let itemsInCart = []
    let cart_items = {
      "PK": product.PK,
      "SK": product.SK,
      "id": product.id,
      "qty": this.counter,
      "box_color": this.boxId,
      "product_name": product.name,
      "price":product.price,
      "item_total":product.price*this.counter,
      "image":product.image,
      "customize": []
    }

    if (localStorage.getItem('cart') == null) {
      local_storage = [];
      console.log("LOCALSTORAGE NULL", JSON.parse(localStorage.getItem('cart')));
      itemsInCart.push(cart_items);
      localStorage.setItem('cart', JSON.stringify(itemsInCart));
      console.log('Pushed first Item: ', itemsInCart);
    }
    else {
      local_storage = JSON.parse(localStorage.getItem('cart'));
      console.log("LOCAL STORAGE HAS ITEMS", JSON.parse(localStorage.getItem('cart')));
      for (var i in local_storage) {
        console.log(local_storage[i].id);
        if (product.id === local_storage[i].id) {
          local_storage[i].qty = this.counter
          console.log('same product! index is ', i);
          cart_items = null;
          break;
        }
      }
      if (cart_items) {
        itemsInCart.push(cart_items);
      }
      local_storage.forEach(function (item) {
        itemsInCart.push(item);
      })
      this.cartProductList = itemsInCart
      console.log(this.cartProductList, 'list')
      localStorage.setItem('cart', JSON.stringify(itemsInCart));
      this.getCartItems();

    }
  }

  //deleting cart item
  deleteItem(item) {
    item = item;
    console.log("Deleting : ", item);
    let shopping_cart;
    let index;
    shopping_cart = JSON.parse(localStorage.getItem('cart'));
    for (let i in shopping_cart) {
      if (item.id == shopping_cart[i].id) {
        index = i;
        console.log(index);
      }
    }
    shopping_cart.splice(index, 1);
    console.log("shopping_cart ", shopping_cart);
    localStorage.setItem('cart', JSON.stringify(shopping_cart));
    this.getCartItems();
  }
  cartItem() {
    this.router.navigate(['cookie-cart'])
  }
}
