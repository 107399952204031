import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup
  submitted = false
  emailPattern = "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/";
  constructor(
    private fb: FormBuilder,
    private api: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getContactFormArray()
  }
  getContactFormArray() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      message: ['', Validators.required]
    })
  }
  send() {
    this.submitted = true
    let data = {
      "name": this.contactForm.controls.name.value,
      "email": this.contactForm.controls.email.value,
      "message": this.contactForm.controls.message.value,
    }
    this.api.addContactMessage(data).subscribe(res => {
      console.log(res)
      if(res){
        this.submitted = false
        let message = res['message']
        this.toastr.success(message);
        this.contactForm.reset();
      }
    })
  }
}
