<div class="col-md-12">
    <div class="row">
        <div class="col-md-7">
            <div class="bgred-side">
                <div class="canvaDiv">
                    <canvas id="canvas" width="280" height="280" style="border-radius: 50%;"></canvas>
                </div>
            </div>
        </div>
        <div class="col-md-5" style="margin: auto;">
            <form [formGroup]="cookieForm">
                <div class="upload-btn-wrapper">
                    <input (change)="uploadImage($event)" #fileInput type="file" id="file" formControlName="image" />
                    <button class="btn cnp-btn h38btn">Change Image</button>
                </div>

                <button class="btn cnp-btn ml-4 h38btn" id="add-text" data-toggle="tooltip" data-placement="bottom"
                    type="button" (click)="addText()" style="margin-top: -2rem;">Add Text</button>
                <br /><br />
                <input type="text" placeholder="Add Text Here" class="form-control" formControlName="textString">

                <div class="custom-item mt-4">
                    <div class="custom-item-title">Select your Font</div>
                    <div class="custom-item-body">
                        <select formControlName="fontFamily" class="form-control" value="props.fontFamily"
                            (change)="setFontFamily()">
                            <option value="arial">Arial</option>
                            <option value="helvetica" selected>Helvetica</option>
                            <option value="verdana">Verdana</option>
                            <option value="courier">Courier</option>
                            <option value="Roboto">Roboto</option>
                            <option value="Open Sans">Open Sans</option>
                            <option value="Zilla Slab">Zilla Slab</option>
                            <option value="Lato">Lato</option>
                            <option value="Bellefair">Bellefair</option>
                            <option value="Fresca">Fresca</option>
                            <option value="Raleway">Raleway</option>
                            <option value="Open Sans Condensed">Open Sans Condensed</option>
                            <option value="Indie Flower">Indie Flower</option>
                            <option value="Josefin Sans">Josefin Sans</option>
                            <option value="Inconsolata">Inconsolata</option>
                            <option value="Pacifico">Pacifico</option>
                            <option value="Gloria Hallelujah">Gloria Hallelujah</option>
                        </select>
                    </div>
                </div>
                <div class="mt-4">Font size</div>
                <input type="range" formControlName="fontSize" (change)="setFontSize()" step="1" min="1"
                    max="120">{{fontSize}}
                <div class="mt-4">
                    <button type="button" class="btn" (click)="setBold()">
                        Bold
                    </button>
                    <button type="button" class="btn ml-4" (click)="setFontStyle()">
                        Italic
                    </button>
                    <button type="button" class="btn ml-4" (click)="setTextDecoration('underline')">
                        Underline
                    </button>
                </div>
                <br />
                <div>BG opacity
                    <input type="range" formControlName="opacity" (change)="setOpacity()">{{opacity}}
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">Text color
                        <input type="color" formControlName="fill" (change)="setFill()">
                    </div>
                    <div class="col-md-6">Bg color
                        <input type="color" formControlName="BgFill" (change)="setBgFill()">
                    </div>
                </div>

                <!-- set brightness, contrast, saturation -->
                <div class="row mHeightCus mt-4">
                    <div class="col-md-2">Brightness</div>
                    <div class="col-md-10">
                        <input class="rangeImg" type="range" formControlName="brightness"
                            (change)="setBrightness()">{{brightness}}
                    </div>
                </div>
                <div class="row mHeightCus">
                    <div class="col-md-2">Contrast</div>
                    <div class="col-md-10">
                        <input type="range" formControlName="contrast" (change)="setContrast()">{{contrast}}
                    </div>
                </div>
                <div class="row mHeightCus">
                    <div class="col-md-2">Saturation</div>
                    <div class="col-md-10">
                        <input type="range" formControlName="saturation" (change)="setSaturation()">{{saturation}}
                    </div>
                </div>
                <!-- end -->
                <div class="text-center mt-4">
                    <button type="button" class="btn btn-snape-red text-center" (click)="saveDesign()">Save Your Design
                    </button>
                </div>
            </form>
        </div>

        <!-- upload image and choose from template -->
        <div class="col-md-5" style="margin: auto;">
            <div class="d-flex align-items-center">
                <div class=" file-upload">
                    <div class="image-upload-wrap">
                        <div (dragover)="false" (dragend)="false" (drop)="handleDrop($event)">

                            <br />
                            <div class="">
                                <form [formGroup]="cookieForm">
                                    <div class="drag-text">
                                        <img width="20%" src="../../../assets/Icon.png" />
                                        <p class="mt-2" style="margin: unset;">Image Upload</p>
                                        <p>Drag and drop a file here or click</p>
                                    </div>
                                    <input class="cus-imgIn" (change)="uploadImage($event)" #fileInput type="file"
                                        id="file" formControlName="image"><br /><br />
                                        <p class="text-center mt-4">OR</p>
                                        <p class="text-center">Choose from template image</p>
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="tab" href="#home">Favourites</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#menu1">Smileys</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#menu2">Quotes</a>
                                            </li>
                                        </ul>
                            
                                        <div class="tab-content">
                                            <div id="home" class="container tab-pane active"><br>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/fav/01003 Quote Born To Dance.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/01590 Coffee A Hug In A Mug.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/fav/balloons.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/fav/Christmas_L5.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/011-surprised-4.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes//01612 Mom Of Girls.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/004-nerd-11.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/005-sweating.svg" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="menu1" class="container tab-pane fade"><br>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/001-suspicious-1.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/002-shocked-7.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/003-angry-5.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/004-nerd-11.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/005-sweating.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/006-happy-14.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/007-surprised-5.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/smileys/008-tired-3.svg" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="menu2" class="container tab-pane fade"><br>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/00999 Mr Mrs Love Heart.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/00879 Quote I Love You To The Moon And Back.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/01001 Quote Love.svg" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/01304 Super Mom.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/01590 Coffee A Hug In A Mug.svg" />
                                                    </div>
                                                    <div class="col-md-3 mt-4">
                                                        <img class="bRadius50" (click)="getImgPolaroid($event)" src="../../../assets/quotes/01612 Mom Of Girls.svg" />
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- end -->
    </div>
</div>