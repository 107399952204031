<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="bg-color">

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 m-r-minus20">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
      <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
        <div class="card m-r-minus30">
          <div class="card-body height550">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active"><i
                        class="fas fa-home"></i> Home</a></li>
                  <!-- <li class="breadcrumb-item"><a routerLink="/edit-profile" routerLinkActive="router-link-active">Account</a></li> -->
                  <li class="breadcrumb-item active" aria-current="page">Favourite images</li>
                </ol>
              </nav>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 col-xs-12 col-lg-12" *ngIf="imageArray?.length == 0">
                  <div class="text-center mb-3 m-t-30">
                    <img src="../../../assets/Group18781.png" class="no-fav-img">
                    <p class="no-order-heading">No favourite images yet !</p>
                    <button class="btn btn-snape-red" data-toggle="modal" data-target="#uploadImg">Upload
                      Images</button>
                  </div>
                </div>
                <div class="col-md-4">
                  <!-- <p class="snape-red ml-1 pt-2">Cookie's Flavours</p> -->
                </div>
                <div class="col-md-8 d-flex justify-content-end" *ngIf="imageArray?.length > 0">
                  <div *ngIf="showDelete == true">
                    <button class="btn btn-snape-gray-outline cursor-pointer m-r-20" data-toggle="modal"
                      data-target="#delModal">Delete</button>
                  </div>
                  <button class="btn btn-snape-red cursor-pointer" data-toggle="modal" data-target="#uploadImg">Upload
                    Images</button>
                </div>
              </div>
              <div class="col-md-12 mt-4">
                <div class="row text-center">
                  <div class="col-sm-6 col-md-2 m-t-10" *ngFor="let image of imageArray">
                    <!-- <input type="checkbox" [value]="image.id"
                                (change)="changeSelection(image,$event.target.checked)"> -->
                    <img class="flavr-img cursor-pointer active" tabindex="1" (click)="changeSelection(image,$event)"
                      src="{{image.image}}" [class.highlighted]="highlightedBatch.indexOf(image) !== -1" />
                    <!-- <p class="text-center mt-2">Snape cookies</p> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="uploadImg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <!-- <div class="modal-header">
                            <h6 class="modal-title snape-red">Upload Image</h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> -->
                  <div class="modal-body">
                    <h5 class="modal-title img-upload-heading">Upload Image</h5>
                    <form [formGroup]="favouriteForm" class="box has-advanced-upload">
                      <div class="box-input" id="getFileLabel">
                        <label for="file-upload">
                          <div *ngIf="imageURL">
                            <img [src]="imageURL" height="150"> <br />
                          </div>
                          <div *ngIf="!imageURL">
                            <span class="material-icons-outlined">
                              cloud_upload
                            </span>
                          </div>

                          <br>
                        </label>
                        <!-- <input id="file-upload" type="file"  accept="image/*"
                                (change)="showPreview($event)" class="form-control"
                                formControlName="filesource" />     -->
                        <input type="file" name="files[]" id="file-upload" class="box-file"
                          (change)="showPreview($event)" formControlName="filesource">
                        <label for="file-upload">Drag and drop a file here or click</label>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-snape-gray-outline" data-dismiss="modal"
                      (click)="clear()">Cancel</button>
                    <button type="button" class="btn btn-snape-red" data-dismiss="modal" (click)="addImage()"
                      [disabled]="!imageUploaded">Upload</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Delete Modal -->
<div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-labelledby="delModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="deleModalLabel">Confirm delete?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row d-flex justify-content-center mb-4">
            Are you sure you want to delete this product?
          </div>
          <div class="row form-group mt-4">
            <div class="col-md-12">
              <div class="d-flex justify-content-center align-items-center">
                <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                <button data-dismiss="modal" class="btn btn-snape-red" (click)="deleteFavImage()"
                  type="button">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>