<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>

<section class="bg-color">

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-2 col-md-4 m-r-minus20">
                <app-profile-sidebar></app-profile-sidebar>
            </div>
            <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
                <div class="card m-r-minus30">
                    <div class="card-body height550">
                        <div class="col-md-12 m-l-minus30">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a routerLink="/home"
                                            routerLinkActive="router-link-active"><i class="fas fa-home"></i> Home</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item"><a routerLink="/edit-profile" routerLinkActive="router-link-active">Account</a></li> -->
                                    <li class="breadcrumb-item active" aria-current="page">Manage Address</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4">
                                <div class="epro-div">
                                    <div class="addAddress cursor-pointer" (click)="showAddress()">
                                        <p class="plus">+</p>
                                        <p class="addText">Add Address</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngFor="let address of addressArray;let i=index"
                                id="addressTopSection">
                                <div class="epro-div mb-4 mob-m-t-20">
                                    <p class="title-grey fw600">Address {{i+1}}</p>
                                    <p>Name : <span class="fw600">{{address.name}}</span></p>
                                    <p>Building : <span class="fw600">{{address.building}}</span></p>
                                    <p>Flat : <span class="fw600">{{address.flat_villa}}</span></p>
                                    <p>Zone : <span class="fw600">{{address.zone}}</span></p>
                                    <p>Street : <span class="fw600">{{address.street}}</span></p>
                                    <p>Area : <span class="fw600">{{address.area}}</span></p>
                                    <p>City : <span class="fw600">{{address.city}}</span></p>
                                    <p>Contact number: <span class="fw600">{{address.contact_number}}</span></p>
                                    <p class="mt-4">
                                        <span class="snape-red mr-2 cursor-pointer"
                                            (click)="updateaddress(address)">Edit</span> |
                                        <span class="snape-red ml-2 cursor-pointer" (click)="setDeletedId(address.id)"
                                            data-toggle="modal" data-target="#deleteAddress">Delete</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <form [formGroup]="addressForm" id="addAddressSection">
                            <div class="row" *ngIf="showAddressForm == true">

                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="Full name"
                                            formControlName="fullName" required />
                                        <label>Full name</label>
                                    </div>
                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.fullName.touched || submitted) && addressForm.controls.fullName.errors?.required">
                                        Full Name is required
                                    </span>
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="Building number"
                                            formControlName="buildingNumber" required />
                                        <label>Building number</label>
                                    </div>
                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.buildingNumber.touched || submitted) && addressForm.controls.buildingNumber.errors?.required">
                                        Building number is required
                                    </span>
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="Flat/Villa number"
                                            formControlName="flatVilla" required />
                                        <label>Flat/Villa number</label>
                                    </div>

                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.flatVilla.touched || submitted) && addressForm.controls.flatVilla.errors?.required">
                                        Flat/Villa number is required
                                    </span>
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="Zone number"
                                            formControlName="zone" required />
                                        <label>Zone number</label>
                                    </div>

                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.zone.touched || submitted) && addressForm.controls.zone.errors?.required">
                                        Zone is required
                                    </span>
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="Street name"
                                            formControlName="streetName" required />
                                        <label>Street name</label>
                                    </div>

                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.streetName.touched || submitted) && addressForm.controls.streetName.errors?.required">
                                        Street name is required
                                    </span>
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="Area number"
                                            formControlName="areaNumber" />
                                        <label>Area number</label>
                                    </div>

                                    <!-- <span class="text-danger"
                                    *ngIf="(addressForm.controls.areaNumber.touched || submitted) && addressForm.controls.areaNumber.errors?.required">
                                    Area number is required
                                </span> -->
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" placeholder="City"
                                            formControlName="city" required />
                                        <label>City</label>
                                    </div>
                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.city.touched || submitted) && addressForm.controls.city.errors?.required">
                                        City is required
                                    </span>
                                </div>
                                <div class="col-md-5 mt-4">
                                    <div class="form-group has-float-label input-group">
                                        <div class="country-code">
                                            +974
                                        </div>
                                        <input class="form-control country-code-txt-box" formControlName="contactNumber"
                                            type="text" placeholder="Contact number" required>
                                        <label class="label-margin">Contact number</label>
                                    </div>
                                    <span class="text-danger"
                                        *ngIf="(addressForm.controls.contactNumber.touched || submitted) && addressForm.controls.contactNumber.errors?.required">
                                        Contact number is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="addressForm.controls.contactNumber.touched && addressForm.controls.contactNumber.errors?.pattern">
                                        Enter a valid number
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="showAddressForm == true">
                                <!-- <div class="col-md-10 mt-4 bRadius5px">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.7373361701484!2d76.95841581404562!3d8.524862393871532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bbd5bd74b023%3A0xb36f66160053530e!2sLinks4Engg%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1613797330450!5m2!1sen!2sin"
                                        width="100%" height="200" style="border:0;" allowfullscreen=""
                                        loading="lazy"></iframe>
                                </div> -->
                                <div class="col-md-10 d-flex justify-content-end mt-3">
                                    <button class="btn btn-snape-bak text-uppercase" (click)="addAddress(address)"
                                        [disabled]="addressForm.invalid">
                                        Save Address
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- popups -->
                <div class="modal fade" id="deleteAddress" tabindex="-1" role="dialog"
                    aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-11">
                                        <h6 class="snape-red mt-2">Delete address</h6>
                                    </div>
                                    <div class="col-md-1">
                                        <button type="button" class="close mt-2" data-dismiss="modal"
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <p>Are you sure you want to delete this address?</p>
                                <div class="row">
                                    <div class="col-md-12 mfooterBtn d-flex justify-content-end">
                                        <button type="button" class="btn btn-cancel-grey mr-2"
                                            data-dismiss="modal">Cancel</button>
                                        <button type="button" class="btn btn-snape-red ml-2" data-dismiss="modal"
                                            (click)="deleteaddress(deletedId)">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>