import { Injectable } from '@angular/core';
import { CommonService} from '../common.service';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const ID_TOKEN = 'id-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const ISLOGGEDIN = 'is-logged';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(
    private api: CommonService
  ) { }
  signOut() {
    localStorage.clear();
    window.sessionStorage.clear();
  }

  // public saveToken(token: string) {
  //   window.sessionStorage.removeItem(TOKEN_KEY);
  //   window.sessionStorage.setItem(TOKEN_KEY, token);
  // }
  public saveToken(token: string) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }
  // public saveRefreshToken(token: string) {
  //   window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  //   window.sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
  // }
  public saveRefreshToken(token: string) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }
  // public saveIdToken(token:string) {
  //   window.sessionStorage.removeItem(ID_TOKEN);
  //   window.sessionStorage.setItem(ID_TOKEN,token);
  // }
  public saveIdToken(token:string) {
    localStorage.removeItem(ID_TOKEN);
    localStorage.setItem(ID_TOKEN,token);
  }
  // public getToken(): string {
  //   return sessionStorage.getItem(TOKEN_KEY);
  // }

  // public getIdToken():string {
  //   return sessionStorage.getItem(ID_TOKEN);
  // }
  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getIdToken():string {
    return localStorage.getItem(ID_TOKEN);
  }
  public saveUser(user) {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public loggedStatus(status){
    localStorage.removeItem(ISLOGGEDIN)
    localStorage.setItem(ISLOGGEDIN,status)
  }
  public getStatus(): string {
    return localStorage.getItem(ISLOGGEDIN);
  }
  public getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }
  // public saveUser(user) {
  //   window.sessionStorage.removeItem(USER_KEY);
  //   window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  // }

  // public loggedStatus(status){
  //   window.sessionStorage.removeItem(ISLOGGEDIN)
  //   window.sessionStorage.setItem(ISLOGGEDIN,status)
  // }
  // public getStatus(): string {
  //   return sessionStorage.getItem(ISLOGGEDIN);
  // }
  // public getUser() {
  //   return JSON.parse(sessionStorage.getItem(USER_KEY));
  // }

}
