import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'fabric';
import { CommonService } from 'src/app/common/common.service';
declare const fabric: any;
import * as uuid from 'uuid';

const myId = uuid.v4();
@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.css']
})
export class AddImageComponent implements OnInit {
  title = 'angular-editor-fabric-js';
  cookieForm: FormGroup;
  canvas: any;
  public textString: string;
  imageSelected: boolean = false;
  changeImages = false
  showText = false;
  selected: any;
  fontSize;
  opacity;
  brightness;
  contrast;
  saturation;
  fontWeight;
  fill;
  textEditor = false;
  props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    brightness: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };
  SelectedProductId: any;
  selectedImage: any;
  images: Object;
  index: any;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private api: CommonService
  ) { }


  ngOnInit(): void {
    console.log(myId, 'id')
    this.getTemplateImages();
    this.getFavImages();
    this.showImageOrText();
    this.SelectedProductId = this.activatedRoute.snapshot.paramMap.get('id')
    this.canvas = new fabric.Canvas('canvas', { selection: false });
    console.log(this.canvas, 'canvas')
    this.cookieForm = this.fb.group({
      textString: [''],
      filesource:[''],
      image: '',
      brightness: [''],
      contrast: [''],
      fontFamily: [''],
      fontSize: [''],
      opacity: [''],
      fill: [''],
      BgFill: [''],
      saturation: ['']
    })
    // this.fontSize=this.cookieForm.controls.fontSize.value
  }

  ngAfterViewInit() {

  }

  getFavImages() {
    // this.api.getFavouriteImage().subscribe(img=>{
    //   this.images= img['data']['Items']
    //   console.log(this.images)
    // })
  }
  showImageOrText() {
    this.activatedRoute.params.subscribe(item => {
      console.log(item.queryParams, item, 'gg')
      if (item.queryParams == "true") {
        this.showText = true;
        this.imageSelected = true
      }
      else {
        this.index = item.queryParams
      }
    })
  }
  getTemplateImages() {
    this.api.getTemplateImages().subscribe(item => {
      console.log(item, 'item')
    })
  }
  handleDrop(e: any) {
    this.ngOnInit();
    this.imageSelected = true
    console.log(e, 'e')
    const file = e.dataTransfer.files[0];
    console.log(file, 'file')
    const reader = new FileReader();

    reader.onload = (imgFile: any) => {
      console.log(imgFile, 'imgFile');
      const data = imgFile.target["result"];
      console.log(data, 'data')
      fabric.Image.fromURL(data,
        (img: {
          set: (arg0: { left: number; top: number; angle: number; }) =>
            { (): any; new(): any; scale: { (arg0: number): any; new(): any; }; };

        }) => {
          console.log(img, 'img')
          let oImg = img.set({
            left: 50,
            top: 100,
            angle: 0
          }).scale(1);
          oImg.scaleToHeight(150);
          oImg.scaleToWidth(150);
          this.canvas.add(oImg).renderAll();
          this.selectItemAfterAdded(oImg);
          var a = this.canvas.setActiveObject(oImg);
          var dataURL = this.canvas.toDataURL({ format: 'png', quality: 0.8 });
        });
    };
    reader.readAsDataURL(file);
    return false;
  }
  uploadImage(event: any) {
    this.ngOnInit();
    this.imageSelected = true
    this.changeImages = true
    this.showText = false
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.cookieForm.patchValue({
        image: event.target.files[0],
     })
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        const data = e.target.result;
        fabric.Image.fromURL(data, img => {
          img.set({
            left: 100,
            top: 350
          });
          img.scaleToHeight(150);
          img.scaleToWidth(150);
          this.canvas.add(img);
          this.selectItemAfterAdded(img);
          this.canvas.centerObject(img);
        });
      };


    }
  }

  //change image with existing
  changeImage(event) {
    this.ngOnInit();
    this.changeImages = true
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result;
      this.canvas.clear().renderAll();
      fabric.Image.fromURL(data, img => {
        img.set({
          left: 100,
          top: 350
        });
        img.scaleToHeight(150);
        img.scaleToWidth(150);
        console.log(data, 'da')
        this.canvas.add(img);
        this.selectItemAfterAdded(img);
        this.canvas.centerObject(img);
      });
    };

    reader.readAsDataURL(file);
  }
  //remove object
  remove() {
    this.canvas.remove(this.canvas.getActiveObject());
  }
  //set brightness
  setBrightness() {
    console.log(this.cookieForm.controls.brightness.value, 'brightness');
    this.applyFilter(5, new fabric.Image.filters.Brightness({
      brightness: parseInt(this.cookieForm.controls.brightness.value, 10)
    }));
    this.applyFilterValue(5, 'brightness', parseFloat(this.cookieForm.controls.brightness.value));
  }
  //set contrast
  setContrast() {
    this.applyFilter(6, new fabric.Image.filters.Contrast({
      contrast: parseInt(this.cookieForm.controls.contrast.value, 10)
    }));
    this.applyFilterValue(6, 'contrast', parseFloat(this.cookieForm.controls.contrast.value));
  }
  setSaturation() {
    this.applyFilter(7, new fabric.Image.filters.Saturation({
      saturation: parseInt(this.cookieForm.controls.saturation.value, 10)
    }));
    this.applyFilterValue(7, 'saturation', parseFloat(this.cookieForm.controls.saturation.value));
  }
  applyFilter(index, filter) {
    var obj = this.canvas.getActiveObject();
    obj.filters[index] = filter;
    obj.applyFilters();
    this.canvas.renderAll();
  }
  applyFilterValue(index, prop, value) {
    var obj = this.canvas.getActiveObject();
    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      var timeStart = +new Date();
      obj.applyFilters();
      this.canvas.renderAll();
    }
  }
  addText() {
    this.showText = true
    this.changeImages = false
    console.log(this.cookieForm.controls.textString.value, 'string')
    if (this.cookieForm.controls.textString.value) {
      const text = new fabric.IText(this.cookieForm.controls.textString.value, {
        left: 75,
        top: 175,
        fontFamily: 'helvetica',
        angle: 0,
        fill: 'red',
        scaleX: 0.5,
        scaleY: 0.5,
        fontWeight: '',
        hasRotatingPoint: true
      });

      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.selectItemAfterAdded(text);
      this.textString = '';
    }
  }
  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }
  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }
  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  //set font family
  setFontFamily() {
    console.log(this.cookieForm.controls.fontFamily.value, 'font')
    this.setActiveProp('fontFamily', this.cookieForm.controls.fontFamily.value);
  }
  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }
  //set font size
  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.cookieForm.controls.fontSize.value, 10), null);
  }
  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({ underline: true });
        } else {
          object.setSelectionStyles({ underline: false });
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }
  //set bold
  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    console.log(this.props.fontWeight, 'weight')
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
  }
  //set Italics
  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null);
    }
  }
  //set text decoration
  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration, null);
  }
  //set bg opacity
  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.cookieForm.controls.opacity.value, 10) / 100, null);
  }
  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }
  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  //set text color
  setFill() {
    console.log(this.cookieForm.controls.fill.value, 'text color')
    this.setActiveStyle('fill', this.cookieForm.controls.fill.value, null);
  }
  //set bg color
  setBgFill() {
    console.log(this.cookieForm.controls.BgFill.value, 'imah')
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.cookieForm.controls.BgFill.value;
      this.canvas.renderAll();
    }
  }
  // choose template image
  getImgPolaroid(event: any) {
    this.ngOnInit();
    this.imageSelected = true
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 30,
        top: 40,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      image.scaleToHeight(200);
      image.scaleToWidth(200);
      this.extend(image, this.randomId());
      this.canvas.add(image);
      this.canvas.selected = true;
      this.selectItemAfterAdded(image);
    });


    // let image = img.image
    // fabric.Image.fromURL(image,
    //   (img: {
    //     set: (arg0: { left: number; top: number; angle: number; }) =>
    //       { (): any; new(): any; scale: { (arg0: number): any; new(): any; }; };

    //   }) => {
    //     let oImg = img.set({
    //       left: -150,
    //       top: 1,
    //       angle: 0
    //     }).scale(1);
    //     oImg.scaleToHeight(500);
    //     oImg.scaleToWidth(570);
    //     this.canvas.add(oImg).renderAll();
    //   });

  }
  //save the  cookie design
  saveDesign() {
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: 'png' });
    let filename = myId
    this.selectedImage = {
      "image": filename
    }
    var key = filename
    var obj = {};
    obj[key] = image.src;

    console.log(typeof (obj), 'src')
    let local_storage
    local_storage = JSON.parse(localStorage.getItem('cart'));
    for (var i in local_storage) {
      if (this.SelectedProductId == local_storage[i].id) {
        let customize = local_storage[i].customize
        console.log(customize, 'customize')
        for (var j in customize) {
          if (customize[j]?.tempId) {
            console.log(customize[j].tempId, 'temp')
            if (customize[j].tempId == this.index) { 
              customize[j].image = filename 
              customize[j].image_url = image.src
            }
          }
          else{
            console.log(customize[j], 'null')
          }
        }
        let filesInCart = []
        let files
        if (localStorage.getItem('file') == null) {
         files = [];
          console.log("LOCALSTORAGE NULL", JSON.parse(localStorage.getItem('file')));
          filesInCart.push(obj);
          localStorage.setItem('cart', JSON.stringify(filesInCart));
        }
        else{
          files = JSON.parse(localStorage.getItem('file'))
          if(obj){
            filesInCart.push(obj);
          }
          files.forEach(function (item) {
            filesInCart.push(item);
          })
          localStorage.setItem('file', JSON.stringify(filesInCart));
        }
      }
    }
    localStorage.setItem('cart', JSON.stringify(local_storage));
    console.log("LOCAL STORAGE HAS ITEMS", JSON.parse(localStorage.getItem('cart')));
    this.router.navigate(['add-cookie/' + this.SelectedProductId])
  }

}
