import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-headerblack',
  templateUrl: './headerblack.component.html',
  styleUrls: ['./headerblack.component.css']
})
export class HeaderblackComponent implements OnInit {

  myImageUrl: string = 'assets/avatar.png';
  userStatus
  userImg: any;
  isLoggedIn: boolean
  cartLength: any;
  notificationArray: any;
  notificationLength: any;
  constructor(
    private router: Router,
    private api: CommonService,
    private token: TokenStorageService
  ) { }
  @HostListener('window:scroll', ['$event'])

  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }
  ngOnInit(): void {
    this.loggedUser();
    this.userProfile();
    let local_storage = JSON.parse(localStorage.getItem('cart'))
    if (local_storage?.length) {
      this.cartLength = local_storage.length
    }
    else {
      this.cartLength = 0
    }
    if (this.isLoggedIn){
    this.api.getNotification().subscribe(res => {
      this.notificationArray = res['data']['Items']
      this.notificationArray = _.filter(this.notificationArray, (item)=>{
        return item.status == 'unread'
      })
      this.notificationLength = this.notificationArray?.length
    })
  }
  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      this.token.loggedStatus(res)
      console.log(this.isLoggedIn, 'user')
    })
  }
  userProfile() {
    if (this.isLoggedIn) {
      this.api.getProfile().subscribe(user => {
        this.userStatus = user['status']
        if (this.userStatus == true) {
          this.userImg = user['data']['Item'].image
          if (this.userImg == undefined) {
            this.userImg = this.myImageUrl
          }
        }
      })
    }
  }
  logout() {
    this.api.loggedIn.next(false);
    this.token.signOut()
    this.router.navigate(["/login"])
  }
}
