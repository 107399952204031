import { Component, OnInit, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service'
import { CustomvalidationService } from '../../common/customvalidation.service'
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  password;
  show = false;

  public showPassword: boolean;
  public showPasswordOnPress: boolean;

  registerForm: FormGroup;
  numberForm: FormGroup
  username;
  submitted: boolean = false;
  verifyPage = false
  activateNumber = false
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]],
      // 
      terms: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      passwordConfirm: ['', [Validators.required]],
    },
      {
        validator: this.customValidator.MatchPassword('password', 'passwordConfirm'),
      }
    );
    this.numberForm = this.fb.group({
      number: ['', [Validators.required, Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      terms: ['', Validators.required],
      // password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      // passwordConfirm: ['', [Validators.required]],
    }
    )

    this.password = 'password';

  }
  register() {
    this.submitted = true
    if (this.registerForm.valid) {
      let changeEmail = this.registerForm.controls.email.value.toLowerCase()
      let data = {
        "name": this.registerForm.controls.name.value,
        "email": changeEmail,
        "password": this.registerForm.controls.password.value,
        "userType": "buyer"
      }
      this.api.memberSignup(data).subscribe(_res => {
        console.log(_res, 'res')
        this.toastr.success(_res['message'])
        if (_res['message'] == 'This username already exists') {
          this.verifyPage = true
        }
        let status = _res['status']
        if (status == true || this.verifyPage == true) {
          localStorage.setItem('count', '1')
          localStorage.setItem('userName', this.registerForm.controls.name.value)
          this.router.navigate(['verify-email'], { queryParams: { loginMethod: 'email' } })
          let data = {
            "username": this.registerForm.controls.email.value.toLowerCase(),
            "password": this.registerForm.controls.password.value
          }
          this.api.verification(data)

        }
      })
    }
  }
  registerNumber() {
    debugger
    this.submitted = true
    if (this.numberForm.valid) {
      let changeEmail = this.numberForm.controls.number.value
      localStorage.setItem('loginNumber',changeEmail)
      let data = {

        "email": changeEmail,
        //"password": this.numberForm.controls.password.value,
        "userType": "buyer"
      }
      this.api.memberSignup(data).subscribe(_res => {
        console.log(_res, 'res')
        
        if (_res['message'] == 'This username already exists') {
          this.verifyPage = true
        }
        let status = _res['status']
        if (status == true || this.verifyPage == true) {
          this.toastr.success(_res['message'])
          localStorage.setItem('count', '1')
          localStorage.setItem('userName', this.numberForm.controls.number.value)
          this.router.navigate(['verify-email'], { queryParams: { loginMethod: 'number' } })
          // let data = {
          //   "username": this.registerForm.controls.email.value.toLowerCase(),
          //   "password": this.registerForm.controls.password.value
          // }
          this.api.verification(data)

        }else{
          this.toastr.error(_res['message'])
        }
      })
    }
  }
  getNumber() {
    this.activateNumber = true
  }
  getEmail() {
    this.activateNumber = false
  }
  redirectToUrl() {
    window.location.href = environment.socialLogin
  }

  showPasswordOnClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}