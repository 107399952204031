import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { STATUS } from 'src/app/common/config/commonConfig';
import * as _ from 'underscore';


@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css', '../../components/breadcrumb/breadcrumb.component.css']
})
export class OrderDetailsComponent implements OnInit {

  statusDropdownSettings;
  selectedStatus;
  statusArray = STATUS;
  messageForm: FormGroup
  orderId: any;
  order_ref
  order_date: any;
  delivery_date
  current_status
  delivery_address
  customize = []
  productList = []
  productArray = []
  orderData
  orderDetails
  i
  cookieData: any;
  flavorArray = [];
  flavorName: any;
  flArr = [];
  itemId
  index: any;
  cookie_image: any;
  userMessages: any;
  adminMessages: any;
  vendorMessages
  admin_vendorMessages
  allMessages = [];
  allVendorChats = []
  vendorEmail
  imageURL: string;
  categories = {}
  extra = {}
  status: any;
  loader = false
  numberLogin:any
  orderStatus = ['Order Placed', 'order confirmed', 'Order Accepted', 'Processing', 'Ready for delivery', 'Out for delivery']
  constructor(
    private route: ActivatedRoute,
    private api: CommonService,
    // private orderService: OrderServiceService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    debugger
    this.route.queryParams.subscribe(item => {
      this.orderDetails = item
      console.log(item, 'item')
    })
    console.log(this.orderId, 'ref')
    this.numberLogin = localStorage.getItem('numberLogin')
    this.getFlavorDetails();
    this.getOrderedItems();
    this.counter(this.i)
    this.getMessageForm();

    this.statusDropdownSettings = {
      singleSelection: false,
      primaryKey: 'id',
      labelKey: 'name',
      text: "Filter by Status",
      enableSearchFilter: false,
      lazyLoading: true,
      showCheckbox: true,
      clearAll: false,
      badgeShowLimit: 1,
      classes: "myclass custom-class"
    };
  }
  getMessageForm() {
    this.messageForm = this.fb.group({
      customer_message: [''],
      company_message: [''],
      fileSource: [''],
      image: ''
    })
  }
  getOrderedItems() {
    debugger
    // if(this.numberLogin == 'true'){
    //   this.orderDetails['user_email'] = localStorage.getItem('loginNumber')
    // }
    this.api.getOrderDetails(this.orderDetails?.order_id, this.orderDetails?.user_email).subscribe(item => {
      console.log('data', item)
      this.status = item['order_data']['Items'][0]['orderstatus']
      this.productArray = item['item_data']['Items']
      // this.categories = this.groupBy(this.productArray['customize'], temp => temp.customize);
      console.log(this.categories, 'cattt')
      // this.vendorEmail = this.productArray[0].company_id.split(/#(.*)/)[1]
      this.vendorEmail = ''
      this.orderData = item['order_data']['Items'][0]
      console.log(item['item_data']['Items'], 'item data')
      console.log(item['order_data']['Items'], 'order date')
      localStorage.setItem('products', JSON.stringify(this.productArray))
      if (localStorage.getItem('edited') == null) {
        this.productList = JSON.parse(localStorage.getItem('products'))
        console.log(this.productList, "sam")
      }
      else {
        this.productList = JSON.parse(localStorage.getItem('edited'))
      }
      let count = 0
      for (var i in this.productArray) {
        if (this.productArray[i]?.message_status == 'unread') {
          count += 1
        }
      }
      if (count == 0) {
        let data = {
          "PK": this.orderData.PK,
          "id": this.orderDetails?.order_id,
          "message_status": "read",
          "email_id": this.orderDetails?.user_email,
          "is_admin": false
        }
        this.api.updateOrderMsgStatus(data).subscribe(res => {
          console.log(res, 'order msg status')
        })
      }
    })
  }
  counter(i: number) {
    return new Array(i);
  }
  boxNumber(i, item, products) {
    debugger
    this.cookieData = item
    console.log(i, item, products, 'dfghj')
    console.log(this.flArr, 'dfghj')
    this.itemId = products.id
    this.index = i
    this.cookie_image = this.cookieData.image_url
    for (var j in this.flavorArray) {
      if (this.flavorArray[j].SK == this.cookieData.flavor.id) {
        this.flavorName = this.flavorArray[j]?.name
        console.log(this.flavorName, 'name')
      }
    }
  }
  getFlavorDetails() {
    this.api.getFlavour().subscribe(item => {
      this.flavorArray = item['data']['Items']
      this.flArr = _.sortBy(this.flavorArray, function (o) {
        return o.name
      })
      console.log(this.flavorArray, 'dfghj')
    })
  }
  updateCookie(id, index) {
    this.router.navigate(['/edit-image/' + id + '/' + index]).then(() => {
      window.location.reload();
    });
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  message(products) {
    this.extra = {
      "PK": products.PK,
      "product_id": products.SK,
      "id": products.id,
      "order_id": this.orderDetails?.order_id,
      "user_email": this.orderDetails?.user_email,
    }
    this.router.navigate(['/message-center'], {
      queryParams: this.extra
    });
  }
  openFile() {
    console.log('hell')
    document.querySelector('input').click()
  }
  handle(event) {
    console.log('Change input file')
    const file = (event.target as HTMLInputElement).files[0];
    this.messageForm.patchValue({
      image: file
    });
    if (file == null) {
      this.imageURL = ''
    }
    this.messageForm.get('image').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageURL = e.target.result;
    };

    reader.readAsDataURL(file)
  }

}


