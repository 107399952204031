<section class="bg-color">
    <div class="container p-50">
        <div class="row">
            <div class="col-md-12 card">
                <div class="row">
                    <div class="col-md-5">
                        <div>
                            <button type="button" class="btn m-3 back-btn-icon" [routerLink]="['/home']">
                                <span class="material-icons-outlined btn-snape-white back-icon">
                                    chevron_left
                                </span>
                                <span class="back-btn-span ml-2">Back</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="p-t-25">
                            <h3 class="heading">Cookie Policy</h3>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <div class="row p-30">
                    <div class="col-md-12">
                        <div>
                            <h5 class="font-size-15">Acceptance of Terms of Use
                            </h5>
                            <p class="secondary-font-color">Please read these Terms of Use carefully. These Terms of Use
                                govern your access and use
                                of
                                this Site. By accessing or using this Site you agree to be bound by these Terms of Use
                                and
                                to any additional guidelines, restrictions, or rules that may be posted in connection
                                with
                                specific sections or services of this Site. All such additional posted guidelines,
                                restrictions, or rules are hereby incorporated by reference into these Terms of Use.</p>
                            <p class="secondary-font-color"> Cookies reserves the right to make changes to this
                                Site and to these Terms of Use
                                at
                                any time without prior notice. You should review these Terms of Use each time you access
                                this Site.
                            </p>
                            <p class="secondary-font-color">You also agree that we may provide all legal communications
                                and notices to you
                                electronically
                                by posting them on our website or, at our election, by sending an e-mail to the e-mail
                                address you provided at purchase. You may withdraw your consent to receive
                                communications
                                from us electronically by contacting customer care customerservice@snapecookies.com.
                            </p>
                            <h5 class="font-size-15"> Copyrighted Materials for Limited Use
                            </h5>
                            <p class="secondary-font-color">This Site contains graphics, photographs, images, document
                                layouts, artwork, text, fonts,
                                software tools, and other information (referred to herein as“Content”). This Site and
                                all
                                Content are the copyrighted property of Links4engg, and/or its subsidiaries (referred to
                                herein as “SnapE Cookies”) or the copyrighted property of parties from whom SnapE
                                Cookies
                                has licensed such property. All rights in this Site and its Content are reserved
                                worldwide.
                                It is strictly prohibited to retain, copy, distribute, publish, or use any portion of
                                the
                                Content except as expressly allowed in these Terms of Use. SnapE Cookies reserves the
                                right
                                to add to, delete from, or modify any part of Content at any time without prior notice.
                                Any
                                modifications to Content, whether by You or SnapE Cookies remain the property of SnapE
                                Cookies and its licensors.
                            </p>
                            <p class="secondary-font-color"> The SnapE Cookies design tools provided on this Site employ
                                a limited number of
                                elements,
                                including icons, fonts, color schemes, and design effects. SnapE Cookies reserves the
                                right
                                to use all such elements and to make all such elements available for use by other
                                parties in
                                the future. You do not obtain any right or claim to any of the individual SnapE Cookies
                                design elements through your creation of a SnapE Cookies design and/or your
                                incorporation of
                                a SnapE Cookies design into one or more products. Other SnapE Cookies customers may use
                                the
                                design tools to create SnapE Cookies that have similar or identical combinations of
                                these
                                elements and SnapE Cookies does not guarantee that your SnapE Cookies will not have
                                similarities to SnapE Cookies designed and used by other parties. SnapE Cookies provides
                                no
                                warranty of any kind that logo designs created using the design tools will not infringe,
                                or
                                be subject to a claim of infringing, the trademark or other rights of another party. It
                                is
                                solely your responsibility to obtain the advice of an attorney regarding whether the
                                SnapE
                                Cookies design is legally available for your use and does not infringe the rights of
                                another
                                party.
                            </p>
                            <h5 class="font-size-15"> Use of Site
                            </h5>
                            <p class="secondary-font-color">You are granted permission to access and use this Site and
                                its Content for the sole
                                purpose
                                of preparing, evaluating, and ordering products or services solely through Snape Cookies
                                (referred to herein as “Products”). No other download, retention, use, publication, or
                                distribution of any portion of the Content is authorized or permitted. Obtaining
                                Products
                                from SnapE Cookies does not entitle you to use any portion of Content apart from the
                                finished Products as they are supplied by SnapE Cookies.
                            </p>
                            <p class="secondary-font-color"> You agree to use this Site in a responsible manner that is
                                in full compliance with these
                                Terms of Use and with your local laws and regulations, including export and import
                                regulations. Without limitation, no portion of Content may be utilized as a trademark or
                                service mark, for any pornographic use, for any unlawful purpose or use, to defame any
                                person, to violate any person’s right of privacy or publicity, to infringe upon any
                                copyright, trade name, trademark, service mark or other intellectual property right of
                                any
                                person or entity. You agree that you will not use the Site to produce Products that are
                                offensive, unlawful, harassing, libelous, threatening, harmful, obscene, malicious or
                                otherwise objectionable. SnapE Cookies may terminate its service to customers found to
                                be
                                using SnapE Cookies to engage in undesirable activities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>