import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/common.service';
import { map } from 'rxjs/operators'
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { MessagingService } from 'src/app/common/messaging.service';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notificationArray: any;
  notificationArrayCopy: any;
  currentMessage = new BehaviorSubject(null);
  message
  email
  notificationId: any;
  type
  extra = {}
  loader = false
  products:any
  isLoggedIn: boolean;
  constructor(
    private api: CommonService,
    private angularFireMessaging: AngularFireMessaging,
    private messagingService: MessagingService,
    private router: Router,
    private toastr: ToastrService,
    private token: TokenStorageService
  ) { }

  ngOnInit(): void {
    
    // this.products = interval(1000)
    // .startWith(0).switchMap(() => this.getNotifications());
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      this.token.loggedStatus(res)
    })
    //this.getNotifications();
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.getNotifications()
  }
  getNotifications() {
    if (this.isLoggedIn) {
      this.loader = true
      this.api.getNotification().subscribe(res => {
        this.notificationArray = res['data']['Items']
        this.notificationArrayCopy = res['data']['Items']
        this.notificationArray = _.sortBy(this.notificationArray, msg => {
          return msg.created_at
        })
        this.notificationArray.reverse();
        this.loader = false
        console.log(this.notificationArray, 'res')
      })
    }
  }
  allNotification() {
    
    this.getNotifications()
  }
  unreadNotification() {
    this.notificationArray = _.filter(this.notificationArrayCopy, (item) => {
      return item.status == 'unread'
     })
  }
  readNotification() {
    this.notificationArray = _.filter(this.notificationArrayCopy, (item) => {
      return item.status == 'read'
     })
  }
  updateStatus(notification) {
    debugger
    let email = notification.PK
    this.type = notification.type
    email = email.substring(email.indexOf('#') + 1);
    console.log(email, 'noti')
    let data = {
      "id": notification.id,
      "email": email,
      "status": "read",
      'is_admin': false
    }
    this.api.updateNotificationStatus(data).subscribe(res => {
      console.log(res, 'result')
      if(res['status']==true){
        this.redirectPage(notification)
      }else{
        this.toastr.error('Something went wrong')
      }
      
    })
  }
  redirectPage(notification) {
    let email = notification.PK
    this.type = notification.type
    email = email.substring(email.indexOf('#') + 1);
    if (this.type == 'message') {
      this.extra = {
        "product_id": 'ORDE#' + notification?.item_id,
        "order_id": notification?.order_id,
      }
      this.router.navigate(['/message-center'], {
        queryParams: this.extra
      });
    }
    else if (this.type == 'order') {
      console.log(notification)
      let extra = {
        "order_id": notification?.order_id,
        "user_email": email
      }
      this.router.navigate(['order/details'], {
        queryParams: extra
      });
    }
  }
  remove(id) {
    this.notificationId = id
  }
  deleteNotification() {
    this.api.deleteNotificationById({ "id": this.notificationId }).subscribe(res => {
      this.toastr.success(res['message'])
      this.ngOnInit();
      this.notificationId = ''
      console.log(res, 'res')
    })
  }
  clearAll() {
    let deleteArray = []
    _.each(this.notificationArray, (item) => {
      deleteArray.push({ PK: item['PK'], id: item['id'] })
    })
    console.log(deleteArray, 'deleteArray')
    this.api.deleteAllNotifications(deleteArray).subscribe(res => {
      this.toastr.success('Deleted successfully');
      this.ngOnInit();
      console.log(res, 'res')
    })
  }
}
