<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="bg-color">
  <!-- <div class="bg-img">

  </div> -->
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center text-center">
          <div class="colorfff" id="Products">
            <h5 class="text-uppercase">Choose your pack</h5>
            <p>Select any pack to continue</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4 p-b-2">
        <div class="row text-center">
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let products of productArray">
            <div class="prodDiv product-image">
              <img src="{{products.image}}" class="product-img" />
              <!-- <img src="../../../assets/MaskGroup02.png" class="product-img" /> -->
            </div>
            <div class="mt-3">
              <h6 class="fsize22">{{products.name}}</h6>
              <p>{{products.description}}</p>
              <h6 class="snape-red fsize18 fw600">QAR {{products.price | number :
                '1.2-2'}}</h6>
              <button type="button" class="btn add-to-cart-btn mt-2" (click)="selectedProduct(products)">Order
                Now</button>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div id="cartTarget"></div>
    <div *ngIf="showItem == true" class="cartDet1">
      <div class="col-md-12 mt-4" id="target">
        <div class="row" id="newtarget">
          <div class="col-md-8 card">
            <div class="row p-t-20">
              <div class="col-md-12 m-l-minus13">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active"><i
                          class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a href="{{baseUrl}}/products#Products"
                        routerLinkActive="router-link-active">Cookies</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{items.name}}</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="row p-t-20">
              <div class="col-md-6 my-auto">
                <img class="bRad10px imgLeft" style="width: 100%; height: auto;object-fit: contain;"
                  src="{{selectedColorImg}}" />
              </div>
              <div class="col-md-6">
                <form [formGroup]="cartForm">
                  <h4 class="mt-2 fsize20 text-uppercase">{{items.name}}</h4>
                  <p class="mb-4 fsize15">
                    <span class="snape-red">QAR {{items.price | number :
                      '1.2-2'}}</span>
                    <span class="ml-3 ColorBOp">Per pack</span>
                  </p>
                  <p class="text-uppercase fsize15 mb-1">Choose pack color</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex mr-3">
                        <div class="box" *ngFor="let colors of items.color, let i=index"
                          [style.background-color]="colors.code" id="colors.SK" [value]="colors" id="box"
                          [ngStyle]="boxId==colors.name ? {'border': '2px solid #ffff', 'outline': '3px solid #FF5C7B'}: null"
                          (click)="changeSelection(colors)"></div>
                      </div>
                    </div>
                  </div>
                  <p class="mt-4 fsize15 text-uppercase mUnset mob-text-align">Select quantity</p>
                  <p class="fsize15 ColorBOp mb-3 mob-text-align">Minimum order quantity {{items.min_qty}} pack</p>
                  <p class="mob-text-align">
                    <span>
                      <button class="btn btn-h34" (click)="decrement(counter)">-</button>
                    </span>
                    {{counter}}
                    <span>
                      <button class="btn btn-h34 btn-h342" *ngIf="items.cookie_number == 1" [disabled]="counter >= 36"
                        (click)="increment(counter)">+</button>
                      <button class="btn btn-h34 btn-h342" *ngIf="items.cookie_number == 2" [disabled]="counter >= 18"
                        (click)="increment(counter)">+</button>
                      <button class="btn btn-h34 btn-h342" *ngIf="items.cookie_number == 3" [disabled]="counter >= 12"
                        (click)="increment(counter)">+</button>
                      <button class="btn btn-h34 btn-h342" *ngIf="items.cookie_number == 6" [disabled]="counter >= 6"
                        (click)="increment(counter)">+</button>
                    </span>
                  </p>
                  <div class="row mt-45px mb-2 total-area">
                    <div class="col-md-12">
                      <div class="d-flex">
                        <span>Total price:</span>
                        <span>QAR {{items.price * counter | number :
                          '1.2-2'}}</span>
                      </div>
                    </div>
                    <!-- <div class="col-md-6 text-right" >
                    </div> -->
                    <div class="col-md-12">
                      <!-- <div class="text-danger" *ngIf="selectBox">
                      Please choose a color
                    </div> -->
                    </div>
                    <div class="col-md-12">
                      <button class="btn btn-snape-red w100 text-uppercase m-t-1" *ngIf="!colorSelected"
                        (click)="alert()">
                        Add to Cart</button>
                      <button class="btn btn-snape-red w100 text-uppercase m-t-1" (click)="addToCart(items)"
                        *ngIf="colorSelected">
                        Add to Cart</button>
                    </div>
                  </div>
                </form>


                <!-- Modal -->
                <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <div class="row d-flex justify-content-center mb-4">
                            <img src="../../../assets/img/12.png" />
                          </div>
                          <div class="row form-group mt-4">
                            <button class="btn-snape-red text-uppercase form-control">Continue
                              Shopping</button>
                          </div>
                          <div class="row form-group">
                            <button class="btn-snape-red-outline text-uppercase form-control">Checkout</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

            </div>
          </div>

          <div class="col-md-4">
            <div class="card cart-summary mob-m-t-50">
              <h6 class="m-l-13">Cart Summary</h6>

              <!-- if no cart -->
              <div class="mt-10" *ngIf="cartLength == 0">
                <div class="row d-flex justify-content-center">
                  <div class="col-md-12">
                    <div class="text-align">
                      <img src="../../../assets/img/12.png" />
                    </div>
                  </div>
                </div><br>
                <p class="fSize15px color-no-item text-center mt-3">No item in your cookie cart</p>
              </div>

              <!-- if no cart end -->


              <!-- if data exist show this -->
              <div *ngIf="cartLength != '' " style="height: 416px;
                                    overflow-y: scroll;">
                <div class="col-md-12" *ngFor=" let cart_item of cartProductList">
                  <div class="row mt-3">
                    <div class="col-md-4 my-auto">
                      <img class="bRad4px" src="{{cart_item.box_img_url}}" />
                    </div>
                    <div class="col-md-8 mt-3">
                      <h6 class="fSize14px mb-3">{{cart_item.product.name}}
                        <span class="float-right">
                          <button class="btnTrans cursor-pointer" type="button" data-toggle="modal"
                            data-target="#delModal" (click)="remove(cart_item)"><i
                              class="far fa-trash-alt fSize18px gfaIcon cursor-pointer"></i>
                          </button>
                        </span>
                      </h6>
                      <p class="fSize13px mb-2"><span class="ColorBOp">QAR
                          {{cart_item.product.price}} x {{cart_item.quantity}} </span>
                        <span class="fSize13px snape-red float-right">QAR
                          {{cart_item.product.price * cart_item.quantity | number :
                            '1.2-2'}}</span>
                      </p>
                      <p class="fSize13px ColorBOp">Pack Color
                        <span class="float-right">{{cart_item.box_color}}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div *ngIf="!cartLength == 0">
                  <button class="btn btn-snape-red text-uppercase checkout m-t-8" (click)="cartItem()">Customize
                    Cookies</button>
                  <span>
                    <a href="{{baseUrl}}/products#Products">
                      <button class="mt-2 btn btn-snape-redOut text-uppercase continueS" (click)="continue()">Continue
                        Shopping</button>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="position: relative;"></div>
</section>

<!-- Modal -->
<div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-labelledby="delModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="deleModalLabel">Confirm delete?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row d-flex justify-content-center mb-4">
            Are you sure you want to delete this product?
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                <button data-dismiss="modal" class="btn btn-snape-red" (click)="deleteItem(index)"
                  type="button">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>