// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  socialLogin:'https://snapeqatar.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=7fn95jrtts3j162jb5dght1tuc&redirect_uri=https://staging.snapecookies.com/',
  initiate: 'https://mxwfi8nbjj.execute-api.us-east-2.amazonaws.com/paymentAPI/',
  execute: 'https://g2b4s4dmg3.execute-api.us-east-2.amazonaws.com/staging/',
  callBack: "https://staging.snapecookies.com/order-successful",
  token:'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/fcmtoken_update',
  firebase: {
    apiKey: "AIzaSyBsKkezOqHChiTg05tOXbqEwc7r75dMeGk",
    authDomain: "snape-cookies.firebaseapp.com",
    databaseURL: "https://snape-cookies-default-rtdb.firebaseio.com",
    projectId: "snape-cookies",
    storageBucket: "snape-cookies.appspot.com",
    messagingSenderId: "903439747243",
    appId: "1:903439747243:web:453c62b9ecf946cbc94fee",
    measurementId: "G-K6BNWYJGGP"
  },
  baseUrl: 'https://39xj4qskj7.execute-api.ap-south-1.amazonaws.com/',
  signUp: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/user',
  verifyEmail: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/email-confirmation',
  verifyNumber: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/email-confirmation',
  resendCode: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/forget-password',
  resendCodesignup:'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/resend-code',
  signIn: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/signin',
  userInfo: 'https://snapeqatar.auth.us-east-2.amazoncognito.com/oauth2/userInfo',
  forgetPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/forget-password',
  confirmPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/confirm-password',
  userProfile: "https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/user",
  address: 'https://8nuzz9wex0.execute-api.us-east-2.amazonaws.com/dev/address',
  listZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/listzones',
  favourite: 'https://zg8eoeiiz2.execute-api.us-east-2.amazonaws.com/dev/favourite',
  templates: 'https://dng7c1zt1j.execute-api.us-east-2.amazonaws.com/images/templates',
  userProducts: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/user-products',
  faq: 'https://ri09cq2uob.execute-api.us-east-2.amazonaws.com/dev/',
  flavour: 'https://7sbe4r5nb0.execute-api.us-east-2.amazonaws.com/dev/flavour',
  cart: 'https://bqeejq5z3b.execute-api.us-east-2.amazonaws.com/dev/cart',
  imageUpload: 'https://6cn2qm94lj.execute-api.us-east-2.amazonaws.com/image-upload/snape-cookie?file=',
  s3bucket: 'https://snape-cookie.s3.us-east-2.amazonaws.com/',
  orders: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders',
  trackOrder: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/tracking?id=',
  orderDetails: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/order-details?id=',
  deliveryStatus: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/change-delivery-status',
  payment: 'https://0qls6s66y0.execute-api.us-east-2.amazonaws.com/dev/payment',
  chat: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/chat/',
  message: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/dev/?id=',
  // notification: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/',
  notification: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/',
  batchDelete: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/user-batch-delete',
  contactUs: 'https://puq9chx22m.execute-api.us-east-2.amazonaws.com/dev/contact-us',
  orderById: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/order-details?id=',
  itemStatus: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/item-status',
  orderStatus: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/orders/order-status',
  newsLetter: 'https://0ir44ib3pb.execute-api.us-east-2.amazonaws.com/dev/news-letter',
  boxcolor: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/boxcolorupload',
  getAdmin: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/dev/getadmin',
  orderCancel:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/ordercancellation',
  getCoupon:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/dev/couponcodediscount?couponCode=',
  otpLogin:'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/signin_otpverification'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
