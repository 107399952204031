<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<div class="container p-50">
    <section class="order-success-sec">
        <div class="row row-bg">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="success-image-area pt-5 pb-5">
                    <img src="../../../assets/gift.svg">
                    <h1 class="mt-3">Thank you for your order</h1>
                    <h2>Order reference : {{orderData.reference_id}}</h2>
                    <h3>Your order was placed successfully.Below are the details of your order</h3>
                    <h3>If you have any queries, feel free to get in touch with us at
                        <a href="" target="_blank">support@snapecookies.com</a>
                    </h3>
                    <button class="btn track-order-btn mt-4" (click)="trackOrder()">Track
                        order</button>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3" *ngIf="pickupStatus == 'home_delivery'">
                <div class="card">
                    <div class="card-body">
                        <img src="../../../assets/location.svg">
                        <h5 class="mt-2">Shipping Address</h5>
                        <h6>{{orderAddress.order.order_address?.name}}</h6>
                        <h6>{{orderAddress.order.order_address?.building}}</h6>
                        <h6>{{orderAddress.order.order_address?.city}}</h6>
                        <h6>{{orderAddress.order.order_address?.street}}</h6>
                        <!-- <h6>985745</h6> -->
                        <h6>Contact Number : {{orderAddress.order.order_address?.contact_number}}</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                <div class="card">
                    <div class="card-body">
                        <img src="../../../assets/truck.svg">
                        <h5 class="mt-2">Prefered Method</h5>
                        <h6 *ngIf="pickupStatus == 'home_delivery'">Home Delivery</h6>
                        <h6 *ngIf="pickupStatus == 'self_pickup'">Self Pickup</h6>
                        <h6>Delivery Date : <span>{{orderAddress?.order.delivery_date}} </span></h6>
                        <h5 *ngIf="pickupStatus == 'self_pickup'" class="mt-3">Pickup Address</h5>
                        <h6 *ngIf="pickupStatus == 'self_pickup'"><i class="fas fa-map-marker-alt"></i>
                            <a href="https://maps.app.goo.gl/RocE1Wdc75kQxEXx5" target="_blank">Location</a>
                        </h6>
                        <h6 *ngIf="pickupStatus == 'self_pickup'">
                            JAZZ CAFE,<br>
                            Al Arabi Stadium,<br>
                            Building no : <span>83,</span>
                            <br>Zone : <span>40,</span>
                            <br>Street : <span>870,</span> <br> <span>Doha, Qatar.</span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="order-list-caption">
                    <h6>Order List</h6>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="order-details-table table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let cartItem of localStorage">
                                <td>
                                    <img src="{{cartItem.box_img_url}}">
                                </td>
                                <td>
                                    <h2>{{cartItem.product.name}}</h2>
                                    <h3>QAR {{cartItem.product.price}} x {{cartItem.quantity}} <span
                                            class="cookie-price">QAR {{cartItem.product.price *
                                            cartItem.quantity | number :
                                            '1.2-2'}}</span></h3>
                                    <h4>Box Color <span class="square">{{cartItem.box_color}}</span>
                                    </h4>
                                    <h5 *ngIf="cartItem.is_gift">This cookie boxes are gift </h5>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="order-total-table table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5>Order Summary</h5>
                                    <h6>Order Amount <span>QAR {{orderProductAmount | number :
                                            '1.2-2'}}</span></h6>
                                    <h6>Delivery Charge <span>QAR {{deliveryCharge}}</span></h6>
                                    <h6 *ngIf="savedAmount">Coupon discount<span>- QAR {{savedAmount | number :
                                            '1.2-2'}}</span></h6>
                                    <p>Total <span>QAR {{orderAmount | number :
                                            '1.2-2'}}</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</div>