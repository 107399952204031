<div class="container">
  <div class="row mt-3">
    <div class="col-md-12">
      <div>
        <button type="button" class="btn m-3 back-btn-icon" routerLink="/home" routerLinkActive="router-link-active">
          <span class="material-icons-outlined btn-snape-white back-icon">
            chevron_left
          </span>
          <span class="back-btn-span">Back to home</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div class="login-image">
        <img src="../../../assets/loginimg.png">
      </div>
    </div>
    <div class="col-md-6">
      <div class="logintxt">
        <div class="auth-ui">
          <div class="ml-3 mob-m-t-20">
            <h3>Login</h3>
            <p>Don't have an account? <a routerLink="/signup" class="snape-red">Sign up</a></p>
          </div>
          <form [formGroup]="loginForm" class="pt-2">
            <div class="position-relative form-group">
              <div class="col-md-12">
                <input formControlName="email" id="inputEmail" placeholder="Email" required="" type="text"
                  class="form-control ">
                <span class="text-danger"
                  *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
                  Email is required
                </span>
                <span class="text-danger"
                  *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.pattern">
                  Enter a valid email address
                </span>
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="col-md-12">
                <div class="input-group mb-3">
                  <input formControlName="password" id="inputPass" placeholder="Password" required="" [type]="password"
                    class="form-control">
                  <div class="input-group-append">
                    <button (click)="showPasswordOnClick()">
                      <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                      <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
                    </button>
                  </div>
                </div>

                <span class="text-danger"
                  *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
                  Password is required
                </span>
                <!-- <span class="text-danger"
                *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.invalidPassword">
                Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                letter, 1 special character and 1 number
              </span> -->
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="d-flex flex-wrap">
                <div class="col-md-12">
                  <div class="text-right">
                    <a class="forgetpass" routerLink="/forgotpassword">Forgot password?</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="d-flex justify-content-center col-md-12 mt-4">
                <button type="submit" [disabled]="loginForm.invalid" (click)="login()"
                  class="btn btn-snape-bak btn-block text-uppercase">Login</button>
              </div>
            </div>
            <div class="text-center">
              <p>or use an existing account</p>
              <a class="p-2" (click)="redirectToUrl()">
                <img src="../assets/fb.svg" />
              </a>
              <a class="p-2" (click)="redirectToUrl()">
                <img src="../assets/google.svg" />
              </a>
            </div>
          </form>

          <!-- Modal -->
          <ng-template #content let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="modal-title" id="deleModalLabel">Confirm Product?</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row d-flex justify-content-center mb-4">
                    Do you want to continue with Online Item?
                  </div>
                  <div class="row form-group mt-4">
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                      <button type="button" class="btn-snape-redOut cursor-pointer form-control" data-dismiss="modal"
                        (click)="modal.close()">No</button>
                    </div>
                    <div class="col-md-6">
                      <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control"
                        data-dismiss="modal" (click)="modal.close(true)" type="button">Yes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #account let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="modal-title" id="deleModalLabel">Activate account?</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row d-flex justify-content-center mb-4">
                    Do you like to activate account?
                  </div>
                  <div class="row form-group mt-4">
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                      <button type="button" class="btn-snape-red cursor-pointer form-control" data-dismiss="modal"
                        (click)="modal.close()">No</button>
                    </div>
                    <div class="col-md-6">
                      <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control"
                        data-dismiss="modal" (click)="modal.close(true)" type="button">Yes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>