<nav class="navbar newnavbar sidenavbar large-screen visibility">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link">
        <span class="m-l-20"></span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/summary" routerLinkActive="router-link-active">
        <span class="material-icons-outlined">
          info
        </span> 
        <span class="m-l-20">Summary</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/edit-profile" routerLinkActive="router-link-active">
        <span class="material-icons-outlined">
          person_outline
        </span>
        <span class="m-l-20">Edit Profile</span>
        </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/manage-address" routerLinkActive="router-link-active">
        <span class="material-icons-outlined">
          location_on
        </span>
        <span class="m-l-20">Manage Address</span>
       </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-orders" routerLinkActive="router-link-active">
        <span class="material-icons-outlined">
          description
        </span>
        <span class="m-l-20">My Orders</span>
        </a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/message-center" routerLinkActive="router-link-active">Message Center</a>
      </li> -->
    <li class="nav-item">
      <a class="nav-link" routerLink="/favourite-images" routerLinkActive="router-link-active">
        <span class="material-icons-outlined">
          star_border
        </span>
        <span class="m-l-20">Favourite Images</span>
        </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="logout()" routerLink="/login">
        <span class="material-icons-outlined">
          logout
        </span>
        <span class="m-l-20">Logout</span>
        </a>
    </li>
  </ul>
</nav>

<!-- mobile nav -->
<div class="bg-white border-rigt mobile-screen p-t-5">
  <p>
    <button class="btn btn-primary side-menu-colapse float-right" type="button" data-toggle="collapse"
      data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      Menu
    </button>
  </p>
  <div class="collapse" id="collapseExample">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/summary" routerLinkActive="router-link-active">
          <!-- <i class="fas fa-info-circle"></i>  -->
          Summary
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/edit-profile" routerLinkActive="router-link-active">
          <!-- <i class="fas fa-user"></i>  -->
          Edit Profile
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/manage-address" routerLinkActive="router-link-active">Manage Address</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/my-orders" routerLinkActive="router-link-active">My Orders</a>
      </li>
      <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/message-center" routerLinkActive="router-link-active">Message Center</a>
        </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/favourite-images" routerLinkActive="router-link-active">Favourite Images</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()" routerLink="/login">Logout</a>
      </li>
    </ul>
  </div>
</div>