import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthguardService } from './common/authguard/authguard.service';
import { AppComponent } from './app.component';
import { SignupComponent } from './auth/signup/signup.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { LayoutModule } from './layout/layout.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptor } from './common/auth.interceptor';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { EditProfileComponent } from './accounts/edit-profile/edit-profile.component';
import { ProfileSidebarComponent } from './accounts/profile-sidebar/profile-sidebar.component';
import { SummaryComponent } from './accounts/summary/summary.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { MessagingService } from './common/messaging.service';
import { environment } from '../environments/environment';
import { ManageAddressComponent } from './accounts/manage-address/manage-address.component';
import { ConfirmPasswordComponent } from './auth/confirm-password/confirm-password.component';
import { FavouriteImagesComponent } from './accounts/favourite-images/favourite-images.component';
import { MyOrdersComponent } from './accounts/my-orders/my-orders.component';
import { ImageCustomizeComponent } from './components/image-customize/image-customize.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ProductsComponent } from './components/products/products.component';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { AddCookieComponent } from './components/add-cookie/add-cookie.component';
import { CookieCartComponent } from './components/cookie-cart/cookie-cart.component';
import { AddImageComponent } from './components/add-image/add-image.component';
import { TrackOrderComponent } from './components/track-order/track-order.component';
import { MessageCenterComponent } from './accounts/message-center/message-center.component';
import { OrderCompleteComponent } from './components/order-complete/order-complete.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HowWeWorkComponent } from './components/how-we-work/how-we-work.component';
import { NotificationsComponent } from './accounts/notifications/notifications.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { OrderSuccessfulComponent } from './components/order-successful/order-successful.component';
import { OrderDetailsComponent } from './accounts/order-details/order-details.component';
// import { FaqComponent } from './components/faq/faq.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgImageSliderModule } from 'ng-image-slider';
import { GateWayComponent } from './gate-way/gate-way.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PhoneNumberLoginComponent } from './auth/phone-number-login/phone-number-login.component';
import { CalendarModule } from 'primeng/calendar';

const appRoutes: Routes = [
  { path: 'signup', component: SignupComponent, canActivate: [AuthguardService] },
  // { path: 'login', component: LoginComponent, runGuardsAndResolvers: 'always', canActivate: [AuthguardService] },
  { path: 'forgotpassword', component: ForgotPasswordComponent, canActivate: [AuthguardService] },
  { path: 'confirm-password', component: ConfirmPasswordComponent, canActivate: [AuthguardService] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthguardService] },
  { path: 'verify-email', component: VerifyEmailComponent, canActivate: [AuthguardService] },
  { path: 'login', component: PhoneNumberLoginComponent, canActivate: [AuthguardService] },

  { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthguardService] },
  { path: 'summary', component: SummaryComponent, canActivate: [AuthguardService] },
  { path: 'manage-address', component: ManageAddressComponent, canActivate: [AuthguardService] },
  { path: 'favourite-images', component: FavouriteImagesComponent, canActivate: [AuthguardService] },
  { path: 'my-orders', component: MyOrdersComponent, canActivate: [AuthguardService] },
  { path: 'image-customize', component: ImageCustomizeComponent, canActivate: [AuthguardService] },
  { path: 'home', component: LandingPageComponent, canActivate: [AuthguardService] },
  { path: 'about-us', component: AboutUsComponent, canActivate: [AuthguardService] },
  { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthguardService] },
  { path: 'how-we-work', component: HowWeWorkComponent, canActivate: [AuthguardService] },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthguardService] },
  { path: 'products', component: ProductsComponent, canActivate: [AuthguardService] },
  { path: 'add-cookie/:id', component: AddCookieComponent, canActivate: [AuthguardService] },
  { path: 'cookie-cart', component: CookieCartComponent, canActivate: [AuthguardService] },
  { path: 'add-image/:id', component: AddImageComponent, canActivate: [AuthguardService] },
  { path: 'track-order', component: TrackOrderComponent, canActivate: [AuthguardService] },
  { path: 'message-center', component: MessageCenterComponent, canActivate: [AuthguardService] },
  { path: 'order-complete', component: OrderCompleteComponent, canActivate: [AuthguardService] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthguardService] },

  { path: 'invoice', component: InvoiceComponent, canActivate: [AuthguardService] },
  { path: 'email-confirmation', component: EmailConfirmationComponent, canActivate: [AuthguardService] },
  { path: 'order-successful', component: OrderSuccessfulComponent, canActivate: [AuthguardService] },
  { path: 'order/details', component: OrderDetailsComponent, canActivate: [AuthguardService] },

  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthguardService] },
  { path: 'cookie-policy', component: CookiePolicyComponent, canActivate: [AuthguardService] },
  { path: 'terms', component: TermsAndConditionsComponent, canActivate: [AuthguardService] },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  }
]

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    EditProfileComponent,
    ProfileSidebarComponent,
    SummaryComponent,
    ManageAddressComponent,
    ConfirmPasswordComponent,
    FavouriteImagesComponent,
    MyOrdersComponent,
    ImageCustomizeComponent,
    LandingPageComponent,
    OrdersComponent,
    ProductsComponent,
    CartSummaryComponent,
    AddCookieComponent,
    CookieCartComponent,
    AddImageComponent,
    TrackOrderComponent,
    MessageCenterComponent,
    OrderCompleteComponent,
    AboutUsComponent,
    ContactUsComponent,
    HowWeWorkComponent,
    NotificationsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsAndConditionsComponent,
    BreadcrumbComponent,
    InvoiceComponent,
    EmailConfirmationComponent,
    OrderSuccessfulComponent,
    OrderDetailsComponent,
    GateWayComponent,
    SpinnerComponent,
    PhoneNumberLoginComponent
    // FaqComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    HttpClientModule,
    ReactiveFormsModule, FormsModule,
    RouterModule.forRoot(appRoutes),
    NgbModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }), // ToastrModule added
    ImageCropperModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64] // [x, y]
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    CarouselModule,
    NgImageSliderModule,
    CalendarModule
  ],
  exports: [RouterModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MessagingService, AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
