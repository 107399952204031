<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="bg-color">

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 m-r-minus20">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
      <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
        <!-- <div class="col-md-12"> -->

        <div class="card mb-3 height550 m-r-minus30">
          <div class="card-body">
            <div class="col-md-12 m-l-minus10">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active"><i
                        class="fas fa-home"></i> Home</a></li>
                  <!-- <li class="breadcrumb-item"><a routerLink="/edit-profile" routerLinkActive="router-link-active">Account</a></li> -->
                  <li class="breadcrumb-item active" aria-current="page">My Orders</li>
                </ol>
              </nav>
            </div>
            <div class="text-center mb-3" *ngIf="orderDetails?.length == 0">
              <img src="../../../assets/Group18780.png">
              <h5 class="no-order-heading">No Order yet !</h5>
              <p>You haven't placed any order yet,
                <br>
                When you do,their status will appear here
              </p>
              <button class="btn btn-snape-red" [routerLink]="['/products']">Explore our cookie</button>
            </div>

            <div class="row m-4 p-4 pro-bfull" *ngFor="let orders of orderDetails">
              <div class="col-md-2">
                <img class="myOrderImg" src="../../../assets/Group17967.png" />
              </div>
              <div class="col-md-8">
                <div class="my-order-list">
                  <p class="order-ref">Order Reference Number
                    <span *ngIf="orders?.message_status == 'unread'" class="unread-msg">
                      <span class="envelope-mail cursor-pointer">
                        <i class="far fa-envelope"></i>
                      </span>
                      Unread message
                    </span>
                  </p>
                  <p class="order-r2 order-id" (click)="orderDetail(orders.id)">{{orders.id}}</p>
                  <p class="order-r2">{{orders.placed_date | date:'dd-MM-yyyy'}}</p>
                  <p class="order-r3 mt-3">Status</p>
                  <!-- <p class="order-r3 order-status">{{orders.orderstatus}}</p> -->
                  <p class="order-r3">
                    <span class="black" *ngIf="orders?.orderstatus == 'Order Placed'">
                      Order Placed
                    </span>
                    <span class="blue" *ngIf="orders?.orderstatus == 'Out for delivery'">
                      Out for delivery
                    </span>
                    <span class="green" *ngIf="orders?.orderstatus == 'Delivered'">
                      Delivered
                    </span>
                    <span class="yellow" *ngIf="orders?.orderstatus == 'Ready for delivery'">
                      Ready for delivery
                    </span>
                    <span class="pink" *ngIf="orders?.orderstatus == 'Processing'">
                      Processing
                    </span>
                    <span class="gray" *ngIf="orders?.orderstatus == 'Delayed'">
                      Delayed
                    </span>
                    <span class="brown" *ngIf="orders?.orderstatus == 'Order Accepted'">
                      Order Accepted
                    </span>
                    <span class="red" *ngIf="orders?.orderstatus == 'Order Cancelled'">
                      Order Cancelled
                    </span>
                  </p>

                </div>
              </div>
              <div class="col-md-2">
                <div class="row" *ngIf="orders?.orderstatus != 'Order Cancelled'">
                  <!-- <button class="btn btn-snape-light-blue btnswid" (click)="message(orders.id)">
                    <span class="material-icons-outlined">
                      comment
                    </span>
                    <span>Message</span>
                  </button> -->
                  <button class="btn btn-snape-light-blue btnswid" (click)="orderDetail(orders.id)">
                    Message <i class="far fa-comment-dots"></i>
                  </button>
                </div>
                <div class="row mt-1" *ngIf="orders?.orderstatus != 'Order Cancelled'">
                  <button class="btn btn-cancel-grey btnswid" (click)="trackOrder(orders.id)">Track
                    order</button>
                </div>
                <!-- <div class="row mt-1" *ngIf="orders?.orderstatus != 'Order Cancelled'">
                  <button class="btn btn-snape-red btnswid" (click)="buyAgain(orders.id)">Buy it again</button>
                </div> -->
                <div class="row mt-1" *ngIf="orders?.orderstatus == 'Order Placed' || orders?.orderstatus == 'Order Accepted'">
                  <button class="btn cancel-btn" data-toggle="modal" (click)="getData(orders)"
                    data-target="#deleteModal">Cancel</button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</section>
<!-- deleteModal start -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pink-color" id="deleteModalLabel">Confirm cancel?</h6>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row d-flex justify-content-center mb-4">
            Are you sure you want to cancel?
          </div>
          <div class="row form-group mt-4">
            <div class="col-md-6 d-flex justify-content-end align-items-center">
              <button type="button" class="btn btn-cancel-grey no-btn" data-dismiss="modal">No</button>
            </div>
            <div class="col-md-6">
              <button data-dismiss="modal" class="btn btn-snape-red yes-btn" type="button"
                (click)="cancelOrder()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- deleteModal end -->