import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  newsLetterForm: FormGroup
  isLoggedIn
  submitted = false;
  showText = 'Subscribe'
  constructor(
    private api: CommonService,
    private fb: FormBuilder,
    private token: TokenStorageService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loggedUser();
    this.newsLetterForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    })
  }
  subscribe() {
    debugger
    if (this.newsLetterForm.valid) {
      if(this.showText == 'Subscribe'){
        let data = {
          "is_admin":false,
          "email":this.newsLetterForm.controls.email.value,
          "subscription_status" : "Subscribed"
        }
        this.api.addnewsletter(data).subscribe(res=>{
          this.toastr.success(res['message'])
          this.showText = 'Unsubscribe'
          if(res['status'] == 'true'){

          }
          else if(res['status'] == 'false'){
  // show popup
  
          }
        })
      }
      else if(this.showText == 'Unsubscribe'){
        let data = {
          "is_admin":false,
          "email":this.newsLetterForm.controls.email.value,
          "SK":this.newsLetterForm.controls.email.value,
          "subscription_status" : "Unsubscribed",
          "reason":"too many news"
        }
        this.api.updatenewsletter(data).subscribe(res=>{
          this.showText = 'Subscribe'
          this.toastr.success(res['message']);
        })
      }
    }
    else {
      this.submitted = false;
    }
  }
  loggedUser() {
    
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      this.token.loggedStatus(res)
    })
  }
  signup() {
    this.api.loggedIn.next(false);
    this.token.signOut()
  }
  redirectToUrl(){
    window.location.href = environment.socialLogin
    }
  redirectInsta(){
    window.location.href = "https://instagram.com/snapecookiesqa?igshid=ZmRlMzRkMDU="
  }
}
