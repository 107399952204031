import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  myImageUrl: string = 'assets/avatar.png';
  userStatus
  userImg: any;
  isLoggedIn: boolean
  cartLength: any;
  notificationArray
  notificationLength: any;
  constructor(
    private router: Router,
    private api: CommonService,
    private token: TokenStorageService
  ) { }
  @HostListener('window:scroll', ['$event'])

  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }
  ngOnInit(): void {
    debugger
    this.loggedUser();
    this.userProfile();
    this.getCount()
  }
  getCount(){
    let local_storage = JSON.parse(localStorage.getItem('cart'))
    if (local_storage?.length) {
      this.cartLength = local_storage.length
    }
    else {
      this.cartLength = 0
    }
    if (this.isLoggedIn){
      this.api.getNotification().subscribe(res => {
        this.notificationArray = res['data']['Items']
        this.notificationArray = _.filter(this.notificationArray, (item)=>{
          return item.status == 'unread'
        })
        this.notificationLength = this.notificationArray?.length
      })
      // this.api.getCart().subscribe((resp)=>{
      //   let online_cart_items = resp['data']
      //   if(local_storage?.length == 0 || local_storage?.length == undefined || local_storage.length == null){
      //     local_storage = online_cart_items
      //     this.cartLength = local_storage.length
      //   }else if(local_storage != null && online_cart_items?.length != 0){
      //     let newdata:any=[]
      //     newdata = online_cart_items
      //     const filteredItems = local_storage.filter((x) => !newdata.find((y) => y.id === x.id));

      //   console.log(filteredItems,'filterItems');
      //    let test = filteredItems.concat(newdata)
      //    local_storage = test
      //    this.cartLength = test.length
      this.api.getCart().subscribe((resp) => {
        const online_cart_items = resp['data'];
        if (local_storage?.length == 0 || local_storage?.length == undefined || local_storage?.length == null) {
          local_storage = online_cart_items;
        } else if (local_storage != null && online_cart_items?.length != 0) {
          const newdata = online_cart_items;
          const filteredItems = local_storage.filter((x) => !newdata.find((y) => y.id === x.id));
          console.log(filteredItems, 'filtereditems');
          const mergedData = filteredItems.concat(newdata);
          this.cartLength = mergedData.length
        }
        
      })
    }
  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      this.token.loggedStatus(res)
    })
  }
  userProfile() {
    if (this.isLoggedIn) {
      this.api.getProfile().subscribe(user => {
        this.userStatus = user['status']
        if (this.userStatus == true) {
          this.userImg = user['data']['Item'].image
          if (this.userImg == undefined) {
            this.userImg = this.myImageUrl
          }
        }
      })
    }
  }
  logout() {
    this.api.loggedIn.next(false);
    this.token.signOut()
    this.router.navigate(["/login"])
  }
}
