<div class="container">
  <div class="row mt-3">
    <div class="col-md-12">
      <div>
        <button type="button" class="btn m-3 back-btn-icon" routerLink="/home" routerLinkActive="router-link-active">
          <span class="material-icons-outlined btn-snape-white back-icon">
            chevron_left
          </span>
          <span class="back-btn-span">Back to home</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div class="login-image">
        <img src="../../../assets/loginimg.png">
      </div>
    </div>
    <div class="col-md-6 logintxt">
      <div class="auth-ui">
        <div class="ml-3">
          <h3>Create an account</h3>
          <p>Already have an account? <a routerLink="/login" class="snape-red">Login</a></p>
        </div>

        <form [formGroup]="registerForm" class="pt-2">
          <div class="using-mail-id-screen" *ngIf="!activateNumber">
            <div class="position-relative form-group">
              <div class="col-md-12">
                <input formControlName="name" id="Fullname" placeholder="Full name" required="" type="text"
                  class="form-control">
                <span class="text-danger"
                  *ngIf="(registerForm.controls.name.touched || submitted) && registerForm.controls.name.errors?.required">
                  Name is required
                </span>
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="col-md-12">
                <input formControlName="email" id="inputEmail" placeholder="Email" required="" type="text"
                  class="form-control">
                <span class="text-danger"
                  *ngIf="(registerForm.controls.email.touched || submitted) && registerForm.controls.email.errors?.required">
                  Email is required
                </span>
                <span class="text-danger"
                  *ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.pattern">
                  Enter a valid email address
                </span>
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="col-md-12">
                <div class="input-group">
                  <input formControlName="password" id="inputPass" placeholder="Password" required="" [type]="password"
                    class="form-control">
                  <div class="input-group-append">
                    <button (click)="showPasswordOnClick()">
                      <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                      <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
                    </button>
                  </div>
                </div>

                <span class="text-danger"
                  *ngIf="(registerForm.controls.password.touched || submitted) && registerForm.controls.password.errors?.required">
                  Password is required
                </span>
                <span class="text-danger"
                  *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.invalidPassword">
                  Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                  letter, 1 special character and 1 number
                </span>
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="col-md-12">
                <div class="input-group">
                  <input name="password" [type]="showPassword ? 'text' : 'password'" class="form-control"
                    id="confirmPass" placeholder="Confirm Password" formControlName="passwordConfirm" required="">
                  <div class="eye-icons">
                    <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
                      [class.hide]="!showPassword"></i>
                    <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                      [class.hide]="showPassword"></i>
                  </div>
                </div>

                <span class="text-danger"
                  *ngIf="(registerForm.controls.passwordConfirm.touched || submitted) && registerForm.controls.passwordConfirm.errors?.required">
                  Confirm Password is required
                </span>
                <span class="text-danger"
                  *ngIf="registerForm.controls.passwordConfirm.touched && registerForm.controls.passwordConfirm.errors?.passwordMismatch">
                  Passwords doesnot match
                </span>
              </div>
            </div>
          </div>
          <div class="using-mobile-number-screen" [formGroup]="numberForm" *ngIf="activateNumber">
            <div class="position-relative form-group">
              <div class="col-md-12">
                <input id="Fullname" placeholder="Enter mobile number with country code" formControlName="number" required="" type="text"
                  class="form-control">
                <span class="text-danger"
                  *ngIf="(numberForm.controls.number.touched) && numberForm.controls.number.errors?.required">
                  Number is required
                </span>
                <span class="text-danger"
                  *ngIf="numberForm.controls.number.touched && numberForm.controls.number.errors?.pattern">
                  Enter a valid phone number
                </span>
              </div>
            </div>

            <!-- <div class="position-relative form-group">
              <div class="col-md-12">
                <div class="input-group">
                  <input id="inputPass" placeholder="Password" formControlName="password" required="" [type]="password"
                    class="form-control">
                  <div class="input-group-append">
                    <button (click)="showPasswordOnClick()">
                      <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                      <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
                    </button>
                  </div>
                </div>
                <span class="text-danger"
                  *ngIf="(numberForm.controls.password.touched || submitted) && numberForm.controls.password.errors?.required">
                  Password is required
                </span>
                <span class="text-danger"
                  *ngIf="numberForm.controls.password.touched && numberForm.controls.password.errors?.invalidPassword">
                  Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                  letter, 1 special character and 1 number
                </span>
              </div>
            </div>
            <div class="position-relative form-group">
              <div class="col-md-12">
                <div class="input-group">
                  <input name="password" [type]="showPassword ? 'text' : 'password'" class="form-control"
                    id="confirmPass" placeholder="Confirm Password" formControlName="passwordConfirm" required="">
                  <div class="eye-icons">
                    <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
                      [class.hide]="!showPassword"></i>
                    <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                      [class.hide]="showPassword"></i>
                  </div>
                </div>
                <span class="text-danger"
                  *ngIf="(numberForm.controls.passwordConfirm.touched || submitted) && numberForm.controls.passwordConfirm.errors?.required">
                  Confirm Password is required
                </span>
                <span class="text-danger"
                  *ngIf="numberForm.controls.passwordConfirm.touched && numberForm.controls.passwordConfirm.errors?.passwordMismatch">
                  Passwords doesnot match
                </span>
              </div>
            </div> -->
          </div>
          <div class="position-relative form-group">
            <div class="d-flex flex-wrap" [formGroup]="registerForm" *ngIf="!activateNumber">
              <div class="col-md-12">
                <p>
                  <input type="checkbox" formControlName="terms" required />
                  <span class="ml-2">By creating an account , you agree to the <a href="https://snapecookies.com/terms"
                      target="_blank" class="snape-red">Terms &
                      conditions</a> and <a href="https://snapecookies.com/privacy-policy" target="_blank"
                      class="snape-red">Privacy
                      policy</a></span><br />
                  <span class="text-danger"
                    *ngIf="(registerForm.controls.terms.touched || submitted) && registerForm.controls.terms.errors?.required">
                    Please accept the terms
                  </span>
                </p>
              </div>
            </div>
            <div class="d-flex flex-wrap" [formGroup]="numberForm" *ngIf="activateNumber">
              <div class="col-md-12">
                <p>
                  <input type="checkbox" formControlName="terms" required />
                  <span class="ml-2">By creating an account , you agree to the <a href="https://snapecookies.com/terms"
                      target="_blank" class="snape-red">Terms &
                      conditions</a> and <a href="https://snapecookies.com/privacy-policy" target="_blank"
                      class="snape-red">Privacy
                      policy</a></span><br />
                  <span class="text-danger"
                    *ngIf="(numberForm.controls.terms.touched || submitted) && numberForm.controls.terms.errors?.required">
                    Please accept the terms
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="position-relative form-group">
            <div class="d-flex justify-content-center col-md-12 mt-4" *ngIf="!activateNumber">
              <button type="submit" (click)="register()" [disabled]="registerForm.invalid"
                class="btn btn-snape-bak btn-block text-uppercase">Sign up</button>
            </div>
            <div class="d-flex justify-content-center col-md-12 mt-4" *ngIf="activateNumber">
              <button type="submit" (click)="registerNumber()" [disabled]="numberForm.invalid"
                class="btn btn-snape-bak btn-block text-uppercase">Sign up</button>
            </div>
          </div>
          <div class="position-relative form-group">
            <div class="d-flex justify-content-center col-md-12 mt-4">
              <button type="submit" *ngIf="!activateNumber" (click)="getNumber()" class="btn btn-link">Use mobile
                number</button>
              <button type="submit" *ngIf="activateNumber" (click)="getEmail()" class="btn btn-link">Use
                Email-ID</button>
            </div>
          </div>
          <div class="text-center">
            <p>or use an existing account</p>
            <!-- hide fb,google icon -->
            <!-- <span class="p-2" (click)="redirectToUrl()">
              <img src="../assets/fb.svg" class="cursor-pointer" />
            </span>
            <span class="p-2" (click)="redirectToUrl()">
              <img src="../assets/google.svg" class="cursor-pointer" />
            </span> -->
            <!-- hide fb,google icon end -->
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="col-md-6 logintxt authentication-screen ">
      <div class="auth-ui">
        <h2>Authentication required</h2>
        <p class="mt-3">Please enter the One time password sent to your registered Email id or Phone number</p>
        <div class="position-relative form-group mt-2">
          <div class="">
            <input placeholder="Enter OTP" type="password" class="form-control">
          </div>
        </div>
        <div class="position-relative form-group">
          <div class="mt-4">
            <button type="submit" class="btn btn-snape-bak btn-block" data-toggle="modal"
              data-target="#emailIdModal">Continue</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>

<!-- emailIdModal start -->
<div class="modal fade" id="emailIdModal" tabindex="-1" role="dialog" aria-labelledby="emailIdModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content p-4">
      <div class="modal-header">
        <h6 class="modal-title" id="deleModalLabel">Email id</h6>
      </div>
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col-md-12">
            <label>Please enter your email id</label>
            <input type="text" class="form-control">
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <div>
              <button type="button" class="btn continue-btn" data-dismiss="modal">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- emailIdModal end -->