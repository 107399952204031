<div class="container">
    <section class="invoice-sec-start">

        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="../../../assets/img/snapeLogo.svg">
                                </td>
                                <td>
                                    <h1 class="invoice-caption">INVOICE</h1>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr class="hr-1">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <div class="m-t-20">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <td class="common-line-height recipient">Recipient</td>
                                <td class="common-line-height float-right">29th May 2021</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="common-line-height font-weight">Albin</td>
                                <td class="common-line-height float-right pink-color">Order Ref : #011245</td>
                            </tr>
                            <tr>
                                <td class="common-line-height">Matar Qadeem.P.O.Box 1123</td>
                            </tr>
                            <tr>
                                <td class="common-line-height">Doha</td>
                            </tr>
                            <tr>
                                <td class="common-line-height">Qatar</td>
                            </tr>
                            <tr>
                                <td class="common-line-height">Phn:(00974) 44433236</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- <hr class="hr-2"> -->
        <div class="row">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <div class="m-t-20 table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <td>PRODUCT</td>
                                <td class="col-align">QTY</td>
                                <td class="col-align">PRICE (QAR)</td>
                                <td class="col-align">Total (QAR)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-weight">1 COOKIE BOX</td>
                                <td class="col-align light-gray">6</td>
                                <td class="col-align light-gray">QAR 14.00</td>
                                <td class="col-align light-gray">QAR 84.00</td>
                            </tr>
                            <tr>
                                <td class="font-weight">2 COOKIE BOX</td>
                                <td class="col-align light-gray">3</td>
                                <td class="col-align light-gray">QAR 28.00</td>
                                <td class="col-align light-gray">QAR 84.00</td>
                            </tr>
                            <tr>
                                <td class="font-weight">1 COOKIE BOX</td>
                                <td class="col-align light-gray">12</td>
                                <td class="col-align light-gray">QAR 14.00</td>
                                <td class="col-align light-gray">QAR 168.00</td>
                            </tr>
                            <tr>
                                <td class="col-align"></td>
                                <td class="col-align"></td>
                                <td class="col-align light-gray">Subtotal</td>
                                <td class="col-align light-gray">QAR 334.00</td>
                            </tr>
                            <tr>
                                <td class="border-nill font-weight">PAYMENT METHOD</td>
                                <td class="col-align border-nill"></td>
                                <td class="col-align light-gray">Shipping</td>
                                <td class="col-align light-gray">0.00</td>
                            </tr>
                            <!-- <tr>
                                <td class="border-nill">Card Payment
                                    <br>
                                    <span class="light-gray">Visa, Mastercard, Payooner</span>
                                </td>
                                <td class="col-align border-nill"></td>
                                <td class="col-align border-nill"></td>
                                <td class="col-align border-nill light-gray bg-color">TOTAL</td>
                                <td class="col-align border-nill light-gray bg-color">QAR 334.00</td>
                            </tr>  -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-6 col-xs-12">
                <div class="mob-text-align">
                    <h6>Card Payment</h6>
                    <p class="light-gray">Visa, Mastercard, Payooner</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xs-12">
                <div class="float-rigt mob-text-align">
                    <button type="button" class="btn btn-pink btn-width">
                        <span class="float-left font-weight">TOTAL</span>
                        <span class="float-rigt mob-float-rigt font-weight">QAR 334.00</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12">
                <div class="m-t-150 text-align">
                    <p class="light-gray">*** This is a computer generated invoice.No signature is required ***
                    </p>
                </div>
            </div>
        </div>
        <div class="row p-t-50">
            <div class="col-md-12 col-lg-12 col-xs-12">
                <div class="mob-text-align">
                    <p class="font-weight">SnapE Cookie</p>
                    <p class="light-gray">123 Street Name, City/Town. Postalzip, Country </p>
                </div>
            </div>
        </div>
        <div class="row p-t-25">
            <div class="col-md-6 col-lg-4 col-xs-12">
                <div class="mob-text-align">
                    <p class="light-gray">
                        <i class="fas fa-mobile-alt icon-bg-color"></i>
                        + 00 123 444 5566
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xs-12">
                <div class="text-align mob-text-align">
                    <p class="light-gray">
                        <i class="fas fa-location-arrow icon-bg-color"></i>
                        snapecookie@mail.com
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xs-12">
                <div class="website-align mob-text-align">
                    <p class="light-gray">
                        <i class="fas fa-globe-europe icon-bg-color"></i>
                        www.snapecookie.com
                    </p>
                </div>
            </div>
        </div>
        <hr class="hr-3">
    </section>

</div>