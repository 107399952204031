import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import * as _ from 'underscore';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss', './trackTimeLine.scss']
})
export class OrdersComponent implements OnInit {
  localStorage = [];
  counter;
  cartItem = []
  product
  isLoggedIn: boolean
  paymentHandler: any = null;
  showAddressForm = false;
  next = false;
  address: any;
  zoneArray;
  addressForm: FormGroup;
  paymentForm: FormGroup;
  selectedAddressForm: FormGroup;
  selectedAddressArray
  input: string;
  showAppleDiv = false;
  showPayDiv = false;
  signature_value
  signature_field
  hashKey
  orderAmount: number = 0.00;
  arrayOfValues: Array<string>;
  deliveryDate: string;
  userEmail: Object;
  deliveryCharge: any;
  total
  orderId: any;
  name: any;
  baseUrl: string;
  returnUrl: string;
  userCity: any;
  isShowPersonal = true
  isShowShipping = false
  public counts = ["Personal Details", "Shipping Details", "Payment"];
  public orderStatus = "Personal Details"
  userDetails: any;
  submitted = false
  cartLength: number;
  index: string;
  itemId: any;
  deletedProduct: any;
  self_pickup = false;
  couponSK:any
  savedAmount:any
  discountType:any
  couponCode:any

  loader = false
  paymentBtn: boolean = false
  isActive;

  constructor(
    private router: Router,
    private api: CommonService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    debugger
    const myArray = this.activatedRoute.snapshot.queryParamMap.get('myArray');
    console.log(myArray, 'mmm')
    if (myArray === null) {
      this.arrayOfValues = new Array<string>();
    } else {
      this.arrayOfValues = JSON.parse(myArray);
    }
    console.log(this.arrayOfValues, 'array')
    this.deliveryCharge = this.arrayOfValues['delivery_charge']
    this.deliveryDate = this.arrayOfValues['delivery_date'].toString().split("-").reverse().join("-");
    this.total = this.arrayOfValues['order_total']
    this.couponSK = this.arrayOfValues['couponSK']
    this.savedAmount = this.arrayOfValues['savedAmount']
    this.couponCode = this.arrayOfValues['couponCode']
    this.discountType = this.arrayOfValues['discountType']
    this.baseUrl = location.origin
    this.returnUrl = this.baseUrl + '/order-complete'
    console.log(this.returnUrl, 'ret')
    if (this.arrayOfValues['preference'] == 'self_pickup') {
      this.next = true
      this.self_pickup = true
      this.orderStatus = "Payment"
    }
    this.loggedUser();
    this.addAddressForm();
    this.calculateOrderAmount();
    this.getAddress();
    this.addSelectAddressForm();
    this.getProfile();
    this.addPaymentForm();
    // this.getOrder();
    this.createSecureHash();
    console.log(this.orderAmount, 'AMOUNT')
  }
  // getOrder() {
  //   this.api.getOrder().subscribe(item => {
  //     console.log(item, 'order')
  //   })
  // }

  addAddressForm() {
    this.addressForm = this.fb.group({
      fullName: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      flatVilla: ['', Validators.required],
      streetName: ['', Validators.required],
      city: ['', Validators.required],
      zone: ['', Validators.required],
      areaNumber: [''],
      contactNumber: ['', [Validators.required, Validators.pattern("^[0-9]{8}$")]],
      // map:['']
    })
  }
  addPaymentForm() {
    this.paymentForm = this.fb.group({
      card: ['']
    })
  }
  cancel() {
    this.addressForm.reset()
    this.showAddressForm = false
  }
  addSelectAddressForm() {
    this.selectedAddressForm = this.fb.group({
      select_address: ['']
    })
  }
  editPersonalDetails() {
    this.isShowPersonal = !this.isShowPersonal
  }
  
  showApplePay(data: string) {
    if (data == 'applePay') {
      this.showAppleDiv = true;
      this.showPayDiv = false;
    }
    if (data == 'pay') {
      this.showAppleDiv = false;
      this.showPayDiv = true;
    }

  }
  editShippingDetails() {
    debugger
    this.orderStatus = "Shipping Details"
    console.log(this.isShowShipping, this.next)
    this.isShowShipping = !this.isShowShipping
    this.next = !this.next
    console.log(this.isShowShipping, this.next)
  }
  showAddress() {
    this.showAddressForm = true
    console.log(this.showAddressForm, this.next)
    this.cd.detectChanges();
    document.querySelector('#showFillAddress').scrollIntoView({ behavior: 'smooth', block: 'center' })
    this.submitted = false
  }
  saveAddress() {
    this.submitted = true
    let data = {
      "name": this.addressForm.controls.fullName.value,
      "building": this.addressForm.controls.buildingNumber.value,
      "flat_villa": this.addressForm.controls.flatVilla.value,
      "street": this.addressForm.controls.streetName.value,
      "city": this.addressForm.controls.city.value,
      "zone": this.addressForm.controls.zone.value,
      "area": this.addressForm.controls.areaNumber.value,
      "contact_number": this.addressForm.controls.contactNumber.value,
    }
    this.api.addAddress(data).subscribe(item => {
      console.log(item, 'item')
      let message = item['message']
      this.toastr.success(message)
      this.ngOnInit();
      this.showAddressForm = false
      this.submitted = false
      this.cd.detectChanges();
      document.querySelector('#address_list').scrollIntoView({ behavior: 'smooth', block: 'center' })
    })

  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      console.log(res, !this.isLoggedIn, 'user')
    })
  }
  calculateOrderAmount() {
    debugger
    let orderTotal = 0
  // this.localStorage = JSON.parse(localStorage.getItem('cart'));
  this.api.getCart().subscribe((resp)=>{
    this.localStorage = resp['data']
    for (var i in this.localStorage) {
      orderTotal = orderTotal + (this.localStorage[i].product.price * this.localStorage[i].quantity)
    }
    this.orderAmount = orderTotal
    this.total = this.orderAmount + parseInt(this.deliveryCharge)
    if(this.savedAmount){
      this.total = this.orderAmount + parseInt(this.deliveryCharge) - this.savedAmount
    }
    this.total = this.total.toFixed(2)
    console.log(this.orderAmount, 'orderAmount')
  })
   
  //  this.api.getCart().subscribe((resp)=>{
  //   let online_cart_items = resp['data']
  //   if(this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null){
  //     this.localStorage = online_cart_items
  //   }else if(this.localStorage != null && online_cart_items?.length != 0){
  //     let newdata:any=[]
  //     newdata = online_cart_items
  //    let test = this.localStorage.concat(newdata)
  //    this.localStorage = test
  //   }
   
  // })
    // this.api.getCart().subscribe((item)=>{
    //   if(item['status']== true){
    //     this.localStorage = item['data']
    //     console.log(this.localStorage, 'local ggg')
    //     for (var i in this.localStorage) {
    //       orderTotal = orderTotal + (this.localStorage[i].product.price * this.localStorage[i].quantity)
    //     }
    //     this.orderAmount = orderTotal
    //     this.total = this.orderAmount + parseInt(this.deliveryCharge)
    //     this.total = this.total.toFixed(2)
    //     console.log(this.orderAmount, 'orderAmount')
    //   }
      
    // })
   
  }
  getAddress() {
    this.api.getAddress().subscribe(add => {
      console.log(add, 'address')
      if (add['data']) {
        this.address = add['data']
        this.address = _.sortBy(this.address, function (o) {
          return o.created_at
        })
        this.address = this.address.reverse();
      }
    })
  }
  getProfile() {
    this.api.getProfile().subscribe(userDetails => {
      this.userDetails = userDetails['data']['Item']
      this.userEmail = userDetails['data']['Item'].email
      console.log(userDetails, 'user data')
    })
  }
  logout() {
    this.api.loggedIn.next(false);
    this.tokenStorage.signOut()
    //for clearing local stoage data

    this.router.navigate(["/login"])
  }
  addressNext() {
    debugger
    this.isShowShipping = !this.isShowShipping
    this.next = !this.next
    this.orderStatus = "Shipping Details"
    this.cd.detectChanges();
    document.querySelector('#orderDetails').scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  deleteItem(index, id, deletedProduct) {
    let deletedItem = deletedProduct.product['price'] * deletedProduct.quantity
    this.api.deleteCart({ "id": deletedProduct.id }).subscribe(item => {
      console.log(item, 'item')
      debugger
      this.localStorage.splice(index, 1);
      console.log("shopping_cart ", this.localStorage);
      this.api.getCart().subscribe(item => {
        this.localStorage = item['data']
      })
      localStorage.setItem('cart', JSON.stringify(this.localStorage));
      this.toastr.success(item['message'])
      this.orderAmount = this.orderAmount - deletedItem
      this.total = this.orderAmount + parseInt(this.deliveryCharge)
      this.total = this.total.toString();
      if (this.localStorage.length == 0 || this.orderAmount == 0) {
        this.cartLength = 0
        this.router.navigate(['/products'])
      }
      else {
        this.calculateOrderAmount();
      }
    })
    let image = JSON.parse(localStorage.getItem('image'))
    if (image) {
      image.splice(index, 1);
    }
  }
  selectedAddress(address) {
    debugger
    this.submitted = true;
  this.showAddressForm = false;
  this.selectedAddressArray = address;
  console.log(this.selectedAddressArray, 'asdasffsf');
// Get the zone value from the selected address
  const selectedZone = this.selectedAddressArray.zone;
  this.api.listZones().subscribe(res => {
  this.zoneArray = res['zone'].filter(zone => zone.zone === selectedZone);
  console.log('zone', this.zoneArray);
  const deliveryamount = this.zoneArray[0].deliverycharge
  console.log(deliveryamount,'deliveryamount')
   this.deliveryCharge = deliveryamount
   this.calculateOrderAmount()
  console.log( this.deliveryCharge ,' this.deliveryCharge ')
  });
  }
  remove(item, index) {
    item = this.localStorage[index]
    console.log("Deleting : ", item);
    for (let i in this.localStorage) {
      if (item.id == this.localStorage[i].id) {
        this.index = i;
        this.itemId = item.id
        this.deletedProduct = this.localStorage[i]
      }
    }
  }
  addOrder() {
    debugger
    this.loader = true
    this.paymentBtn = true
    let contact_number = ''
    if (this.selectedAddressArray) {
      contact_number = this.selectedAddressArray['contact_number'].toString()
    }
    else {
      contact_number = ''
    }
    let data = {
      "payment_mode": "card",
      "address": this.selectedAddressArray,
      "email": this.userEmail,
      "phone": contact_number,
      // "phone": this.selectedAddressArray['contact_number'].toString(),
      // "delivery_charge": this.arrayOfValues['delivery_charge'].toString(),
      "delivery_charge" : this.deliveryCharge.toString(),
      "delivery_date": this.deliveryDate,
      "delivery_time": this.arrayOfValues['delivery_time'],
      "order_amount": this.orderAmount.toString(),
      "couponSK":this.couponSK,
      "discountAmount":this.savedAmount,
      "order_total": this.total.toString(),
      "couponCode":this.couponCode,
      "discountType":this.discountType,
      "preference": this.arrayOfValues['preference']
    }
    console.log(typeof (this.total), 'tyr')
    this.api.addOrder(data).subscribe(item => {
      // console.log(item['return_data'].address['name'], 'order')
      this.orderId = item['return_data'].order_id
      window.sessionStorage.setItem('order_id', this.orderId);
      // this.orderId = 'ORD' +this.orderId.slice(this.orderId.length - 9)
      // this.orderId = this.orderId.replace(/-/g, "");
      // this.orderId = 'ORD' + this.orderId.slice(0, 9)
      // this.name = item['return_data'].address['name']
      this.name = 'Albin'
      // this.userCity = item['return_data'].address['city']
      this.userCity = 'Doha'
      console.log(this.orderId, typeof (this.orderId), 'id')
      // get order id or order details
      this.gateWay();

    })
  }
  gateWay() {
    debugger
    let data: any = {
      "InvoiceAmount": this.total,
      "CurrencyIso": "QAR"
    }
    this.api.payment(data).subscribe((resp) => {
      console.log(resp)
      if (resp) {
        let paymentReq: any = {
          "paymentMethodId": 2,
          "InvoiceValue": this.total,
          "orderId": this.orderId,
          "CallBackUrl": environment.callBack,
          "ErrorUrl": "https://example.com/callback.php"
        }
        this.api.executePayment(paymentReq).subscribe((result) => {
          console.log(result)
          this.paymentBtn = false
          this.loader = false
          // window.open(result.executepay_response.Data.PaymentURL, 'popup', 'width=800,height=800,top=150px,left=500px')
          window.location.href = result.executepay_response.Data.PaymentURL
          // window.open(result.executepay_response.Data.PaymentURL, '_blank')
        })
      }
    })
  }
  appleAddOrder() {
    debugger
    this.loader = true
    this.paymentBtn = true
    let contact_number = ''
    if (this.selectedAddressArray) {
      contact_number = this.selectedAddressArray['contact_number'].toString()
    }
    else {
      contact_number = ''
    }
    let data = {
      "payment_mode": "card",
      "address": this.selectedAddressArray,
      "email": this.userEmail,
      "phone": contact_number,
      // "phone": this.selectedAddressArray['contact_number'].toString(),
      // "delivery_charge": this.arrayOfValues['delivery_charge'].toString(),
      "delivery_charge" : this.deliveryCharge.toString(),
      "delivery_date": this.deliveryDate,
      "delivery_time": this.arrayOfValues['delivery_time'],
      "order_amount": this.orderAmount.toString(),
      "couponSK":this.couponSK,
      "discountAmount":this.savedAmount,
      "order_total": this.total.toString(),
      "couponCode":this.couponCode,
      "discountType":this.discountType,
      "preference": this.arrayOfValues['preference']
    }
    console.log(typeof (this.total), 'tyr')
    this.api.addOrder(data).subscribe(item => {
      // console.log(item['return_data'].address['name'], 'order')
      this.orderId = item['return_data'].order_id
      window.sessionStorage.setItem('order_id', this.orderId);
      // this.orderId = 'ORD' +this.orderId.slice(this.orderId.length - 9)
      // this.orderId = this.orderId.replace(/-/g, "");
      // this.orderId = 'ORD' + this.orderId.slice(0, 9)
      // this.name = item['return_data'].address['name']
      this.name = 'Albin'
      // this.userCity = item['return_data'].address['city']
      this.userCity = 'Doha'
      console.log(this.orderId, typeof (this.orderId), 'id')
      // get order id or order details
      this.appleGateWay();

    })
  }
  appleGateWay() {
    debugger
    let data: any = {
      "InvoiceAmount": this.total,
      "CurrencyIso": "QAR"
    }
    this.api.payment(data).subscribe((resp) => {
      console.log(resp)
      if (resp) {
        let paymentReq: any = {
          "paymentMethodId": 11,
          "InvoiceValue": this.total,
          "orderId": this.orderId,
          "CallBackUrl": environment.callBack,
          "ErrorUrl": "https://example.com/callback.php"
        }
        this.api.executePayment(paymentReq).subscribe((result) => {
          console.log(result)
          this.paymentBtn = false
          this.loader = false
          // window.open(result.executepay_response.Data.PaymentURL, 'popup', 'width=800,height=800,top=150px,left=500px')
          window.location.href = result.executepay_response.Data.PaymentURL
          // window.open(result.executepay_response.Data.PaymentURL, '_blank')
        })
      }
    })
  }
  async makePayment() {
    const res = await this.paymentAction(' https://demopaymentapi.qpayi.com/api/gateway/v2.0 ');
    if (!res) {
      alert("not loaded payment gateway");
      return;
    }



  }
  paymentAction(src) {

    return new Promise((resolve) => {
      const script = window.document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      }
      // script.error = () => {
      //           resolve(false);
      //       }
      window.document.body.appendChild(script);
    })
  }
  submit() {
    let data = {
      "action": "capture",
      "gatewayId": "017855659",
      "signatureFields": "gatewayId,amount,referenceId",
      "referenceId": this.orderId,
      "amount": this.total,
      "currency": "QAR",
      "mode": "TEST",
      "description": "product",
      "returnUrl": "http://localhost:4200/order-complete",
      "name": this.name,
      "address": "Doha",
      "city": "Grand Hamad Street",
      "state": "Doha",
      "country": "QA",
      "phone": "56782",
      "email": this.userEmail
    }

    //   this.signature_field =  this.createSecureHash(data['gatewayId'], data['referenceId'],
    //  data['amount'],"2-8sruzTk2u8PK7z");


    $("#qpay_paymentform").attr("action", "https://demopaymentapi.qpayi.com/api/gateway/v2.0");
    setTimeout(function () {
      // document.getElementById(form).submit();
      $("#qpay_paymentform").submit();
      $(".checkout-wrapper").fadeOut('slow');
    }, 500);
    this.hashKey = this.createSecureHash()
    console.log(this.hashKey, 'hashkey')
  }
  createSecureHash(gatewayId = "014331468", referenceId = this.orderId,
    amount = this.total, secretKey = "2-2TVhR+5Pw2pXHg") {
    this.input = "gatewayId=" + gatewayId + ",amount=" + amount
      + ",referenceId=" + referenceId
    console.log(this.input)
    console.log(secretKey, 'secretkey')

    return this.sign(this.input, secretKey);
  }
  sign(input, key) {
    let hash = CryptoJS.HmacSHA256(input, key);
    console.log(hash, 'hash')
    let hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    console.log(hashInBase64, 'hashInbase64')
    this.signature_value = hashInBase64
    console.log(this.signature_value, 'signature')
    return hashInBase64
  }

}
