<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<!-- cookie cart start -->
<section class="bg-color">
  <div class="container pageTop">
    <div class="row p-50">
      <div class="col-md-12 mb-4">
        <div class="row">
          <div class="col-md-6">
            <p class="fsize24 fw600">My Cart - Customize Cookie</p>
          </div>
          <div class="col-md-6 d-flex justify-content-end" *ngIf="localStorage?.length > 0">
            <!-- <span class="mr-1" *ngIf="note == true">Note: Save to cart redirect to login page</span> -->
            <!-- <button class="btn-trans snape-red fw500 fsize16px" type="button" (click)="saveCart()"><i
                class="fa fa-save mr-1"></i> Save my cart</button> -->
          </div>
        </div>
        <div class="row" *ngIf="localStorage?.length == 0">
          <div class="col-md-12">
            <div class="no-order-image-area text-center mb-3">
              <img src="../../../assets/cart-empty.png">
              <!-- <h5 class="no-order-heading">No Order yet !</h5> -->
              <h5 class="no-order-heading">No item in your cart</h5>
              <!-- <p>You haven't placed any order yet,
              <br>
              When you do,their status will appear here
            </p> -->
              <button class="btn btn-snape-red" [routerLink]="['/products']">Explore our cookie</button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="localStorage?.length > 0">
          <div class="col-md-6 mb-3" *ngFor="let cartItem of localStorage; let i = index">
            <div class="card">
              <div class="card-body">

                <div class="row mt-2">
                  <div class="col-md-4">
                    <img style="width: 100%; height: auto; border-radius: 5px;
                                object-fit: cover;" src="{{cartItem.box_img_url}}" />
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="fSize20 p-t-20">{{cartItem.product.name}} <span class="float-right">
                            <button class="btnTrans cursor-pointer" type="button" data-toggle="modal"
                              data-target="#delModal" (click)="remove(cartItem)"><i
                                class="far fa-trash-alt fSize18px gfaIcon cursor-pointer"></i>
                            </button>
                          </span></h6>
                        <p class="fsize14 gray75">Price per pack : {{cartItem.product.price}}</p>
                        <!-- <p class="fsize14 gray75" *ngIf="cartItem.flavourPrice">Price for flavour : {{cartItem.flavourPrice}}</p> -->
                        <p class="fsize14 gray75 line-height-5">Quantity : {{cartItem.quantity}}
                          <span class="fSize12px snape-red float-right">QAR
                            {{cartItem.product.price*cartItem.quantity | number :
                            '1.2-2'}}</span>
                        </p>
                        <!-- <p class="fSize12px mb-2">
                          <span class="gray75 mr-2">Total</span> <span class="ColorBOp">(QAR
                            {{cartItem.product.price}} x
                            {{cartItem.quantity}})</span>
                          <span class="fSize12px snape-red float-right">QAR
                            {{cartItem.product.price*cartItem.quantity}}</span>
                        </p> -->
                        <p class="fSize12px"><span class="fsize14 gray75 line-height-5">Pack color :
                            <span class="fweight">
                              {{cartItem.box_color}}
                            </span>
                          </span>
                          <!-- <span
                            class="float-right fSize12px">{{cartItem.box_color}}
                          </span> -->
                        </p>
                        <button class="btn btn-snape-green mt-2 mb-2 btn210 fSize14px" (click)="designCookie(cartItem)"
                          *ngIf="cartItem.count == cartItem.quantity*cartItem.product.cookie_number">Customized</button>
                        <button class="btn btn-snape-red mt-2 mb-2 btn210 fSize14px" (click)="designCookie(cartItem)"
                          *ngIf="cartItem.count != cartItem.quantity*cartItem.product.cookie_number">Customize</button>
                        <span class="float-right">
                          <!-- <span><button class="btn btn-h34"
                                                    (click)="decrement(cartItem.quantity,cartItem)">-</button></span> -->

                          <!-- <span><button class="btn btn-h34 btn-h3421"
                                                    (click)="increment(cartItem.quantity,cartItem)">+</button></span> -->
                        </span>

                      </div>
                    </div>
                    <div class="row col-md-12">
                      <form [formGroup]="checkBoxForm">
                        <!-- <input type="checkbox" (change)="check($event, cartItem.id)" [value]="cartItem.id"
                        formControlName="checkbox" id="{{cartItem.id}}"  [checked]="cartItem.is_gift">
                        <label class="ColorBOp fsize16px ml-2" for="{{cartItem.id}}"> This order contains a gift.</label> -->
                      </form>
                    </div>
                  </div>
                </div>

                <!-- Modal -->
                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <h6 class="modal-title" id="deleModalLabel">Confirm Product?</h6>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modal.close()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <div class="row d-flex justify-content-center mb-4">
                          Do you want to continue with Online Item?
                        </div>
                        <div class="row form-group mt-4">
                          <div class="col-md-6 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn-snape-red cursor-pointer form-control" data-dismiss="modal"
                              (click)="modal.close()">No</button>
                          </div>
                          <div class="col-md-6">
                            <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control"
                              data-dismiss="modal" (click)="modal.close(true)" type="button">Yes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

              </div>

              <!-- Modal -->
              <div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-labelledby="delModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h6 class="modal-title" id="deleModalLabel">Confirm delete?</h6>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="col-md-12">
                        <div class="row d-flex justify-content-center mb-4">
                          Are you sure you want to delete this product?
                        </div>
                        <div class="row form-group mt-4">
                          <div class="col-md-12">
                            <div class="d-flex justify-content-center align-items-center">
                              <button type="button" class="btn btn-cancel-grey mr-2"
                                data-dismiss="modal">Cancel</button>
                              <button data-dismiss="modal" class="btn btn-snape-red"
                                (click)="deleteItem(index, itemId, deletedProduct)" type="button">Delete</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #pending let-modal>
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="col-md-12">
                      <div class="align-text">
                        <span class="material-icons-outlined info-icon">
                          info
                        </span>
                        <h6 class="modal-title font-weight" id="deleModalLabel">Pending Customization</h6>
                      </div>
                      <div class="align-text p-t-15 font-size-12">
                        Not all cookies are customized.Please
                        <br>
                        customize all and proceed to checkout
                      </div>
                      <div class="align-text p-t-15 font-size-12">
                        Info : <span class="font-weight">Press</span>
                        <span><button type="button"
                            class="btn btn-snape-red design-cookie-btn font-size-12">Customize</button></span>
                        <span class="font-weight">to customize cookie</span>
                      </div>
                      <div>
                        <button type="button" class="btn btn-snape-red ok-btn" (click)="modal.close()">OKAY.GOT IT
                          !</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6" *ngIf="localStorage?.length > 0">
            <div class="continue-shopping-btn">
              <button class="btn-trans snape-red mt-3 text-uppercase fw500" type="button" (click)="addMoreProducts()">
                <span class="arrow"><i class="fa fa-arrow-left mr-2"></i> </span>
                Continue Shopping</button>
            </div>
          </div>
          <div class="col-md-6 d-flex justify-content-end" *ngIf="localStorage?.length > 0">
            <button class="btn btn-white snape-red mt-2 text-uppercase fw500 checkoutBtn" type="button"
              (click)="checkoutBtn()">Checkout <i class="fa fa-arrow-right ml-2"></i></button>
          </div>
        </div>
        <section id="selectPreference">
          <div class="row" *ngIf="localStorage?.length > 0 && checkoutDiv == true">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <form [formGroup]="checkoutForm">
                    <div class="row">
                      <div class="col-md-7">
                        <p class="pl-2">Select your preference</p>
                        <div class="row">
                          <div class="col-md-11">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    <input type="radio" id="homed" formControlName="preference" value="home_delivery"
                                      (change)="selectedPrefernce($event.target.value)" required>
                                    <label for="homed" class="ml-2 ColorBOp pt-2 mb-0">Home Delivery</label>
                                    <p class="delivery-charge-info text-left snape-red mb-0" *ngIf="showHomeDelivery">
                                      Delivery charge will be calculated at the time of checkout
                                    </p>
                                  </td>
                                  <!-- <td class="text-right" *ngIf="showHomeDelivery">
                                    Delivery charge will be calculated during checkout
                                  </td> -->
                                </tr>
                                <tr>
                                  <td class="d-flex">
                                    <input type="radio" id="self" formControlName="preference" value="self_pickup"
                                      (change)="selectedPrefernce($event.target.value)" required>
                                    <label for="self" class="ml-2 ColorBOp pt-2">Self Pickup</label>
                                  </td>
                                  <td class="text-right">
                                    Free
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <!-- <div class="col-md-8 pl-4">
                            <input type="radio" id="homed" formControlName="preference" value="home_delivery"
                              (change)="selectedPrefernce($event.target.value)" required>
                            <label for="homed" class="ml-2 ColorBOp">Home Delivery</label>

                          </div>
                          <div class="col-md-4 text-right">
                            QAR 10.00
                          </div> -->
                        <!-- <div class="row">
                          <div class="col-md-8 pl-4">

                            <input type="radio" id="self" formControlName="preference" value="self_pickup"
                              (change)="selectedPrefernce($event.target.value)" required>
                            <label for="self" class="ml-2 ColorBOp">Self Pickup</label>

                          </div>
                          <div class="col-md-4 text-right">
                            Free
                          </div>
                        </div> -->
                        <br />
                        <p>Select delivery date & time</p>
                        <div class="row">
                          <div class="col-md-5 col-lg-4">
                            <input class="form-control" id="datepicker" min="{{minDate | date:'yyyy-MM-dd'}}"
                              type="date" formControlName="date" (change)="clearTime()" required
                              onfocus="(this.type='date')" placeholder="Date" />
                          </div>
                          <div class="col-md-5 col-lg-4">
                            <div class="mob-m-t-b-20" required>

                              <p-calendar [timeOnly]="true" inputId="timeonly" [showIcon]="true" icon="fas fa-clock"
                                placeholder="Time" class="p-button-sm" formControlName="time" [hourFormat]="24" [required]="true"
                                (onBlur)="getTime($event)"></p-calendar>

                              <!-- <input class="form-control" type="time" id="appt-time" name="appt-time"
                                formControlName="time" (change)="getTime($event)" required placeholder="Time" /> -->

                            </div>
                          </div>
                          <div class="col-md-1 col-lg-1">
                            <div class="popover__wrapper">
                              <i class="fas fa-info-circle"></i>
                              <div class="popover__content">
                                <p>Cookies will be delivered 3 hours after placing an order. Please make sure to choose
                                  your delivery time
                                  accordingly.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-5 borLeft">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="discount-coupon-area">
                              <h6 class="pl-2">Discount coupon</h6>
                              <div class="input-group mb-2 pl-2 mt-3" [formGroup]="couponForm">
                                <input type="text" class="form-control" placeholder="Code" formControlName="couponCode"
                                  aria-label="Recipient's username" aria-describedby="basic-addon2"
                                  (keyup.enter)="getCoupon()">
                                <div class="input-group-append">
                                  <button class="btn remove-btn" (click)="cancel()" type="button">Remove</button>
                                </div>
                              </div>
                              <p *ngIf="savedAmount" class="pl-2">Coupon saving QAR {{savedAmount | number :
                                '1.2-2'}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3 order-summary-row">
                          <div class="col-md-12 pl-3">
                            <h6 class="pl-2">Order Summary</h6>
                            <table class="table">
                              <tbody>
                                <tr>
                                  <td>Order Amount</td>
                                  <td class="fw600 text-right">QAR {{orderAmount | number :
                                    '1.2-2'}}</td>
                                </tr>
                                <!-- <tr>
                                  <td>Delivery Charge</td>
                                  <td class="fw600 text-right">QAR {{delivery_charge}}</td>
                                </tr> -->
                                <tr *ngIf="savedAmount">
                                  <td>Coupon Discount</td>
                                  <td class="fw600 text-right">- QAR {{savedAmount | number :
                                    '1.2-2'}}</td>
                                </tr>
                                <tr>
                                  <td class="fw600">Total
                                    <div class="total-info-msg text-nowrap" *ngIf="showHomeDelivery">
                                      <p><i class="fas fa-info-circle mr-1"></i>Delivery charge will be calculated at the time of checkout </p>
                                    </div>
                                  </td>
                                  <td class="fw600 snape-red text-right text-nowrap">
                                    <span *ngIf="!savedAmount"> QAR {{orderAmount +
                                      delivery_charge | number :
                                      '1.2-2'}}</span>
                                    <span *ngIf="savedAmount">QAR {{orderAmount +
                                      delivery_charge - savedAmount | number :
                                      '1.2-2'}}</span>
                                    <!-- <div class="total-info-msg" *ngIf="showHomeDelivery">
                                      <p><i class="fas fa-info-circle mr-1"></i>Delivery charge will calculated during
                                        checkout </p>
                                    </div> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <!-- <div class="col-md-8 pl-4 ColorBOp">
                            Order Amount
                          </div>
                          <div class="col-md-4 text-right">
                            QAR {{orderAmount}}.00
                          </div>
                          <div class="col-md-8 pl-4 mt-2 mb-2 ColorBOp">
                            Delivery Charge
                          </div>
                          <div class="col-md-4 text-right mt-2 mb-2">
                            QAR {{delivery_charge}}.00
                          </div>
                          <div class="col-md-8 pl-4">
                            Total
                          </div>
                          <div class="col-md-4 text-right snape-red">
                            QAR {{orderAmount + delivery_charge}}.00
                          </div> -->
                        </div>

                        <div class="row mt-4">
                          <div class="col-md-12 mt-4">
                            <button class="btn btn-snape-red w100 text-uppercase" [disabled]="checkoutForm.invalid || disableCheckoutButton"
                             (click)="checkout()">Checkout</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
<!-- cookie cart ends -->