import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.css', '../../components/breadcrumb/breadcrumb.component.css']
})
export class MessageCenterComponent implements OnInit {
  orderId: any;
  datestring: string;
  messageForm: FormGroup
  userDetails: any;
  dateTimeString: any;
  utc: string;
  sendDate: any;
  sendTime: any;
  currentTime;
  time: any;
  hour: any;
  min: any;
  userMessages: any;
  adminMessages: any;
  allMessages = [];
  profileData: any;
  imageURL: any;
  product_id
  urls = [];
  imageList: any[] = []
  id
  orderDetails: any;
  sendBtn = false
  loginEmail: any
  adminEmail: any
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private api: CommonService,
    private token: TokenStorageService
  ) { }

  ngOnInit(): void {
    debugger
    this.loginEmail = localStorage.getItem('loginEmail')
    this.activatedRoute.queryParams.subscribe(item => {
      this.orderDetails = item
      console.log(item, 'data')
      this.orderId = item.order_id
      this.product_id = item.product_id
      this.id = item.id
      console.log(this.product_id)
    })
    this.getCurrentUser();
    this.userDetails = JSON.parse(localStorage.getItem('auth-user'))
    let data = {
      "PK": this.orderDetails?.PK,
      "id": this.id,
      "message_status": "read",
      "email_id": this.userDetails['user_email'],
      "is_admin": false
    }
    this.api.updateOrderItemMsgStatus(data).subscribe(res => {
      console.log(res, 'res')
    })
    this.getMessages();
    // this.getCurrentDateTime();
    this.getMessageForm();
    this.getAdminDetails()
    document.querySelector('#scrollToChatBox').scrollIntoView({ behavior: 'smooth' })
  }
  getMessageForm() {
    this.messageForm = this.fb.group({
      message: [''],
      fileSource: [''],
      image: ''
    })
  }
  getAdminDetails() {
    this.api.getAdmin().subscribe((resp) => {
      console.log(resp)
      if (resp['status'] == true) {
        this.adminEmail = resp['users'][0]['email']
        console.log(this.adminEmail)
      }
    })
  }
  getCurrentUser() {
    debugger
    this.api.getProfile().subscribe(result => {
      this.profileData = result['data']['Item']
      console.log(this.profileData, 'data')
    })
  }
  // getCurrentDateTime() {
  //   let a = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
  //   console.log((this.utc), 'current')
  //   let s = this.join(this.utc, a, '-');
  //   // this.datestring = s + ", " + this.formatAMPM(new Date)
  //   console.log(s, 'timee')
  //   this.datestring = s + ", " + this.sendTime
  //   console.log(this.datestring, 'send date')
  // }
  join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }
  //get current time in am/pm format
  // formatAMPM(date) {
  //   var hours = date.getHours();
  //   var minutes = date.getMinutes();
  //   var ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? '0' + minutes : minutes;
  //   var strTime = hours + ':' + minutes + ' ' + ampm;
  //   return strTime;
  // }
  getUserMessages() {
    // this.dateTimeString.map(dt => {
    //   console.log(dt.datetime, 'date and time')
    //   this.sendDate = this.dateTimeString.slice(0, 10);
    //   this.sendTime = this.dateTimeString.split(' ')
    //   this.time = this.sendTime[1].split(':');
    //   var suffix = this.time[0] >= 12 ? "PM" : "AM";
    //   this.hour = this.time[0] > 12 ? this.time[0] - 12 : this.time[0];
    //   this.hour = this.hour < 10 ? "0" + this.hour : this.hour;
    //   this.min = this.time[1];
    //   this.sendTime = this.hour + ':' + this.min + suffix;
    //   this.utc = new Date(this.sendDate).toUTCString();
    //   this.currentTime = this.sendTime
    //   console.log(this.sendTime, 'time', this.utc, 'utc')
    // })
  }
  getMessages() {
    let product_id = this.product_id.split(/#(.*)/)[1]
    this.api.getUserMessage(this.orderId, product_id).subscribe(res => {
      this.allMessages = res['data']
      this.allMessages = _.sortBy(this.allMessages, function (o) { return o['datetime']; });
      console.log(this.allMessages, 'allMsg')
    })
  }
  getIndex(index: any) {
    debugger
    this.urls.splice(index, 1)
  }

  send() {
    debugger
    this.sendBtn = true
    console.log(this.messageForm.controls.message.value, 'message')
    if (this.messageForm.controls.message?.value == "\n" || this.messageForm.controls.message.value == null) {
      window.location.reload()
    }
    else {
      let data = {
        "prod_id": this.id,
        "order_id": this.orderId,
        "from": this.orderDetails['user_email'],
        "ordered_user": this.userDetails.email,
        "item_id": this.id,
        "to": this.adminEmail,
        "message": this.messageForm.controls.message.value,
        "type": "admin-user",
        "image": this.urls
      }
      let file = {
        "filename": this.urls
      }
      this.api.addMessage(data).subscribe(res => {
        console.log(res, 'res')
        if (res) {
          this.sendBtn = false
          this.getMessages();
          this.messageForm.reset();
          this.imageURL = ''
          this.urls = []
          //window.location.reload()
        }
      })
    }
  }
  openFile() {
    document.querySelector('input').click()
  }

  handle(event) {
    debugger
    console.log('Change input file')
    const file = (event.target as HTMLInputElement).files[0];
    this.messageForm.patchValue({
      image: file
    });
    // if (file == null) {
    //   this.imageURL = ''
    // }
    this.messageForm.get('image').updateValueAndValidity()
    // File Preview
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.api.uploadBoxcolor({ "imagedata": event.target.result, "filename": file.name }).subscribe((resp) => {
            console.log(resp)
            this.urls.push(resp['body'])
          })
          //this.urls.push(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      this.send();
    }
  }
  newLine(event) {
    if (event.keyCode == 13 && event.shiftKey) {
    }
  }
  // onSelectFile(event) {
  //   if (event.target.files && event.target.files[0]) {
  //       var filesAmount = event.target.files.length;
  //       for (let i = 0; i < filesAmount; i++) {
  //               var reader = new FileReader();
  //               reader.onload = (event:any) => {
  //                 console.log(event.target.result);
  //                  this.urls.push(event.target.result); 
  //               }
  //               reader.readAsDataURL(event.target.files[i]);
  //       }
  //   }
  // }
}
