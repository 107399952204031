import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../common/common.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit{
  emailVerifyForm : FormGroup;
  emailForm:FormGroup
  phoneNumberForm:FormGroup
  baseUrl: string;
  userName:any
  email = false
  number = false
  loader = false
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.baseUrl = location.origin
    this.userName = localStorage.getItem('userName')
    let c = JSON.parse(localStorage.getItem('count'))
    c += 1;;
    localStorage.setItem('count', c)
    this.emailVerifyForm = this.fb.group({
      code: ['', Validators.required]
    })
    this.emailForm = this.fb.group({
      email:['',[Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]],
      userName:['',[Validators.required]]
    })
    this.phoneNumberForm = this.fb.group({
      phoneNumber:['',[Validators.required,Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
    })
    console.log(localStorage.getItem('count'), 'count')
    if(c > 2){
    window.location.href= this.baseUrl+'/signup';
    localStorage.removeItem('count')
    }
    this.activatedRoute.queryParams.subscribe((params)=>{
      console.log(params)
      if(params['loginMethod'] == 'email'){
        this.email = true
      }else{
        this.number = true
      }
    })
  }
sendEmail(){
  this.api.emailVerify(this.emailVerifyForm.controls.code.value,this.userName).subscribe(res=>{
   
   
    
    if(res.status == true){
      this.toastr.success(res['message'])
      this.router.navigate(['login'])
    }else{
      this.toastr.error(res['message'])
    }
  })
  localStorage.removeItem('count')
}
sendEmailNumber(){
  debugger
  this.loader = true
  this.api.verifyNumber(this.emailVerifyForm.controls.code.value,this.phoneNumberForm.controls.phoneNumber.value).subscribe(res=>{
    debugger
    console.log(res,'responenumber')
    if(res.status == true){
      this.toastr.success(res['message'])
      this.loader = false
      this.router.navigate(['login'])
    }else{
      this.loader = false
      this.toastr.error(res['message'])
    }
  })
}
sendNumber(){
  debugger
  this.loader = true
  this.api.emailVerifyNumber(this.emailVerifyForm.controls.code.value,this.emailForm.controls.email.value.toLowerCase(),this.emailForm.controls.userName.value).subscribe(res=>{
   
   
    
    if(res.status == true){
      this.toastr.success(res['message'])
      this.loader = false
      this.router.navigate(['login'])
    }else{
      this.loader = false
      this.toastr.error(res['message'])
    }
  })
  localStorage.removeItem('count')
}
resendOtp()
{
  this.api.resendSignupOtp().subscribe(res=>{
    console.log(res, 'result')
    let message = res['message']
    this.toastr.success(message)
  })
}
}
