<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<div class="container">
  <div class="row mt-3">
    <div class="col-md-12">
      <div>
        <button type="button" class="btn m-3 back-btn-icon" routerLink="/signup" routerLinkActive="router-link-active">
          <span class="material-icons-outlined btn-snape-white back-icon">
            chevron_left
          </span>
          <span class="back-btn-span">Back</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div class="login-image">
        <img src="../../../assets/loginimg.png">
      </div>
    </div>
    <div class="col-md-6 logintxt authentication-screen">
      <div class="auth-ui">
        <div class="ml-3">
          <h5 class="snape-red">Authentication required</h5>
          <p>Please enter the One time password sent to your registered Email id or Phone number</p>
        </div>

        <form [formGroup]="emailVerifyForm" class="pt-2">
          <div class="position-relative form-group">
            <div class="col-md-12">
              <input formControlName="code" id="inputEmail" placeholder="Enter OTP" required="" type="number"
                class="form-control form-control">
            </div>
          </div>
          <!-- <div class="text-align">
              <p>Didn't receive the code?
                <span>
                  <button type="button" class="btn btn-link-pink">RESEND OTP</button>
                </span>
              </p>
            </div> -->
          <div class="position-relative form-group">
            <!-- <div class="d-flex justify-content-center col-md-12 mt-4" *ngIf="email">
              <button type="submit" (click)="sendEmail()"
                class="btn btn-snape-bak btn-block text-uppercase">Continue</button>
            </div> -->
            <div class="d-flex justify-content-center col-md-12 mt-4" *ngIf="number">
              <button type="submit" data-toggle="modal" data-target="#emailIdModal"
                class="btn btn-snape-bak btn-block text-uppercase"
                [disabled]="emailVerifyForm.invalid">Continue</button>
            </div>
            <div class="d-flex justify-content-center col-md-12 mt-4" *ngIf="email">
              <button type="submit" data-toggle="modal" data-target="#mobileNumberModal"
                class="btn btn-snape-bak btn-block text-uppercase" [disabled]="emailVerifyForm.invalid">Continue</button>
            </div>
          </div>
          <div class="text-align">
            <p>Didn't receive the code?
              <span>
                <button type="button" class="btn btn-link-pink" (click)="resendOtp()">RESEND OTP</button>
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- emailIdModal start -->
<div class="modal fade" id="emailIdModal" tabindex="-1" role="dialog" aria-labelledby="emailIdModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content p-4">
      <div class="modal-header">
        <h6 class="modal-title" id="deleModalLabel">Enter Details</h6>
      </div>
      <div class="modal-body">
        <div class="row mb-4" [formGroup]="emailForm">
          <div class="col-md-12 mb-3">
            <!-- <label>Please enter your details</label> -->
            <input type="email" formControlName="userName" class="form-control" placeholder="Enter your name">
            <span class="text-danger"
              *ngIf="(emailForm.controls.userName.touched) && emailForm.controls.userName.errors?.required">
              Username is required
            </span>
          </div>
          <div class="col-md-12">
            <!-- <label>Please enter your details</label> -->
            <input type="email" formControlName="email" class="form-control" placeholder="Enter email id">
            <span class="text-danger"
              *ngIf="(emailForm.controls.email.touched) && emailForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger"
              *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.pattern">
              Enter a valid email address
            </span>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <div>
              <button type="button" class="btn continue-btn" [disabled]="!emailForm.valid" (click)="sendNumber()"
                data-dismiss="modal">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- emailIdModal end -->

<!-- mobileNumberModal start -->
<div class="modal fade" id="mobileNumberModal" tabindex="-1" role="dialog" aria-labelledby="mobileNumberModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content p-4">
      <div class="modal-header">
        <h6 class="modal-title">Enter Details</h6>
      </div>
      <div class="modal-body">
        <div class="row mb-4" [formGroup]="phoneNumberForm">
          <div class="col-md-12 mb-3">
            <input type="text" class="form-control" formControlName="phoneNumber" placeholder="Enter mobile number">
            <span class="text-danger"
            *ngIf="phoneNumberForm.controls.phoneNumber.touched && phoneNumberForm.controls.phoneNumber.errors?.required">
            Number is required
          </span>
          <span class="text-danger"
          *ngIf="phoneNumberForm.controls.phoneNumber.touched && phoneNumberForm.controls.phoneNumber.errors?.pattern">
          Enter a valid phone number
        </span>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <div>
              <button type="button" class="btn continue-btn" data-dismiss="modal" [disabled]="!phoneNumberForm.valid" (click)="sendEmailNumber()">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- mobileNumberModal end -->