<!-- Shipping Address start -->
<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="bg-color" id="orderDetails">
  <div class="container">
    <!-- <div class="timeline">
      <span *ngFor="let item of counts;let i = index">
        <span class="circle-desc" [class.circle-desc-active]="orderStatus == item">{{ item }}</span>
        <span class="circles" [class.active]="orderStatus == item"></span>
        <span class="bar" *ngIf="(i != counts.length-1)"></span>
      </span>
    </div> -->
    <!-- timeline start -->
    <section class="ps-timeline-sec">
      <ol class="ps-timeline">
        <li>
          <div>
            <div class="ps-bot">
              <h5>Personal Details</h5>
            </div>
            <!-- <div class="ps-bot2">
          <h5>Requested</h5> 
      </div> -->
            <div>
              <span class="ps-sp-top1"
                *ngIf="orderStatus == 'Personal Details' || orderStatus == 'Shipping Details' || orderStatus == 'Payment'">
                <i class="fas fa-check-circle"></i>
              </span>
              <span class="ps-sp-top2"
                *ngIf="orderStatus != 'Personal Details' && orderStatus != 'Shipping Details' && orderStatus != 'Payment'">
                <i class="fas fa-times-circle"></i>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="ps-bot">
              <h5>Shipping Details</h5>
            </div>
            <!-- <div class="ps-bot2">
          <h5>Requested</h5> 
      </div> -->
            <div>
              <span class="ps-sp-top1" *ngIf="orderStatus == 'Shipping Details' || orderStatus == 'Payment'">
                <i class="fas fa-check-circle"></i>
              </span>
              <span class="ps-sp-top2" *ngIf="orderStatus != 'Shipping Details' && orderStatus != 'Payment'">
                <i class="fas fa-times-circle"></i>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="ps-bot">
              <h5>Payment</h5>
            </div>
            <!-- <div class="ps-bot2">
          <h5>Requested</h5> 
      </div> -->
            <div>
              <span class="ps-sp-top1" *ngIf="orderStatus == 'Payment'">
                <i class="fas fa-check-circle"></i>
              </span>
              <span class="ps-sp-top2">
                <i class="fas fa-times-circle"></i>
              </span>
            </div>
          </div>
        </li>
      </ol>
    </section>
    <!-- timeline end -->
    <div class="row">
      <div class="col-md-12 p-50">
        <div class="row mt-4">
          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-10">
                    <h6 class="mUnset">
                      <i class="fa fa-check GCheck"></i>
                      <span class="pl-2">Personal information</span>
                    </h6>
                  </div>
                  <div class="col-md-2 text-right">
                    <!-- <i class="fa fa-edit cursor-pointer" (click)="editPersonalDetails()"></i> -->
                  </div>
                  <div class="col-md-12" [hidden]="isShowPersonal">
                    <div class="row">
                      <div class="SA-border">
                        <div class="row">
                          <div class="col-md-9">
                            <div class="ml-4">
                              <p class="fSize12px pl-4 pt-0">
                                Name: {{userDetails?.name}}
                              </p>
                              <p class="fSize12px pl-4 pt-0">
                                Email: {{userDetails?.email}}
                              </p>
                              <p class="fSize12px pl-4 pt-0">
                                <button type="button" class="btn btn-snape-red continue-btn"
                                  (click)="logout()">Logout</button>
                              </p>
                              <p class="fSize12px pl-4 pt-0">
                                <button type="button" class="btn btn-snape-red continue-btn"
                                  (click)="editPersonalDetails()">Continue to Checkout</button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-4" *ngIf="next == false">
              <div class="card-body" id="address_list">
                <div class="row">
                  <h6 class="pl-3 mb-3 font-weight">Shipping Address</h6>
                  <div class="col-md-12" [hidden]="isShowShipping">
                    <div class="col-md-12">
                      <button class="btn btn-bglR" (click)="showAddress()">
                        <i class="fa fa-plus RPlus"></i>
                        <span class="pl-2">Add new address</span>
                      </button>
                    </div>

                    <div class="col-md-12">
                      <div class="row">
                        <div class="SA-border">
                          <form #addForm="ngForm">
                            <div class="row">
                              <div class="col-md-6" *ngFor="let add of address">
                                <div class="ml-4">
                                  <input class="SA-radio" type="radio" value="{{add.id}}" id="{{add.id}}" name="radio"
                                    (change)="selectedAddress(add)" required [(ngModel)]="isActive" />
                                  <p class="fSize12px pl-4 pt-0">
                                    {{add.name}}
                                  </p>
                                  <p class="fSize12px pl-4 pt-0">
                                    {{add.building}},{{add.flat_villa}}
                                  </p>
                                  <p class="fSize12px pl-4 pt-0">
                                    {{add.street}},{{add.city}}
                                  </p>
                                  <p class="fSize12px pl-4 pt-0">
                                    {{add.contact_number}}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-end">
                      <button class="btn btn-snape-red continue-btn" (click)="addressNext()" [disabled]="!submitted">
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-4" *ngIf="next== true">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="shipping-address">
                      <div>
                        <h6 class="mUnset">
                          <i class="fa fa-check GCheck"></i>
                          <span class="pl-2">Shipping Address</span>
                        </h6>
                      </div>
                      <div *ngIf="!self_pickup">
                        <i class="fa fa-edit cursor-pointer" (click)="editShippingDetails()"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-4 mb-10" *ngIf="next == true">
              <div class="card-body">
                <form [formGroup]="paymentForm">
                  <div class="row smargin">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <h6 class="font-weight">Payment method</h6>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-4">
                          <input class="SA-radio" type="radio" formControlName="card" (click)="showApplePay('pay')" value="card" required />
                          <div class="ml-4">
                            <p class="fSize12px">Pay by card</p>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <input class="SA-radio" type="radio"  (click)="showApplePay('applePay')"  value="applepay" formControlName="card"  required />
                          <div class="ml-4">
                            <p class="fSize12px">Apple Pay</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div id="card-element"></div>
                    </div>
                    <!-- <div class="col-md-6">
              <input class="form-control" type="text" placeholder="Name on card" />
            </div>
            <div class="col-md-6">
              <input class="form-control" type="text" placeholder="Card number" />
            </div>
            <div class="col-md-6">
              <input class="form-control" type="text" placeholder="Expiry date" />
            </div>
            <div class="col-md-3">
              <input class="form-control" type="text" placeholder="CVV/VCC" />
            </div> -->
                    <div class="col-md-12 mt-4" *ngIf="showPayDiv">
                      <button   class="btn btn-snape-red w100 text-uppercase" (click)="addOrder()"
                        [disabled]="paymentForm.invalid || paymentBtn == true">
                        Make Payment
                      </button>
                    </div>
                    <div class="col-md-12 mt-4" *ngIf="showAppleDiv">
                     <button  class="btn btn-snape-red w100 text-uppercase" (click)="appleAddOrder()"
                      [disabled]="paymentForm.invalid || paymentBtn == true">
                        Make Payment
                      </button>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
            <div id="showFillAddress"></div>
            <div class="card mt-4 mb-10" *ngIf="showAddressForm == true && next==false">
              <div class="card-body">
                <form [formGroup]="addressForm">
                  <div class="row smargin">

                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="Full name" formControlName="fullName"
                          required />
                        <label>Full name</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.fullName.touched || submitted) && addressForm.controls.fullName.errors?.required">
                        Full Name is required
                      </span>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="Building number"
                          formControlName="buildingNumber" required />
                        <label>Building number</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.buildingNumber.touched || submitted) && addressForm.controls.buildingNumber.errors?.required">
                        Building number is required
                      </span>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="Flat/Villa number"
                          formControlName="flatVilla" required />
                        <label>Flat/Villa number</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.flatVilla.touched || submitted) && addressForm.controls.flatVilla.errors?.required">
                        Flat/Villa number is required
                      </span>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="Zone number" formControlName="zone"
                          required />
                        <label>Zone number</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.zone.touched || submitted) && addressForm.controls.zone.errors?.required">
                        Zone is required
                      </span>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="Street name" formControlName="streetName"
                          required />
                        <label>Street name</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.streetName.touched || submitted) && addressForm.controls.streetName.errors?.required">
                        Street name is required
                      </span>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="Area number" formControlName="areaNumber">
                        <label>Area number</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label">
                        <input class="form-control" type="text" placeholder="City" formControlName="city" required />
                        <label>City</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.city.touched || submitted) && addressForm.controls.city.errors?.required">
                        City is required
                      </span>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-float-label input-group">
                        <div class="country-code">
                          +974
                        </div>
                        <input class="form-control country-code-txt-box" formControlName="contactNumber" type="text"
                          placeholder="Contact number" required>
                        <label class="label-margin">Contact number</label>
                      </div>
                      <span class="text-danger"
                        *ngIf="(addressForm.controls.contactNumber.touched || submitted) && addressForm.controls.contactNumber.errors?.required">
                        Contact number is required
                      </span>
                      <span class="text-danger"
                        *ngIf="addressForm.controls.contactNumber.touched && addressForm.controls.contactNumber.errors?.pattern">
                        Enter a valid number
                      </span>
                    </div>
                    <!-- <div class="col-md-12 mt-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.7373361701484!2d76.95841581404562!3d8.524862393871532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bbd5bd74b023%3A0xb36f66160053530e!2sLinks4Engg%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1616221330960!5m2!1sen!2sin"
                  width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div> -->

                    <div class="col-md-12 mt-4 d-flex justify-content-end">
                      <button class="cancel-btn mr-3" (click)="cancel()">
                        CANCEL
                      </button>
                      <button class="btn btn-snape-red" (click)="saveAddress()"
                        [disabled]="addressForm.invalid || submitted == true">
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>


          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="">
                  <h6>Order Summary</h6>
                  <div class="row mt-4 order-summary-area"
                    *ngFor="let cartItem of localStorage; let cartIndex = index;">
                    <div class="col-md-12 pl-4">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td style="width: 30%">
                              <img class="bRad10px" src="{{cartItem.box_img_url}}" />
                            </td>
                            <td class="fw600">
                              <h6>{{cartItem.product.name}}</h6>
                              <p class="fSize12px snape-red">QAR {{cartItem.product.price}}</p>
                              <p class="fSize12px ColorBOp">Quantity: {{cartItem.quantity}}</p>
                            </td>
                            <td>
                              <i class="far fa-trash-alt fSize18px gfaIcon cursor-pointer"
                                (click)="remove(cartItem,cartIndex)" data-toggle="modal" data-target="#delModal"
                                *ngIf="orderStatus !== 'Payment'"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- <div class="col-md-4">
                      <img class="bRad10px" style="width: 100%;" src="{{cartItem.box_img_url}}" />
                    </div>
                    <div class="col-md-6">
                      <h6>{{cartItem.product.name}}</h6>
                      <p class="fSize12px snape-red">QAR {{cartItem.product.price}}</p>
                      <p class="fSize12px ColorBOp">Quantity: {{cartItem.quantity}}</p>
                    </div> -->
                  <!-- <div class="col-md-2" *ngIf="orderStatus !== 'Payment'"> -->


                  <!-- <button class="btnTrans cursor-pointer" type="button" data-toggle="modal"
                              data-target="#delModal" (click)="remove(cartItem)"><i
                                class="far fa-trash-alt fSize18px gfaIcon cursor-pointer"></i>
                            </button> -->
                  <!-- <i class="far fa-trash-alt fSize18px gfaIcon cursor-pointer" (click)="remove(cartItem,cartIndex)"
                        data-toggle="modal" data-target="#delModal"></i> -->
                  <!-- </div> -->
                  <div class="row">
                    <div class="col-md-12 pl-4">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Order Amount</td>
                            <td class="fw600 text-right">QAR {{orderAmount | number :
                              '1.2-2'}}</td>
                          </tr>
                          <tr>
                            <td>Delivery Charge</td>
                            <td class="fw600 text-right">QAR {{deliveryCharge}}</td>
                          </tr>
                          <tr *ngIf="savedAmount">
                            <td>Coupon Discount</td>
                            <td class="fw600 text-right">- QAR {{savedAmount | number :
                              '1.2-2'}}</td>
                          </tr>
                          <tr>
                            <td class="fw600">Total</td>
                            <td class="fw600 snape-red text-right">QAR {{total | number :
                              '1.2-2'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                      <p class="fSize12px ColorBOp">Order Summary</p>
                    </div>
                    <div class="col-md-6">
                      <p class="fSize12px text-right">QAR {{orderAmount}}.00</p>
                    </div>
                   <div class="row">
                    <div class="col-md-6">
                      <p class="fSize12px ColorBOp">Delivery Charge</p>
                    </div>
                    <div class="col-md-6">
                      <p class="fSize12px text-right">QAR {{deliveryCharge}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p class="fSize12px">Total</p>
                    </div>
                    <div class="col-md-6">
                      <p class="fSize12px snape-red text-right">QAR {{total}} </p>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- delete confirmation Modal start -->
<div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-labelledby="delModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="deleModalLabel">Confirm delete?</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row d-flex justify-content-center mb-4">
            Are you sure you want to delete this product?
          </div>
          <div class="row form-group mt-4">
            <div class="col-md-12">
              <div class="d-flex justify-content-center align-items-center">
                <button type="button" class="btn btn-cancel-grey mr-2" data-dismiss="modal">Cancel</button>
                <button data-dismiss="modal" class="btn btn-snape-red"
                  (click)="deleteItem(index, itemId, deletedProduct)" type="button">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- delete confirmation Modal end -->

<!-- <i class="far fa-trash-alt fSize18px gfaIcon cursor-pointer" (click)="remove(cartItem)" data-toggle="modal"
  data-target="#paymentFailed"></i> -->

<!-- payment failed Modal start -->
<div class="modal fade" id="paymentFailed" tabindex="-1" role="dialog" aria-labelledby="paymentFailedLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body p-50">
        <div class="row">
          <div class="col-md-12">
            <div class="failed-image">
              <img src="../../../assets/info.png">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="failed-caption">
              <h6>Payment Failed</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="failed-para">
              <p>Your transaction has failed due to some
                <br>
                technical error. Please try again
              </p>
              <p class="reason-color">Reason for payment failed came here</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="try-again-btn">
              <button data-dismiss="modal" class="btn" type="button">Try Again</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- payment failed Modal end -->

<!-- <button type="button" class="btn btn-snape-red continue-btn" data-toggle="modal"
data-target="#paymentFailed">Check order failed</button> -->


<form method="POST" action="https://demopaymentapi.qpayi.com/api/gateway/v2.0" name="qpay_paymentform"
  id="qpay_paymentform">
  <input name="action" value="capture" hidden />
  <input name="gatewayId" value="014331468" hidden />
  <!-- <input name="secretKey" value="2-4Sz0RO/shVtLR4" /> -->
  <input name="signatureFields" value="gatewayId,amount,referenceId" hidden />

  <input name="signature" value="{{signature_value}}" hidden />
  <input name="referenceId" value="{{orderId}}" hidden />
  <input name="amount" value="{{total}}" hidden />
  <input name="currency" value="QAR" hidden />
  <input name="mode" value="TEST" hidden />
  <input style="width:500px" name="description" value="product" hidden />
  <input style="width:500px" name="returnUrl" value="{{returnUrl}}" hidden />
  <input name="name" value="{{name}}" hidden />
  <input style="width:500px" name="address" value="Doha" hidden />
  <input name="city" value="{{userCity}}" hidden />
  <input name="state" value="Doha" hidden />
  <input name="country" value="QA" hidden />
  <input name="phone" value="56782145" hidden />
  <input name="email" value="{{userEmail}}" hidden />
  <input value="Submit" type="submit" (click)="submit()" hidden />
</form>