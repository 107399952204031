import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  myImageUrl: string = 'assets/avatar.png';
  userData: any;
  orders
  userImg: any;
  constructor(
    private api: CommonService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getProfile();
    this.getOrders();
  }
  getProfile(){
    this.api.getProfile().subscribe(item=>{
      this.userData = item['data']['Item']
      this.userImg = item['data']['Item'].image
        if(this.userImg == undefined){
          this.userImg = this.myImageUrl
        }
      // console.log(this.userData)
    })
  }
  getOrders(){
    this.api.getOrder().subscribe(item=>{
      this.orders = item['order_data']['Items']
      this.orders =  _.filter(this.orders,function(itm){return itm.orderstatus == 'Order Placed' 
      || itm.orderstatus == 'Order Confirmed' || itm.orderstatus == 'Processing' || itm.orderstatus == 'Readyfordelivery'})
      // console.log(this.orders, 'item')
    })
  }
  trackOrder(id){
    let orderId = id
    let extra = {
      "order_id": orderId
    }
    this.router.navigate(['track-order'], { queryParams: extra })
  }
}
