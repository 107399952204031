import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { TokenStorageService } from 'src/app/common/token/token-storage.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.css']
})
export class ProductHeaderComponent implements OnInit {


  myImageUrl: string = 'assets/avatar.png';
  userStatus
  userImg: any;
  isLoggedIn: boolean
  cartLength: any;
  notificationArray: any;
  notificationLength: any;
  constructor(
    private router: Router,
    private api: CommonService,
    private token: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.loggedUser();
    if (this.isLoggedIn) {
      this.userProfile();
    }
    this.getCount()
    // console.log(this.cartLength, 'lennl')
    // this.api.cartCount.subscribe(res => {
    //   this.cartLength = res
    //   console.log(res, !this.isLoggedIn, 'user')
    // })
    // if (this.isLoggedIn){
    //   this.api.getNotification().subscribe(res => {
    //     this.notificationArray = res['data']['Items']
    //     this.notificationArray = _.filter(this.notificationArray, (item)=>{
    //       return item.status == 'unread'
    //     })
    //     this.notificationLength = this.notificationArray?.length
    //   })
    // }
  }
  getCount() {
    let local_storage = JSON.parse(localStorage.getItem('cart'))
    if (local_storage?.length) {
      this.cartLength = local_storage.length
    }
    else {
      this.cartLength = 0
    }
    if (this.isLoggedIn) {
      this.api.getNotification().subscribe(res => {
        this.notificationArray = res['data']['Items']
        this.notificationArray = _.filter(this.notificationArray, (item) => {
          return item.status == 'unread'
        })
        this.notificationLength = this.notificationArray?.length
      })
      this.api.getCart().subscribe((resp) => {
        let online_cart_items = resp['data']
        if (local_storage?.length == 0 || local_storage?.length == undefined || local_storage.length == null) {
          local_storage = online_cart_items
          this.cartLength = local_storage.length
        } else if (local_storage != null && online_cart_items?.length != 0) {
          let newdata: any = []
          let localdata: any = []

          newdata = online_cart_items
          // local_storage.forEach(array1Item => {

          //   newdata.forEach(array2Item => {

          //     if (array1Item.id != array2Item.id) {
          //       localdata.push(array1Item)
          //     }
          //     else {

          //     }

          //   })
          // })
          // if (localdata.length > 0) {
            const filteredItems = local_storage.filter(x => newdata.some(y => y.id !== (x.id)));

            console.log(filteredItems,'filterItems');
             let test = filteredItems.concat(newdata)
         // let test = local_storage.concat(newdata)
          // let test = localdata.concat(newdata)
          local_storage = test
          // }
          //   newdata = online_cart_items
          //  let test = local_storage.concat(newdata)
          //  local_storage = test
          this.cartLength = local_storage.length
        }

      })
    }
  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      this.token.loggedStatus(res)
    })
  }
  userProfile() {
    this.api.getProfile().subscribe(user => {
      this.userStatus = user['status']
      if (this.userStatus == true) {
        this.userImg = user['data']['Item'].image
        if (this.userImg == undefined) {
          this.userImg = this.myImageUrl
        }
      }
      else {
        this.userImg = this.myImageUrl
      }
    })
  }
  getNotifications() {
    this.api.getNotification().subscribe(res => {
      console.log(res, 'res')
    })
  }
  logout() {
    this.api.loggedIn.next(false);
    this.token.signOut()
    this.router.navigate(["/login"])
  }
}


