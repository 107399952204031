import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  baseUrl = environment.baseUrl
  public loggedIn = new BehaviorSubject(false);
  public cartCount;

  userDetails
  forgotemail
  newPassword
  img: any;
  imageFile: File
  imageArray = []
  imageData: any
  file_name: string;
  imageFileDict: any = {}
  cartLength: any;
  constructor(private httpClient: HttpClient, private router: Router) {
    let local_storage = JSON.parse(localStorage.getItem('cart'))
    if (local_storage?.length) {
      this.cartLength = local_storage?.length
    }
    else {
      this.cartLength = 0
    }
    this.cartCount = new BehaviorSubject(this.cartLength);
  }

  memberSignup(obj: any) {
    let url = environment.signUp
    return this.httpClient.post(url, obj, httpOptions)
  }
  //get username and password for email verification in creation of account
  verification(user: any) {
    console.log(user)
    this.userDetails = {
      "username": user.username,
      "password": user.password
    }
  }
  //get verification code and call verify email function
  emailVerify(obj: any, user: any): Observable<any> {
    let url = environment.verifyEmail;
    let data = {
      "username": this.userDetails.username,
      "password": this.userDetails.password,
      "code": obj.toString(),
      "name": user,
      "userType": "buyer"
    }
    return this.httpClient.post(url, data)
  }
  emailVerifyNumber(obj: any, user: any, name: any): Observable<any> {
    let url = environment.verifyEmail;
    let data = {
      "number": localStorage.getItem('loginNumber'),
      "username": name,
      'code': obj.toString(),
      'email': user,
      'userType': 'buyer'
    }
    return this.httpClient.post(url, data)
  }
  verifyNumber(obj: any, number: any): Observable<any> {
    let url = environment.verifyNumber;
    let data = {
      'username': this.userDetails.username,
      'code': obj.toString(),
      'name': localStorage.getItem('userName'),
      'phone_number': number,
      'userType': 'buyer'
    }
    return this.httpClient.post(url, data)
  }
  resendSignupOtp() {
    debugger
    let data = {
      "username": localStorage.getItem('loginNumber')
    }
    let url = environment.resendCodesignup;
    return this.httpClient.post(url, data)
  }
  // account login
  Signin(obj: any) {
    let url = environment.signIn;
    return this.httpClient.post(url, obj, httpOptions)
  }
  otpLogin(obj: any) {
    let url = environment.otpLogin;
    return this.httpClient.post(url, obj)
  }
  async getSocialLogin(token) {
    let url = environment.userInfo
    return await this.httpClient.get(url, { headers: { social: "true", access_token: token } }).toPromise()
  }
  // verification code send to email address for forgot password
  tokenUpdate(obj: any) {
    let url = environment.token;
    return this.httpClient.post(url, obj)
  }
  forgotPassword(obj: any) {
    let url = environment.forgetPassword;
    return this.httpClient.post(url, obj)
  }
  forgetEmail(obj: any) {
    this.forgotemail = obj.username;
  }
  resetPassword(obj: any) {
    this.newPassword = obj.password;
  }
  //reset password for forgot password
  confirmPassword(obj: any) {
    let url = environment.confirmPassword;
    let data = {
      "username": this.forgotemail,
      "password": this.newPassword,
      "code": obj.toString()
    }
    return this.httpClient.post(url, data)
  }
  changePassword(obj: any) {
    let url = environment.confirmPassword;
    return this.httpClient.post(url, obj)
  }
  resendPasswordOtp(obj: any) {
    let url = environment.forgetPassword;
    return this.httpClient.post(url, obj)
  }
  resendOtp() {
    debugger
    let data = {
      "username": this.forgotemail
    }
    // let url = 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/dev/forget-password';
    let url = environment.resendCode;
    return this.httpClient.post(url, data)
  }
  getProfile() {
    let url = environment.userProfile;
    return this.httpClient.get(url, httpOptions)
  }
  profileUpdate(obj: any, file: any): Observable<any> {
    let url = environment.userProfile;
    let formdata = new FormData();
    if (file != '') {
      if (file.filename != '' && file.filename.name != undefined) {
        formdata.append('filename', file.filename, JSON.stringify(file.filename.name));
      }
      else if (file.filename != '') {
        formdata.append('filename', file.filename);
      }
    }
    formdata.append('Metadata', JSON.stringify(obj));
    return this.httpClient.put(url, formdata);
  }
  //add address in profile
  addAddress(obj: any) {
    let url = environment.address;
    return this.httpClient.post(url, obj)
  }
  //get address in profile
  getAddress() {
    let url = environment.address;
    return this.httpClient.get(url)
  }
  //update address in profile
  updateAddress(obj: any) {
    let url = environment.address;
    return this.httpClient.put(url, obj)
  }

  //delete address in profile
  deleteAddress(obj: any) {
    let url = environment.address;
    return this.httpClient.request('delete', url, { body: obj })
  }
  //api
  //get favourite image
  getFavouriteImage() {
    let url = environment.favourite;
    return this.httpClient.get(url)
  }
  getTemplateImage() {
    let url = environment.templates;
    return this.httpClient.get(url)
  }
  //api
  //add favourite
  addFavouriteImage(obj: any) {
    let url = environment.favourite
    return this.httpClient.post(url, obj)
  }
  //api
  //delete favourite image
  deleteFavouriteImage(obj: any): Observable<any> {
    let url = environment.favourite;
    return this.httpClient.request('delete', url, { body: obj })
  }
  //get product
  // pass email as param
  getProduct() {
    let url = environment.userProducts
    // let url = 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/products';
    return this.httpClient.get(url, { headers: { skip: "true" } })
  }
  //getFaq
  getFaq() {
    let url = environment.faq;
    return this.httpClient.get(url)
  }
  orderCancel(obj: any) {
    let url = environment.orderCancel

    return this.httpClient.post(url, obj)
  }
  //getFlavour
  getFlavour() {
    let url = environment.flavour;
    return this.httpClient.get(url, { headers: { skip: "true" } });
  }
  //get template images
  getTemplateImages() {
    let url = environment.templates;
    return this.httpClient.get(url, { headers: { skip: "true" } })
  }
  uploadBoxcolor(obj: any) {
    let url = environment.boxcolor

    return this.httpClient.post(url, obj)
  }
  listZones() {
    let url = environment.listZone;
    return this.httpClient.get(url)
  }
  //add to cart
  // addCart(obj: any, files) {
  //   let url = 'https://bqeejq5z3b.execute-api.us-east-2.amazonaws.com/dev/cart';
  //   console.log(typeof (files), 'filenameeee')
  //   let formdata = new FormData();
  //   formdata.append('Metadata', JSON.stringify(obj))
  //   if (files) {
  //     let res = files.map(x => Object.keys(x)[0]);
  //     console.log(res)
  //     let val = files.map(x => Object.values(x)[0]);
  //     console.log(val)
  //     res.forEach((filename, index) => {
  //       this.img = val[index];
  //       this.img= this.DataURIToBlob(this.img)
  //       let imageName = filename+'.png'
  //       const imageFile: File = new File([this.img], imageName, {
  //         type: "image/png"
  //       });
  //       console.log(imageName, this.img, imageFile.name);
  //        formdata.append(filename, this.img, filename);
  //     });
  //   }
  //   return this.httpClient.post(url, formdata)
  // }
  DataURIToBlob(dataURI: string, filename: any) {

    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    this.imageFileDict[filename] = new Blob([ia], { type: mimeString })
  }
  addCart(obj: any, files) {
    if (files) {
      // this.imageFileDict = {}
      // Object.keys(files).map((k) => {
      //   let val = files[k]
      //   this.DataURIToBlob(val, k)
      //   console.log(k, 'kkkk')
      //   this.getImage(k).then(res => {
      //     this.imageData = res['URL']['fields']
      //     this.postImage(this.imageData, this.imageFileDict[res['URL']['fields'].key]).then(res => {
      //       console.log(res, 'res')
      //     }).catch((err) => {
      //       console.log(err)
      //     })
      //   }).catch((err) => {
      //     console.log(err)
      //   })
      //   // });
      // })
      let url = environment.cart;
      // let formdata = new FormData();
      // formdata.append('Metadata', JSON.stringify(obj))
      return this.httpClient.post(url, JSON.stringify(obj))
    }
  }
  async getImage(filename) {
    let url = environment.imageUpload + filename
    return await this.httpClient.get(url, { headers: { skip: "true" } }).toPromise();
  }
  async postImage(imageData, file) {
    let imageFormData = new FormData();
    imageFormData.append('key', imageData.key)
    imageFormData.append('x-amz-algorithm', imageData['x-amz-algorithm'])
    imageFormData.append('x-amz-credential', imageData['x-amz-credential'])
    imageFormData.append('x-amz-date', imageData['x-amz-date'])
    imageFormData.append('x-amz-security-token', imageData['x-amz-security-token'])
    imageFormData.append('x-amz-signature', imageData['x-amz-signature'])
    imageFormData.append('policy', imageData.policy)
    imageFormData.append('file', file, JSON.stringify(this.file_name))
    // imageFormData.append('file', file)
    let url = environment.s3bucket
    return await this.httpClient.post(url, imageFormData, { headers: { skip: "true" } }).toPromise();
  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename + '.png', { type: mime });
  }
  //getCartItem
  getCart() {
    let url = environment.cart;
    return this.httpClient.get(url)
  }
  //update cart
  updateCart(obj: any, files) {
    if (files) {

      this.imageFileDict = {}
      Object.keys(files).map((k) => {
        let val = files[k]
        this.DataURIToBlob(val, k)
        console.log(k, 'kkkk')
        this.getImage(k).then(res => {
          this.imageData = res['URL']['fields']
          this.postImage(this.imageData, this.imageFileDict[res['URL']['fields'].key]).then(res => {
            console.log(res, 'res')
          }).catch((err) => {
            console.log(err)
          })
        }).catch((err) => {
          console.log(err)
        })
        // });
      })
      let url = environment.cart;
      let formdata = new FormData();
      formdata.append('Metadata', JSON.stringify(obj))
      return this.httpClient.put(url, formdata)
    }
  }
  //delete cart item
  deleteCart(obj: any): Observable<any> {
    let url = environment.cart;
    return this.httpClient.request('delete', url, { body: obj })
  }
  //add order
  addOrder(obj: any) {
    let url = environment.orders;
    return this.httpClient.post(url, obj)
  }
  //edit order

  getOrder() {
    let url = environment.orders
    return this.httpClient.get(url)
  }
  payment(obj: any): Observable<any> {
    let url = environment.initiate
    return this.httpClient.post(url, obj)
  }
  executePayment(obj: any): Observable<any> {
    let url = environment.execute
    return this.httpClient.post(url, obj)
  }
  trackOrder(id) {
    let url = environment.trackOrder + id;
    return this.httpClient.get(url)
  }
  buyAgain(id: any, email) {
    let url = environment.orderDetails + id + '&email=' + email
    return this.httpClient.get(url)
  }
  cancelOrder(obj: any) {
    let url = environment.deliveryStatus;
    this.httpClient.put(url, obj)
  }
  paymentSuccess(obj: any) {
    let formdata = new FormData()
    let data = JSON.stringify(obj)
    formdata.append('Metadata', data)
    let url = environment.payment
    return this.httpClient.put(url, formdata)
  }
  addMessage(obj: any) {
    let formdata = new FormData()
    let data = JSON.stringify(obj)
    formdata.append('Metadata', data)

    // if (file.filename) {
    //   formdata.append('filename', file.filename, JSON.stringify(file.filename.name))
    // }
    let url = environment.chat
    return this.httpClient.post(url, formdata)
  }
  getUserMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=admin-user&item_id=' + productId
    return this.httpClient.get(url)
  }
  getNotification() {
    let url = environment.notification;
    return this.httpClient.get(url)
  }
  updateNotificationStatus(obj) {
    let url = environment.notification;
    return this.httpClient.put(url, obj)
  }
  deleteNotificationById(obj) {
    let url = environment.notification
    return this.httpClient.request('delete', url, { body: obj })
  }
  deleteAllNotifications(obj) {
    let url = environment.batchDelete;
    return this.httpClient.request('delete', url, { body: obj })
  }
  addContactMessage(obj: any) {
    let url = environment.contactUs;
    return this.httpClient.post(url, obj)
  }
  qpay(data) {
    let url = ''
    return this.httpClient.post(url, data)
  }
  // copy admin start
  getAdminUserMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=admin-user&item_id=' + productId
    return this.httpClient.get(url)
  }
  getAdminMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=user-admin&item_id=' + productId
    return this.httpClient.get(url)
  }
  getAdminVendorMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=admin-vendor&item_id=' + productId
    return this.httpClient.get(url)
  }
  getVendorMessage(id: any, productId) {
    console.log(id, 'id')
    let url = environment.message + id + '&type=vendor-admin&item_id=' + productId
    return this.httpClient.get(url)
  }
  getOrderDetails(id: any, email: any) {
    let url = environment.orderById + id + '&email=' + email
    return this.httpClient.get(url)
  }
  updateOrderItemMsgStatus(obj) {
    let url = environment.itemStatus;
    return this.httpClient.put(url, obj);
  }
  updateOrderMsgStatus(obj) {
    let url = environment.orderStatus;
    return this.httpClient.put(url, obj);
  }
  // copy admin end
  addnewsletter(obj) {
    let url = environment.newsLetter;
    return this.httpClient.post(url, obj)
  }
  updatenewsletter(obj) {
    let url = environment.newsLetter;
    let formData = new FormData();
    formData.append('Metadata', JSON.stringify(obj))
    return this.httpClient.put(url, formData)
  }
  getAdmin() {
    let url = environment.getAdmin;
    return this.httpClient.get(url)
  }
  getCoupon(obj: any) {
    debugger
    let url = environment.getCoupon + obj['id'] + '&user=' + obj['email']
    return this.httpClient.get(url)
  }
}

