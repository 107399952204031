<div class="container">
  <div class="row mt-3">
    <div class="col-md-12">
      <div>
        <button type="button" class="btn m-3 back-btn-icon" routerLink="/reset-password" routerLinkActive="router-link-active">
          <span class="material-icons-outlined btn-snape-white back-icon">
            chevron_left
          </span>
          <span class="back-btn-span">Back</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6">
      <div class="login-image">
        <img src="../../../assets/loginimg.png">
      </div>
    </div>
    <div class="col-md-6 logintxt">
      <!-- Forget password -->
      <div class="auth-ui">
        <div class="ml-3">
          <h5 class="snape-red">Reset Password</h5>
          <p>Enter the verification code we just send you on your email address</p>
        </div>

        <form class="pt-2" [formGroup]="confirmPasswordForm">
          <div class="position-relative form-group">
            <div class="col-md-12">
              <input formControlName="code" id="inputEmail" placeholder="Enter OTP" type="text" class="form-control"
                required>
              <span class="text-danger"
                *ngIf="(confirmPasswordForm.controls.code.touched || submitted) && confirmPasswordForm.controls.code.errors?.required">
                Otp is required
              </span>
              <span class="text-danger"
                *ngIf="confirmPasswordForm.controls.code.touched && confirmPasswordForm.controls.code.errors?.pattern">
                Enter a valid otp number
              </span>
            </div>
          </div>
          <div class="position-relative form-group">
            <div class="d-flex justify-content-center col-md-12 mt-4">
              <button type="submit" (click)="continue()" class="btn btn-snape-bak btn-block"
                [disabled]="confirmPasswordForm.invalid">SUBMIT</button>
            </div>
          </div>
          <div class="text-align">
            <p>Didn't receive the code?
              <span>
                <button type="button" class="btn btn-link-pink" (click)="resendOtp()">RESEND OTP</button>
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>