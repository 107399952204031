import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.css']
})
export class OrderCompleteComponent implements OnInit {

  localStorage = [];
  status
  reason
  showFailed = false
  showSuccess = false
  pickupStatus:any
  orderProductAmount: number = 0.00;
  orderAmount: number = 0.00;
  deliveryCharge: number = 0.00;
  orderAddress:any=[]
  orderData = []
  orderId:any
  constructor(
    private route: ActivatedRoute,
    private api: CommonService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // let orderId = window.sessionStorage.getItem('order_id')
    // let userDetails = JSON.parse(window.sessionStorage.getItem('auth-user'))
    // let email = userDetails['email']
    // console.log(userDetails, 'user')
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
      this.reason = params['reason'];
      let orderId = params['orderID']
      this.orderId = params['orderID']

      // console.log(params, this.status)
      // if(this.status == 'error'){
      //   this.showFailed = true
      // }
      // else{
      //   this.showSuccess= true
      // }
      this.showSuccess = true
      // let arr = {},prod_array= []
      // let local_storage = JSON.parse(localStorage.getItem('cart'))
      // for (var i in local_storage) {
      //   arr['product_name'] = local_storage[i].product['name']
      //   arr['rate']= local_storage[i].product['price'].toString()
      //   let total = local_storage[i].product['price'] * local_storage[i].quantity
      //   arr['total']= total.toString();
      //   arr['quantity']= local_storage[i].quantity.toString()
      //   arr['box_color']= local_storage[i].box_color
      //   prod_array.push(arr)
      // }
      // console.log(prod_array, 'prod array')
      let data = {
        "reference_id": params['referenceId'],
        "paid_amount": params['amount'],
        "orderstatus": "order Placed",
        "transaction_date": new Date(),
        "transaction_id": params.Id,
        "id": orderId,
        "product_details":""
      }
      this.api.paymentSuccess(data).subscribe(async item => {
        //console.log('sdsd', item)
        this.orderAddress = item
        console.log('address',this.orderAddress)
        this.api.buyAgain(orderId,this.orderAddress.order.email).subscribe(res => {
          localStorage.removeItem('cart')
          console.log(res, 'res')
          let orderTotal = 0
          this.localStorage = res['item_data']['Items']
          this.orderData = res['order_data']['Items']
          this.pickupStatus = res['order_data']['Items'][0]['home_delivery']
          console.log('pickup',this.pickupStatus)
          this.orderData = this.orderData[0]
          this.deliveryCharge = parseInt(this.orderData['delivery_charge'])
          for (var i in this.localStorage) {
            orderTotal = orderTotal + (this.localStorage[i].product.price * this.localStorage[i].quantity)
          }
          this.orderProductAmount = orderTotal
          this.orderAmount = this.orderProductAmount + this.deliveryCharge
          console.log(this.orderProductAmount,this.deliveryCharge,this.orderAmount, 'orderAmount')
        })
      })
    });

  }
  trackOrder() {
    this.router.navigate(['track-order'], { queryParams:{order_id: this.orderId }})
  }
  continueShopping(){
    this.router.navigate(['products'])
  }
}
