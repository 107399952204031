<div class="col-md-12 mt-4">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <img class="bRad10px" style="width: 100%;" src="../assets/1.jpg" />
                </div>
                <div class="col-md-4">
                    <form [formGroup]="cartForm">
                        <h4 class="mt-4 text-uppercase">{{items.name}}</h4>
                        <p>
                            <span class="snape-red">QAR {{items.price}}</span>
                            <span class="ml-2 ColorBOp">Per cookie</span>
                        </p>
                        <p class="text-uppercase">Choose box color</p>
                        <div *ngFor="let colors of items.color, let i=index">
                            <input type="radio" formControlName="color" [value]="colors.SK"
                                (change)="changeSelection(colors,$event.target.checked)">
                            <img src="{{colors.image_url}}" width="15px" height="15px">

                        </div>
                        <p class="mt-3 text-uppercase mUnset">Select quantity</p>
                        <p class="fSize12px ColorBOp mb-2">Minimum order qty {{items.min_qty}} pieces</p>
                        <p>
                            <span>
                                <button class="btn btn-h34" (click)="decrement(counter)">-</button>
                            </span>
                            {{counter}}
                            <span>
                                <button class="btn btn-h34 btn-h342" (click)="increment(counter)">+</button>
                            </span>
                        </p>
                        <div class="row mt-2 mb-2">
                            <div class="col-md-6">
                                <span>Total price:</span>
                            </div>
                            <div class="col-md-6 text-right">
                                <span>QAR {{items.price * counter}}</span>
                            </div>
                        </div>
                        <button class="btn btn-snape-red w100 text-uppercase" (click)="addToCart(items)">Add to
                            Cart</button>
                    </form>
                </div>
                <div class="col-md-4">
                    <div class="cart-summary">
                        <h6>Cart Summary</h6>

                        <!-- if no cart -->
                        <div class="d-flex justify-content-center align-items-center mt-10" *ngIf="cartLength == 0">
                            <img src="../../../assets/img/12.png" />
                            <p class="fSize12px ColorBOp text-center mt-3">No item in your cookie cart</p>
                        </div>

                        <!-- if no cart end -->


                        <!-- if data exist show this -->
                        <div *ngIf="cartLength != '' ">
                        <div class="row mt-4" *ngFor=" let cart_item of cartProductList">
                            <div >
                                <div class="col-md-4">
                                    <img class="bRad10px" style="width: 100%;" src="{{cart_item.image}}" />
                                </div>
                                <div class="col-md-4">
                                    <h6 class="fSize14px">{{cart_item.product_name}}</h6>
                                    <p class="fSize12px ColorBOp">QAR {{cart_item.price}} x {{cart_item.qty}}</p>
                                    <p class="fSize12px ColorBOp">Box color</p>
                                </div>
                                <div class="col-md-4 text-right">
                                    <button type="button"(click)="deleteItem(cart_item)"><i class="far fa-trash-alt fSize18px gfaIcon"></i></button> 
                                    <p class="fSize12px snape-red">QAR {{cart_item.item_total}}</p>
                                    <p class="fSize12px">{{cart_item.box_color}}</p>
                                </div>
                            </div>
                        </div>
                        
                        <button class="btn btn-snape-redOut text-uppercase checkout" (click)="cartItem()">Checkout</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>