<div class="col-md-12">
    <div class="row bg-about">
        <p class="a-title1">Contact us</p>
        <p class="a-title2">Baking with passion</p>
    </div>
</div>
<div class="container">
    <div class="col-md-12 p-50">
        <div class="row">
            <div class="col-md-3">
                <div class="mt-4">
                    <img src="../../../assets/contactLogo.jpg" width="100%" />
                </div>
            </div>
            <div class="col-md-9">
                <p class="snape-red fw600 text-uppercase">Contact Us</p>
                <p class="fsize40 fw600">We are always here to help you</p>
                <p class="cus-txt fsize18">Looking for a quick answer to your question? Check out our FAQs.
                    Questions not answered yet?<br>
                    We are here to help!</p>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="../../../assets/img/phone.svg" />
                            </div>
                            <div class="col-md-10">
                                <p>Phone</p>
                                <p class="cus-txt mb-unset">+974 7009 9709</p>
                                <!-- <p class="cus-txt">(+032) 3427 7670</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="../../../assets/img/pin.svg" />
                            </div>
                            <div class="col-md-10">
                                <p>Address</p>
                                <p class="cus-txt">Zone 25, Street 330, <br>Building No.57, Floor No.7, <br>Office No.4,
                                    Doha, Qatar.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-2">
                                <img src="../../../assets/img/unread-mail.svg" />
                            </div>
                            <div class="col-md-10">
                                <p>Email</p>
                                <p class="cus-txt mb-unset">support@snapecookies.com</p>
                                <p class="cus-txt">Info@snapecookies.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="col-md-12 mt-4 p-t-40">
                    <p class="snape-red fw600 text-uppercase">Send us a message</p>
                    <p class="fsize40 fw600">Get in touch with us</p>
                    <p class="cus-txt">If you have any questions or queries a member of staff will always be happy to
                        help. Feel free to contact us by filling the form below and we will be sure to get back to you
                        as soon as possible.</p>
                </div>
                <div class="row col-md-12 mt-4">
                    <div class="col-md-6">
                        <form [formGroup]="contactForm">
                            <div class="form-group has-float-label mb-3">
                                <input class="form-control" type="text" placeholder="Your name" formControlName="name"
                                    required />
                                <label>Your name</label>
                                <div class="text-danger"
                                    *ngIf="(contactForm.controls.name.touched || submitted) && contactForm.controls.name.errors?.required">
                                    Name is required
                                </div>
                            </div>
                            <div class="has-float-label">
                                <input class="form-control" type="text" placeholder="Your Email" formControlName="email"
                                    required />
                                <label>Your Email</label>
                            </div>
                            <div class="text-danger"
                                *ngIf="(contactForm.controls.email.touched || submitted) && contactForm.controls.email.errors?.required">
                                Email is required
                            </div>
                            <div class="text-danger"
                                *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.pattern">
                                Enter a valid email address
                            </div>
                            <div class="has-float-label mt-3 mb-3">
                                <textarea rows="4" class="form-control" formControlName="message"
                                    placeholder="Your Message"></textarea>
                                <label>Your Message</label>
                                <div class="text-danger"
                                    *ngIf="(contactForm.controls.message.touched || submitted) && contactForm.controls.message.errors?.required">
                                    Message is required
                                </div>
                            </div>
                            <button class="btn btn-snape-red mb-4" (click)="send()"
                                [disabled]="contactForm.invalid || submitted">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>