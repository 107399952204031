import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'snape-cookies-front';
  showHead: boolean = false;
  showTransHeader: boolean = false
  showHeadBlack: boolean = false
  showProductNavbar: boolean = false
  showFooter: boolean = false
  url
  id
  urlParams
  orderParam
  url2
  msgParam
  socialParams
  social: string;
  socialSignin1:string
  socialSignin2:string
  constructor(private zone: NgZone, private router: Router, private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef) {

    // on route change to '/login', set the variable showHead to false
    debugger
    this.router.events.subscribe((event: any) => {
      this.activatedRoute.fragment.subscribe((fragment: string) => {
        this.socialParams = fragment
        this.socialSignin1= "/home#"+this.socialParams
        this.socialSignin2 = '/#'+this.socialParams
      })
      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')
        this.id = this.url[2]
        this.urlParams = event.url.split('?')
        this.orderParam = this.urlParams[1]
        this.url2 = event.url.split(';')
        this.msgParam = this.url2[1]
        if (event.url === "/" || event.url == "/home" || event.url === "/products?" + this.orderParam || event.url === "/image-customize" || event.url === "/about-us" || event.url === "/contact-us"
          || event.url === "/how-we-work" || event.url === "/products#Products" || event.url === this.socialSignin1 || event.url === this.socialSignin2) {
          this.showTransHeader = true
          this.showHead = false;
          this.showHeadBlack = false;
          this.showFooter = true;
          this.showProductNavbar = false;
        } else if (event.url === "/" || event.url === "/cookie-cart" || event.url === "/orders" || event.url === "/edit-profile" || event.url === "/order/details?" + this.orderParam
          || event.url === "/summary" || event.url === "/manage-address" || event.url === "/favourite-images" || event.url === "/message-center?" + this.orderParam
          || event.url === "/my-orders" || event.url === "/track-order" || event.url === "/message-center;" + this.msgParam
          || event.url === "/order-complete" || event.url === "/notifications" || event.url === "/orders?" + this.orderParam
          || event.url === "/track-order;" + this.msgParam || event.url === "/privacy-policy" || event.url === "/cookie-policy" || event.url === "/terms") {
          this.showHead = true;
          this.showTransHeader = false
          this.showHeadBlack = false;
          this.showFooter = true;
          this.showProductNavbar = false;
        }
        else if (event.url === "/add-cookie/" + this.id) {
          this.showHead = false;
          this.showTransHeader = false
          this.showHeadBlack = true
          this.showFooter = false;
          this.showProductNavbar = false;
        }
        else if (event.url === "/products") {
          this.showProductNavbar = true;
          this.showFooter = true;
          this.showHead = false;
          this.showTransHeader = false
          this.showHeadBlack = false
        }
        else {
          this.showTransHeader = false
          this.showHead = false
          this.showFooter = false;
          this.showHeadBlack = false
          this.showProductNavbar = false;
        }
      }
    });
  }
}
