import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import { TokenStorageService } from '../../common/token/token-storage.service';
import { CustomvalidationService } from '../../common/customvalidation.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from '../../common/messaging.service'
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phone-number-login',
  templateUrl: './phone-number-login.component.html',
  styleUrls: ['./phone-number-login.component.scss']
})
export class PhoneNumberLoginComponent implements OnInit {

  password;
  show = false;

  message;
  @ViewChild('content') content;
  @ViewChild('account') account;
  loginForm: FormGroup
  submitted: boolean = false;
  online_cart_items = []
  localStorage = []
  userStatus: any;
  fullName: any;
  emailLogin = false
  numberLogin = false
  fcm_token

  routeToCart: string;
  routeToCheckout: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private token: TokenStorageService,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private messagingService: MessagingService,
    private angularFireMessaging: AngularFireMessaging
  ) { }

  ngOnInit(): void {
    debugger
    console.log(window.location.origin, 'origin')
    //Validators.pattern("^([0-9]{9})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$")
    this.loginForm = this.fb.group({
      email: ['', [Validators.required,]],
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      otp: ['', [Validators.required]]
    })
    this.routeToCart = localStorage.getItem('fromCart')
    console.log(this.routeToCart, 'route')
    this.password = 'password';

  }

  login() {
    debugger
    this.submitted = true;
    if (this.loginForm.controls.email.valid && this.loginForm.controls.password.valid) {
      let changeEmail = this.loginForm.controls.email.value.toLowerCase()
      this.angularFireMessaging
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          this.fcm_token = token
          let data = {
            "username": changeEmail,
            "password": this.loginForm.controls.password.value,
            "fcm_token": { "web": this.fcm_token },
            "userType": "buyer"
          }
          this.api.Signin(data).subscribe(res => {
            if (res['data']) {
              let status = res['status']
              this.token.loggedStatus(status)
              if (status == true) {
                localStorage.setItem('loginEmail', this.loginForm.controls.email.value)
                this.token.saveToken(res['access_token']);
                this.token.saveRefreshToken(res['refresh_token']);
                this.token.saveIdToken(res['id_token']);
                this.token.saveUser(res['data']['Item'])
                this.userStatus = res['data']['Item'].user_status
                this.fullName = res['data']['Item'].name
                if (this.userStatus == 'inactive') {
                  this.modalService.open(this.account, { centered: true }).result.then(res => {
                    if (res === true) {
                      this.activateAccount();
                    }
                  })
                }
                else {
                  this.toastr.success('Welcome ' + this.fullName);
                  this.localStorage = JSON.parse(localStorage.getItem('cart'))
                  this.api.getCart().subscribe(item => {
                    //   let newdata:any=[]
                    //   newdata = item['data']
                    //  let test = this.localStorage.concat(newdata)
                    //  this.localStorage = test
                    if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
                      if (this.routeToCart == 'true') {
                        this.router.navigate(['home'])

                        localStorage.setItem('fromCart', "false")
                      }
                      else {
                        // this.router.navigate(['edit-profile'])
                        this.api.getCart().subscribe(item => {
                          if (item['data'].length > 0) {
                            this.localStorage = item['data']
                            // localStorage.setItem('cart', JSON.stringify(this.localStorage));
                            this.router.navigate(['cookie-cart'])
                          } else {
                            this.router.navigate(['home'])
                          }


                        })

                      }
                    }
                    else {
                      this.api.getCart().subscribe(item => {
                        this.online_cart_items = item['data']
                        console.log(this.online_cart_items, ' online cartitem')
                        if (this.localStorage != null && this.online_cart_items?.length != 0) {
                          this.modalService.open(this.content, { centered: true }).result.then(res => {
                            if (res === true) {
                              // this.localStorage = []
                              // this.localStorage = this.online_cart_items
                              let newdata: any = []
                              newdata = this.online_cart_items
                              let test = this.localStorage.concat(newdata)
                              this.localStorage = test
                              // localStorage.setItem('cart', JSON.stringify(this.localStorage));

                              window.location.reload();
                            }
                            else {
                              this.localStorage = this.localStorage
                            }
                            // localStorage.setItem('cart', JSON.stringify(this.localStorage));
                            console.log(this.localStorage, 'currrent local storage')
                            this.router.navigate(['cookie-cart'])
                          })
                        }
                        else if (this.localStorage == null && this.online_cart_items?.length != 0) {
                          this.localStorage = this.online_cart_items
                          // localStorage.setItem('cart', JSON.stringify(this.localStorage));
                        }
                        if (this.routeToCart == 'true') {
                          this.router.navigate(['cookie-cart'])
                          localStorage.setItem('fromCart', "false")
                        }
                        else {
                          //this.router.navigate(['edit-profile'])
                          this.router.navigate(['home'])
                        }
                        console.log(this.localStorage, 'currrent local storage')
                      })
                    }
                  })

                }
              }
              else {
                let message = res['message'];
                this.toastr.error(message);
              }
            }
            else {
              this.toastr.info(res['message'])
            }
          })
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  }
  refreshPage() {
    window.location.reload();
  }
  change() {
    debugger
    this.loginForm.controls.email.reset()
    this.loginForm.controls.password.reset()
    this.loginForm.controls.otp.reset()
    this.emailLogin = false
    this.numberLogin = false
  }
  Continue() {
    debugger
    let fieldValue = this.loginForm.controls.email.value.toLowerCase()
    console.log(fieldValue)
    if (fieldValue.includes('@')) {
      this.emailLogin = true
    } else {
      // this.numberLogin = true
      localStorage.setItem('loginNumber', this.loginForm.controls.email.value)
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          this.fcm_token = token
          let data = {
            "username": fieldValue,
            // "password": this.loginForm.controls.password.value,
            "fcm_token": { "web": this.fcm_token },
            "userType": "buyer"
          }
          this.api.Signin(data).subscribe(res => {
            // if (res['data']) {
              let status = res['status']
              if (status == true) {
                this.numberLogin = true
                this.toastr.info(res['message'])
                localStorage.setItem('numberLogin','true')
              } else {
                this.toastr.error(res['message'])
              }

              //   this.token.loggedStatus(status)
              //   if (status == true) {
              //     localStorage.setItem('loginEmail', this.loginForm.controls.email.value)
              //     this.token.saveToken(res['access_token']);
              //     this.token.saveRefreshToken(res['refresh_token']);
              //     this.token.saveIdToken(res['id_token']);
              //     this.token.saveUser(res['data']['Item'])
              //     this.userStatus = res['data']['Item'].user_status
              //     this.fullName = res['data']['Item'].name
              //     if (this.userStatus == 'inactive') {
              //       this.modalService.open(this.account, { centered: true }).result.then(res => {
              //         if (res === true) {
              //           this.activateAccount();
              //         }
              //       })
              //     }
              //     else {
              //       this.toastr.success('Welcome ' + this.fullName);
              //       this.localStorage = JSON.parse(localStorage.getItem('cart'))
              //       this.api.getCart().subscribe(item => {
              //         //   let newdata:any=[]
              //         //   newdata = item['data']
              //         //  let test = this.localStorage.concat(newdata)
              //         //  this.localStorage = test
              //         if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
              //           if (this.routeToCart == 'true') {
              //             this.router.navigate(['home'])

              //             localStorage.setItem('fromCart', "false")
              //           }
              //           else {
              //             // this.router.navigate(['edit-profile'])
              //             this.api.getCart().subscribe(item => {
              //               if (item['data'].length > 0) {
              //                 this.localStorage = item['data']
              //                 // localStorage.setItem('cart', JSON.stringify(this.localStorage));
              //                 this.router.navigate(['cookie-cart'])
              //               } else {
              //                 this.router.navigate(['home'])
              //               }


              //             })

              //           }
              //         }
              //         else {
              //           this.api.getCart().subscribe(item => {
              //             this.online_cart_items = item['data']
              //             console.log(this.online_cart_items, ' online cartitem')
              //             if (this.localStorage != null && this.online_cart_items?.length != 0) {
              //               this.modalService.open(this.content, { centered: true }).result.then(res => {
              //                 if (res === true) {
              //                   // this.localStorage = []
              //                   // this.localStorage = this.online_cart_items
              //                   let newdata: any = []
              //                   newdata = this.online_cart_items
              //                   let test = this.localStorage.concat(newdata)
              //                   this.localStorage = test
              //                   // localStorage.setItem('cart', JSON.stringify(this.localStorage));

              //                   window.location.reload();
              //                 }
              //                 else {
              //                   this.localStorage = this.localStorage
              //                 }
              //                 // localStorage.setItem('cart', JSON.stringify(this.localStorage));
              //                 console.log(this.localStorage, 'currrent local storage')
              //                 this.router.navigate(['cookie-cart'])
              //               })
              //             }
              //             else if (this.localStorage == null && this.online_cart_items?.length != 0) {
              //               this.localStorage = this.online_cart_items
              //               // localStorage.setItem('cart', JSON.stringify(this.localStorage));
              //             }
              //             if (this.routeToCart == 'true') {
              //               this.router.navigate(['cookie-cart'])
              //               localStorage.setItem('fromCart', "false")
              //             }
              //             else {
              //               //this.router.navigate(['edit-profile'])
              //               this.router.navigate(['home'])
              //             }
              //             console.log(this.localStorage, 'currrent local storage')
              //           })
              //         }
              //       })

              //     }
              //   }
              //   else {
              //     let message = res['message'];
              //     this.toastr.error(message);
              //   }
            // }
            // else {
            //   this.toastr.info(res['message'])
            //   this.numberLogin = true
            // }
          })
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  }
  activateAccount() {
    let data = {
      "name": this.fullName,
      "user_status": "active",
    }
    // let data = {
    //   "name": this.profileData.name,
    //   "status": "inactive"
    // }
    let file = ''
    console.log(data, file)
    this.api.profileUpdate(data, file).subscribe(res => {
      console.log(res, 'res')
      this.toastr.success('Account activated successfully. Signin again');
    })
  }
  redirectToUrl() {
    window.location.href = environment.socialLogin
  }
  verifyOtp() {
    debugger
    if (this.loginForm.controls.email.valid && this.loginForm.controls.otp.valid) {
      let data: any = { "fcm_token": { "web": this.fcm_token },"username": this.loginForm.controls.email.value, "code": this.loginForm.controls.otp.value, "userType": "buyer" }
      this.api.otpLogin(data).subscribe((result) => {
        console.log(result)
        if (result['data']) {
          if (result['status'] == true) {
            let status = result['status']
            this.token.loggedStatus(status)
            localStorage.setItem('loginEmail', this.loginForm.controls.email.value)
            this.token.saveToken(result['access_token']);
            this.token.saveRefreshToken(result['refresh_token']);
            this.token.saveIdToken(result['id_token']);
            this.token.saveUser(result['data']['Item'])
            this.userStatus = result['data']['Item'].user_status
            this.fullName = result['data']['Item'].email
            if (this.userStatus == 'inactive') {
              this.modalService.open(this.account, { centered: true }).result.then(res => {
                if (res === true) {
                  this.activateAccount();
                }
              })
            }
            else {
              this.toastr.success('Welcome ' + this.fullName);
              this.localStorage = JSON.parse(localStorage.getItem('cart'))
              this.api.getCart().subscribe(item => {
                //   let newdata:any=[]
                //   newdata = item['data']
                //  let test = this.localStorage.concat(newdata)
                //  this.localStorage = test
                if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
                  if (this.routeToCart == 'true') {
                    this.router.navigate(['home'])

                    localStorage.setItem('fromCart', "false")
                  }
                  else {
                    // this.router.navigate(['edit-profile'])
                    this.api.getCart().subscribe(item => {
                      //if (item['data'].length > 0) {
                      // this.localStorage = item['data']
                      // localStorage.setItem('cart', JSON.stringify(this.localStorage));
                      this.router.navigate(['cookie-cart'])
                      // } else {
                      this.router.navigate(['home'])
                      // }


                    }, (error => {
                      this.router.navigate(['home'])
                    }))

                  }
                }
                else {
                  this.api.getCart().subscribe(item => {
                    this.online_cart_items = item['data']
                    console.log(this.online_cart_items, ' online cartitem')
                    if (this.localStorage != null && this.online_cart_items?.length != 0) {
                      this.modalService.open(this.content, { centered: true }).result.then(res => {
                        if (res === true) {
                          // this.localStorage = []
                          // this.localStorage = this.online_cart_items
                          let newdata: any = []
                          newdata = this.online_cart_items
                          let test = this.localStorage.concat(newdata)
                          this.localStorage = test
                          // localStorage.setItem('cart', JSON.stringify(this.localStorage));

                          window.location.reload();
                        }
                        else {
                          this.localStorage = this.localStorage
                        }
                        // localStorage.setItem('cart', JSON.stringify(this.localStorage));
                        console.log(this.localStorage, 'currrent local storage')
                        this.router.navigate(['cookie-cart'])
                      })
                    }
                    else if (this.localStorage == null && this.online_cart_items?.length != 0) {
                      this.localStorage = this.online_cart_items
                      // localStorage.setItem('cart', JSON.stringify(this.localStorage));
                    }
                    if (this.routeToCart == 'true') {
                      this.router.navigate(['cookie-cart'])
                      localStorage.setItem('fromCart', "false")
                    }
                    else {
                      //this.router.navigate(['edit-profile'])
                      this.router.navigate(['home'])
                    }
                    console.log(this.localStorage, 'currrent local storage')
                  })
                }
              })

            }
          } else {
            let message = result['message'];
            this.toastr.error(message);
          }
        } else {
          this.toastr.info(result['message'])
        }

      })
    }
  }
  resendOtp() {
    this.api.resendSignupOtp().subscribe(res => {
      console.log(res, 'result')
      let message = res['message']
      this.toastr.success(message)
    })
  }

  showPasswordOnClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
