import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-order-successful',
  templateUrl: './order-successful.component.html',
  styleUrls: ['./order-successful.component.scss']
})
export class OrderSuccessfulComponent implements OnInit {
  localStorage = [];
  status
  reason
  showFailed = false
  showSuccess = false
  pickupStatus:any
  orderProductAmount: number = 0.00;
  orderAmount: number = 0.00;
  deliveryCharge: number = 0.00;
  orderAddress:any=[]
  orderData:any = []
  orderId:any
  savedAmount:any
  loader = false
  numberLogin:any
  constructor(private route: ActivatedRoute,
    private api: CommonService,
    private router: Router) { }

  ngOnInit(): void {
    debugger
    this.loader = true
    this.numberLogin = localStorage.getItem('numberLogin')
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
      this.reason = params['reason'];
      let orderId = params['orderID']
      this.orderId = params['orderID']

      // console.log(params, this.status)
      // if(this.status == 'error'){
      //   this.showFailed = true
      // }
      // else{
      //   this.showSuccess= true
      // }
      this.showSuccess = true
      // let arr = {},prod_array= []
      // let local_storage = JSON.parse(localStorage.getItem('cart'))
      // for (var i in local_storage) {
      //   arr['product_name'] = local_storage[i].product['name']
      //   arr['rate']= local_storage[i].product['price'].toString()
      //   let total = local_storage[i].product['price'] * local_storage[i].quantity
      //   arr['total']= total.toString();
      //   arr['quantity']= local_storage[i].quantity.toString()
      //   arr['box_color']= local_storage[i].box_color
      //   prod_array.push(arr)
      // }
      // console.log(prod_array, 'prod array')
      let data = {
        "reference_id": params['referenceId'],
        "paid_amount": params['amount'],
        "orderstatus": "order Placed",
        "transaction_date": new Date(),
        "transaction_id": params.Id,
        "id": orderId,
        "product_details":""
      }
      this.api.paymentSuccess(data).subscribe(async item => {
        //console.log('sdsd', item)
        this.orderAddress = item
        console.log('address',this.orderAddress)
        if(this.numberLogin == 'true'){
          this.orderAddress.order.email = localStorage.getItem('loginNumber')
        }
        this.api.buyAgain(orderId,this.orderAddress.order.email).subscribe(res => {
          this.loader = false
          localStorage.removeItem('cart')
          console.log(res, 'res')
          let orderTotal = 0
          this.localStorage = res['item_data']['Items']
          console.log('local',this.localStorage)
          let orderData = res['order_data']['Items']
          this.pickupStatus = res['order_data']['Items'][0]['home_delivery']
          console.log('pickup',this.pickupStatus)
          this.orderData = orderData[0]
          console.log('orderdata',this.orderData)
          this.deliveryCharge = parseFloat(this.orderData['delivery_charge'])
          if(this.orderData['discountAmount']){
            this.savedAmount = parseFloat(this.orderData['discountAmount'])
          }
          
          for (var i in this.localStorage) {
            orderTotal = orderTotal + (this.localStorage[i].product.price * this.localStorage[i].quantity)
          }
          this.orderProductAmount = orderTotal
          this.orderAmount = this.orderProductAmount + this.deliveryCharge
          if(this.orderData['discountAmount']){
            this.savedAmount = parseFloat(this.orderData['discountAmount'])
            this.orderAmount = this.orderProductAmount + this.deliveryCharge - this.savedAmount
          }
          console.log(this.orderProductAmount,this.deliveryCharge,this.orderAmount, 'orderAmount')
        })
      })
    });

  }
  trackOrder() {
    this.router.navigate(['track-order'], { queryParams:{order_id: this.orderId }})
  }

}
