<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>

<section class="bg-color">
  <div class="container">

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active"><i
                          class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/my-orders" routerLinkActive="router-link-active">My
                        Orders</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> Track Order</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="track-order-caption">
              <p>Track Order</p>
            </div>

            <!-- timeline start -->
            <section class="ps-timeline-sec">
              <ol class="ps-timeline">
                <li>
                  <div>
                    <div class="ps-bot">
                      <h5>Order Placed</h5>
                      <p>{{placed_date |date:'dd-MMM-YYYY'}}</p>
                      <p>{{placed_date |date :'shortTime'}}</p>
                    </div>
                    <div>
                      <span class="ps-sp-top1" *ngIf="orderStatus == 'Order Accepted' || orderStatus == 'Order Placed' || orderStatus == 'Delayed'
                      || orderStatus == 'Processing' || orderStatus == 'Ready for delivery'
                      || orderStatus == 'Out for delivery' || orderStatus == 'Delivered' ">
                        <i class="fas fa-check-circle"></i>
                      </span>
                      <span class="ps-sp-top2" *ngIf="orderStatus != 'Order Accepted' && orderStatus != 'Order Placed' && orderStatus != 'Delayed'
                      && orderStatus != 'Processing' && orderStatus != 'Ready for delivery'
                      && orderStatus != 'Out for delivery' && orderStatus != 'Delivered' ">
                        <i class="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="ps-bot">
                      <h5>Order Accepted</h5>
                      <p>{{accepted_date |date:'dd-MMM-YYYY'}}</p>
                      <p>{{accepted_date |date :'shortTime'}}</p>
                    </div>
                    <div>
                      <span class="ps-sp-top1" *ngIf="orderStatus == 'Order Accepted' || orderStatus ==  'Processing' || orderStatus ==  'Delayed'
                      || orderStatus ==  'Ready for delivery' || orderStatus ==  'Out for delivery'
                      || orderStatus ==  'Delivered'">
                        <i class="fas fa-check-circle"></i>
                      </span>
                      <span class="ps-sp-top2" *ngIf="orderStatus != 'Order Accepted' && orderStatus != 'Delayed'
                      && orderStatus != 'Processing' && orderStatus != 'Ready for delivery'
                      && orderStatus != 'Out for delivery' && orderStatus != 'Delivered' ">
                        <i class="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="ps-bot">
                      <h5>Processing</h5>
                      <p>{{processed_date |date:'dd-MMM-YYYY'}}</p>
                      <p>{{processed_date |date :'shortTime'}}</p>
                    </div>
                    <div>
                      <span class="ps-sp-top1" *ngIf="orderStatus == 'Processing' || orderStatus ==  'Ready for delivery' || orderStatus ==  'Delayed' 
                      || orderStatus ==  'Out for delivery'
                      || orderStatus ==  'Delivered'">
                        <i class="fas fa-check-circle"></i>
                      </span>
                      <span class="ps-sp-top2" *ngIf="orderStatus != 'Delayed'
                      && orderStatus != 'Processing' && orderStatus != 'Ready for delivery'
                      && orderStatus != 'Out for delivery' && orderStatus != 'Delivered' ">
                        <i class="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="ps-bot">
                      <h5>Ready for delivery</h5>
                      <p>{{ready_for_delivery_date |date:'dd-MMM-YYYY'}}</p>
                      <p>{{ready_for_delivery_date |date :'shortTime'}}</p>
                    </div>
                    <div>
                      <span class="ps-sp-top1" *ngIf="orderStatus == 'Ready for delivery' || orderStatus ==  'Out for delivery'
                      || orderStatus ==  'Delivered'">
                        <i class="fas fa-check-circle"></i>
                      </span>
                      <span class="ps-sp-top2" *ngIf="
                      orderStatus != 'Ready for delivery'
                      && orderStatus != 'Out for delivery' && orderStatus != 'Delivered' ">
                        <i class="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="ps-bot">
                      <h5>Out for delivery</h5>
                      <p>{{out_for_delivery_date |date:'dd-MMM-YYYY'}}</p>
                      <p>{{out_for_delivery_date |date :'shortTime'}}</p>
                    </div>
                    <div>
                      <span class="ps-sp-top1" *ngIf="orderStatus == 'Out for delivery'|| orderStatus ==  'Delivered' ">
                        <i class="fas fa-check-circle"></i>
                      </span>
                      <span class="ps-sp-top2" *ngIf="orderStatus != 'Out for delivery' && orderStatus != 'Delivered' ">
                        <i class="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="ps-bot">
                      <h5>Delivered</h5>
                      <p>{{delivered_date |date:'dd-MMM-YYYY'}}</p>
                      <p>{{delivered_date |date :'shortTime'}}</p>
                    </div>
                    <div>
                      <span class="ps-sp-top1" *ngIf="orderStatus == 'Delivered'">
                        <i class="fas fa-check-circle"></i>
                      </span>
                      <span class="ps-sp-top2" *ngIf="orderStatus != 'Delivered'">
                        <i class="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </div>
                </li>
              </ol>
            </section>
            <!-- timeline end -->

            <!-- <div class="row justify-content-between top">
              <div class="row d-flex icon-content">
                <div class="d-flex flex-column text-align">
                  <p class="order-caption">Order Placed</p>
                  <p class="txt-color">{{placed_date |date:'dd-MMM-YYYY'}}</p>
                </div>
              </div>
              <div class="row d-flex icon-content text-align">
                <div class="d-flex flex-column">
                  <p class="order-caption">Order Accepted</p>
                  <p class="txt-color">{{accepted_date |date:'dd-MMM-YYYY'}}</p>
                </div>
              </div>
              <div class="row d-flex icon-content text-align">
                <div class="d-flex flex-column">
                  <p class="order-caption">Processing</p>
                  <p class="txt-color">{{processed_date |date:'dd-MMM-YYYY'}}</p>
                </div>
              </div>
              <div class="row d-flex icon-content text-align">
                <div class="d-flex flex-column">
                  <p class="order-caption">Ready for delivery</p>
                  <p class="txt-color">{{ready_for_delivery_date |date:'dd-MMM-YYYY'}}</p>
                </div>
              </div>
              <div class="row d-flex icon-content text-align">
                <div class="d-flex flex-column">
                  <p class="order-caption">Out for delivery</p>
                  <p class="txt-color">{{out_for_delivery_date |date:'dd-MMM-YYYY'}}</p>
                </div>
              </div>
              <div class="row d-flex icon-content text-align">
                <div class="d-flex flex-column">
                  <p class="order-caption">Delivered</p>
                  <p class="txt-color">{{delivered_date |date:'dd-MMM-YYYY'}}</p>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-center">
              <div class="col-12">
                <ul id="progressbar" class="text-center">
                  <li
                    class="{{orderStatus == 'Order Accepted' || orderStatus ==  'Order Placed' || orderStatus ==  'Delayed'
                                          || orderStatus ==  'Processing' || orderStatus ==  'Ready for delivery' 
                                          || orderStatus ==  'Out for delivery' || orderStatus ==  'Delivered' ? 'active' : ''}} step0">
                  </li>
                  <li class="{{orderStatus == 'Order Accepted' || orderStatus ==  'Processing' || orderStatus ==  'Delayed'
                                           || orderStatus ==  'Ready for delivery' || orderStatus ==  'Out for delivery'
                                           || orderStatus ==  'Delivered'  ? 'active' : ''}} step0">
                  </li>
                  <li class="{{orderStatus == 'Processing' || orderStatus ==  'Ready for delivery' || orderStatus ==  'Delayed' 
                                          || orderStatus ==  'Out for delivery'
                                          || orderStatus ==  'Delivered' ? 'active' : ''}} step0"></li>
                  <li class="{{orderStatus == 'Ready for delivery' || orderStatus ==  'Out for delivery'
                    || orderStatus ==  'Delivered' ? 'active' : ''}} step0">
                  </li>
                  <li
                    class="{{orderStatus == 'Out for delivery'|| orderStatus ==  'Delivered'  ? 'active' : ''}} step0">
                  </li>
                  <li class="{{orderStatus == 'Delivered' ? 'active' : ''}} step0"></li>
                </ul>
              </div>
            </div> -->
            <div class="truck-img text-center">
              <img src="../../../assets/img/track.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>