export const COMPANY_NAME = [
  {
    id: 1,
    name: 'Snape Cookie'
  },
  {
    id: 2,
    name: 'Cookie Snape'
  }
];

export const PREFERENCE = [
  {
    id: 1,
    name: 'Home Delivery'
  },
  {
    id: 2,
    name: 'Self Pickup'
  }
];

export const STATUS = [
  {
    id: 1,
    name: 'Order Placed'
  },
  {
    id: 2,
    name: 'Processing'
  },
  {
    id: 3,
    name: 'Ready for Delivery'
  },
  {
    id: 4,
    name: 'Delivery'
  },
  {
    id: 5,
    name: 'Delayed'
  },
];
export const CUSTOMER_STATUS = [
  {
    id: 1,
    name: 'Active'
  },
  {
    id: 2,
    name: 'Inactive'
  },
]