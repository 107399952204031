<footer class="section footer-classic context-dark bg-image p-4" style="background: #eee; ">
  <div class="container">
    <div class="row col-md-12 row-30">
      <div class="col-md-3">
        <div class="pr-xl-4"><a class="brand" href="index.html"><img class="brand-logo-light"
              src="../../../assets/img/snapeLogo.svg" alt="" width="140" height="100"></a>
          <p>Your favorite moments on our delicious cookies!</p>
          <!-- Rights-->

        </div>
      </div>
      <div class="col-md-6 borlr">
        <div class="row">
          <div class="col-md-4 mb-3">
            <h6 class="text-uppercase">Menu</h6>
            <ul class="nav-list">
              <li><a routerLink="/about-us" routerLinkActive="router-link-active">About us</a></li>
              <li><a routerLink="/how-we-work" routerLinkActive="router-link-active">How we work</a></li>
              <li><a routerLink="/contact-us" routerLinkActive="router-link-active">Contact us</a></li>
            </ul>
          </div>
          <div class="col-md-4 mb-3">
            <h6 class="text-uppercase">Quick Links</h6>
            <ul class="nav-list">
              <li><a routerLink="/products" routerLinkActive="router-link-active">Product</a></li>
              <li *ngIf="isLoggedIn"><a routerLink="/edit-profile" routerLinkActive="router-link-active">My account</a>
              </li>
              <li *ngIf="!isLoggedIn"><a routerLink="/login" routerLinkActive="router-link-active">My account</a></li>
              <li *ngIf="!isLoggedIn"><a routerLink="/signup" routerLinkActive="router-link-active"
                  (click)="signup()">Sign up</a></li>
            </ul>
          </div>
          <div class="col-md-4 mb-3">
            <h6 class="text-uppercase">Legal</h6>
            <ul class="nav-list">
              <li><a routerLink="/terms" routerLinkActive="router-link-active">Terms & condition</a></li>
              <li><a routerLink="/privacy-policy" routerLinkActive="router-link-active">Privacy policy</a></li>
              <li><a routerLink="/cookie-policy" routerLinkActive="router-link-active">Cookie policy</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <form [formGroup]="newsLetterForm">
          <h6 class="text-uppercase">Newsletter</h6>
          <p>Signup to our newsletter to receive email updates on new cookie announcements, gift ideas, special
            promotions
            and more.</p>
          <div class="footer-btn">
            <input type="text" class="form-control bkgrey" placeholder="Your Email Id" formControlName="email" />
            <button class="snape-red txtSubmit" (click)="subscribe()"
              [disabled]="newsLetterForm.invalid">{{showText}}</button>
          </div>

          <div class="text-danger"
            *ngIf="(newsLetterForm.controls.email.touched || submitted) && newsLetterForm.controls.email.errors?.required">
            Email is required
          </div>
          <div class="text-danger"
            *ngIf="newsLetterForm.controls.email.touched && newsLetterForm.controls.email.errors?.pattern">
            Enter a valid email address
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row no-gutters social-container mt-4">
    <div class="col-md-3 tcenMob">
      <img src="../../../assets/img/facebook-logo.svg" (click)="redirectToUrl()" class="mr-2 cursor-pointer" />

      <img src="../../../assets/img/instagram.svg" (click)="redirectInsta()" class="cursor-pointer" />

    </div>
    <div class="col-md-6 text-center">
      <p class="rights">Copyright <span>©  </span><span
          class="copyright-year">2023</span><span> </span><span>Snapecookies</span><span>. </span><span>All rights
          reserved.</span></p>
    </div>
    <div class="col-md-3 imgFtrCard tcenMob">
      <img src="../../../assets/img/iconfinder_Visa-Curved_70599.svg" class="cursor-pointer" />
      <img src="../../../assets/img/iconfinder_American-Express-Straight_70584.svg" class="cursor-pointer" />
      <img src="../../../assets/img/iconfinder_Discover-Curved_70605.svg" class="cursor-pointer" />
      <img src="../../../assets/img/iconfinder_Mastercard-Curved_70593.svg" class="cursor-pointer" />
    </div>
  </div>
</footer>