<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap" rel="stylesheet">

<!-- <div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div> -->

<section>
  <div class="myback">
    <div class="five-circ">
      <img class="fcirc" width="40%" src="../../../assets/home2.png" />
      <div class="home-txtmid">
        <h1>Your favorite moments on our delicious cookies! </h1>
        <p>We take your special photographs and selfies and transform them into unique products by printing them onto
          our freshly cooked and utterly delicious cookies. Our service is easy and hassle-free: we promise to deliver
          the cookies to you within 3 hours.</p>
        <button class="btn snape-red order-now" (click)="order()">ORDER NOW</button>
      </div>
    </div>
  </div>
</section>
<section class="mt-20rem p-l-r-50">
  <div class="text-center">
    <p class="snape-red fsize18 fw600">What we offer</p>
    <h1 class="ttl2 mb-4">Try our Cookies</h1>
  </div>
  <div class="col-md-12">
    <div class="row text-center cookieSet">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let products of productArray"
        (click)="selectedProduct(products.id)">
        <div class="prodDiv image">
          <img src="{{products.image}}" />
        </div>
        <h6 class="fsize22">{{products.name}}</h6>
        <p class="fsize14 color70">{{products.description}}</p>
        <h6 class="snape-red fsize18 fw600">QAR {{products.price | number :
          '1.2-2'}}</h6>
      </div>
    </div>
  </div>
</section>

<section class="p-50">
  <div class="col-md-12">
    <div class="text-center m-4">
      <p class="snape-red fsize18 fw600">How we work</p>
      <h1 class="ttl2 mb-4rem">Why choose our Cookies</h1>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="why-choose">
                <div class="whyChoose2">
                  <img class="scardImg" src="../../../assets/img/snape3.svg" />
                  <img class="scardImgW" src="../../../assets/img/snape3W.svg" />
                  <h5>Share your most precious moments</h5>
                  <p>With our cookies, you can capture, share, and show off your most special moments, whether for a
                    birthday, anniversary, graduation, wedding or any other important occasion. Our cookies fit any
                    event!</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="why-choose mt-4 mb-4">
                <div class="whyChoose2">
                  <img class="scardImg" src="../../../assets/img/snape2.svg" />
                  <img class="scardImgW" src="../../../assets/img/snape2W.svg" />
                  <h5>Perfect personalized gift <br> idea</h5>
                  <p>Our cookies are the perfect gift! Celebrate with your friends and family and make their day with
                    our gift cookie box - its uniqueness will make them want to take lots of pictures to share on social
                    media! Making your gift more memorable.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="why-choose">
                <div class="whyChoose2">
                  <img class="scardImg" src="../../../assets/img/snape1.svg" />
                  <img class="scardImgW" src="../../../assets/img/snape1W.svg" />
                  <h5>Amazing business branding idea</h5>
                  <p>Print your company brand or logo onto our cookies for a one-of-a-kind customer business experience.
                    Make your business stand out and win over customers through our highly memorable cookies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="carousel-item row  w-100  mx-3 text-center {{ i == 0 ? 'active' : '' }} m-t-0" style="margin-right: 70px;"
  *ngFor='let fav of userFavourite; let i = index'>
  <div class=" d-flex justify-content-around w-100">{{fav}}
    <div class="mainSlide " style="align-content: center;" *ngFor="let x of fav;">

      {{x}}
      <div class="prodDiv image">
        <img width="100%" src="{{x.image}}" />
      </div>

    </div>
  </div>
</div>

<section class="p-50">
  <div class="text-center">
    <p class="snape-red fsize18 fw600">Personal, Corporate & Social Media</p>
    <h1 class="ttl2 mb-4rem">Baking special moments for our customers</h1>
  </div>
  <div class="col-md-12">
    <img width="100%" src="../../../assets/1.png" />
  </div>
  <!-- <div class="text-center m-4">
    <p class="snape-red">Testimonials</p>
    <h1>Satisfied Customers</h1>
  </div> -->
</section>

<section class="p-50">
  <div class="col-md-12">
    <div class="row m-4">
      <div class="col-md-4">
        <div class="text-center">
          <i class="fas fa-map-marker-alt"></i>
          <h5>Zone 25, Street 330, <br>Building No.57, Floor No.7, <br> Office No.4, Doha, Qatar.</h5>
          <p>Address</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="text-center">
          <i class="fas fa-clock"></i>
          <h5>Daily 9:00 AM to 7:30 PM</h5>
          <p>Working Hours</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="text-center">
          <i class="fas fa-phone-alt"></i>
          <h5>+974 7009 9709</h5>
          <p>For Enquiries</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="p-50">
  <div class="row mobile-app-area-bg-color">
    <!-- <div class="col-md-12">
      <div class="app-download-bg-image"></div>
    </div> -->

    <div class="col-lg-7 col-md-8 col-xs-12 col-xl-6">
      <div class="m-l-100 mob-text-align">
        <img src="../../../assets/Group18788.png" class="mobile-image">
      </div>
    </div>
    <div class="col-lg-5 col-md-4 col-xs-12 col-xl-6">
      <div class="app-area">
        <h6 class="app-heading">Download App</h6>
        <h4>Download the app & get service quickly</h4>
        <p class="p-t-20 app-content"> Download the App and start your cookie customization
          Simply choose your favorite cookie flavor, upload your photo or Selfie, customize your cookie box and we will
          deliver it to you fresh
        </p>
        <div class="mob-p-b-20 mob-text-align p-t-20">
          <a href="https://play.google.com/store/apps/details?id=com.snapecookies.snape_cookies" target="_blank">
            <img src="../../../assets/googleplay.jpg" class="google-play-logo cursor-pointer mr-3 mb-3">
          </a>
          <a href="https://apps.apple.com/jo/app/snape-cookies/id1568674032" target="_blank">
            <img src="../../../assets/iphonestore.jpg" class="apple-logo cursor-pointer mr-3 mb-3">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<script>
  $(document).ready(function () {
    $("ul.navbar-nav > li").click(function (e) {
      $("ul.navbar-nav > li").removeClass("active");
      $(this).addClass("active");
    });
  });

</script>