import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { formatDate, ViewportScroller } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookie-cart',
  templateUrl: './cookie-cart.component.html',
  styleUrls: ['./cookie-cart.component.scss']
})
export class CookieCartComponent implements OnInit, AfterViewInit {

  @ViewChild('content') content;
  @ViewChild('pending') pending;
  localStorage = [];
  counter;
  cartItem = []
  disableCheckoutButton:boolean
  product
  isLoggedIn: boolean
  checkoutForm: FormGroup;
  checkBoxForm: FormGroup
  online_cart_items = []
  cart_tems: any
  preference_value: any;
  showHomeDelivery = false;
  delivery_charge: number = 0.00;
  cartQuantity: number;
  showCustomized = false
  count: number = 0
  index: string;
  cartLength: number;
  itemId: any;
  orderAmount: number = 0;
  deletedProduct: any;
  sum: number;
  submitted = false;
  showCheckoutButton: boolean = false;
  customizedCount: number;
  mindate: Date;
  checkoutDiv = false
  note = true
  minDate: Date;
  isEnabled = false
  loader = false
  todayDate: any
  savedAmount: any
  couponDetails: any
  couponForm: FormGroup

  date8: Date;
  constructor(
    private router: Router,
    private api: CommonService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private viewportScroller: ViewportScroller,
    private cd: ChangeDetectorRef
  ) {
    this.couponForm = this.fb.group({
      couponCode: ['']
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.minDate = new Date();
    this.loggedUser();
    this.getCartItems();

    // this.calculateOrderAmount();
    this.mindate = new Date();
    this.getCheckoutForm();
    this.getCheckboxForm();
  }
  ngAfterViewInit() {
    // var dateToday = new Date();    
    // $(function () {
    //     $("#datepicker").datepicker({ 
    //         minDate: dateToday 
    //     });
    // });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  getCoupon() {
    debugger
    let id = this.couponForm.controls.couponCode.value
    let data: any = { "id": id, "email": localStorage.getItem('loginEmail') }
    let currentDate = new Date()
    let today = this.formatDate(currentDate)
    this.savedAmount = ''
    this.api.getCoupon(data).subscribe((resp) => {
      console.log(resp)
      if (resp['status'] == true) {
        this.couponDetails = resp['couponcode']
        let expiry = this.couponDetails[0].expiry
        console.log(this.couponDetails, 'details')
        if (this.couponDetails[0].couponUsage == this.couponDetails[0].usageCount) {
          this.toastr.error('Coupon limit exceeded')
        }
        else if (expiry < today) {
          this.toastr.error('Coupon Expired')
        }
        else {
          if (this.couponDetails[0].type == 'Percentage discount') {
            this.savedAmount = this.orderAmount * (parseInt(this.couponDetails[0].typeValue) / 100)
            console.log(this.savedAmount)
          } else {
            this.savedAmount = parseFloat(this.couponDetails[0].typeValue)
          }
        }


      } else {
        this.toastr.error('Invalid couponcode')
        this.cancel()
      }

    })

  }
  cancel() {
    this.couponForm.reset()
    this.savedAmount = ''
  }
  getCheckboxForm() {
    this.checkBoxForm = this.fb.group({
      checkbox: ['']
    })
  }
  openModal() {
    this.modalService.open(this.content, { centered: true, size: 'sm' });
  }
  check(e, id) {
    let product_id = id
    this.isEnabled = !this.isEnabled
    for (var i in this.localStorage) {
      if (this.localStorage[i].id == product_id) {
        this.localStorage[i].is_gift = this.isEnabled
      }
    }
    localStorage.setItem('cart', JSON.stringify(this.localStorage));
  }
  loggedUser() {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
      if (this.isLoggedIn) {
        this.note = false
      }
    })
  }
  getTime(event: any) {
    debugger
    this.disableCheckoutButton = false;
    console.log(this.checkoutForm.controls.date.value)
    let userDate = this.checkoutForm.controls.date.value
    let currentdate: any = new Date()
    currentdate = formatDate(currentdate, 'yyyy-MM-dd', 'en-US');
    // currentdate = currentdate.getFullYear() + '-' + currentdate.getMonth() + '-' + currentdate.getDate()
    console.log(currentdate)
    let currentTime = new Date().getTime()
    // let updatedTIme = new Date()
    // updatedTIme.setTime(currentTime.getTime() + (3 * 60 * 60 * 1000));
    let updatedTIme = new Date(currentTime + 2 * 60 * 60 * 1000);
    let date = updatedTIme.getHours() + ':' + updatedTIme.getMinutes()
    this.todayDate = date
    console.log(this.todayDate)
    // let time = event.getHours() + ':' + event.getMinutes()
    let time = event.target.value;

    const time_ = new Date()
    const [hh, mm] = time.split(':')

    // min time
    const min_time = new Date()
    const [mi_hh, mi_mm] = '07:30'.split(':')
    min_time.setMinutes(parseInt(mi_mm))
    min_time.setHours(parseInt(mi_hh))

    
    // maximum time
    const max_time = new Date()
    const [mx_hh, mx_mm] = '21:00'.split(':')
    max_time.setMinutes(parseInt(mx_mm))
    max_time.setHours(parseInt(mx_hh))
    

    //user entered time
    const user_time = new Date()
    const [user_hh, user_mm] = time.split(':')
    user_time.setMinutes(parseInt(user_mm))
    user_time.setHours(parseInt(user_hh))
    
    console.log("CHCKTIME", min_time, '\nmaxtime', max_time, '\nuser time:', user_time, 'CHECKTIME_LIMITS \n', user_time.getTime() >= min_time.getTime(), user_time.getTime() >= max_time.getTime())


    let newtime = time.split(':')
    let hours = newtime[0]
    let minutes = newtime[1]
    let meridian = ''
    if (parseInt(hours) >= 12) {
      meridian = 'PM'
    } else if (parseInt(hours) < 12) {
      meridian = 'AM'
    }
    if (userDate == currentdate) {
      if (time < this.todayDate) {
        this.toastr.error('select  valid time', null, { timeOut: 5000 })
        this.checkoutForm.controls.time.reset()
      }
    }
    if (meridian == 'PM') {
      //time > '7:30' || time > '19:30'
      if (time > '7:30' || time > '22:30') {
        this.toastr.error('Please choose time between 9:00 AM to 10:30 PM', null, { timeOut: 5000 })
        this.disableCheckoutButton = true;
      }
    } else if (meridian == 'AM') {
      if ("09:00" > time ||  time > '21:00') {
        this.toastr.error('Please choose time between 9:00 AM to 10:30 PM', null, { timeOut: 5000 })
        this.disableCheckoutButton = true;
      }
    }


  }
  clearTime() {
    this.checkoutForm.controls.time.reset()
  }
  getCartItems() {
    debugger
    // if (localStorage.getItem('cart') != null) {
    //   this.localStorage = JSON.parse(localStorage.getItem('cart'))
    //   console.log('localdata', this.localStorage)
    // this.localStorage = JSON.parse(localStorage.getItem('cart'))
    // this.api.getCart().subscribe((resp) => {
    //   let online_cart_items = resp['data']
    //   if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
    //     this.localStorage = online_cart_items
    //   } else if (this.localStorage != null && online_cart_items?.length != 0) {
    //     let newdata: any = []
    //     let localdata: any = []

    //     newdata = online_cart_items
    //     const filteredItems = this.localStorage.filter(x => newdata.some(y => y.id !== (x.id)));

    //     console.log(filteredItems,'filterItems');
    //     let test = filteredItems.concat(newdata)
    //     this.localStorage = test
        //   this.localStorage.forEach( array1Item => {

        //     newdata.forEach( array2Item => {

        //        if(array1Item.id != array2Item.id){
        //           localdata.push(array1Item)
        //       }
        //       else{

        //       }

        //     })
        //   })
        //   if(localdata.length>0){
        //    // let test = this.localStorage.concat(newdata)
        //    let test = localdata.concat(newdata)
        //  this.localStorage = test
        //   }
        this.localStorage = JSON.parse(localStorage.getItem('cart'));
        this.api.getCart().subscribe((resp) => {
          const online_cart_items = resp['data'];
          if (this.localStorage?.length == 0 || this.localStorage?.length == undefined || this.localStorage?.length == null) {
            this.localStorage = online_cart_items;
          } else if (this.localStorage != null && online_cart_items?.length != 0) {
            const newdata = online_cart_items;
            const filteredItems = this.localStorage.filter((x) => !newdata.find((y) => y.id === x.id));
            console.log(filteredItems, 'filteredItems');
            const mergedData = filteredItems.concat(newdata);
            this.localStorage = mergedData

      }
      this.getLocalProduct();
      this.enableCheckout();
      this.calculateOrderAmount()
    })

    // }

    // this.api.getCart().subscribe((resp)=>{
    //   if(resp['status'] == true){
    //     let newdata:any=[]
    //     for(var i in resp['data']){
    //       newdata.push(resp['data'][i])
    //     }
    //    let test:any=[] 
    //    test = this.localStorage.concat(newdata)
    //    this.localStorage = test
    //     console.log('newdata',test)
    //   }
    //   console.log('savedcart',resp)
    // })
    // this.api.getCart().subscribe(item => {
    //   this.localStorage = item['data']
    // })
    // if (this.isLoggedIn) {
    //   this.api.getCart().subscribe(item => {
    //     this.online_cart_items = item['data']
    //     if (this.localStorage != null) {
    //       this.modalService.open(this.content, { centered: true }).result.then(res => {
    //         if (res === true) {
    //           localStorage.clear()
    //           this.localStorage = this.online_cart_items
    //         }
    //       })
    //     }
    //     else {
    //       this.localStorage = this.online_cart_items
    //     }
    //     localStorage.setItem('cart', JSON.stringify(this.localStorage));
    //   })
    // }
  }
  isLocalOrOnline() {

  }

  calculateOrderAmount() {
    debugger
    for (var i in this.localStorage) {
      this.orderAmount = this.orderAmount + (this.localStorage[i].product.price * this.localStorage[i].quantity)
      this.orderAmount = this.orderAmount
    }
  }
  getLocalProduct() {
    debugger
    if (this.localStorage != null)
      for (var i in this.localStorage) {
        this.sum = 0
        let customized = this.localStorage[i].customize
        for (var j in customized) {
          if (customized[j]?.image_url) {
            this.sum = this.sum + 1
          }
          this.localStorage[i].count = this.sum
        }
      }
    // localStorage.setItem('cart', JSON.stringify(this.localStorage));
  }
  enableCheckout() {
    debugger
    this.customizedCount = 0
    for (var i in this.localStorage) {
      let totaldesignedItem = this.localStorage[i].quantity * this.localStorage[i].product.cookie_number
      if (this.localStorage[i].count == totaldesignedItem) {
        this.customizedCount = this.customizedCount + 1
      }
    }
    if (this.customizedCount == this.localStorage?.length) {
      this.showCheckoutButton = true
    }
  }
  getCheckoutForm() {
    this.checkoutForm = this.fb.group({
      preference: [''],
      date: [''],
      time: ['']
    })
  }
  selectedPrefernce(value) {

    this.preference_value = value
    if (this.preference_value == 'home_delivery') {
      this.showHomeDelivery = true;
      // this.delivery_charge = 15.00
      // this.toastr.success('Delivery charges will be calculated during checkout')
      }
    else {
      this.delivery_charge = 0.00
      this.showHomeDelivery = false;
    }
  }
  remove(item) {
    item = item;
    let shopping_cart;
    shopping_cart = this.localStorage
    // shopping_cart = this.online_cart_items
    for (let i in shopping_cart) {
      if (item.id == shopping_cart[i].id) {
        this.index = i;
        this.itemId = item.product.id
        this.deletedProduct = shopping_cart[i]
      }
    }
  }
  //deleting cart item
  deleteItem(index, id, deletedProduct) {
    debugger
    let shopping_cart;
    let item_total:any
    if(deletedProduct.product?.min_qty){
       item_total = deletedProduct.product.min_qty * deletedProduct.product.price
    }else{
       item_total = deletedProduct.min_qty * deletedProduct.product.price
    }
    
    let deletedItem = item_total
    shopping_cart = this.localStorage
    this.localStorage.splice(index, 1);
    let image = JSON.parse(localStorage.getItem('image'))
    if (image) {
      image.splice(index, 1);
    }
    localStorage.setItem('cart', JSON.stringify(this.localStorage));
    if (shopping_cart.length == 0) {
      this.cartLength = 0
    }
    this.toastr.success('Deleted product successfully')
    if (this.isLoggedIn) {
      this.api.deleteCart({ "id": deletedProduct.id }).subscribe(item => {
        if (item['status'] == true) {

          //this.getCartItems();
          // window.location.reload()
        }

      })
    } else {
      // this.orderAmount = this.orderAmount - deletedItem
      // this.getCartItems();
    }
    this.orderAmount = this.orderAmount - deletedItem
    // window.location.reload()
  }
  addMoreProducts() {
    this.router.navigate(['products'])
  }
  getQuantity(item) {
    return this.counter = item
  }
  increment(count, item) {
    for (var i in this.localStorage) {
      if (item.id == this.localStorage[i].id) {
        this.localStorage[i].quantity = this.localStorage[i].quantity + 1
      }
    }
    localStorage.setItem('cart', JSON.stringify(this.localStorage));
  }
  decrement(count, item) {
    for (var i in this.localStorage) {
      if (item.id == this.localStorage[i].id) {
        if (this.localStorage[i].quantity > this.localStorage[i].min_qty) {
          this.localStorage[i].quantity = this.localStorage[i].quantity - 1
        }
      }
    }
    localStorage.setItem('cart', JSON.stringify(this.localStorage));
  }
  designCookie(item) {
    debugger
    let cookieId = item.id
    this.router.navigate(['add-cookie/' + cookieId])
  }
  checkoutBtn() {
    this.enableCheckout();
    if (this.customizedCount != this.localStorage?.length) {
      this.modalService.open(this.pending, { centered: true, size: 'sm' });
    }
    else {
      this.checkoutDiv = true
    }
    this.cd.detectChanges();
    document.querySelector('#selectPreference').scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }
  checkout() {
    this.loader = true
    const queryParams: any = {};
    // this.submitted = true
    let data: any
    if (this.couponDetails) {
      data = {
        "preference": this.preference_value,
        "delivery_date": this.checkoutForm.controls.date.value,
        "delivery_time": this.checkoutForm.controls.time.value,
        "delivery_charge": this.delivery_charge.toFixed(2),
        "couponSK": this.couponDetails[0]?.SK,
        "savedAmount": this.savedAmount.toFixed(2),
        "couponCode": this.couponDetails[0]?.couponCode,
        "discountType": this.couponDetails[0]?.type
      }
    } else {
      data = {
        "preference": this.preference_value,
        "delivery_date": this.checkoutForm.controls.date.value,
        "delivery_time": this.checkoutForm.controls.time.value,
        "delivery_charge": this.delivery_charge.toFixed(2),

      }
    }

    queryParams.myArray = JSON.stringify(data)
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    if (this.isLoggedIn) {
      // this.cartCheckout()
      localStorage.removeItem('cart');
      localStorage.removeItem('file');
      // localStorage.setItem('cart', JSON.stringify(this.localStorage));
      this.router.navigate(['orders'], navigationExtras)
      // this.cartCheckout().subscribe(res => {
      //   this.router.navigate(['orders'], navigationExtras) 
      // })
      // this.cartCheckout(navigationExtras).then(resp => {
      //   console.log('print', resp)
      //   // this.router.navigate(['orders'], navigationExtras)
      // })
    }
    else {
      this.router.navigate(['login'])
    }
  }
  async cartCheckout() {
    let array = []
    let fileToSend = []
    let keyFile = {}
    let local_storage = this.localStorage
    for (var i in local_storage) {
      local_storage[i].box_color = local_storage[i].box_color
      local_storage[i].cookie_box = local_storage[i].product['cookie_number']
      local_storage[i].image = local_storage[i].product['image']
      local_storage[i].price = local_storage[i].product['price']
      local_storage[i].product_name = local_storage[i].product['name']
      local_storage[i].quantity = local_storage[i].quantity
      local_storage[i].item_total = local_storage[i].quantity * local_storage[i].price
      local_storage[i].box_img_url = local_storage[i].box_img_url
      if (local_storage[i].product['PK']) { local_storage[i].PK = local_storage[i].product['PK'] }
      if (local_storage[i].product['SK']) { local_storage[i].SK = local_storage[i].product['SK'] }
      delete local_storage[i].product_name
      delete local_storage[i].price
      delete local_storage[i].cookie_box
      delete local_storage[i].image
      delete local_storage[i].min_qty
      let customization = local_storage[i].customize
      // for (var j in customization) {
      //   if (customization[j]?.image) {
      //     keyFile[customization[j].image] = customization[j].image_url
      //     fileToSend.push(keyFile)
      //    // customization[j].image_url = environment.s3bucket + customization[j].image
      //     delete customization[j].image
      //     if (customization[j].image == null) {
      //       customization[j].tempId = null
      //     }
      //   }
      //   else {
      //     customization[j].image_url = customization[j].image_url
      //   }
      // }
      delete local_storage[i].product
      delete local_storage[i].count
      delete local_storage[i].id
      array.push(local_storage[i])
    }
    let converted = JSON.parse(localStorage.getItem('converted'))

    // let products = {
    //   "products": array
    // }

    // this.api.addCart(products, keyFile).subscribe(cart_item => {
    //   console.log('cart', cart_item)
    //   if (cart_item['status'] == true) {
    //     let message = cart_item['message']
    //     //this.toastr.success(message)
    //     localStorage.removeItem('cart');
    //     localStorage.removeItem('file');
    //     this.localStorage = []
    //     this.api.getCart().subscribe(item => {
    //       this.localStorage = item['data']
    //       this.loader = false
    //       localStorage.setItem('cart', JSON.stringify(this.localStorage));
    //       this.router.navigate(['orders'], params)
    //     })
    //     // this.ngOnInit();
    //   } else {
    //     this.loader = false
    //     this.toastr.error('Failed')
    //   }
    // })




  }
  async saveCart() {
    debugger
    this.loader = true
    let array = []
    let fileToSend = []
    let keyFile = {}
    let local_storage = JSON.parse(localStorage.getItem('cart'))
    for (var i in local_storage) {
      local_storage[i].box_color = local_storage[i].box_color
      local_storage[i].cookie_box = local_storage[i].product['cookie_number']
      local_storage[i].image = local_storage[i].product['image']
      local_storage[i].price = local_storage[i].product['price']
      local_storage[i].product_name = local_storage[i].product['name']
      local_storage[i].quantity = local_storage[i].quantity
      local_storage[i].item_total = local_storage[i].quantity * local_storage[i].price
      local_storage[i].box_img_url = local_storage[i].box_img_url
      if (local_storage[i].product['PK']) { local_storage[i].PK = local_storage[i].product['PK'] }
      if (local_storage[i].product['SK']) { local_storage[i].SK = local_storage[i].product['SK'] }
      delete local_storage[i].product_name
      delete local_storage[i].price
      delete local_storage[i].cookie_box
      delete local_storage[i].image
      delete local_storage[i].min_qty
      let customization = local_storage[i].customize
      // for (var j in customization) {
      //   if (customization[j]?.image) {
      //     keyFile[customization[j].image] = customization[j].image_url
      //     fileToSend.push(keyFile)
      //    // customization[j].image_url = environment.s3bucket + customization[j].image
      //     delete customization[j].image
      //     if (customization[j].image == null) {
      //       customization[j].tempId = null
      //     }
      //   }
      //   else {
      //     customization[j].image_url = customization[j].image_url
      //   }
      // }
      delete local_storage[i].product
      delete local_storage[i].count
      delete local_storage[i].id
      array.push(local_storage[i])
    }
    let converted = JSON.parse(localStorage.getItem('converted'))
    let products = {
      "products": array
    }
    if (this.isLoggedIn) {
      this.api.addCart(products, keyFile).subscribe(cart_item => {
        console.log('cart', cart_item)
        if (cart_item['status'] == true) {
          let message = cart_item['message']
          this.toastr.success(message)
          localStorage.removeItem('cart');
          localStorage.removeItem('file');
          this.localStorage = []
          this.api.getCart().subscribe(item => {
            this.localStorage = item['data']
            this.loader = false
            localStorage.setItem('cart', JSON.stringify(this.localStorage));
            window.location.reload()
          })
          // this.ngOnInit();
        } else {
          this.loader = false
          this.toastr.error('Failed')
        }
      })
      this.ngOnInit();
    }
    else {
      this.router.navigate(['login'])
    }
    return await this.localStorage
  }

}