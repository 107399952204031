// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/common.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent implements OnInit {
  faqArray;
  isLoggedIn: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,
    public api: CommonService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.api.loggedIn.subscribe(res => {
      this.isLoggedIn = res
    })
    this.getFaq();
  }

  getFaq() {
    //if(this.isLoggedIn){
    this.api.getFaq().subscribe(re => {
      debugger;
      this.faqArray = re['data']
      console.log(this.faqArray)
    })
    // }
  }
}
