import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/common.service';
import { TokenStorageService } from '../../common/token/token-storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.css']
})
export class ProfileSidebarComponent implements OnInit {

  constructor(
    private commonService: CommonService, private tokenStorage: TokenStorageService,
		private route: Router
  ) { }

  ngOnInit(): void {
  }
  logout() {
		this.commonService.loggedIn.next(false);
		this.tokenStorage.signOut()
    //for clearing local stoage data

		// this.route.navigate(["/login"])
	}

}
