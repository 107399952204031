<section class="bg-color">
    <div class="container p-50">
        <div class="row">
            <div class="col-md-12 card">
                <div class="row">
                    <div class="col-md-5">
                        <div>
                            <button type="button" class="btn m-3 back-btn-icon" [routerLink]="['/home']">
                                <span class="material-icons-outlined btn-snape-white back-icon">
                                    chevron_left
                                </span>
                                <span class="back-btn-span ml-2">Back</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="p-t-25">
                            <h3 class="heading">Privacy Policy</h3>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <div class="row p-30">
                    <div class="col-md-12">
                        <div>
                            <!-- <h5 class="font-size-15">Acceptance of Terms of Use
                            </h5> -->
                            <p class="secondary-font-color">SNAPE COOKIES TRADING and/or its affiliates ("Snape
                                Cookies," the "Company”," we," "us," and "our,") respect your privacy and is committed
                                to protecting it through its compliance with its privacy policies. This policy
                                describes:</p>
                            <ul class="secondary-font-color">
                                <li>The types of information that Snape Cookies may collect from you when you access or
                                    use its websites, applications, and other online services (collectively, referred to
                                    as "Services"); and</li>
                                <li>Its practices for collecting, using, maintaining, protecting, and disclosing that
                                    information.</li>
                            </ul>
                            <p class="secondary-font-color">This policy applies only to the information Snape Cookies
                                collects through its Services, in email, text, and other electronic communications sent
                                through or in connection with its Services.
                            </p>
                            <p class="secondary-font-color">Please read this policy carefully to understand the policies
                                of Snape Cookies and its practices regarding your information and how Snape Cookies will
                                treat it. By accessing or using its Services and/or registering for an account with
                                Snape Cookies, you agree to this privacy policy, and you are consenting to Snape
                                Cookies’ collection, use, disclosure, retention, and protection of your personal
                                information as described here. If you do not provide the information Snape Cookies
                                requires, Snape Cookies may not be able to provide all its Services to you.
                            </p>
                            <p class="secondary-font-color">If you reside in Qatar, Snape Cookies Trading, located at
                                Zone 25, Street 330, Building no. 57, Floor No.7, Office No. 4, Doha - 55963, Qatar will
                                be the controller of your personal data provided to or collected by or for, or processed
                                in connection with our Services.</p>
                            <p class="secondary-font-color">Your data controller is responsible for the collection, use,
                                disclosure, retention, and protection of your personal information in accordance with
                                its privacy standards as well as any applicable national laws. Your data controller may
                                transfer data to other members of Snape Cookies as described in this Privacy Policy.</p>
                            <p class="secondary-font-color">This policy may change from time to time, your continued use
                                of Snape Cookies’ Services after it makes any change is deemed to be acceptance of those
                                changes, so please check the policy periodically for updates.</p>
                            <h5 class="font-size-15"> The information we collect and how we use it </h5>
                            <p class="secondary-font-color">Snape Cookies Trading ("Snape Cookies," the
                                "Company,""we,""us," and "our”) collects several types of information from and about
                                users of our Services, including:
                            </p>
                            <ul class="secondary-font-color">
                                <li>Your Personal Identifiable Information("PII") - Personal Information is the
                                    information that can be associated with a specific person and could be used to
                                    identify that specific person whether from that data or from the data and other
                                    information that we have or is likely to have access. We do not consider personal
                                    information to include information that has been made anonymous or aggregated so
                                    that it can no longer be used to identify a specific person, whether in combination
                                    with other information or otherwise.</li>
                                <li>• Information about your internet connection, the equipment you use to access our
                                    Services, and your usage details.</li>
                            </ul>
                            <p class="secondary-font-color">We collect this information:</p>
                            <ul class="secondary-font-color">
                                <li>Directly from you when you provide it to us; and/or
                                    automatically as you navigate through our Services (information collected
                                    automatically may include usage details, IP addresses, and information collected
                                    through cookies, web beacons, and other tracking technologies).
                                </li>
                            </ul>
                            <h5 class="font-size-15">Information You Provide to Us</h5>
                            <p class="secondary-font-color">The information we collect on or through our Services may
                                include:</p>
                            <ul class="secondary-font-color">
                                <li>Your account information: Your full name, email address, postal code, password, and
                                    other information you may provide with your account, such as your mobile phone
                                    number and name. Your profile picture that will be publicly displayed as part of
                                    your account profile. You may optionally provide us with this information through
                                    third-party sign-in services such as Facebook and Google Plus. In such cases, we
                                    fetch and store whatever information is made available to us by you through these
                                    sign-in services.</li>
                                <li>Your preferences: Your preferences and settings such as time zone and language.</li>
                                <li>Your searches and other activities: The search terms you have looked up and the
                                    results you selected.</li>
                                <li>Your communications: Communications between you and merchants through our Services;
                                    your participation in a survey, poll, sweepstakes, contest, or promotion scheme;
                                    your request for certain features (e.g., newsletters, updates, or other products);
                                    your communication with us about employment opportunities posted to the services.
                                </li>
                                <li>Your transactional information: If you make reservations or purchases through our
                                    Services, we may collect and store information about you to process your requests
                                    and automatically complete forms for future transactions, including (but not limited
                                    to) your phone number, address, email, billing information and credit or payment
                                    card information. This information may be shared with third parties which assist in
                                    processing and fulfilling your requests, including PCI-compliant payment gateway
                                    processors. When you submit credit or payment card information, we encrypt the
                                    information using industry-standard technologies, as further described below under
                                    "Payment Card Information."</li>
                            </ul>
                            <p class="secondary-font-color"> We use the information you provide to us to enhance the
                                functionality and improve the quality of our Services, and to personalize your
                                experience while using our Services. We also use this information to display relevant
                                advertising, provide support to you, communicate with you, and comply with our legal
                                obligations.</p>
                            <p class="secondary-font-color">You have the option to request your friends to join the
                                Services by providing their contact information. If you request a friend to join and
                                connect with you on the Services, we will only use your friend's contact information to
                                process your request.</p>
                            <h5 class="font-size-15">Information about your friends</h5>

                            <p class="secondary-font-color">You have the option to request your friends to join the
                                Services by providing their contact information. If you request a friend to join and
                                connect with you on the Services, we will only use your friend's contact information to
                                process your request.</p>
                            <h5 class="font-size-15">Information about your messages</h5>
                            <p class="secondary-font-color">If you exchange messages with others through the Services,
                                we may store them to process and deliver them, allow you to manage them, and investigate
                                possible violations of our Terms of Service and wrongdoing in connection with the
                                Services.</p>
                            <h5 class="font-size-15">Your email address</h5>
                            <ul class="secondary-font-color">
                                <li>To register for an account, we require you to supply us with your email address, or
                                    other information needed to contact you online and over the phone. We use your
                                    e-mail address and other contact information you provide us with to confirm your
                                    order and to communicate with you in case of an order problem, so one cannot
                                    register without an e-mail address. If you supply us with your email address, you
                                    may later access it, update it, modify it, and delete any inaccuracies by accessing
                                    your account through the my account information link on the user main page. You may
                                    also choose simply not to provide us with your email address; however, you will not
                                    be able to register on the website and place orders. We also use this information to
                                    facilitate and improve your use of the website, to communicate with you, for
                                    internal purposes and to comply with any requirements of the law. This information
                                    may be disclosed to our staff and to third parties involved in the delivery of your
                                    order or the analysis and support of your use of the website. We do not sell and
                                    will not divulge your personal contact information to third parties other than as
                                    specified in this Privacy Policy without your permission unless we are legally
                                    entitled or obliged to do so (for example, if required to do so by Court order or
                                    for the purposes of prevention of fraud or other crime).</li>
                            </ul>
                            <h5 class="font-size-15">Order Placement Information</h5>

                            <p class="secondary-font-color">Personal information required during the order process
                                includes name, address, phone number, email address, and other similar information used
                                to identify you and completean order. Your personal information will be used to notify
                                you of your order status. Personal information will not be shared without your
                                permission. It is treated as confidential and will not be disclosed to outside parties
                                unless compelled by applicable legislation. We only send marketing emails to those
                                people who have specifically requested to receive this information.</p>
                            <h5 class="font-size-15">Do we disclose your personal information?</h5>

                            <p class="secondary-font-color">We may disclose your information to our staff and to third
                                parties involved in the delivery of your order, and the analysis and support of your use
                                of the website. We may disclose your information if necessary to protect our legal
                                rights if the information relates to actual or threatened harmful conduct, or we have
                                good faith belief that such action is necessary to (1) conform to the requirements of
                                law or comply with governmental orders, court orders, or legal process served on us or
                                (2) to protect and defend our property or other rights, the users of the website or the
                                public. This includes exchanging information with other companies and organizations for
                                fraud protection and credit risk protection.</p>
                            <h5 class="font-size-15">Security</h5>
                            <p class="secondary-font-color">We use reasonable organizational, technical, and
                                administrative measures to protect PII under our control. Unfortunately, no data
                                transmission over the Internet or data storage system can be guaranteed to be 100%
                                secure. Please do not send us sensitive information through email. If you have reason to
                                believe that your interaction with us is no longer secure (for example, if you feel that
                                the security of any account you might have with us has been compromised), you must
                                immediately notify us of the problem by contacting us in accordance with the "Contacting
                                Us" section below (note that if you choose to notify us via physical mail, this will
                                delay the time it takes for us to respond to the problem).</p>
                            <h5 class="font-size-15">Information about the services we offer</h5>
                            <p class="secondary-font-color">It is very important to us that we provide you with the
                                highest level of service. To help us do this, from time to time we may send you details
                                of products, services, discounts, promotions, and campaigns, which we think, may be of
                                interest to you. We may send you marketing communications with your consent. If at any
                                time you do not wish to receive these details and wish to unsubscribe from marketing
                                communications, contact us via e-mail or log onto your account to change your relevant
                                settings from my account link on the user main page.</p>
                            <h5 class="font-size-15">Reviewing, changing, or deleting information</h5>
                            <p class="secondary-font-color">If you would like to review, change, or delete the personal
                                information we have collected from you, or permanently delete your account, please use
                                the "Contact Us" link at the bottom of every page.</p>
                            <p class="secondary-font-color">If you delete your User Contributions from our websites,
                                copies of your User Contributions may remain viewable in cached and archived pages or
                                might have been copied or stored by other users of our websites. </p>
                            <h5 class="font-size-15">Accessing and correcting your personal information</h5>
                            <p class="secondary-font-color">We will take reasonable steps to accurately record the
                                personal information that you provide to us and any subsequent updates.</p>
                            <p class="secondary-font-color">We encourage you to review, update and correct the personal
                                information that we maintain about you, and you may request that we delete personal
                                information about you that is inaccurate, incomplete, or irrelevant for legitimate
                                purposes, or is being processed in a way that infringes any applicable legal
                                requirement.</p>
                            <p class="secondary-font-color">Your right to review, update, correct, and delete your
                                personal information may be limited, and subject to the law of your jurisdiction:</p>
                            <ul class="secondary-font-color">
                                <li>If your requests are abusive or unreasonably excessive,
                                    Where the rights or safety of another person or persons would be encroached upon, or
                                </li>
                                <li>If the information or material you request relates to existing or anticipated legal
                                    proceedings between you and us, or providing access to you would prejudice
                                    negotiations between us or an investigation of possible unlawful activity. Your
                                    right to review, update, correct, and delete your information is subject to our
                                    records retention policies and applicable law, including any statutory retention
                                    requirements.</li>
                            </ul>
                            <h5 class="font-size-15">Data retention or account termination</h5>
                            <p class="secondary-font-color">You can close your account by visiting your profile settings
                                page on our website and application. We will remove your public posts from view and/or
                                dissociate them from your account profile, but we may retain information about you for
                                the purposes authorized under this Privacy Policy unless prohibited by law. Thereafter,
                                we will either delete your personal information or de-identify it so that it is
                                anonymous and not attributed to your identity. For example, we may retain information to
                                prevent, investigate, or identify possible wrongdoing in connection with the Service or
                                to comply with legal obligations.</p>
                            <h5 class="font-size-15">Privacy Policy amendment</h5>
                            <p class="secondary-font-color">Snape Cookies may change this Privacy Policy at any time by
                                publishing the new version on the website.</p>
                            <h5 class="font-size-15">Contact</h5>
                            <p class="secondary-font-color">All comments, queries, and requests relating to our use of
                                your information are welcomed and should be addressed to Snape Cookies Trading.</p>
                            <p class="secondary-font-color">For questions or concerns relating to our privacy policy, we
                                can be contacted by email at </p>
                            <p class="secondary-font-color">support@snapecookies.com or at:</p>
                            <p class="secondary-font-color">P.O Box 55963, Doha, Qatar</p>
                            <p class="secondary-font-color">Phone: (+974) 7009 9709</p>
                            <p class="secondary-font-color">Calls to and from Snape Cookies may be recorded for quality
                                and training purposes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>