<div class="container">
    <div class="row mt-3">
        <div class="col-md-12">
            <div>
                <button type="button" class="btn m-3 back-btn-icon" routerLink="/home"
                    routerLinkActive="router-link-active">
                    <span class="material-icons-outlined btn-snape-white back-icon">
                        chevron_left
                    </span>
                    <span class="back-btn-span">Back to home</span>
                </button>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-6">
            <div class="login-image">
                <img src="../../../assets/loginimg.png">
            </div>
        </div>
        <div class="col-md-6 mail-id-login my-auto" *ngIf="!numberLogin">
            <div class="logintxt">
                <div class="auth-ui">
                    <div class="text-center ml-3 mob-m-t-20">
                        <h1>Login</h1>
                        <p class="mt-3">Please login to continue</p>
                    </div>
                    <div class="" [formGroup]="loginForm" *ngIf="!emailLogin && !numberLogin">
                        <div class="position-relative form-group pt-2">
                            <div class="">
                                <input id="inputEmail" formControlName="email" placeholder="Email or phone number with country code"
                                    required="" type="text" class="form-control">
                                <span class="text-danger"
                                    *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
                                    Email or phone number is required
                                </span>
                                <span class="text-danger"
                                    *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.pattern">
                                    Enter a valid email or phone number
                                </span>
                            </div>
                        </div>
                        <div class="position-relative form-group">
                            <div class="mt-3">
                                <button type="submit" [disabled]="loginForm.controls.email.invalid" (click)="Continue()"
                                    class="btn btn-snape-bak btn-block">Continue</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="emailLogin" [formGroup]="loginForm">
                        <div>
                            <h3 class="mt-3">{{loginForm.controls.email.value}} <span (click)="change()">Change</span>
                            </h3>
                        </div>
                        <div class="position-relative form-group mt-3">
                            <div class="">
                                <div class="input-group">
                                    <input id="inputPass" formControlName="password" placeholder="Password" required=""
                                        [type]="password" class="form-control">
                                    <div class="input-group-append">
                                        <button (click)="showPasswordOnClick()">
                                            <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                                            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
                                        </button>
                                    </div>
                                </div>
                                <span class="text-danger"
                                    *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
                                    Password is required
                                </span>
                            </div>
                        </div>
                        <div class="position-relative form-group">
                            <div class="mt-3">
                                <button type="submit" [disabled]="loginForm.controls.password.invalid" (click)="login()"
                                    class="btn btn-snape-bak btn-block">Login</button>
                            </div>
                        </div>
                    </div>
                    <div class="position-relative form-group">
                        <div class="d-flex flex-wrap justify-content-end">
                            <div class="">
                                <div class="text-right">
                                    <a class="forgetpass" routerLink="/forgotpassword">Forgot password?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <p>or use an existing account</p>
                        <!-- hide fb,google icon -->
                        <!-- <a class="p-2" (click)="redirectToUrl()">
                            <img src="../assets/google.svg" />
                        </a>
                        <a class="p-2" (click)="redirectToUrl()">
                            <img src="../assets/fb.svg" />
                        </a> -->
                        <!-- hide fb,google icon end-->
                    </div>
                    <div class="text-center mt-5">
                        <p>Don't have an account? <a routerLink="/signup" class="snape-red">Sign up</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 phone-num-login my-auto" [formGroup]="loginForm" *ngIf="numberLogin">
            <div class="logintxt">
                <div class="auth-ui">
                    <h2>Authentication required</h2>
                    <h3 class="mt-3">{{loginForm.controls.email.value}} <span (click)="change()">Change</span></h3>
                    <p class="mt-3">We’ve sent a One Time Password (OTP) to the mobile number above. Please enter it to
                        complete
                        verification</p>
                    <div class="position-relative form-group mt-2">
                        <div class="">
                            <input placeholder="Enter OTP" formControlName="otp" type="password" class="form-control">
                        </div>
                    </div>
                    <div class="position-relative form-group">
                        <div class="mt-4">
                            <button type="submit" (click)="verifyOtp()"
                                class="btn btn-snape-bak btn-block">Verify</button>
                        </div>
                    </div>
                    <div class="text-center resend-otp">
                        <p>Did n’t receive the code? <span (click)="resendOtp()">Resend OTP</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h6 class="modal-title" id="deleModalLabel">Confirm Product?</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="row d-flex justify-content-center mb-4">
                    Do you want to continue with Online Item?
                </div>
                <div class="row form-group mt-4">
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <button type="button" class="btn-snape-redOut cursor-pointer form-control" data-dismiss="modal"
                            (click)="modal.close()">No</button>
                    </div>
                    <div class="col-md-6">
                        <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control"
                            data-dismiss="modal" (click)="modal.close(true)" type="button">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #account let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h6 class="modal-title" id="deleModalLabel">Activate account?</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="row d-flex justify-content-center mb-4">
                    Do you like to activate account?
                </div>
                <div class="row form-group mt-4">
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <button type="button" class="btn-snape-red cursor-pointer form-control" data-dismiss="modal"
                            (click)="modal.close()">No</button>
                    </div>
                    <div class="col-md-6">
                        <button data-dismiss="modal" class="btn-snape-red cursor-pointer form-control"
                            data-dismiss="modal" (click)="modal.close(true)" type="button">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>