<div *ngIf="loader == true">
  <app-spinner></app-spinner>
</div>
<section class="order-details-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-4 m-r-minus20">
        <app-profile-sidebar></app-profile-sidebar>
      </div>
      <div class="col-lg-10 col-md-8 p-4 mt-2 pro-Bordr">
        <div class="card mb-3 height550 m-r-minus30">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="order-details-heading">
                  <h1>Order Details</h1>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mt-4">
                <div class="vendor-list-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item cursor-pointer" routerLink="/home"
                        routerLinkActive="router-link-active">
                        <a> <i class="fas fa-home"></i> Home</a>
                      </li>
                      <li class="breadcrumb-item cursor-pointer" routerLink="/my-orders"
                        routerLinkActive="router-link-active">
                        <a> My Order</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">Order id : {{orderDetails?.order_id}}</li>
                    </ol>
                  </nav>
                </div>
              </div>
              <!-- </div>
            <div class="row"> -->

            </div>
            <!-- order details -->
            <div class="row">
              <div class="col-md-12 col-xs-12 col-lg-12">
                <div *ngFor="let products of productList">
                  <!-- cookie box 1 -->
                  <div class="row" *ngIf="products.product.cookie_number == 1">
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row m-b-50">
                        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                          <div class="cookie-box-one text-align">
                            <img src="{{products.box_img_url}}">
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                          <div class="m-t-15 justify-content">
                            <p class="cookie-box-caption">1 COOKIE BOX
                              <span *ngIf="products?.message_status == 'unread'" class="unread-msg">
                                <span class="envelope-mail cursor-pointer">
                                  <i class="far fa-envelope"></i>
                                </span>
                                Unread message
                              </span>
                            </p>
                            <p class="line-height">Cookie Qty : {{products.quantity}} </p>
                            <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}} </span>
                            <!-- <p class="line-height">Price : <span class="pink-color">QAR {{products[0].order_total | number :
                                '1.2-2'}}
                              </span>
                            </p> -->
                            <p class="line-height">Price : <span class="pink-color">QAR {{products.product.price *
                              products.quantity | number :
                              '1.2-2'}}
                            </span>
                          </p>
                            <p class="pink-color m-l-20 line-height">
                            </p>
                            <!-- <p class="line-height">Double chocolate : 5 Red velvet : 5</p>
                            <p class="line-height">Chocolate Chip : 2</p> -->
                          </div>
                          <div>
                            <button *ngIf="status != 'Order Cancelled'" type="button" class="btn btn-royal-pink"
                              (click)="message(products)">Message</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row">
                        <div class="col-md-2 col-lg-2 col-sm-6 col-xs-12"
                          *ngFor="let item of products.customize; let i=index">
                          <div class="d-flex align-items-center justify-content-center snape-bgColor1 ckieAdd">
                            <div *ngIf="products.customize[i]?.image_url" class="ckieAdd-bg-image-one-cookie"
                              [ngStyle]="{'background-image':' url('+ products.customize[i]?.flavor?.image +')'}">
                              <div class="ckieAdd-bg-color-one-cookie tooltip-top">
                                <img src="{{products.customize[i]?.image_url}}" (click)="boxNumber(i, item, products)"
                                  class="cursor-pointer">
                                <span class="tooltip">{{item.flavor.name}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- cookie box 2 -->
                <div *ngFor="let products of productList">
                  <div class="row" *ngIf="products.product.cookie_number == 2">
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row m-b-50">
                        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                          <div class="cookie-box-one text-align">
                            <img src="{{products.box_img_url}}">
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                          <div class="m-t-15 justify-content">
                            <p class="cookie-box-caption">2 COOKIE BOX
                              <span *ngIf="products?.message_status == 'unread'" class="unread-msg">
                                <span class="envelope-mail cursor-pointer">
                                  <i class="far fa-envelope"></i>
                                </span>
                                Unread message
                              </span>
                            </p>
                            <p class="line-height">Cookie Qty : {{products.quantity}}</p>
                            <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}}</span>
                            </p>
                            <p class="line-height">Price : <span class="pink-color">QAR {{products.product.price *
                                products.quantity | number :
                                '1.2-2'}}
                              </span>
                            </p>
                            <p class="pink-color m-l-20 line-height">
                              <!-- <input class="form-check-input m-t-minus-1" type="checkbox" value="" id="flexCheckDefault"
                        [checked]="products.is_gift">
                      <label class="form-check-label" for="flexCheckDefault">
                        This order contains a gift
                      </label> -->
                            </p>
                            <!-- <p class="line-height">Double chocolate : 5 Red velvet : 5</p>
                            <p class="line-height">Chocolate Chip : 2</p> -->
                          </div>
                          <div>
                            <button type="button" class="btn btn-royal-pink" *ngIf="status != 'Order Cancelled'"
                              (click)="message(products)">Message</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12"
                          *ngFor="let item of counter(products.customize.length  / 2); let i=index;">
                          <div class="d-flex align-items-center justify-content-center snape-bgColor2 ckieAdd2 m-b-10">
                            <span *ngFor="let num of counter(2);let j=index;">
                              <div *ngIf="products.customize[i*2+j]?.image_url" class="ckieAdd-bg-image-testing"
                                [ngStyle]="{'background-image':' url('+ products.customize[i*2+j]?.flavor?.image +')'}">
                                <div class="ckieAdd-bg-color-testing tooltip-top">
                                  <img src="{{products.customize[i*2+j]?.image_url}}"
                                    (click)="boxNumber(i*2+j, products.customize[i*2+j], products)"
                                    class="cursor-pointer">
                                  <span class="tooltip">{{products.customize[i*2+j]?.flavor.name}}</span>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- cookie box 3 -->
                <div *ngFor="let products of productList">
                  <div class="row" *ngIf="products.product.cookie_number == 3">
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row m-b-50">
                        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                          <div class="cookie-box-one text-align">
                            <img src="{{products.box_img_url}}">
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                          <div class="m-t-15 justify-content">
                            <p class="cookie-box-caption">3 COOKIE BOX
                              <span *ngIf="products?.message_status == 'unread'" class="unread-msg">
                                <span class="envelope-mail cursor-pointer">
                                  <i class="far fa-envelope"></i>
                                </span>
                                Unread message
                              </span>
                            </p>
                            <p class="line-height">Cookie Qty : {{products.quantity}}</p>
                            <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}}</span>
                            </p>
                            <p class="line-height">Price : <span class="pink-color">QAR {{products.product.price *
                                products.quantity | number :
                                '1.2-2'}}
                              </span>
                            </p>
                            <p class="pink-color m-l-20 line-height">
                              <!-- <input class="form-check-input m-t-minus-1" type="checkbox" value="" id="flexCheckDefault"
                        [checked]="products.is_gift">
                      <label class="form-check-label" for="flexCheckDefault">
                        This order contains a gift
                      </label> -->
                            </p>
                            <!-- <p class="line-height">Double chocolate : 5 Red velvet : 5</p>
                            <p class="line-height">Chocolate Chip : 2</p> -->
                          </div>
                          <div>
                            <button type="button" class="btn btn-royal-pink" *ngIf="status != 'Order Cancelled'"
                              (click)="message(products)">Message</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row">
                        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12"
                          *ngFor="let item of counter(products.customize.length  / 3); let i=index;">
                          <div
                            class="d-flex align-items-center justify-content-center snape-bgColor4 ckieAdd3 m-b-10 flex">
                            <div class="d-flex m-t-5">
                              <span *ngFor="let num of counter(3);let j=index;">
                                <div *ngIf="products.customize[3*((i+1)-1)+((j+1)-1)]?.image_url"
                                  class="ckieAdd-bg-image-testing"
                                  [ngStyle]="{'background-image':' url('+ products.customize[3*((i+1)-1)+((j+1)-1)]?.flavor?.image +')'}">
                                  <div class="ckieAdd-bg-color-three-cookie tooltip-top">
                                    <img src="{{products.customize[3*((i+1)-1)+((j+1)-1)]?.image_url}}"
                                      (click)="boxNumber(3*((i+1)-1)+((j+1)-1), products.customize[3*((i+1)-1)+((j+1)-1)], products)"
                                      class="cursor-pointer">
                                    <span
                                      class="tooltip">{{products.customize[3*((i+1)-1)+((j+1)-1)]?.flavor.name}}</span>

                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- cookie box 6 -->
                <div *ngFor="let products of productList">
                  <div class="row" *ngIf="products.product.cookie_number == 6">
                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row m-b-50">
                        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                          <div class="cookie-box-one text-align">
                            <img src="{{products.box_img_url}}">
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-8 col-sm-6 col-xs-12">
                          <div class="m-t-15 justify-content">
                            <p class="cookie-box-caption">6 COOKIE BOX
                              <span *ngIf="products?.message_status == 'unread'" class="unread-msg">
                                <span class="envelope-mail cursor-pointer">
                                  <i class="far fa-envelope"></i>
                                </span>
                                Unread message
                              </span>
                            </p>
                            <p class="line-height">Cookie Qty : {{products.quantity}}</p>
                            <p class="line-height">Box Color : <span class="pink-color">{{products.box_color}}</span>
                            </p>
                            <p class="line-height">Price : <span class="pink-color">QAR {{products.product.price *
                                products.quantity | number :
                                '1.2-2'}}
                              </span>
                            </p>
                            <p class="pink-color m-l-20 line-height">
                              <!-- <input class="form-check-input m-t-minus-1" type="checkbox" value="" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault" [checked]="products.is_gift">
                        This order contains a gift
                      </label> -->
                            </p>
                            <!-- <p class="line-height">Double chocolate : 5 Red velvet : 5</p>
                            <p class="line-height">Chocolate Chip : 2</p> -->
                          </div>
                          <div>
                            <button type="button" class="btn btn-royal-pink" *ngIf="status != 'Order Cancelled'"
                              (click)="message(products)">Message</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-6 col-lg-6 col-xs-12">
              <div class="row">
                <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12"
                  *ngFor="let item of counter(products.customize.length  / 6); let i=index;">
                  <div class="align-items-center justify-content-center snape-bgColor6 six-ckieAdd m-b-10 flex">
                    <div class="d-flex mob-p-t-38">
                      <div *ngFor="let num of counter(6);let j=index;">
                        <div class="ckieAdd-bg-color-6cookie">
                          <img src="{{products.customize[6*((i+1)-1)+((j+1)-1)]?.image_url}}"
                            (click)="boxNumber(6*((i+1)-1)+((j+1)-1), products.customize[6*((i+1)-1)+((j+1)-1)], products)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

                    <div class="col-md-6 col-lg-6 col-xs-12">
                      <div class="row">
                        <div class="col-md-4"
                          *ngFor="let item of counter(products.customize.length  / 6); let i=index;">
                          <div class="row snape-bgColor6 six-ckieAdd">
                            <div class="col-md-4 p-l-r" *ngFor="let num of counter(6);let j=index;">
                              <div *ngIf="products.customize[6*((i+1)-1)+((j+1)-1)]?.image_url"
                                class="ckieAdd-bg-image-6cookie"
                                [ngStyle]="{'background-image':' url('+ products.customize[6*((i+1)-1)+((j+1)-1)]?.flavor?.image +')'}">
                                <!-- <div *ngIf="cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.image_url" class="ckieAdd-bg-image-6cookie" [ngStyle]="{'background-image':' url('+ cookieBoxArray[6*((i+1)-1)+((j+1)-1)]?.flavor?.image +')'}"> -->
                                <div class="ckieAdd-bg-color-6cookie tooltip-top">
                                  <img class="cursor-pointer"
                                    src="{{products.customize[6*((i+1)-1)+((j+1)-1)]?.image_url}}"
                                    (click)="boxNumber(6*((i+1)-1)+((j+1)-1), products.customize[6*((i+1)-1)+((j+1)-1)], products)">
                                  <span
                                    class="tooltip">{{products.customize[6*((i+1)-1)+((j+1)-1)]?.flavor.name}}</span>
                                </div>
                              </div>
                              <!-- </div>  -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>