<div class="col-md-12">
    <div class="row bg-about">
        <p class="a-title1">About us</p>
        <p class="a-title2">Baking with passion</p>
    </div>
</div>
<div class="container p-50">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6 p-4">
                <p class="fw600 fsize32">We are always happy to create your beautiful moments.</p>
                <p class="justify-text secondary-font-color">
                    SnapE Cookies is the perfect platform to capture and make our customers special moments even more
                    memorable whether it is selfie photo with friends or loved ones or even best memory photos featured
                    in a fun and delicious way.
                </p>
            </div>
            <div class="col-md-6 p-4">
                <img width="100%" src="../../../assets/img/abt1.svg" />
                <p class="fw600 m-t-20">
                    SnapE Cookies make any event more memorable. Make your photos emerge on delicious cookies.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 p-4">
                <img width="100%" src="../../../assets/img/whocookie.svg" />
            </div>
            <div class="col-md-6 p-4">
                <p class="text-uppercase fw600 snape-red">Who are we</p>
                <p class="fw600 fsize32">Hello, we are <span class="snape-red">SnapE Cookies!</span> </p>
                <p class="justify-text secondary-font-color">
                    Our platform captures special moments and transforms them into unique and highly memorable
                    experiences in a fun and delicious way. Whether it is a selfie photo with friends or a picture of
                    loved ones you want to feature, SnapE Cookies provides you with free creative reign and a
                    personalised service.
                </p>
                <p class="justify-text secondary-font-color">
                    Our cookies are also the ideal customizable branding tool for companies, retail shops and any large
                    event. Simply upload your logo or marketing message and impress your customers with our entertaining
                    and unforgettable experience.
                </p>
                <p class="justify-text secondary-font-color">
                    The SnapE Cookies team consists of experienced bakeries and IT specialists who strive to maximise
                    customer experience and satisfaction.
                </p>
            </div>
        </div>
    </div>
</div>